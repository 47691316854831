import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Accordion, AccordionTabCloseEvent } from 'primeng/accordion';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { firstValueFrom } from 'rxjs';
import Swal from 'sweetalert2';
import { UdlControlledVocabulary } from 'src/app/shared/models/udl-controlled-vocabulary.model';
import { EsaUdlOverlapToolService } from 'src/app/shared/services/esa-udl-overlap-tool.service';
import { UdlOverlapToolContainerService } from 'src/app/shared/services/udl-overlap-tool-container.service';
import { EsaInputs } from 'src/app/shared/models/esa-inputs';
import { Project } from 'src/app/shared/models/project';
import { ProjectXEpaXModelInputsDTO } from 'src/app/shared/models/project-x-epa-x-model-inputs-dto';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { EPAProjectInputsService } from '../esa-setup-inputs/esa-setup-inputs.api.service';

@Component({
  selector: 'app-esa-setup-models-to-be-run',
  templateUrl: './esa-setup-models-to-be-run.component.html',
  styleUrls: ['./esa-setup-models-to-be-run.component.css']
})

export class ESASetupModelsToBeRunComponent {
  @ViewChild('accordion') accordion!: Accordion;

  @Input() selectedProject?: Project;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() projectPk: number = 0;
  @Input() modelsLoading: boolean = false;

  @Output() areSetupModelsValidEvent = new EventEmitter<boolean>();

  model: UdlControlledVocabulary = {} as UdlControlledVocabulary;
  modelVersion: number = 0;

  activeIndex: number | null = null;
  isResettingModelInputs: boolean = false;

  modelHeader: string = "EPA UDL Overlap";
  chemicalName: string = '';
  isChemicalNameValid: boolean = false;

  selectedSpeciesHabitat?: UdlControlledVocabulary;
  speciesHabitatOptions: UdlControlledVocabulary[] = [];
  isSpeciesHabitatValid: boolean = false;

  includeDrift: string = '';
  isIncludeDriftValid: boolean = true;

  regionsToIncludePlaceholder: string = 'Select Regions to Include';
  regionsToIncludeOptions: UdlControlledVocabulary[] = [];
  selectedRegionsToInclude?: UdlControlledVocabulary[] = [];
  isRegionsToIncludeValid: boolean = false;
  selectAllRegions: boolean = false;

  statesToIncludeLabel: string = 'States to Include*';
  statesToIncludePlaceholder: string = 'Select States to Include';
  statesToIncludeOptions: UdlControlledVocabulary[] = [];
  selectedStatesToInclude?: UdlControlledVocabulary[] = [];
  isStatesToIncludeValid: boolean = false;
  isStatesToIncludeRequired: boolean = true;
  selectAllStates: boolean = false;

  bufferDistancesPlaceholder: string = 'Select Buffer Distances';
  bufferDistancesOptions: UdlControlledVocabulary[] = [];
  bufferDistancesOptionsWithSettings: UdlControlledVocabulary[] = [];
  selectedBufferDistances: UdlControlledVocabulary[] = [];
  isBufferDistancesValid: boolean = false;
  selectAllBufferDistances: boolean = false;
  bufferDistancesDeselectableList: UdlControlledVocabulary[] = [];
  bufferDistancesPreSelectedList: UdlControlledVocabulary[] = [];

  onOffFieldConsiderationsPlaceholder: string = 'Select On/Off Field Considerations';
  onOffFieldConsiderationsOptions: UdlControlledVocabulary[] = [];
  selectedOnOffFieldConsiderations?: UdlControlledVocabulary[] = [];
  isOnOffFieldConsiderationsValid: boolean = false;
  selectAllOnOffFieldConsiderations: boolean = false;

  adjustForPCT: string = '';
  isAdjustForPCTValid: boolean = true;

  bufferDistanceInSummaryReportOptions: UdlControlledVocabulary[] = [];
  selectedBufferDistanceInSummaryReport?: UdlControlledVocabulary;
  isBufferDistanceInSummaryReportValid: boolean = false;

  isPCT: boolean = false;
  isPCTValuesFileValid: boolean = false;
  isCropUseFileValid: boolean = false;

  isOnAndOffConsiderationsFileSelected: boolean = false;
  isOnAndOffConsiderationsFileValid: boolean = false;
  isObligateAndIndirectRelationshipFileSelected: boolean = false;
  isObligateAndIndirectRelationshipFileValid: boolean = false;

  @ViewChild('cropUseFileSelected') cropUseFileInput!: ElementRef;
  @ViewChild('pctValuesFileSelected') pctValuesFileInput!: ElementRef;
  @ViewChild('onAndOffConsiderationsFileSelected') onAndOffConsiderationsFileInput!: ElementRef;
  @ViewChild('obligateAndIndirectRelationshipFileSelected') obligateAndIndirectRelationshipFileInput!: ElementRef;

  uploadedCropFilename: string = '';
  uploadedPCTFilename: string = '';
  uploadedOnOffConsiderationsFilename: string = '';
  uploadedObligateAndIndirectRelationshipFilename: string = '';

  markedForDeletionFiles: string[] = [];

  constructor(private esaUdlOverlapToolService: EsaUdlOverlapToolService,
    public udlOverlapToolContainerService: UdlOverlapToolContainerService,
    private epaProjectInputsService: EPAProjectInputsService,
    private selectedProjectApiService: SelectedProjectApiService) {
      this.initalizeModel();
    }

  private async initalizeModel() {
    var model = (await this.getCVItemsByKey("Model"))[0];
    this.modelVersion = Number(model.metadata ? JSON.parse(model.metadata).version : 0)
    this.model = model;
  }

  async ngOnInit(): Promise<void> {
    await this.getControlledVocabularyItems();
    this.initializeDefaultModelInputs();

    this.areSetupModelsValidEvent.subscribe(async (valid: boolean) => {
      if (valid) {
          await this.setModelInputs();
          await this.setMarkedForDeletionFiles();
        }
    });

    this.setSavedInputsOnSelectedProject();
  }

  onClose(event: AccordionTabCloseEvent) {
    this.activeIndex = null;
  }

  onOpen({ index }: AccordionTabCloseEvent) {
    this.activeIndex = index;
  }

  private async initializeDefaultModelInputs() {
    this.includeDrift = 'Yes';
    this.isIncludeDriftValid = true;
    this.adjustForPCT = 'No';
    this.isAdjustForPCTValid = true;
    this.isPCT = false;

    this.initializeControlsUdl();
  }

  private async resetDefaultModelInputs() {
    this.isResettingModelInputs = true;

    this.initializeDefaultModelInputs();
    this.chemicalName = '';
    this.isChemicalNameValid = false;
    this.selectedOnOffFieldConsiderations = [];
    this.isOnOffFieldConsiderationsValid = false;
    this.selectAllRegions = false;
    this.selectAllBufferDistances = false;
    this.selectAllOnOffFieldConsiderations = false;
    this.isBufferDistanceInSummaryReportValid = false;
    this.selectedBufferDistanceInSummaryReport = undefined;
    this.cropUseFileInput.nativeElement.value = null;
    this.isCropUseFileValid = false;
    this.pctValuesFileInput.nativeElement.value = null;
    this.isPCTValuesFileValid = false;
    this.onAndOffConsiderationsFileInput.nativeElement.value = null;
    this.isOnAndOffConsiderationsFileSelected = false;
    this.isOnAndOffConsiderationsFileValid = false;
    this.obligateAndIndirectRelationshipFileInput.nativeElement.value = null;
    this.isObligateAndIndirectRelationshipFileSelected = false;
    this.isObligateAndIndirectRelationshipFileValid = false;

    this.uploadedCropFilename = '';
    this.uploadedPCTFilename = '';
    this.uploadedOnOffConsiderationsFilename = '';
    this.uploadedObligateAndIndirectRelationshipFilename = '';

    this.isResettingModelInputs = false;
  }

  private async getControlledVocabularyItems() {
    this.speciesHabitatOptions = await this.getCVItemsByKey("SpeciesHabitat");
    this.regionsToIncludeOptions = await this.getCVItemsByKey("Regions");
    this.statesToIncludeOptions = await this.getCVItemsByKey("States");
    this.onOffFieldConsiderationsOptions = await this.getCVItemsByKey("OnOffFieldConsiderations");
    this.bufferDistancesOptions = await this.getCVItemsByKey("BufferDistances");
  }

  private initializeControlsUdl() {
    this.selectedSpeciesHabitat = this.speciesHabitatOptions?.find(x => x.long_value?.toLowerCase() == "range");
    this.isSpeciesHabitatValid = this.selectedSpeciesHabitat != null;

    this.selectedRegionsToInclude = this.regionsToIncludeOptions.filter(x => x.short_value?.toLowerCase() == "conus");
    this.isRegionsToIncludeValid = this.selectedRegionsToInclude?.length != 0;

    this.setupStatesToInclude();

    if (!this.isResettingModelInputs) {
      this.setBufferDistancesAttributes();
    }
    this.selectedBufferDistances = this.bufferDistancesPreSelectedList;
    this.isBufferDistancesValid = this.selectedBufferDistances?.length != 0;

    this.setBufferDistanceInSummaryReportOptions();
  }

  private async getCVItemsByKey(key: string): Promise<UdlControlledVocabulary[]> {
    let resp: UdlControlledVocabulary[] = await firstValueFrom(this.esaUdlOverlapToolService.getControlledVocabularyItemsByKey(key));
    return resp;
  }

  getOptionsByModel(modelHeader: string, controlledVocabulary: UdlControlledVocabulary[]) {
    const optionsForModel: UdlControlledVocabulary[] = [];

    controlledVocabulary.forEach(CVItem => {
      if (CVItem.metadata) {
        const jsonObject = JSON.parse(CVItem.metadata);
        if (jsonObject.versions && Array.isArray(jsonObject.versions)) {
          if (jsonObject.versions.includes(modelHeader)) {
            optionsForModel.push(CVItem);
          }
        }
      }
    });

    return optionsForModel;
  }

  setBufferDistancesAttributes() {
    this.bufferDistancesOptionsWithSettings = this.bufferDistancesOptions.map(item => {
      if (item.metadata) {
        const jsonObject = JSON.parse(item.metadata);
        if (jsonObject.bufferDistancesAttributes) {
          if (jsonObject.bufferDistancesAttributes.isDeselectable) {
            item.disabled = false;
          }
          else {
            item.disabled = true; // Business requirement: Zero will be disabled to deselect.
          }
          if (jsonObject.bufferDistancesAttributes.isPreSelected) {
            this.bufferDistancesPreSelectedList.push(item)
          }
        }
      }
      return item;
    });
  }

  async chemicalNameChanged(value: string) {
    this.isChemicalNameValid = value.length > 0;
    this.chemicalName = value;

    this.validateModelInputs();
  }

  speciesHabitatSelectionChanged({ value }: DropdownChangeEvent) {
    this.isSpeciesHabitatValid = value != null;
    this.selectedSpeciesHabitat = value;

    this.validateModelInputs();
  }

  includeDriftSelectionChanged({ value }: DropdownChangeEvent) {
    this.isIncludeDriftValid = value != null;
    this.includeDrift = value;

    this.validateModelInputs();
  }

  regionsToIncludeSelectionChanged(event: any) {
    this.selectedRegionsToInclude = event.value;
    this.isRegionsToIncludeValid = this.selectedRegionsToInclude?.length != 0;
    this.selectAllRegions = this.selectedRegionsToInclude?.length == this.regionsToIncludeOptions.length;
    this.setupStatesToInclude();

    this.validateModelInputs();
  }

  regionsToIncludeSelectAllChanged(event: any) {
    this.selectAllRegions = event.checked;
    this.selectedRegionsToInclude = this.selectAllRegions ? this.regionsToIncludeOptions : [];
    this.isRegionsToIncludeValid = this.selectedRegionsToInclude?.length != 0;
    this.setupStatesToInclude();

    this.validateModelInputs();
  }

  statesToIncludeSelectionChanged(event: any) {
    this.selectedStatesToInclude = event.value;
    this.isStatesToIncludeValid = this.selectedStatesToInclude?.length != 0;
    this.selectAllStates = this.selectedStatesToInclude?.length == this.statesToIncludeOptions.length;

    this.validateModelInputs();
  }

  statesToIncludeSelectAllChanged(event: any) {
    this.selectAllStates= event.checked;
    this.selectedStatesToInclude = this.selectAllStates ? this.statesToIncludeOptions : [];
    this.isStatesToIncludeValid = this.selectedStatesToInclude?.length != 0;

    this.validateModelInputs();
  }

  bufferDistancesChanged(value: UdlControlledVocabulary[]) {
    this.selectedBufferDistances = value;
    this.isBufferDistancesValid = this.selectedBufferDistances?.length != 0;
    this.selectAllBufferDistances = this.selectedBufferDistances?.length == this.bufferDistancesOptionsWithSettings.length;

    this.setBufferDistanceInSummaryReportOptions();
    this.validateModelInputs();
  }

  bufferDistancesSelectAllChanged(event: any) {
    this.selectAllBufferDistances= event.checked;
    this.selectedBufferDistances = this.selectAllBufferDistances ? this.bufferDistancesOptionsWithSettings : this.bufferDistancesOptionsWithSettings.filter(x =>x.disabled); // Business requirement: Zero will be disabled to deselect.
    this.isBufferDistancesValid = this.selectedBufferDistances?.length != 0;

    this.setBufferDistanceInSummaryReportOptions();
    this.validateModelInputs();
  }

  onOffFieldConsiderationsChanged(value: UdlControlledVocabulary[]) {
    this.selectedOnOffFieldConsiderations = value;
    this.isOnOffFieldConsiderationsValid = this.selectedOnOffFieldConsiderations?.length != 0;
    this.selectAllOnOffFieldConsiderations = this.selectedOnOffFieldConsiderations?.length == this.onOffFieldConsiderationsOptions.length;

    this.validateModelInputs();
  }

  onOffFieldConsiderationsSelectAllChanged(event: any) {
    this.selectAllOnOffFieldConsiderations= event.checked;
    this.selectedOnOffFieldConsiderations = this.selectAllOnOffFieldConsiderations ? this.onOffFieldConsiderationsOptions : [];
    this.isOnOffFieldConsiderationsValid = this.selectedOnOffFieldConsiderations?.length != 0;

    this.validateModelInputs();
  }

  adjustForPCTSelectionChanged({ value }: DropdownChangeEvent) {
    this.isAdjustForPCTValid = value != null;
    this.adjustForPCT = value;
    this.isPCT = this.adjustForPCT?.toLowerCase() == "yes";
    if (!this.isPCT) {
      this.pctValuesFileInput.nativeElement.value = null;
    }

    this.validateModelInputs();
  }

  bufferDistanceInSummaryReportSelectionChanged(value: UdlControlledVocabulary | undefined) {
    this.isBufferDistanceInSummaryReportValid = value != null;
    this.selectedBufferDistanceInSummaryReport = value;

    this.validateModelInputs();
  }

  setBufferDistanceInSummaryReportOptions() {
    this.bufferDistanceInSummaryReportOptions = this.selectedBufferDistances?.map(option => ({
      ...option,
      disabled: false
    })).sort((a, b) => (Number(a.short_value) || 0) - (Number(b.short_value) || 0));

    if (!this.bufferDistanceInSummaryReportOptions.find(x => x.short_value == this.selectedBufferDistanceInSummaryReport?.short_value)) {
      this.selectedBufferDistanceInSummaryReport = undefined;
      this.isBufferDistanceInSummaryReportValid = false;
    }
  }

  async downloadProjectFile(filename: string) {
    await this.udlOverlapToolContainerService.downloadProjectFile(this.projectPk, this.modelVersion, filename);;
  }

  async markProjectFileForDeletion(filename: string) {
    this.markedForDeletionFiles.push(filename);
    switch (filename) {
      case this.uploadedCropFilename:
        this.uploadedCropFilename = "";
        this.isCropUseFileValid = false;
        break;
      case this.uploadedPCTFilename:
        this.uploadedPCTFilename = "";
        break;
      case this.uploadedOnOffConsiderationsFilename:
        this.uploadedOnOffConsiderationsFilename = "";
        break;
      case this.uploadedObligateAndIndirectRelationshipFilename:
        this.uploadedObligateAndIndirectRelationshipFilename = "";
        break;
    }
    this.validateModelInputs();
  }

  async onFileSelected(event: Event, fileExtension: string, isValidVariable: keyof ESASetupModelsToBeRunComponent, fileCategory?: string): Promise<void> {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement?.files?.length) {
      const file = inputElement.files[0];

      if (fileCategory == 'OnAndOffConsiderationsFile') {
        this.isOnAndOffConsiderationsFileSelected = true;
      }
      if (fileCategory == 'ObligateAndIndirectRelationshipFile') {
        this.isObligateAndIndirectRelationshipFileSelected = true;
      }

      (this[isValidVariable] as boolean) = (file.name.endsWith(fileExtension));

      await this.setFilesToBeUploaded();
      this.validateModelInputs();
    }
  }

  setupStatesToInclude() {
    if (this.selectedRegionsToInclude?.some(x => x.short_value?.toLowerCase() == "conus")) {
      if (this.selectedStatesToInclude?.length == 0 || this.isResettingModelInputs) {
        this.selectedStatesToInclude = this.statesToIncludeOptions;
        this.isStatesToIncludeValid = this.selectedStatesToInclude != null;
        this.isStatesToIncludeRequired = true;
        this.selectAllStates = true;
        this.statesToIncludeLabel = 'States to Include*';
      }
    }
    else {
      this.selectedStatesToInclude = [];
      this.isStatesToIncludeRequired = false;
      this.isStatesToIncludeValid = false;
      this.selectAllStates = false;
      this.statesToIncludeLabel = 'States to Include';
      this.statesToIncludePlaceholder = '';
    }
  }

  clearModelInputs() {
    Swal.fire({
      title: 'Clear Model Inputs',
      text: 'Do you want clear model inputs?',
      showCancelButton: true,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      icon: 'warning'
    }).then((result) => {
      if (result.value) {
        this.resetDefaultModelInputs();
      }
    });
  }

  validateModelInputs() {
    this.areSetupModelsValidEvent.emit(this.isChemicalNameValid &&
      this.isSpeciesHabitatValid &&
      this.isIncludeDriftValid &&
      this.isRegionsToIncludeValid &&
      (this.isStatesToIncludeRequired ? this.isStatesToIncludeValid : true) &&
      this.isBufferDistancesValid &&
      this.isOnOffFieldConsiderationsValid &&
      this.isAdjustForPCTValid &&
      this.isCropUseFileValid &&
      (this.isPCT ? this.isPCTValuesFileValid : true) &&
      (this.isOnAndOffConsiderationsFileSelected ? this.isOnAndOffConsiderationsFileValid : true) &&
      (this.isObligateAndIndirectRelationshipFileSelected ? this.isObligateAndIndirectRelationshipFileValid : true) &&
      this.isBufferDistanceInSummaryReportValid);
  }

  async setModelInputs() {
    var inputs : EsaInputs = {
      chemicalName: this.chemicalName,
      selectedSpeciesHabitat: this.selectedSpeciesHabitat!,
      includeDrift: this.includeDrift,
      selectedRegionsToInclude: this.selectedRegionsToInclude!,
      selectedStatesToInclude: this.selectedStatesToInclude!,
      selectedBufferDistances: this.selectedBufferDistances!,
      selectedOnOffFieldConsiderations: this.selectedOnOffFieldConsiderations!,
      adjustForPCT: this.adjustForPCT,
      selectedBufferDistanceInSummaryReport: this.selectedBufferDistanceInSummaryReport!,
      isPCT: this.isPCT,
      selectedCropUseFileName: this.uploadedCropFilename && this.uploadedCropFilename.length > 0 ? this.uploadedCropFilename : this.cropUseFileInput.nativeElement.files[0]?.name,
      selectedPCTValuesFileName: this.uploadedPCTFilename && this.uploadedPCTFilename.length > 0 ? this.uploadedPCTFilename : this.pctValuesFileInput.nativeElement.files[0]?.name,
      selectedOnOffConsiderationsFileName: this.uploadedOnOffConsiderationsFilename && this.uploadedOnOffConsiderationsFilename.length > 0
        ? this.uploadedOnOffConsiderationsFilename
        : this.onAndOffConsiderationsFileInput.nativeElement.files[0]?.name,
      selectedObligateAndIndirectRelationshipFileName: this.uploadedObligateAndIndirectRelationshipFilename && this.uploadedObligateAndIndirectRelationshipFilename.length > 0
        ? this.uploadedObligateAndIndirectRelationshipFilename
        : this.obligateAndIndirectRelationshipFileInput.nativeElement.files[0]?.name
    };

    var modelPk = this.model.udl_controlled_vocabulary_pk;
    var modelInputs : ProjectXEpaXModelInputsDTO = {
      inputs: JSON.stringify(inputs),
      project_pk: 0, // set after we have the project saved and the pk is returned. The save function will pass on the value to be used.
      model_pk: modelPk
    }

    this.epaProjectInputsService.setSelectedEsaInputs(modelInputs);
  }

  async setMarkedForDeletionFiles() {
    this.udlOverlapToolContainerService.setSelectedMarkedFilesForDeletion(this.modelVersion, this.markedForDeletionFiles);
  }

  async setFilesToBeUploaded() {
    var files = [this.cropUseFileInput.nativeElement.files[0], this.pctValuesFileInput.nativeElement.files[0], this.onAndOffConsiderationsFileInput.nativeElement.files[0], this.obligateAndIndirectRelationshipFileInput.nativeElement.files[0]];
    var validFiles = files.filter(x => x != null);
    var filesToBeUploaded: File[]= validFiles;
    this.udlOverlapToolContainerService.setFilesToBeUploaded(this.modelVersion, filesToBeUploaded);
  }

  setSavedInputsOnSelectedProject() {
    this.selectedProjectApiService.selectedProject.subscribe(async (project) => {
      if (!project || project?.projectPk < 0 || project?.name.length == 0) {
        this.resetDefaultModelInputs();
        return;
      }

      this.getModelInputs(project.projectPk).then(async (modelInputsSubscription) => {
        modelInputsSubscription.subscribe(async inputsDto => {
          if (inputsDto === undefined && inputsDto.length > 0) return;
          var modelInputs = inputsDto[0].inputs
          if (modelInputs) {
            var inputs = JSON.parse(modelInputs) as EsaInputs;
            this.chemicalNameChanged(inputs.chemicalName);
            this.bufferDistancesChanged(this.bufferDistancesOptions.filter(x => inputs.selectedBufferDistances.some(y => y.udl_controlled_vocabulary_pk == x.udl_controlled_vocabulary_pk)));
            this.onOffFieldConsiderationsChanged(this.onOffFieldConsiderationsOptions.filter(x => inputs.selectedOnOffFieldConsiderations.some(y => y.udl_controlled_vocabulary_pk == x.udl_controlled_vocabulary_pk)));
            this.bufferDistanceInSummaryReportSelectionChanged(this.bufferDistanceInSummaryReportOptions.find(x => x.udl_controlled_vocabulary_pk == inputs.selectedBufferDistanceInSummaryReport.udl_controlled_vocabulary_pk));

            this.selectedRegionsToInclude = this.regionsToIncludeOptions.filter(x => inputs.selectedRegionsToInclude.some(y => y.udl_controlled_vocabulary_pk == x.udl_controlled_vocabulary_pk));
            this.selectedStatesToInclude = this.statesToIncludeOptions.filter(x => inputs.selectedStatesToInclude.some(y => y.udl_controlled_vocabulary_pk == x.udl_controlled_vocabulary_pk));
            this.selectedSpeciesHabitat = this.speciesHabitatOptions.find(x => x.udl_controlled_vocabulary_pk == inputs.selectedSpeciesHabitat.udl_controlled_vocabulary_pk);
            this.includeDrift = inputs.includeDrift;
            this.adjustForPCT = inputs.adjustForPCT;
            this.isPCT = inputs.isPCT;

            this.uploadedCropFilename = inputs.selectedCropUseFileName;
            this.uploadedPCTFilename = inputs.selectedPCTValuesFileName;
            this.uploadedOnOffConsiderationsFilename = inputs.selectedOnOffConsiderationsFileName;
            this.uploadedObligateAndIndirectRelationshipFilename = inputs.selectedObligateAndIndirectRelationshipFileName;

            this.isCropUseFileValid = (inputs.selectedCropUseFileName != undefined && inputs.selectedCropUseFileName.length > 0);
            this.isPCTValuesFileValid = (inputs.selectedPCTValuesFileName != undefined && inputs.selectedPCTValuesFileName.length > 0);
            this.isOnAndOffConsiderationsFileValid = (inputs.selectedOnOffConsiderationsFileName != undefined && inputs.selectedOnOffConsiderationsFileName.length > 0);
            this.isObligateAndIndirectRelationshipFileValid = (inputs.selectedObligateAndIndirectRelationshipFileName != undefined && inputs.selectedObligateAndIndirectRelationshipFileName.length > 0);

            this.validateModelInputs();
            await this.setModelInputs();
          }
        });
      });
    });
  }

  async getModelInputs(selectedProjectPk: number) {
    return this.epaProjectInputsService.getProjectEpaInputs(selectedProjectPk);
  }
}
