import { Injectable } from '@angular/core';
import { Project } from '../models/project';
import { FormulationApiService } from './echo/formulation.api.service';
import { DataSetApiService } from './echo/data-set.api.service';
import { DataSet } from '../models/echo/data-set';
import { Constants } from '../utils/constants';
import { firstValueFrom } from 'rxjs';
import { HhraGapApiService } from 'src/app/human-health-risk-assessments/hhra-gap/hhra-gap-non-dietary/hhra-gap-non-dietary.api.service';
import { EndpointDatasetService } from '../components/endpoint-dataset/endpoint-dataset.service';
import { SubstancePropertiesUSDataSet, ToxicityInfo, USHAndlerExposureApplicationScheme, UsHandlerExposureInputs } from '../models/us-handler-exposure-Inputs';

@Injectable({
  providedIn: 'root'
})
export class UsHandlerExposureLogicService {
  USHandlerInputsCombinations: UsHandlerExposureInputs[]= [];

  constructor(
    private readonly dataSetApiService: DataSetApiService,
    private readonly HhraGapApiService: HhraGapApiService,
    private readonly formulationApiService: FormulationApiService,
    private readonly endpointDatasetService: EndpointDatasetService
  ) { }

  async fillUsHandlerExposureInputs(selectedProject: Project) {
    const usHandlerExposureInputsList: UsHandlerExposureInputs[] = [];
    const substancePropertiesDataSetList = await this.getSubstanceProperties(selectedProject);
    const humanHealthUSApplicationSchemes = await this.getHumanHealthUSApplicationScheme(selectedProject);
    const groupedSchemes = this.groupByApplicationScheme(humanHealthUSApplicationSchemes);

    for (let substancePropertiesDataSet of substancePropertiesDataSetList) {
      const toxicityInfoList = this.createToxicityInfo(substancePropertiesDataSet.endpoints);

      Object.keys(groupedSchemes).forEach(applicationSchemeName => {
        const humanHealthUSApplicationScheme = groupedSchemes[applicationSchemeName];

        toxicityInfoList.forEach(toxicityInfo => {
          const usHandlerExposureInputs: UsHandlerExposureInputs = {
            projectFullName: selectedProject.name,
            humanHealthApplicationScheme: humanHealthUSApplicationScheme,
            substancePropertiesDataSet: {
              substanceName: substancePropertiesDataSet.substanceName,
              description: substancePropertiesDataSet.description,
              source: substancePropertiesDataSet.source,
              projectPk: substancePropertiesDataSet.projectPk,
              dataSetPk: substancePropertiesDataSet.dataSetPk,
              toxicityInfo,
            },
          };
          usHandlerExposureInputsList.push(usHandlerExposureInputs);
        });
      });
    }

    this.USHandlerInputsCombinations = usHandlerExposureInputsList;
  }

  createToxicityInfo(endpoints: any[] | undefined): ToxicityInfo[] {
    if (!endpoints) {
      return [];
    }
    const groupedEndpoints = new Map<string, any[]>();
    endpoints.forEach(endpoint => {
      if (!groupedEndpoints.has(endpoint.exposureDuration)) {
        groupedEndpoints.set(endpoint.exposureDuration, []);
      }
      groupedEndpoints.get(endpoint.exposureDuration)?.push(endpoint);
    });
    const toxicityInfoList: ToxicityInfo[] = [];
    groupedEndpoints.forEach((group, duration) => {
      const toxicityInfo: ToxicityInfo = {
        dermalNonCancerPod: 0,
        dermalNonCancerPodSourceStudy: '',
        dermalNonCancerLoc: 0,
        dermalAbsorptionFraction: 0,
        dermalAbsorptionSourceStudy: '',
        inhalationNonCancerPod: 0,
        inhalationNonCancerPodSourceStudy: '',
        inhalationNonCancerLoc: 0,
        inhalationAbsorptionFraction: 0,
        inhalationAbsorptionSourceStudy: '',
        cancerQDay: 0,
        cancerQDaySourceStudy: '',
        exposureDuration: duration,
        dermalNonCancerBodyWeight: 0,
        inhalationNonCancerBodyWeight: 0,
        cancerBodyWeight: 0
      };
      group.forEach(endpoint => {
        switch (endpoint.exposureType) {
          case 'Dermal':
            toxicityInfo.dermalNonCancerPod = endpoint.podNonCancer;
            toxicityInfo.dermalNonCancerPodSourceStudy = endpoint.podSourceStudy;
            toxicityInfo.dermalNonCancerLoc = endpoint.locNonCancer;
            toxicityInfo.dermalAbsorptionFraction = endpoint.fractionAbsorption;
            toxicityInfo.dermalAbsorptionSourceStudy = endpoint.absorptionSourceStudy;
            toxicityInfo.dermalNonCancerBodyWeight = endpoint.podNonCancerBodyWeight
            break;
          case 'Inhalation':
            toxicityInfo.inhalationNonCancerPod = endpoint.podNonCancer;
            toxicityInfo.inhalationNonCancerPodSourceStudy = endpoint.podSourceStudy;
            toxicityInfo.inhalationNonCancerLoc = endpoint.locNonCancer;
            toxicityInfo.inhalationAbsorptionFraction = endpoint.fractionAbsorption;
            toxicityInfo.inhalationAbsorptionSourceStudy = endpoint.absorptionSourceStudy;
            toxicityInfo.inhalationNonCancerBodyWeight = endpoint.podNonCancerBodyWeight
            break;
          case 'Cancer':
            toxicityInfo.cancerQDay = endpoint.qDayValue;
            toxicityInfo.cancerQDaySourceStudy = endpoint.qSourceStudy;
            toxicityInfo.cancerBodyWeight = endpoint.cancerBodyWeight
            break;
        }
      });

      toxicityInfoList.push(toxicityInfo);
    });
    return toxicityInfoList;
  }

  groupByApplicationScheme(
    humanHealthUSApplicationSchemes: USHAndlerExposureApplicationScheme[]
  ): Record<string, USHAndlerExposureApplicationScheme[]> {
    return humanHealthUSApplicationSchemes.reduce((grouped, scheme) => {
      if (!grouped[scheme.name]) {
        grouped[scheme.name] = [];
      }
      grouped[scheme.name].push(scheme);
      return grouped;
    }, {} as Record<string, USHAndlerExposureApplicationScheme[]>);
  }

  async getSubstanceProperties(selectedProject: Project): Promise<SubstancePropertiesUSDataSet[]> {
    const substanceProperties: SubstancePropertiesUSDataSet[] = [];

    const datasetList = await firstValueFrom(this.endpointDatasetService.getDataSetsByProject(selectedProject.projectPk, Constants.FEATURE_ACRONYM.HUMAN_HEALTH_RISK_ASSESSMENT));
    const endpointByDataSet = await firstValueFrom(this.dataSetApiService.getEndpointsByDataSetsExpoTox(datasetList));
    const formulation = await firstValueFrom(this.formulationApiService.getFormulationConcentration(selectedProject.formulationPk!));

    endpointByDataSet.forEach((dataset: DataSet) => {

      if (dataset.useInProject && dataset.endpoints?.length) {
        const substancePropertiesObject: SubstancePropertiesUSDataSet = {
          dataSetPk: dataset.dataSetPk!,
          substanceName: formulation.find(molecule => molecule.moleculePk === dataset.moleculePk)?.molecule ?? '',
          moleculePk: dataset.moleculePk!,
          metabolitePk: dataset.metabolitePk!,
          formulationPk: dataset.formulationPk!,
          description: dataset.description!,
          source: dataset.source!,
          projectPk: dataset.projectPk!,
          calculatorGroup: dataset.calculatorGroup!,
          endpoints: dataset.endpoints,
        };
        substanceProperties.push(substancePropertiesObject);
      }
    });
    return substanceProperties;
  }

  async getHumanHealthUSApplicationScheme(selectedProject: Project): Promise<USHAndlerExposureApplicationScheme[]> {
    const humanHealthUSApplicationSchemes: USHAndlerExposureApplicationScheme[] = [];
    const compartment = await firstValueFrom(this.dataSetApiService.getCompartments(Constants.CONST_COMPARTMENT));
    const applicationSchemesByProjectAndCompartment = await firstValueFrom(this.HhraGapApiService.getHumanHealthApplicationSchemeExposureByProjectAndCompartment(
      selectedProject.projectPk!,
      compartment.find(x => x.compartment.toLowerCase() === Constants.HUMAN_HEALTH_RISK_ASSESSMENT_GAP[0].label.toLowerCase())?.endpointCompartmentPk!
    ));

    applicationSchemesByProjectAndCompartment.forEach((applicationScheme: any) => {
      if (applicationScheme.useInProject) {
        const humanHealthUSApplicationSchemeObject: USHAndlerExposureApplicationScheme = {
          projectPk: applicationScheme.projectPk,
          name: applicationScheme.name,
          compartmentPk: applicationScheme.compartmentPk,
          rate: applicationScheme.applicationSchemeXActiveIngredientRate[0].rate,
          unit: applicationScheme.applicationSchemeXActiveIngredientRate[0].unitOfMeasure,
          workerActivity: applicationScheme.workerActivity,
          formulation: applicationScheme.formulation,
          applicationEquipment: applicationScheme.applicationEquipment,
          applicationType: applicationScheme.applicationType,
          cropTargetCategory: applicationScheme.cropTargetCategory,
          dermal: applicationScheme.dermal,
          inhalation: applicationScheme.inhalation,
        };
        humanHealthUSApplicationSchemes.push(humanHealthUSApplicationSchemeObject);
      }
    });
    return humanHealthUSApplicationSchemes ?? [];
  }

  public setUSHandlerInputsCombinations(USHandlerInputsCombinations: UsHandlerExposureInputs[]): void {
    this.USHandlerInputsCombinations = USHandlerInputsCombinations;
  }

  public getUSHandlerInputsCombinations() {
    return this.USHandlerInputsCombinations;
  }

  transformDataQC(data: UsHandlerExposureInputs[]) {
      let finalResult: any = [];
      data?.forEach(usInput => {
        usInput.humanHealthApplicationScheme.forEach(scheme => {
          let mapedInput = {
            projectFullName: usInput.projectFullName,
            applicationEquipment : scheme.applicationEquipment,
            applicationType : scheme.applicationType,
            compartmentPk: scheme.compartmentPk,
            cropTargetCategory: scheme.cropTargetCategory,
            dermal: scheme.dermal,
            formulation: scheme.formulation,
            inhalation: scheme.inhalation,
            name: scheme.name,
            projectPk: scheme.projectPk,
            rate: scheme.rate,
            unit: scheme.unit,
            workerActivity: scheme.workerActivity,
            dataSetPk: usInput.substancePropertiesDataSet.dataSetPk,
            description: usInput.substancePropertiesDataSet.description,
            source: usInput.substancePropertiesDataSet.source,
            substanceName: usInput.substancePropertiesDataSet.substanceName,
            toxicityInfo: usInput.substancePropertiesDataSet.toxicityInfo
          }
          finalResult.push(mapedInput);
        })
      });
      return finalResult;
    }
}