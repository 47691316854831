import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderByPipe } from '../pipes/order-by.pipe';

@Injectable({
  providedIn: 'root'
})
export abstract class HttpApiService {
  constructor(private http: HttpClient,
              public orderByPipe: OrderByPipe) {}

  abstract apiUrl: string;

  // Método GET
  get<T>(endpoint: string, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    const url = `${this.apiUrl}${endpoint}`;
    const options = { headers,params};
    return this.http.get<T>(url, options);
  }

  // Método PUT
  put<T>(endpoint: string, body: any, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    const url = `${this.apiUrl}${endpoint}`;
    const options = {  headers,params};
    return this.http.put<T>(url, body, options);
  }

  putText(endpoint: string, body: any, headers?: HttpHeaders, params?: HttpParams): Observable<string> {
    const url = `${this.apiUrl}${endpoint}`;
    const options = { headers, params, responseType: 'text' as 'json' };
  
    return this.http.put<string>(url, body, options);
  }
  
  // Método POST
  post<T>(endpoint: string, body: any, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    const url = `${this.apiUrl}${endpoint}`;
    const options = {  headers,params};
    return this.http.post<T>(url, body, options);
  }

  // Método DELETE
  delete<T>(endpoint: string, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    const url = `${this.apiUrl}${endpoint}`;
    const options = {  headers,params};
    return this.http.delete<T>(url, options);
  }
}
