<div class="grid flex">
    <div class="flex align-items-center justify-content-center">
        <app-button *ngIf="useSaveAction" 
            [icon]="'pi pi-save'" 
            [size]="'small'" 
            [outlined]="true" 
            [severity]="'secondary'" 
            [label]="'Save'" 
            (onClickEvent)="save()">
        </app-button>
        <app-button *ngIf="useAddAction" 
            class="ml-2" 
            [icon]="'pi pi-arrow-down'" 
            [iconPos]="'right'" 
            [severity]="'warning'" 
            [label]="addLabel" 
            (onClickEvent)="add()">
        </app-button>
        <div *ngIf="useHelperNote" class="flex flex-column gap-2">
            <div class="label-with-icon">
                <img src="assets/img/infoIcon.png" (click)="showHelperNotesPopup(noteToUse)"
                    style="width: 16px; height: 16px; margin-left: 8px; cursor: pointer;">
            </div>
        </div>
        <app-button *ngIf="useCustomAction"
            class="ml-2"
            [label]="customLabel"
            [size]="'small'"
            [outlined]="true"
            [severity]="'secondary'"
            (onClickEvent)="custom()">
        </app-button>
        <app-button *ngIf="useServerExcelExportAction"
            class="ml-2" 
            [icon]="isDownloading ? 'pi pi-spin pi-spinner': ''"
            [iconPos]="'right'" 
            [style]="'black-button'"
            [label]="'Export to Excel'" 
            (onClickEvent)="onBtServerExcelExport()">
        </app-button>
    </div> 
</div>