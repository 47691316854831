<div [hidden]="disabled" class="singleselection-dropDown-div">
    <p-dropdown #dropdownList
        appendTo="body" 
        [placeholder]="placeholder" 
        [options]="options" 
        [optionLabel]="optionLabel" 
        [filter]="filterable" 
        [filterBy]="optionLabel" 
        [disabled]="disabled" 
        [(ngModel)]="selectedValue" 
        (onChange)="onChange($event)"
        [optionValue]="optionValue" 
        (onShow)="onShow($event)" 
        styleClass="ddlSelection">
    </p-dropdown>
</div>

<span *ngIf="disabled" class="text-600">{{getReadOnlySelectedValue()}}</span>