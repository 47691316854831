<div class="pt-3 pr-5 pb-5 pl-5">
    <div class="col-12 w-12">
        <app-wizard class="surface-200" [items]="HHRAGapLogicService.items" [message]="message" [loading]="wizardLoading" [activeItem]="activeItem"  [isSetupValid]="true" (activeIndexChangeEmitter)="onActiveIndexChange($event)" [isSetupValid]="isApplicationSchemeValid"></app-wizard>
    </div>

    <div class="col-12 w-12" >
        <ng-container *ngIf="activeItem.index == 0">
            <app-hhra-gap-non-dietary [isProjectOwnershipValid]="isProjectOwnershipValid" [subTabMenuService]="HHRAGapLogicService" [menuService]="menuService" [selectedProject]="selectedProject" (isValid)="getIsApplicationSchemeValid($event)"></app-hhra-gap-non-dietary>
        </ng-container>
    </div>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading>