import { Injectable } from '@angular/core';
import { HttpApiService } from './http.api.service';
import { environment } from 'src/environments/environment';
import { RunRisk } from '../models/run-risk';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs';
import { OpexInputs } from '../models/opex-Inputs';
import { UsHandlerExposureInputs } from '../models/us-handler-exposure-Inputs';
import { Constants } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class RunRiskApiService extends HttpApiService {
  override apiUrl: string = environment.BAMS_Api_URL;

  GetAquaticRiskRunsByProject(data: RunRisk[], projectPk: number, geography: string, featureAcronym: string){
    const body = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/aquaticRiskRunsByProject/${projectPk}/${geography}/${featureAcronym}`;
    return this.put(query, body, headers)
      .pipe(map((data: any) => data));
  }

  RunOpex(data: OpexInputs[], projectPk: number, isRefresh: boolean){
    const body = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/runOpex/${projectPk}/${isRefresh}`;
    return this.put(query, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  RunHandler(data: UsHandlerExposureInputs[], projectPk: number, isRefresh: boolean){
    const body = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/runUSHandlerExposure/${projectPk}/${isRefresh}`;
    this.putText(query, body, headers).pipe().subscribe({
      next: (response: any) => {
      }, 
      error: (err) => console.error('Error:', err)
    });
  }

  GetOPEXRunsByProject(projectPk: number){
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/GetOPEXByProject/${projectPk}`;
    return this.get(query, headers)
      .pipe(map((data: any) => data));
  }
  GetUsHandlerExposureRunList(projectPk: number){
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/GetUsHandlerExposureRunList/${projectPk}`;
    return this.get(query, headers)
      .pipe(map((data: any) => data));
  }
}