import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map, of } from 'rxjs';
import { Project, ProjectStatus } from '../../models/project';
import { HttpApiService } from '../../services/http.api.service';
import { environment } from 'src/environments/environment';
import { SaveStructure, TransactionType } from '../../models/transaction-type';
import { HttpHeaders } from '@angular/common/http';
import { ProjectXCompoundXModel } from '../../models/project-x-compound-x-model';
import { Environmental_Assessments } from '../../services/user.logic.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedProjectApiService extends HttpApiService {
  override apiUrl: string = environment.BAMS_Api_URL;

  private selectedProjectSubject = new BehaviorSubject<Project | undefined>(undefined);
  public readonly selectedProject: Observable<Project | undefined> = this.selectedProjectSubject.asObservable();

  private selectedProjectStatusSubject = new BehaviorSubject<number>(-1);
  public readonly selectedProjectStatus: Observable<number> = this.selectedProjectStatusSubject.asObservable();

  private projectsSubject: BehaviorSubject<Project[]> = new BehaviorSubject([] as Project[]);
  public readonly projects: Observable<Project[]> = this.projectsSubject.asObservable();
  
  private selectedProjectRunsSavedSubject = new BehaviorSubject<boolean>(false);
  public readonly selectedProjectRunsSaved: Observable<boolean> = this.selectedProjectRunsSavedSubject.asObservable();

  public matchingFACTSOPEX: [] = [];

  featureAcronym?: Environmental_Assessments;
  loadingProjects = false;
  myProjectsFilter: boolean = true;

  initNewProject(createdBy: string) {
    const newProject: Project = {
      active: true,
      deletable: false,
      description: '',
      formulationPk: 0,
      formulation: '',
      name: '',
      projectPk: -1,
      createdDate: new Date(),
      createdBy: createdBy,
      status: 0,
      projectXCompoundXModel: [],
      useRateGHA: false,
      useRateLBACRE: false,
      useBBCH: false,
      useApplicationWindow: false,
      useNumberOfApplications: false,
      useIntervalBetweenApplications: false,
    };
    this.setSelectedProject(newProject);
  }

  setSelectedProject(selectedProject?: Project): void {
    this.selectedProjectSubject.next(selectedProject);
  }

  areProjectsEmpty(){
    return this.projectsSubject.value.length == 0;
  }

  setProjects(projects: Project[]): void {
    this.projectsSubject.next(projects);
  }

  addProjectToDropdown(savedProjectPk: any) {
    if (!this.projectsSubject.value.map(p => p.projectPk).includes(savedProjectPk)) {
      if (!this.selectedProjectSubject.value) return;
      if (this.selectedProjectSubject.value) {
        this.selectedProjectSubject.value.projectPk = savedProjectPk;
        this.insertProject(this.selectedProjectSubject.value);
      }
    }
  }

  insertProject(project: Project) {
    this.projectsSubject.next(this.orderByPipe.transform(this.projectsSubject.value.concat([project]), 'name'));
  }

  updateSelectedProject(updatedProject: Project) {
    this.selectedProjectSubject.next({ ...this.selectedProjectSubject.value, ...updatedProject });
  }

  updateSelectedProjectStatus(status: number) {
    if (this.selectedProjectSubject.value) {
      this.selectedProjectSubject.value.status = status;
      this.selectedProjectStatusSubject.next(status);
    }
  }

  updateRunsHaveBeenSaved(runsSaved: boolean) {
    if (this.selectedProjectSubject.value) {
      this.selectedProjectSubject.value.runsSaved = runsSaved;
      this.selectedProjectRunsSavedSubject.next(runsSaved);
    }
  }
  
  async getProjects(createdBy: string = 'NA') {
    this.loadingProjects = true;
    const endpoint = `api/projects/${createdBy}`;
    await firstValueFrom(this.get(endpoint).pipe(map((data: any) => data.Value))).then(projects => {
      this.setProjects(projects);
    });
  }

  getMatchingFACTSOPEX() {
    const endpoint = `api/projects/GetMatchingFACTSOPEX`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  saveProject(source: string = ""): Observable<number> {
    if (!this.selectedProjectSubject?.value?.name) return of(-1);
    const projectPk = this.selectedProjectSubject.value.projectPk >= 0 ? this.selectedProjectSubject.value.projectPk : undefined;
    if (source && source != "") this.selectedProjectSubject.value.source = source;
    const endpoint = 'api/projects';
    const saveStructure: SaveStructure = { transactionType: projectPk ? TransactionType.update : TransactionType.insert, row: { ...this.selectedProjectSubject.value, projectXCompoundXModel: undefined, projectPk, createdDate: projectPk ? this.selectedProjectSubject.value.createdDate : new Date() } }
    const body = JSON.stringify(saveStructure);
    return this.put(endpoint, body).pipe(map((data: any) => data.Value));
  }

  getProjectByPk(projectPk: number): Observable<Project> {
    this.loadingProjects = true;
    const endpoint = `api/project/${projectPk}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  setProjectStatus(projectPk: number): Observable<ProjectStatus>
  {
    if (!this.selectedProjectSubject?.value?.name) return of();

    const endpoint = `api/projects/projectStatus/${this.featureAcronym}`;
    const data: ProjectStatus =
    {
      projectPk,
      status: this.selectedProjectSubject.value.status,
    }
    const body = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.put<ProjectStatus>(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  saveProjectXCompoundXModels(projectPk: number, disciplinePk: number): Observable<boolean> {

    if (this.selectedProjectSubject.value && !this.selectedProjectSubject.value.projectXCompoundXModel) this.selectedProjectSubject.value.projectXCompoundXModel = [];

    this.selectedProjectSubject?.value?.projectXCompoundXModel?.map(m => m.ProjectPk = projectPk);
    const endpoint = `api/projects/projectxcompoundxmodel/${projectPk}/${disciplinePk}`;
    const body = JSON.stringify(this.selectedProjectSubject?.value?.projectXCompoundXModel);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.put(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  getProjectXCompoundXModels(projectPk: number, disciplinePk?: number): Observable<ProjectXCompoundXModel[]> {
    if (!projectPk || projectPk < 0) return of([]);
    const endpoint = `api/projects/projectxcompoundxmodel/${projectPk}/${disciplinePk}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  getAndCopyProject(projectPK: number, projectNew?: number, nameProject?: string, createdBy?: string): Observable<Project> {
    if (nameProject == '')
      nameProject = "NA";
    const endpoint = `api/projects/getAndCopyProject/${projectPK}/${projectNew}/${nameProject}/${createdBy}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }
}

