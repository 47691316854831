import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { UpdateSmeSelection } from './eea-output-project.interface';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectApiService extends HttpApiService {
  override apiUrl: string = environment.BAMS_Api_URL;

  updateSmeSelection(smeSelections: UpdateSmeSelection[]): Observable<boolean> {
    const body = JSON.stringify(smeSelections);
    let endpoint = `api/UpdateSmeSelection`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }

}
