import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, ColGroupDef, RowGroupingDisplayType } from 'ag-grid-community';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { HhraRunRiskAssessmentOpexResultsColDef } from './hhra-run-risk-assessment-opex-results-coldef';
import { OpexInputs } from 'src/app/shared/models/opex-Inputs';
import { RunRiskApiService } from 'src/app/shared/services/run-risk.api.service';
import { Subscription, switchMap, take, timer } from 'rxjs';
import { ProjectApiService } from 'src/app/shared/services/project.api.service';
import { Constants } from '../../../shared/utils/constants';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { HumanHealthOpexLogicService } from 'src/app/shared/services/human-health-opex-logic.service';

@Component({
  selector: 'app-hhra-run-risk-assessment-opex-result',
  templateUrl: './hhra-run-risk-assessment-opex-result.component.html',
  styleUrls: ['./hhra-run-risk-assessment-opex-result.component.css']
})

export class HhraRunRiskAssessmentOpexResultComponent implements OnInit {

  @Input() selectedProject?: Project;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;
  @Output() status: EventEmitter<any>;
  @Output() isProjectRunning: EventEmitter<boolean>;

  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  columnDefs: (ColDef | ColGroupDef)[] | null = [];
  rowData: any[] = [];
  isLoading!: boolean;
  subscription!: Subscription;
  projectStatusStyle = {
    statusId: this.selectedProject?.status,
    statusName: "",
    badgeStyle: ""
  };

  groupDisplayType: RowGroupingDisplayType = 'custom';

  @ViewChild('grid') grid!: GridComponent;

  constructor(private readonly hhraRunRiskAssessmentOpexResultsColDef: HhraRunRiskAssessmentOpexResultsColDef,
    private readonly runRiskApiService: RunRiskApiService,
    private projectApiService: ProjectApiService,
    private selectedProjectApiService: SelectedProjectApiService,
    private readonly humanHealthOpexLogicService: HumanHealthOpexLogicService) {
    this.status = new EventEmitter<boolean>();
    this.isProjectRunning = new EventEmitter<boolean>();
  }

  ngOnInit() {
    this.rowData = [];
    this.isLoading = true;
    this.monitorProjectStatus();
  }

  getData() {
    this.columnDefs = this.hhraRunRiskAssessmentOpexResultsColDef.getColumnsDefinition();
    this.runRiskApiService.GetOPEXRunsByProject(this.selectedProject?.projectPk!).subscribe((data: OpexInputs[]) => {
      this.rowData = this.humanHealthOpexLogicService.transformData(data);
      this.isLoading = false;
    });
  }

  monitorProjectStatus(): void {
    this.subscription = timer(0, 10000).pipe(
      switchMap(() => this.projectApiService.getProjectStatus(this.selectedProject!.projectPk, this.selectedProjectApiService.featureAcronym!).pipe(take(1)))
    ).subscribe({
      next: (status) => {
        this.selectedProject!.status = status;
        if (this.selectedProject!.status == Constants.PROJECT_STATUS_ID.COMPLETED) {
          this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
          this.isProjectRunning.emit(true);
          this.statusStyle(status, Constants.PROJECT_STATUS.COMPLETED, Constants.BADGE_STYLE.COMPLETED);
          this.getData();
          this.subscription.unsubscribe();
        }
        if (this.selectedProject!.status == Constants.PROJECT_STATUS_ID.FAILED) {
          this.statusStyle(status, Constants.PROJECT_STATUS.FAILED, Constants.BADGE_STYLE.FAILED);
          this.isLoading = false;
          this.subscription.unsubscribe();
        }
      },
      error: (error) => {
        console.warn(error)
      }
    });
  }

  statusStyle(statusId: number, status: string, bageStyle: string) {
    this.projectStatusStyle = {
      badgeStyle: bageStyle,
      statusId: statusId,
      statusName: status.concat("\u00A0")
    }
    this.status.emit(this.projectStatusStyle);
  }

  onButtonExpandClick(event: any) {
    this.toggleDetailGrid(event, this.grid);
  }

  toggleDetailGrid(event: any, grid: GridComponent) {
    event.row = event.row ? event.row : event.data;
    var node1 = grid.gridApi.getRowNode(event?.node ? event?.node?.id : event.id)!;
    grid.gridApi.setRowNodeExpanded(node1, event?.row?.isExpanded);
  }
}