
<div class="border-bottom-1 font-bold mb-2">
    <label [ngClass]="!isValid ? 'lbl-invalid' : 'lbl-valid'">Models to be run*</label>
</div>
<p-accordion #accordion [multiple]="false" *ngIf="modelsToBeRun.length > 0 && !modelsLoading && showModels" [activeIndex]="activeIndex" (onClose)="onClose($event)" (onOpen)="onOpen($event)">
    <p-accordionTab *ngFor="let model of modelsToBeRun | orderBy: 'activeIngredient':'asc':'activeIngredientName'; let index = index">
        <ng-template pTemplate="icon">
            <app-button [text]="true" [plain]="true" [size]="'small'" [icon]="activeIndex == index ? 'pi pi-angle-down': 'pi pi-angle-right'" [iconPos]="'right'" [disabled]="!model.selected"></app-button>
        </ng-template>
        <!-- Header -->
        <ng-template class="flex" pTemplate="header">
            <div class="ml-2 flex align-items-center" [ngClass]="model.selected ? 'text-color' : 'text-400'">
                <span class="vertical-align-middle">{{model.activeIngredient.activeIngredientName}}</span>
            </div>
            <div class="flex align-items-end">
                <app-button [text]="true"  [disabled]="!isProjectOwnershipValid" [plain]="true" [icon]="model.selected ? 'pi pi-check-square': 'pi pi-stop'" [iconPos]="'right'" (onClickEvent)="model.selected = !model.selected"></app-button>
            </div>
        </ng-template>

        <!-- Content -->
        <ng-template pTemplate="content">
            <app-grid [columnDefs]="columnsDefinition" [rowData]="model.rowData" [rowHeight]="rowHeight" [style]="gridStyle" [autoAdjustColumns]="true" [isProjectOwnershipValid]="isProjectOwnershipValid" (listBoxSelectionChangeEvent)="modelsListBoxSelectionChanged($event)"></app-grid>
        </ng-template>
    </p-accordionTab>
</p-accordion>
<div *ngIf="modelsToBeRun.length == 0 && !modelsLoading || !showModels" class="flex justify-content-center flex-wrap">
        <h4>No models to display</h4>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="modelsLoading && showModels"></app-loading>
