import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-input-text-render',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css']
})
export class InputTextRenderer implements ICellRendererAngularComp {

  public value: string = '';
  public params: any;
  public parent: any;
  public field: string = '';
  public disabled: boolean = true;
  public isRequired: boolean = false;
  public isValid: boolean = true;
  isGroupNode = false;

  constructor(){}

  public refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  public agInit(params: any): void {
    this.disabled = params.disabled
    this.params = params;
    this.value = params.value;
    this.parent = params.context;
    this.field = params.colDef.field ?? params.node.field;
    this.isGroupNode = this.params.node.group;
    this.setIsRequired(params.isRequired);
    this.manageIsValid();
  }

  public onBlur(event: any) {
    const value = event.target.value;
    if(this.isGroupNode)
    {
      let updateRows: any[] = [];
      this.params.node.allLeafChildren.map((x:any) => updateRows.push(x.data));
      let tempList = this.params.context.rowData.filter((x:any) => updateRows.includes(x));
      tempList.map((x:any)=> x[this.field] = value);
      this.params.value = value;
    }else{
      this.params.data[this.field] =  value;
      this.params.data.modified = true;
    }
    this.parentSetValues(value);
    this.manageIsValid();
}

  private parentSetValues(value: string): void {

  let changeValues = {
    field: this.field,
    id: this.isGroupNode ? this.params.node.allLeafChildren[0].id : this.params.node.id,
    row: this.isGroupNode ? this.params.node.allLeafChildren[0].data : this.params.data,
    value: value,
  };
  if (this.parent && typeof this.parent.setChangeValues === 'function') {
    this.parent.setChangeValues(changeValues);
  } else if (this.params.context.componentParent && typeof this.params.context.componentParent.setChangeValues === 'function') {
    this.params.context.componentParent.setChangeValues(changeValues);
  }
}

  private setIsRequired(isRequired: boolean | undefined): void {
    if(isRequired != undefined)
      this.isRequired = isRequired;
  }

  protected manageIsValid(): void {
    if(this.isRequired && (this.value == undefined || this.value == null || this.value === ''))
      this.isValid = false;
    else this.isValid = true;
  }

  protected valueAlreadyExistsInRowAbove()
  {
    return this.parent.rowData
    .filter((_: any, index: number) => index < this.params.node.rowIndex) 
    .some((row: any) => row.name?.toLowerCase() === this.value?.toLowerCase());
  }
}