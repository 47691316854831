import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { InputTextRenderer } from '../input-text.component';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';

@Component({
  selector: 'app-input-text-application-scheme',
  templateUrl: './input-text-application-scheme.component.html',
  styleUrls: ['./input-text-application-scheme.component.css']
})
export class InputTextApplicationSchemeRenderer extends InputTextRenderer {
  @ViewChild('inputRef', { static: false }) inputRef!: ElementRef;
  @Output() enableDelete = new EventEmitter<any>();
  constructor(private renderer: Renderer2, private gapApplicationSchemeLogicService: GapApplicationSchemeLogicService) {
    super();
  }
  public override manageIsValid(): void {
    super.manageIsValid();
  }

  public override onBlur(event: any) { 
    super.onBlur(event)
    const applicationSchemeAlreadyExists:boolean = this.gapApplicationSchemeLogicService.applicationSchemeAlreadyExistsOnBlur(this.params.context,this.value)
    this.isValid = !applicationSchemeAlreadyExists && !(this.isRequired && (this.value === undefined || this.value === null || this.value === ''));    
    if(applicationSchemeAlreadyExists){
      Utils.showErrorMessage('The Application Scheme name already exists.','Please use another name');
    } 
  }
  onKeyPress(event: any) {    
    Utils.omitIllegalCharacters(event,Constants.illegalCharacters);
  }
  
  omitIllegalCharactersOnPaste(event: any) {
    Utils.omitIllegalCharactersOnPaste(event, Constants.illegalCharacters)
  }

  onKeyUp(event: any){
    const applicationSchemeAlreadyExists:boolean = this.gapApplicationSchemeLogicService.applicationSchemeAlreadyExistsOnKeyUp(this.params.context,this.value)
    this.isValid = !applicationSchemeAlreadyExists && !(this.isRequired && (this.value === undefined || this.value === null || this.value === ''));    
    this.params.data.isDuplicate = false;
    this.manageIsValidOnDuplicate();
  }

  private manageIsValidOnDuplicate(): void {
    if (this.inputRef)
    {
      if (this.params.data.isDuplicate) {
        this.renderer.addClass(this.inputRef?.nativeElement, 'p-inputtext-invalid');
      } else {
        this.renderer.removeClass(this.inputRef?.nativeElement, 'p-inputtext-invalid');
      }
    }
  }

}
