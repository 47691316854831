<div *ngIf="showGrid">
    <p class="mt-2 mb-4" style="color: red; font-size: 1.17em;">{{missingDataRows.length}} out of {{runs.length}} encountered missing or incorrect data.  Please review and fix them appropriately in their corresponding sections.  
      Or feel free to continue with what has been loaded and the ones with missing data will be ignored for the modeling process.</p>
    <app-grid #grid [columnDefs]="columnsDef" [rowData]="missingDataRows" [autoAdjustColumns]="true"  [useAddAction]="false" [useDeleteAction]="false" ></app-grid>
  </div>
  
  <p-card  *ngIf="showAlert" [style]="{ width: '30%' }" class="flex align-items-center justify-content-center">
    <ng-template pTemplate="header">
      <span class="pi pi-check flex align-items-center justify-content-center mt-3"   style="color: green; font-size: 4.5em;"></span>
    </ng-template>
    <ng-template pTemplate="title" class="flex align-items-center justify-content-center">
      <p  class="flex align-items-center justify-content-center">QC passed successfully</p>
    </ng-template>
    <ng-template pTemplate="subtitle" class="flex align-items-center justify-content-center">
      <p class="flex align-items-center justify-content-center"> You can now run the project</p>
    </ng-template>
  </p-card>
  
  <p-card  *ngIf="showWarningAlert" [style]="{ width: '30%' }" class="flex align-items-center justify-content-center">
    <ng-template pTemplate="header">
      <span class="pi pi-info flex align-items-center justify-content-center mt-3"   style="color: #F59E0B; font-size: 4.5em;"></span>
    </ng-template>
    <ng-template pTemplate="title" class="flex align-items-center justify-content-center">
      <p  class="flex align-items-center justify-content-center">{{warningAlertText}}</p>
    </ng-template>
  </p-card>