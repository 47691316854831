<div class="pt-1 pr-5 pb-5 pl-5 center-content" *ngIf="!loading && isInputsModelFilesValidationPassed">
  <div class="success-icon">
    <i class="pi pi-check-circle success-icon-style"></i>
  </div>
  <p>The QC has Successfully Completed</p>
  <h2>Are you ready to run the UDL Overlap Tool?</h2>
  <div>
    <p-button [disabled]="false" styleClass="standard-button" class="p-button-outlined mr-2" label="Yes" (onClick)="GoToOutput()"></p-button>
    <p-button [disabled]="false" styleClass="standard-button" class="p-button-outlined ml-2" label="No" (onClick)="BackToSetup()"></p-button>
  </div>
</div>
<div class="pt-1 pr-5 pb-5 pl-5 center-content" *ngIf="!loading && !isInputsModelFilesValidationPassed">
  <p>QC Failed</p>
  <p>You must return to the Setup step to correct these files</p>
  <div>
    <ag-grid-angular #agGrid style="width: 100%; height: 620px;" [columnDefs]="columnDefs" [rowData]="rowData" [defaultColDef]="defaultColDef" [paginationPageSize]="10" class="ag-theme-alpine"
      [rowHeight]="50" [pagination]="true"></ag-grid-angular>
  </div>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading>
