import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin } from 'rxjs';
import { TriggersEcotoxService } from 'src/app/administrator/regulatory-models/triggers-ecotox/triggers-ecotox.service';
import { EndpointDatasetService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.service';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { Project } from 'src/app/shared/models/project';
import { TriggerEcotox } from 'src/app/shared/models/trigger-ecotox';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { DatafieldValueApiService } from 'src/app/shared/services/echo/data-field-value.api.service';
import { DisciplineApiService } from 'src/app/shared/services/echo/discipline.api.service';
import { GeographyApiService } from 'src/app/shared/services/echo/geography.api.service';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { EnviromentalRiskAssessmentService } from '../environmental-risk-assessment/environmental-risk-assessment.service';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';

@Component({
  selector: 'app-era-run-risk-assessment',
  templateUrl: './era-run-risk-assessment.component.html',
  styleUrls: ['./era-run-risk-assessment.component.css']
})
export class ERARunRiskAssessmentComponent implements OnInit {
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() isInverseModule = false;
  @Input() isProjectOwnershipValid: boolean = false;

  eraRunRiskAssessmentComponent = this;
  items: CustomTabMenuItem[] = Constants.RUN_RISK_ASSESSMENT_SECTIONS;
  geographies: Catalog[] = [];
  isLoading!: boolean;
  environmentalAssessment: string = Constants.FEATURE_ACRONYM.ENVIRONMENTAL_RISK_ASSESSMENT;
  datasets: DataSet[] = [];
  tempDatasets: DataSet[] = [];
  disciplines: Discipline[] = [];
  mainDiscipline!: number;
  taxonCatalogList: Catalog[] = [];
  triggers: TriggerEcotox[] = [];
  regionPk: number = 0;
  countries: Catalog[] = [];
  crops: Catalog[] = [];
  destroyRef = inject(DestroyRef)

  constructor(private geographyAPIService: GeographyApiService,
              private orderByPipe: OrderByPipe,
              public dataSetService: EndpointDatasetService,
              private disciplineApiService: DisciplineApiService,
              private datafieldValueApiService: DatafieldValueApiService,
              private triggerEcotoxService: TriggersEcotoxService,
              private ERALogicService: EnviromentalRiskAssessmentService){}

  ngOnInit(): void {
    this.isLoading = true;
    this.items.forEach((section: CustomTabMenuItem) => {
      this.items[section.index].visible = false; 
    });
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.menuService.setSuccess(true));
    this.ERALogicService.usedModelsSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((usedModels: string[]) => {
      usedModels.forEach((modelName: string) => {
        if (modelName != Constants.COMPARTMENTS.PLANT.toLowerCase()){
          this.items.forEach((section: CustomTabMenuItem) => {
            if (modelName === section.model) {
              this.items[section.index].visible = true; 
            }      
          });
        }
      })
    });
    this.regionPk = this.selectedProject?.countryPk !== undefined ? this.selectedProject.countryPk : (this.selectedProject?.regionPk as number);
    this.getData();
  }

  getData(): void {
    forkJoin([
      this.geographyAPIService.getRegionCountryCatalog(),
      this.disciplineApiService.getDisciplineInformation(),
      this.dataSetService.getDataSetsByProject(this.selectedProject!.projectPk, this.environmentalAssessment),
      this.datafieldValueApiService.getDatafieldValueInformation(),
      this.triggerEcotoxService.getTaxonTriggerList(),
      this.geographyAPIService.getCountries()
    ]).subscribe({
      next: ([geographies, disciplines, datasets, datafields, triggers, countries]) => {
        this.mainDiscipline = disciplines.filter(x => x.name === Constants.DISCIPLINE_IDENTITIES.ECOTOXICOLOGY)[0]?.disciplinePk;
        this.geographies = this.orderByPipe.transform(geographies.filter(x => x.name == 'USA' || x.name == 'Europe Union'), 'name');
        this.countries = countries.map((x: any) => { return { key: x.countryPk, name: x.name } });
        this.tempDatasets = datasets;
        this.getEcotoxData(triggers);
        this.isLoading = false;
      },
      error: (err: any) => {
        console.warn('There was an error retrieving the data');
      }
    });
  }

  getEcotoxData(triggers: TriggerEcotox[]) {
    this.geographies = this.geographies.filter(
      (x: Catalog) => x.key === this.regionPk
    );

    this.triggers = triggers.filter(
      (x: TriggerEcotox) =>
        x.geography && x.geography.trim() === this.geographies[0].name
    );

    this.triggers.forEach((x: TriggerEcotox) => {
      var newCatalog: Catalog = {
        key: x.id,
        name: x.description,
      };
      this.taxonCatalogList.push(newCatalog);
    });
  }
}