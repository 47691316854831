
<p-autoComplete *ngIf="!disabled" 
     [(ngModel)]="value" 
     [suggestions]="filteredvalues" 
     appendTo="body" 
     (completeMethod)="onFilter($event)" 
     (onSelect)="onSelect()" 
     (onBlur)="onBlur()" 
     placeholder="Select" 
     [size]="20" [readonly]="false" 
     [dropdown]="true" 
     [forceSelection]="forceSelection">
</p-autoComplete>

<span *ngIf="disabled">{{value}}</span>
