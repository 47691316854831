<p-tabView [(activeIndex)]="activeIndex">
   
    <p-tabPanel class="mb-2" [disabled]="disabledInputs" header="Inputs used in calculation">
        <eea-output-project-pwc-inputs-used-in-calculation *ngIf="!disabledInputs"
            [selectedProject]="selectedProject" [selectedModel]="selectedModel"></eea-output-project-pwc-inputs-used-in-calculation>
    </p-tabPanel>
    <p-tabPanel class="mb-2" [disabled]="disabledSummary" header="Result Summary">
        <app-eaa-output-project-pwc-result-summmary *ngIf="!disabledSummary"
            [selectedProject]="selectedProject" [selectedModel]="selectedModel"></app-eaa-output-project-pwc-result-summmary>
    </p-tabPanel>
    
</p-tabView>
