import { inject, Injectable } from "@angular/core";
import { UpdateSmeSelection } from "./eea-output-project.interface";
import { EEAOutputProjectApiService } from "./eea-output-project.api.service";
import { lastValueFrom, take } from "rxjs";
import { RowSelectedEvent } from "ag-grid-community";
import { GridComponent } from "src/app/shared/components/grid/grid.component";

@Injectable({
  providedIn: 'root'
})

export class EEAOutputProjectLogicService {

  private apiService = inject(EEAOutputProjectApiService);

  public async updateSmeSelection(smeSelections: UpdateSmeSelection[]): Promise<boolean> {
    const source$ = this.apiService.updateSmeSelection(smeSelections).pipe(take(1));
    const result = await lastValueFrom(source$);
    return result;
    }

  public setSmeSelection( event: RowSelectedEvent, source: string ){
    const { node } = event;
    const { data, selected } = node as any;
    const { ResultsSurfaceWaterPk } = data;
    const smeSelection: UpdateSmeSelection ={
      resultSurfaceWaterPk: ResultsSurfaceWaterPk,
      smeSelectionValue: selected,
      source: source
    }
    this.updateSmeSelection( [smeSelection] );
  }

  public setSmeSelections( grid: GridComponent, source: string ): void {
    const smeSelections: UpdateSmeSelection[] = [];

    grid.gridApi.forEachNode((node: any) => {
      const { data, selected} = node;
      smeSelections.push({
        resultSurfaceWaterPk: data.ResultsSurfaceWaterPk,
        smeSelectionValue: selected,
        source: source
      });
    });

    this.updateSmeSelection( smeSelections );
  }

}
