import { Component, DestroyRef, EventEmitter, Input, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EEARunProjectLogicService } from '../eea-run-project.logic.service';
import { Run } from 'src/app/shared/models/run/run';
import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { RunApiService } from 'src/app/shared/services/run.api.service';
import { Utils } from 'src/app/shared/utils/utils';
import { EEAGapLogicService } from '../../eea-gap/eea-gap.logic.service';
import { Compartment } from 'src/app/shared/models/compartment';
import { Endpoint } from 'src/app/shared/models/endpoint';
import { ProjectXCompoundXModel } from 'src/app/shared/models/project-x-compound-x-model';
import { GapXRun } from 'src/app/shared/models/run/gap-x-run';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { EndpointXRun } from 'src/app/shared/models/run/endpoint-x-run';
import { FractionTransformedXEndpoint } from 'src/app/shared/models/run/fraction-transformed-x-endpoint';
import { Precursor } from 'src/app/shared/models/echo/precursor';
import { ApplicationsXRun } from 'src/app/shared/models/run/application-x-run';
import { take } from 'rxjs';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { EEARunProjectQCComponent } from '../shared/eea-run-project-qc/eea-run-project-qc.component';
import Swal from 'sweetalert2';
import { ApplicationSchemeXActiveIngredientRate, ApplicationSchemeXApplication } from 'src/app/shared/models/application-scheme';
import { RunModelsFactory } from '../shared/run-models-factory';
import { RunModel } from 'src/app/shared/models/run/run-model';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { MoleculePkAndName } from 'src/app/shared/models/echo/molecule';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { EEAGapApiService } from '../../eea-gap/eea-gap.api.service';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { CropListService } from 'src/app/administrator/regulatory-models/crop-list/crop-list.service';
import { ExposureAssessmentOutputViewApiService } from '../../eea-output/eea-output/eea-output.api.service';
import { ActiveIngredientApiService } from 'src/app/shared/services/echo/active-ingredient.api.service';
import { SwashInputs } from 'src/app/shared/models/run/swash-inputs';

@Component({
  selector: 'app-eea-run-project',
  templateUrl: './eea-run-project.component.html',
  styleUrls: ['./eea-run-project.component.css']
})
export class EEARunProjectComponent {
  runs: Run[] = [];
  @Input() selectedProject?: Project;
  @Input() selectedProjectStatus?: number;
  @Input() menuService!: TabMenuLogicService;
  @Input() environmentalAssessment!: string;
  @ViewChild('qcComponent') qcComponent!: EEARunProjectQCComponent;
  @Output() updateEEAMenuLoadingStatus = new EventEmitter<boolean>();
  @Output() showLoadingForOutput = new EventEmitter<boolean>();
  private endpoints: Endpoint[] = [];
  private compartments: Compartment[] = [];
  runQc: Boolean = false;
  loading: boolean = false;
  destroyRef = inject(DestroyRef)
  transactionList: any;
  dataFieldValues: DatafieldValue[] = [];
  activeIngredients: MoleculePkAndName[] = [];
  geographiesList!: Catalog[];
  isProjectOwnershipValid: boolean = false;
  molecules: MoleculePkAndName[] = [];
  activeItem!: CustomTabMenuItem;

  constructor(private runProjectLogicService: EEARunProjectLogicService,
    private runApiService: RunApiService,
    private gapLogicService: EEAGapLogicService,
    private userService: UserLogicService,
    public selectedProjectApiService: SelectedProjectApiService,
    private gapLogicApi: EEAGapApiService,
    private cropListService: CropListService,
    private activeIngredientService: ActiveIngredientApiService,
    public assesmentOutputService: ExposureAssessmentOutputViewApiService) { }

  ngOnInit(): void {
    this.assesmentOutputService.getAllFields();
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async () => {
      if (this.menuService.nextIndex == Constants.TAB_INDEX.OUTPUT || this.menuService.nextIndex == Constants.TAB_INDEXIMEEA.RUN_ERA) {
        if (this.isProjectOwnershipValid) {
          const overwritePreviousRuns = await this.overwritePreviousRuns();
          this.executeModels(overwritePreviousRuns);
        } else {
          this.menuService.setSuccess(true);
        }
      } else {
        this.menuService.setSuccess(true);
      }
    });
    this.setLoadingState(true);
  }

  async overwritePreviousRuns(): Promise<boolean | undefined> {
    if (!this.selectedProject?.previousRuns || this.selectedProject?.previousRuns.length == 0) {
      return false;
    }

    const result = await Swal.fire({
      text: 'Do you want to overwrite the existing project or run it separately?',
      showCancelButton: true,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Overwrite',
      cancelButtonText: 'Run separately',
      icon: 'warning'
    });

    if (result.dismiss && result.dismiss === Swal.DismissReason.backdrop)
      return undefined;

    if (result.dismiss && result.dismiss === Swal.DismissReason.cancel)
      return false;

    return result.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.runQc = false;
      this.isProjectOwnershipValid = this.userService.verifyProjectOwnership(this.selectedProject?.createdBy);
      this.setLoadingState(true);
      this.fillInitValues(changes);
    }
  }

  public fillInitValues(changes: SimpleChanges): void {
    if (!this.selectedProject) return;

    let promises: Promise<any>[] = [
      this.runProjectLogicService.getDataSetsByProject(this.selectedProject.projectPk, Constants.FEATURE_ACRONYM.ENVIRONMENTAL_EXPOSURE_ASSESSMENT),
      this.gapLogicService.getCompartments(),
      this.gapLogicService.getDataFieldValues(),
      this.gapLogicService.getRegionCountry(changes['selectedProject'].currentValue),
      this.runProjectLogicService.getPreviousRunsByProject(this.selectedProject.projectPk)
    ];

    Promise.all(promises)
      .then(([dataSets, compartments, dataFieldValues, geographies, projectPreviousRun]) => {
        if (!this.selectedProject) return;
        this.selectedProject.dataSets = dataSets;
        this.compartments = Utils.createCompartments(compartments);
        this.setCompartments(compartments);
        this.dataFieldValues = dataFieldValues;
        this.geographiesList = geographies;
        this.selectedProject.previousRuns = projectPreviousRun;
        this.createRuns();
      })
      .catch(error => {
        console.error(`Error trying to get Data Error:  ${error}`);
      });
  }

  createRuns() {
    if (!this.selectedProject) return;
    this.runApiService.createRuns(this.selectedProject, this.environmentalAssessment).subscribe({
      next: (runs: Run[]) => {
        this.runs = runs;
        this.getMolecules();
      },
      error: (error: Error) => {
        console.error(error);
      }
    });
  }

  getEndpointsForRuns() {
    this.runProjectLogicService.getRunsEndpoints(this.runs).then((endpoints: Endpoint[]) => {
      this.endpoints = endpoints;
      this.setEndpointsToRuns();
      this.setLoadingState(false)
      this.runQc = true;
    }).catch((error) => {
      console.error(error);
    })
  }

  getMolecules() {
    let activeIngredientsPk = this.runs?.map((x) => x.activeIngredientPk).flat().filter((value, index, self) => self.indexOf(value) === index);
    if (activeIngredientsPk !== undefined) {
      this.runProjectLogicService.getBamsMoleculePkAndNameByPks(activeIngredientsPk as number[]).then((molecules: MoleculePkAndName[]) => {
        this.molecules = molecules;
        this.getEndpointsForRuns();
      }).catch((error) => {
        console.error(error);
      })
    }
  }

  setCompartments(compartments: any) {
    if (!this.selectedProject) return;
    let selectedModels = this.selectedProject?.projectXCompoundXModel?.map(model => model?.ModelName);
    if (selectedModels) {
      let modelsByCompartments = Constants.SPECIFIC_MODELS_BY_COMPARTMENT.filter(x => Utils.getFirstMatchBetweenTwoArrays(selectedModels, x.models));
      this.selectedProject.compartments = Utils.createCompartments(compartments.filter((x: any) => modelsByCompartments.map((m: any) => m.compartment).includes(x.compartment)));
    }
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
  }

  setEndpointsToRuns(): void {
    this.runs.forEach((run) => {
      let runCompartment = this.selectedProject?.compartments?.find(x => x.pk == run.compartmentPk)?.name;
      let endpointExist = this.endpoints.find((x: Endpoint) => x.dataSetPk == run.dataSet?.dataSetPk && x.moleculePk == run.activeIngredientPk && x.groupedEndpointFieldValues && x.groupedEndpointFieldValues.length > 0 && x.endpointTypePk == runCompartment);
      if (endpointExist) {
        let endpoint = JSON.parse(JSON.stringify(endpointExist));
        let runMetaboliteAsParent = run.dataSet?.substanceType === Constants.SUBSTANCE_TYPES.METABOLITE;
        this.setMoleculeNames(run);
        run.bbchRange = this.getBbchRange(run);
        run.compartment = this.compartments.find((compartment: Compartment) => compartment.pk === run.compartmentPk)?.name;
        let metabolites = this.processMetabolites(endpoint.metabolites, run);
        run.metabolites = metabolites;
        runMetaboliteAsParent ? this.setMetaboliteEndpointsToRun(run) : this.setActiveIngredientEndpointsToRun(run, endpoint);
      }
    });
  }

  setActiveIngredientEndpointsToRun(run: Run, endpoint: Endpoint): void {
    run.molecularWeight = endpoint.molecularWeight;
    run.exponent = endpoint.exponent;
    run.substanceName = endpoint.substanceName;
    run.groupedEndpointFieldValues = endpoint.groupedEndpointFieldValues;
    run.precursors = endpoint.precursors;
    run.fne = endpoint.fne;
    run.kdes = endpoint.kdes;
    run.metabolites = run.metabolites.filter((metabolite: any) => metabolite.datasetXMetabolites?.excludeMetabolite ? false : true);
    Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpointValue) => {
      const filteredValues = endpoint.groupedEndpointFieldValues.filter((x: any) => x.key.toLowerCase() == endpointValue.name);
      let value;
      if (filteredValues.length > 0) {
        const fieldValue = filteredValues[0];
        value = fieldValue.value !== null ? fieldValue.value : fieldValue.textValue;
      }
      if (value || value === 0)
        run[endpointValue.field as keyof Run] = value;
      if (endpointValue.field == Constants.DATA_VALUES_NAMES.Kfoc)
        run.kom = Utils.getKomValue(run.kfoc ?? 0);
    });
  }

  setMetaboliteEndpointsToRun(run: Run): void {
    let metabolite = run.metabolites.find((metabolite: any) => metabolite.metabolitePk === run.dataSet?.metabolitePk);
    if (metabolite) {
      run.molecularWeight = metabolite.molecularWeight;
      run.exponent = metabolite.exponent;
      run.substanceName = metabolite.substanceName;
      run.groupedEndpointFieldValues = metabolite.groupedEndpointFieldValues;
      run.precursors = metabolite.precursors;
      run.fne = metabolite.fne;
      run.kdes = metabolite.kdes;
      run.metabolites = run.metabolites.filter((met: any) => met.datasetXMetabolites?.excludeMetabolite || met.metabolitePk == metabolite?.metabolitePk ? false : true);
      Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpointValue) => {
        let endpoint = metabolite?.groupedEndpointFieldValues?.filter((x: any) => x.key.toLowerCase() == endpointValue.name)[0];
        let value = (endpointValue.name == Constants.DATA_VALUES_NAMES.pelmoLetter || endpointValue.name == Constants.DATA_VALUES_NAMES.kineticModel) ? endpoint?.textValue : endpoint?.value;
        if (value != null || value != undefined) {
          run[endpointValue.field as keyof Run] = value;
        }
        if (endpointValue.field == Constants.DATA_VALUES_NAMES.Kfoc)
          run.kom = Utils.getKomValue(run.kfoc ?? 0);
      });
    }
  }

  getStep1n2CropCoverageObject(pNumber: number) {
    for (const range in Constants.STEP1N2_INTERCEPTION_RANGES) {
      const [start, end] = range.split("-");
      if (pNumber >= parseInt(start) && pNumber <= parseInt(end)) {
        return range;
      }
    }
    return null;
  }

  getBbchRange(run: Run): string | null | undefined {
    if (run.model?.name === Constants.MODELS.STEP_1_2) {
      let bbchRange = this.getStep1n2CropCoverageObject(run.applicationSchemeData?.bbchEarliest);
      return bbchRange === Constants.NO_INTERCEPTION_RANGE_SIMPLE ? Constants.NO_INTERCEPTION_RANGE : bbchRange;
    }
    return run.bbchRange;
  }

  processMetabolites(metabolites: any[], parentRun: Run): any[] {
    let metabolitesByRun = parentRun.projectXCompoundXModel?.flatMap((x: ProjectXCompoundXModel) => x.MetabolitePk);
    let compartmentName = this.compartments.find((x: Compartment) => x.pk === parentRun.compartmentPk)?.name;
    let metabolitesToInclude = metabolites.filter((x: any) => metabolitesByRun?.includes(x.metabolitePk) && x.endpointTypePk === compartmentName);
    metabolitesToInclude?.forEach((row) => {
      Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpointValue) => {
        let endpoint = row?.groupedEndpointFieldValues?.filter((x: any) => x.key.toLowerCase() == endpointValue.name)[0];
        let value = (endpointValue.name == Constants.DATA_VALUES_NAMES.pelmoLetter || endpointValue.name == Constants.DATA_VALUES_NAMES.kineticModel) ? endpoint?.textValue : endpoint?.value;
        if (value != null || value != undefined) {
          row[endpointValue.field] = value;
        }
        if (endpointValue.field == Constants.DATA_VALUES_NAMES.Kfoc)
          row.kom = Utils.getKomValue(row.kfoc);
      });

      if (row.precursors != undefined) {
        row.precursors.forEach((precursor: { substanceNamePK: any; metabolitePk: any; moleculePk: any; }) => {
          precursor.substanceNamePK = precursor.metabolitePk ? precursor.metabolitePk : precursor.moleculePk;
        });
      }
    });
    return metabolitesToInclude;
  }

  setMoleculeNames(run: Run): void {
    run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.forEach((item: ApplicationSchemeXActiveIngredientRate) => {
      let molecule = this.molecules.find((x: MoleculePkAndName) => x.moleculePk === item.moleculePk);
      if (molecule)
        item.substanceName = molecule.moleculeName;
    });

    run.applicationSchemeData?.applicationSchemeXApplication?.forEach((application: ApplicationSchemeXApplication) => {
      application?.applicationSchemeXActiveIngredientRate?.forEach((item: ApplicationSchemeXActiveIngredientRate) => {
        let molecule = this.molecules.find((x: MoleculePkAndName) => x.moleculePk === item.moleculePk);
        if (molecule)
          item.substanceName = molecule.moleculeName;
      });
    });
  }

  changeEEAMenuLoadingStatus(status: boolean) {
    this.updateEEAMenuLoadingStatus.emit(status);
  }

  updateLoadingStatusForOutput(status: boolean) {
    this.showLoadingForOutput.emit(status);
  }

  executeModels(overwritePreviousRuns: boolean | undefined): void {
    if (this.runs.length == 0 || overwritePreviousRuns == undefined) {
      this.menuService.setSuccess(true);
      return;
    }

    if (this.qcComponent.missingDataRows.length > 0) {
      this.updateLoadingStatusForOutput(false);
      Swal.fire({
        text: 'Runs with missing data are not going to be executed. Do you still want to proceed?',
        showCancelButton: true,
        confirmButtonColor: '#0069be',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        icon: 'warning'
      }).then((result) => {
        if (result.value) {
          this.changeEEAMenuLoadingStatus(true);
          this.setLoadingState(true);
          let runs: Run[] = this.cleanRun();
          let runModel = RunModelsFactory.createRunModels(runs, this.selectedProject, this.userService?.profile?.displayName ?? '', this.cropListService, this.gapLogicApi, this.gapLogicService, this.selectedProjectApiService, overwritePreviousRuns, this.geographiesList);
          let excludeMacroRunsByLimit: number = Utils.excludeMacroRunsByLimit(runModel);
          if (excludeMacroRunsByLimit > 0) {
            this.changeEEAMenuLoadingStatus(false);
            this.setLoadingState(false);
            let totalRunsForMacro = runModel.swash!.filter(run => run.scenario && run.scenario.startsWith('D')).length;
            runs = this.excludeSwashMacroRuns(runModel, excludeMacroRunsByLimit, runs);
            this.showLimitMacroRunsAlert(runModel, excludeMacroRunsByLimit, runs, totalRunsForMacro);
          } else {
            this.assemblySaveRuns(runs, runModel);
            this.setLoadingState(false);
          }
        }
        else {
          this.updateLoadingStatusForOutput(true);
          this.menuService.setSuccess(true);
        }
        return;
      });
    }
    else {
      this.setLoadingState(true);
      let runModel = RunModelsFactory.createRunModels(this.runs, this.selectedProject, this.userService?.profile?.displayName ?? '', this.cropListService, this.gapLogicApi, this.gapLogicService, this.selectedProjectApiService, overwritePreviousRuns, this.geographiesList);
      let excludeMacroRunsByLimit: number = Utils.excludeMacroRunsByLimit(runModel);
      if (excludeMacroRunsByLimit > 0) {
        let totalRunsForMacro = runModel.swash!.filter(run => run.scenario && run.scenario.startsWith('D')).length;
        this.runs = this.excludeSwashMacroRuns(runModel, excludeMacroRunsByLimit, this.runs);
        this.showLimitMacroRunsAlert(runModel, excludeMacroRunsByLimit, this.runs, totalRunsForMacro);
      } else {
        this.assemblySaveRuns(this.runs, runModel);
        this.setLoadingState(false);
      }
    }
  }

  showLimitMacroRunsAlert(runModel: RunModel, limit: number, runs: Run[], totalRunsForMacro: number): void {
    Swal.fire({
      text: `There are ${totalRunsForMacro} runs to be executed for MACRO SW, and the limit is ${limit}. Do you still want to proceed? If so, the system will cut down the runs up to the limit`,
      showCancelButton: true,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      icon: 'warning'
    }).then((result) => {
      if (result.value) {
        this.changeEEAMenuLoadingStatus(true);
        this.setLoadingState(true);
        this.assemblySaveRuns(runs, runModel);
        this.setLoadingState(false);
      }
      else {
        this.updateLoadingStatusForOutput(true);
        this.menuService.setSuccess(true);
      }
      return;
    });
  }

  excludeSwashMacroRuns(runModel: RunModel, limit: number, runs: Run[]): Run[] {
    let macroRuns = [...runModel.swash!.filter(run => run.scenario!.startsWith('D'))];
    macroRuns = this.sortSwashMacroRunsByPathway(macroRuns);
    let runsToBeExclude = macroRuns.slice(limit).flatMap(run => run.runID);
    runModel.swash = runModel.swash!.filter(r => !runsToBeExclude.includes(r.runID));
    return runs.filter(r => !runsToBeExclude.includes(r.id));
  }

  sortSwashMacroRunsByPathway(swashMacrohInputs: SwashInputs[]): SwashInputs[] {
    return swashMacrohInputs.sort((a, b) => {
      if (a.pathway! < b.pathway!) {
        return -1;
      }
      if (a.pathway! > b.pathway!) {
        return 1;
      }
      return 0;
    });
  }

  async runModels(runModel: RunModel) {
    await this.runApiService.runModels(runModel).pipe(take(1)).subscribe({
      next: () => {
        this.setProjectStatus();
      },
      
      error: (error: any) => {
        console.error(error);
        this.menuService.setSuccess(false)
      }
    });
  }

  setProjectStatus(): void {
    this.runProjectLogicService.getProjectsByPk([this.selectedProject!.projectPk]).then((projects: Project[]) => {
      const status = projects[0]?.status;
      this.selectedProject!.status = status;
      this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status!);
      this.updateLoadingStatusForOutput(true);
      this.menuService.setSuccess(true);
    }).catch((error) => {
      console.warn(error)
      this.menuService.setSuccess(false);
    })
  }

  cleanRun(): Run[] {
    if (this.qcComponent.missingDataRows.length == 0)
      return this.runs;
    let runList: Run[] = [];

    this.runs.forEach((run, index) => {
      let runHasMissingData = this.qcComponent.missingDataRows.find((x: any) => x.run.id === run.id);
      if (!runHasMissingData)
        runList.push(run);
    });
    return runList;
  }

  async assemblySaveRuns(runs: Run[], runModel: RunModel) {
    try {
      await this.getActivesIngredients(runs);
      const saveRun = await this.saveRunObjectAssembly(runs);
      this.runApiService.save(saveRun).pipe(take(1)).subscribe({
        next: () => {
          this.runModels(runModel);
        },
        error: (err: any) => {
          console.warn(err);
          this.menuService.setSuccess(false);
        }
      });
    } catch (error) {
      this.menuService.setSuccess(false);
    }
  }

  async saveRunObjectAssembly(runs: Run[]): Promise<Run[]> {
    let saveRuns: Run[] = [];

    for (const run of runs) {
      const applicationsXRun = await this.applicationXRunAssambly(run);
      saveRuns.push({
        runPk: run?.runPk,
        id: run?.id,
        applicationSchemePk: run?.applicationSchemeData.applicationSchemePk,
        projectPk: this.selectedProject?.projectPk,
        activeIngredientPk: run?.activeIngredientPk,
        activeIngredient: run?.substanceName,
        compartmentPk: run?.applicationSchemeData.compartmentPk,
        dataSetPk: run?.dataSet?.dataSetPk,
        modelCropPk: run?.globalCropPk,
        modelCropXScenarioPk: run?.modelCropXScenario,
        regionAndSeasonPk: run?.regionAndSeason?.regionAndSeasonPk,
        regionAndSeason: run?.regionAndSeason,
        cropDriftInterceptionName: run?.cropDriftInterceptionName,
        model: run.model,
        modelCropXScenario: run?.modelCropXScenario,
        gapXRun: this.gapXRunAssembly(run),
        endpointXRun: this.endpointXRunAssembly(run),
        applicationsXRun: applicationsXRun,
        metabolites: run?.metabolites,
        dataSetName: run?.dataSet?.description,
        location: run.applicationSchemeData.location,
        waterType: run.applicationSchemeData.waterType,
        applicationSchemeData: run.applicationSchemeData,
        applicationInterval: run.applicationSchemeData.applicationInterval != undefined ? run.applicationSchemeData.applicationInterval.toString() : 0,
        waterDiffusionCoefficient: run.waterDiffusionCoefficient,
        featurePk: run.featurePk,
      } as Run);
    }
    return saveRuns;
  }

  gapXRunAssembly(run: Run): GapXRun {
    return {
      applicationMethodPk: run.applicationSchemeData.applicationMethodPk,
      chemicalApplicationMethodPk: run.applicationSchemeData.chemicalApplicationMethodPk,
      soilDepthIncorporated: run.applicationSchemeData.soilDepth,
      dateType: run.applicationSchemeData.dateType,
      cropEvent: run.applicationSchemeData.cropEvent,
      earliestBbch: run.applicationSchemeData.bbchEarliest,
      cropInterception: run.applicationSchemeData.cropInterception,
      firstApplicationDate: run.applicationSchemeData.firstDate,
      beginningDate: run.applicationSchemeData.firstDate,
      endDate: run.applicationSchemeData.endDate,
      emergenceDay: run.applicationSchemeData.emergenceDate,
      harvestDay: run.applicationSchemeData.harvestDate,
      nozzleDriftReduction10m: run.applicationSchemeData.nozzleDriftReduction10m,
      nozzleDriftReduction20m: run.applicationSchemeData.nozzleDriftReduction20m,
      formulation: this.selectedProject?.formulation,
      applicationMethod: run.applicationSchemeData.applicationMethod,
      chemicalApplicationMethod: run.applicationSchemeData.chemicalApplicationMethod,
      specifyYears: run.applicationSchemeData.specifyYear,
      cropCoverage: run.applicationSchemeData.cropCoverage,
      simulationYear: run.applicationSchemeData.simulationYear,
      cropAppDatePk: run.applicationSchemeData.cropAppDatePk,
      cropDriftInterceptionPk: run.applicationSchemeData.cropDriftInterceptionPk,
      cropInterceptionPk: run.applicationSchemeData.cropInterceptionPk,
      cropSprayPk: run.applicationSchemeData.cropSprayPk,
      tillage: run.applicationSchemeData.tillage,
      regionOrCountry: this.getGeographiesByApplicationScheme(run.applicationSchemeData),
    } as GapXRun;
  }

  async applicationXRunAssambly(run: Run): Promise<ApplicationsXRun[]> {
    let applicationsXRun: ApplicationsXRun[] = [];
    if (run.applicationSchemeData.applicationSchemeXActiveIngredientRate) {
      for (const application of run.applicationSchemeData.applicationSchemeXActiveIngredientRate.filter((ai: ApplicationSchemeXActiveIngredientRate) => run.activeIngredientPk == ai.moleculePk)) {
        applicationsXRun.push({
          runPk: run?.runPk,
          rate: application?.rate,
          unitOfMeasure: application?.unitOfMeasure,
          gapPk: application?.applicationSchemePk,
          applicationDate: run.applicationSchemeData.firstDate,
          applicationInterval: run.applicationSchemeData.applicationInterval,
          daysSince: run.applicationSchemeData.daysSince,
          applicationMethod: run.applicationSchemeData.applicationMethod,
          cropInterception: run.applicationSchemeData?.cropInterception,
          earliestBbch: run.applicationSchemeData?.bbchEarliest,
          applicationNumber: run.applicationSchemeData?.numberOfApplications,
          minApplicationInterval: run.applicationSchemeData?.minApplicationInterval,
          activeIngredientPk: application?.moleculePk,
          activeIngredient: run?.substanceName,
        } as ApplicationsXRun);
      }
    }
    return applicationsXRun;
  }

  getSubstanceName(moleculePk: number): Promise<string> {
    let pkValue = [moleculePk];
    return new Promise<string>((resolve, reject) => {
      this.activeIngredientService.getBamsMoleculePkAndNameByPks(pkValue).subscribe(
        actives => {
          resolve(actives[0].moleculeName);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  endpointXRunAssembly(run: Run): EndpointXRun {
    return {
      runPk: run?.runPk,
      id: run?.id,
      solubilityInWater: run?.solubilityInWater,
      saturatedVapourPressure: run?.saturatedVapourPressure,
      kfoc: run?.kfoc,
      kom: run?.kom,
      exponent: run?.exponent,
      coefficientForUptakeByPlant: run?.coefficientForUptakeByPlant,
      halfLifeInSoil: run?.halfLifeInSoil,
      halfLifeinSediment: run?.halfLifeinSediment,
      halfLifeInWater: run?.halfLifeInWater,
      halfLifeInSedimentWaterSystem: run?.halfLifeInSedimentWaterSystem,
      maximumOccurrenceInWaterSediment: run?.maximumOccurrenceInWaterSediment,
      maximumOccurrenceInWater: run?.maximumOccurrenceInWater,
      maximumOccurrenceInSediment: run?.maximumOccurrenceInSediment,
      maximumOccurrenceInSoil: run?.maximumOccurrenceInSoil,
      molecularWeight: run?.molecularWeight,
      substanceType: run?.substanceType,
      precursors: this.setPrecursors(run?.precursors),
      measuredAtSoil: run?.measuredAtSoil,
      measuredAtSediment: run?.measuredAtSediment,
      measuredAtWater: run?.measuredAtWater,
      aqueousPhotolysisHalfLife: run?.aqueousPhotolysisHalfLife,
      photolysisReferenceLatitude: run?.photolysisReferenceLatitude,
      hydrolysisHalflifeDay: run?.hydrolysisHalflifeDay,
      foliarHalflife: run?.foliarHalflife,
      henrysConstant: run?.henrysConstant,
      airDiffusionCoefficient: run?.airDiffusionCoefficient,
      heatOfHenry: run?.heatOfHenry,
      waterDiffusionCoefficient: run?.waterDiffusionCoefficient
    } as EndpointXRun;
  }

  setPrecursors(precursors: Precursor[] | FractionTransformedXEndpoint[] | undefined): FractionTransformedXEndpoint[] {
    let fractionTransformed: FractionTransformedXEndpoint[] = [];
    precursors?.forEach(precursor => {
      fractionTransformed.push({
        endpointPrecursorPk: precursor?.endpointPrecursorPk,
        moleculePk: precursor?.moleculePk,
        metabolitePk: precursor?.metabolitePk,
        formationFraction: precursor?.formationFraction,
        letter: precursor?.letter,
        precursorType: precursor?.precursorType,
        substanceName: precursor?.substanceName
      } as FractionTransformedXEndpoint);
    });
    return fractionTransformed
  }

  isValidRun(pRun: any) {
    var exist = this.qcComponent.missingDataRows.find((x: { runId: any; }) => x.runId === pRun.id)
    if (exist) {
      return exist.onlyMetabolites ? true : false;
    }
    return true
  }


  getGeographiesByApplicationScheme(applicationSchemeData: any) {
    let geographiesNameList: string[] = [];

    applicationSchemeData.applicationSchemeXGeography.forEach((geography: any) => {
      let geographieName = this.geographiesList.filter((x: Catalog) => x.key === geography.geographyPk)[0].name!;

      if (!geographiesNameList.includes(geographieName)) {
        geographiesNameList.push(geographieName);
      }
    });

    return geographiesNameList.join(', ');
  }

  getActivesIngredients(runs: Run[]): Promise<void> {
    let activeIngredientPks: number[] = [];
    runs.forEach(r => {
      if (r?.activeIngredientPk !== undefined) {
        activeIngredientPks.push(r.activeIngredientPk);
      }
    });

    return new Promise<void>((resolve) => {
      this.activeIngredientService.getBamsMoleculePkAndNameByPks(activeIngredientPks).subscribe(actives => {
        this.activeIngredients = actives;
        resolve();
      })
    });
  }
}