import { EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjectXEpaXModelInputsDTO } from 'src/app/shared/models/project-x-epa-x-model-inputs-dto';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { ProjectXEpaXModelInputsRequest } from 'src/app/shared/models/project-x-epa-x-model-inputs-request';

@Injectable({
    providedIn: 'root'
})
export class EPAProjectInputsService {
    private apiUrl: string = environment.BAMS_Api_URL;
    private inputsSubject = new BehaviorSubject<ProjectXEpaXModelInputsDTO | undefined>(undefined);

    @Output() saveProjectEpaInputsEvent: EventEmitter<number> = new EventEmitter<number>();

    constructor(private httpClient: HttpClient) {
        this.saveProjectEpaInputsEvent.subscribe(projectPk =>
            this.saveProjectEpaInputs(projectPk).subscribe()
        )
    }

    public setSelectedEsaInputs(inputsDto: ProjectXEpaXModelInputsDTO) {
        this.inputsSubject.next(inputsDto);
    }

    private saveProjectEpaInputs(projectPk: number): Observable<boolean> {
        const request: ProjectXEpaXModelInputsRequest = {
            data: [this.inputsSubject.value? this.inputsSubject.value : {}]
        }
        request.data.forEach(inputs => { inputs.project_pk = projectPk });
        const endpoint = `${this.apiUrl}api/projects/epa/InputsModel/`;
        const body = JSON.stringify(request);
        return this.httpClient.put(endpoint, body).pipe(map((data: any) => data.Value));
    }

    public getProjectEpaInputs(projectPk: number): Observable<any> {
        const endpoint = `${this.apiUrl}api/projects/${projectPk}/epa/InputsModel/`;
        return this.httpClient.get(endpoint).pipe(map((data: any) => data.Value));
    }
}