import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Constants } from '../../utils/constants';

@Component({
  selector: 'app-listbox',
  templateUrl: './listbox.component.html',
  styleUrls: ['./listbox.component.css'],
})

export class ListboxRenderer implements ICellRendererAngularComp {

  params: any;
  parent: any;
  selectedValues: number[] = [];
  options: any;
  optionValue: any;
  optionLabel: any;
  checkbox: boolean = false;
  filter: boolean = false;
  multiple: boolean = false;
  showToggleAll: boolean = false;
  disabled: boolean = false;
  module: string = '';

  constructor() { }

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.parent = params.context;
    this.optionValue = params.optionValue;
    this.optionLabel = params.optionLabel;
    this.disabled = params.disabled ?? false;
    this.checkbox = params.checkbox;
    this.filter = params.filter;
    this.multiple = params.multiple;
    this.selectedValues = params.value;
    this.options = params.options;
    if (!params.disabled && params.validateModel) this.validateModel();
  }

  validateModel() {
    let paramsCompartment: any[] = this.params.data.compartments;
    this.module = this.params.module;
    let columnCompartment: string = this.params.compartment;
    if (this.module === '') {
      this.disabled = !this.params.data.compartments.map((x: string) => x.toLowerCase()).includes(this.params.compartment.toLowerCase());
    }
    else {
      this.validateEraModule(this.module, paramsCompartment, columnCompartment);
    }
  }

  defaultValueIndex() {
    return this.options.findIndex((x: any) => {
      if (x.isDefault && x.isDefault === true) {
        return true;
      }
      return false;
    });
  }

  validateEraModule(module: string, paramsData: any[], columnCompartment: string) {
    if (module != undefined && paramsData.length > 0 && module === Constants.FEATURE_ACRONYM.ENVIRONMENTAL_RISK_ASSESSMENT && columnCompartment === Constants.COMPARTMENTS.SURFACE_WATER) {
      this.disabled = !paramsData.map((x: string) => x.toLowerCase()).includes(columnCompartment.toLocaleLowerCase());
    } else {
      let exist: boolean = false;
      if (module != undefined && paramsData.length > 0 && columnCompartment === Constants.ECOTOXICOLOGY_COMPARMENTS.TERRESTRIAL) {
        exist = paramsData.map((soil) => soil.toLowerCase()).includes(Constants.COMPARTMENTS.SOIL.toLowerCase());
        if (!exist) exist = paramsData.map((soil) => soil.toLowerCase()).includes(Constants.ECOTOXICOLOGY_COMPARMENTS.PLANT.toLowerCase());
        if (!exist) exist = paramsData.map((terrestrial) => terrestrial.toLowerCase()).includes(Constants.ECOTOXICOLOGY_COMPARMENTS.TERRESTRIAL.toLowerCase());
        this.disabled = !exist;
      }

    }
  }

  onChange() {
    this.parent.listBoxSelectionChanged({
      id: this.params.node.id,
      selectedValues: this.selectedValues,
      data: this.params.data,
      compartment: this.params.compartment
    });
  }
}
