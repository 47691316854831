<input #inputRef
  *ngIf="!disabled"
  type="text"
  pInputText [(ngModel)]="value"
  (blur)="onBlur($event)"
  [ngClass]="isValid? 'ng-valid' : 'ng-invalid ng-dirty p-inputtext-invalid'"
  [class.ng-valid]="!params.data.isDuplicate"
  [class.ng-invalid]="params.data.isDuplicate"
  (keypress)="onKeyPress($event)"
  (keyup)="onKeyUp($event)"
  (paste)="omitIllegalCharactersOnPaste($event)"
/>

<span *ngIf="disabled">{{value}}</span>
