import { Component, DestroyRef, EventEmitter, Input, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { Compartment } from 'src/app/shared/models/compartment';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { EEARunProjectQCComponent } from '../shared/eea-run-project-qc/eea-run-project-qc.component';
import { RunApiService } from 'src/app/shared/services/run.api.service';
import { EEARunProjectLogicService } from '../eea-run-project.logic.service';
import { EEAGapLogicService } from '../../eea-gap/eea-gap.logic.service';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { Endpoint } from 'src/app/shared/models/endpoint';
import { MoleculePkAndName } from 'src/app/shared/models/echo/molecule';
import { ApplicationSchemeXActiveIngredientRate } from 'src/app/shared/models/application-scheme';
import { ProjectXCompoundXModel } from 'src/app/shared/models/project-x-compound-x-model';
import { Run } from 'src/app/shared/models/run/run';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import Swal from 'sweetalert2';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { ExposureAssessmentOutputViewApiService } from '../../eea-output/eea-output/eea-output.api.service';
import { take } from 'rxjs';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { PWCApiService } from 'src/app/shared/services/pwc.api.service';
import { RunModelsFactory } from '../shared/run-models-factory';
import { RunModel } from 'src/app/shared/models/run/run-model';
import { Chemical } from 'src/app/shared/models/pwc/chemical';
import { ApplicationMethod } from 'src/app/shared/models/pwc/application-method';
import { Applications } from 'src/app/shared/models/pwc/applications';
import { ApplicationSchemePWC, PWCInputs, Scenarios, WaterShed } from 'src/app/shared/models/run/pwc-inputs';
import { CropXRegionUI } from 'src/app/shared/models/pwc/scenarios.interface';
import { RunDetails } from 'src/app/shared/models/run/run-details';
import { EeaGapSurfacewaterPwcScenariosLogicService } from '../../eea-gap/eea-gap-surfacewater-pwc/eea-gap-surfacewater-pwc-scenarios/eea-gap-surfacewater-pwc-scenarios.logic.service';

@Component({
  selector: 'app-eea-run-project-pwc',
  templateUrl: './eea-run-project-pwc.component.html',
  styleUrls: ['./eea-run-project-pwc.component.css']
})
export class EEARunProjectPwcComponent {
  @Input() selectedProject?: Project;
  @Input() environmentalAssessment!: string;
  @Input() menuService!: TabMenuLogicService;
  @Input() selectedProjectStatus?: number;

  @Output() updateEEAMenuLoadingStatus = new EventEmitter<boolean>();
  @Output() showLoadingForOutput = new EventEmitter<boolean>();

  @ViewChild('qcComponent') qcComponent!: EEARunProjectQCComponent;

  private compartments: Compartment[] = [];
  private endpoints: Endpoint[] = [];

  private pwcScenariosLogicService = inject(EeaGapSurfacewaterPwcScenariosLogicService);

  runQc: Boolean = false;
  loading: boolean = false;
  dataFieldValues: DatafieldValue[] = [];
  geographiesList!: Catalog[];
  runs: Run[] = [];
  molecules: MoleculePkAndName[] = [];
  destroyRef = inject(DestroyRef)
  isProjectOwnershipValid: boolean = false;

  constructor(private runProjectLogicService: EEARunProjectLogicService,
    private runApiService: RunApiService,
    private gapLogicService: EEAGapLogicService,
    private userService: UserLogicService,
    public selectedProjectApiService: SelectedProjectApiService,
    private runService: PWCApiService
  ) { }

  ngOnInit(): void {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async () => {
      if (this.menuService.nextIndex == Constants.TAB_INDEX.OUTPUT || this.menuService.nextIndex == Constants.TAB_INDEXIMEEA.RUN_ERA) {
        if (this.isProjectOwnershipValid) {
          await  this.getPreviusRuns();
          const overwritePreviousRuns = await this.overwritePreviousRuns();
          this.executeModels(overwritePreviousRuns);
        } else {
          this.menuService.setSuccess(true);
        }
      } else {
        this.menuService.setSuccess(true);
      }
    });
    this.setLoadingState(true);
  }


  async getPreviusRuns(): Promise<void> {
     this.runProjectLogicService.getPreviousRunsByProject(this.selectedProject!.projectPk);
  }

  async overwritePreviousRuns(): Promise<boolean | undefined> {
    if (!this.selectedProject?.previousRuns || this.selectedProject?.previousRuns.length == 0) {
      return false;
    }

    const result = await Swal.fire({
      text: 'Do you want to overwrite the existing project or run it separately?',
      showCancelButton: true,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Overwrite',
      cancelButtonText: 'Run separately',
      icon: 'warning'
    });

    if (result.dismiss && result.dismiss === Swal.DismissReason.backdrop)
      return undefined;

    if (result.dismiss && result.dismiss === Swal.DismissReason.cancel)
      return false;

    return result.value;
  }

  async executeModels(overwritePreviousRuns: boolean | undefined): Promise<void> {
    if (this.runs.length == 0 || overwritePreviousRuns == undefined) {
      this.menuService.setSuccess(true);
      return;
    }
    if (this.qcComponent.missingDataRows.length > 0) {
      this.updateLoadingStatusForOutput(false);
      Swal.fire({
        text: 'Runs with missing data are not going to be executed. Do you still want to proceed?',
        showCancelButton: true,
        confirmButtonColor: '#0069be',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        icon: 'warning'
      }).then(async (result) => {
        if (result.value) {
          this.changeEEAMenuLoadingStatus(true);
          this.setLoadingState(true);
          let runs: Run[] = this.cleanRun();
          let runModel = await RunModelsFactory.createRunModelsForPWC(runs, this.selectedProject, this.userService?.profile?.displayName ?? '',this.selectedProjectApiService, overwritePreviousRuns);
          runModel.pwc = await this.CreatePwcObject(runs)
          this.assemblySaveRuns(runModel);
          this.setLoadingState(false);
        }
        else {
          this.updateLoadingStatusForOutput(true);
          this.menuService.setSuccess(true);
        }
        return;
      });
    }
    else {
      this.setLoadingState(true);
      let runModel = await RunModelsFactory.createRunModelsForPWC(this.runs, this.selectedProject, this.userService?.profile?.displayName ?? '',this.selectedProjectApiService, overwritePreviousRuns);
      runModel.pwc = await this.CreatePwcObject(this.runs)
      this.assemblySaveRuns(runModel);
      this.setLoadingState(false);
    }
  }

  async assemblySaveRuns(runModel: RunModel) {
    try {
      this.runApiService.savePWC(runModel.pwc).pipe(take(1)).subscribe({
        next: () => {
          this.runModels(runModel);
        },
        error: (err: any) => {
          console.warn(err);
          this.menuService.setSuccess(false);
        }
      });
    } catch (error) {
      console.warn(error);
      this.menuService.setSuccess(false);
    }
  }

  runModels(runModel: RunModel) {
    this.runApiService.runModels(runModel).pipe(take(1)).subscribe({
      next: () => {
        this.setProjectStatus();
      },
      error: (error: any) => {
        console.error(error);
        this.menuService.setSuccess(false)
      }
    });
  }

  setProjectStatus(): void {
    this.runProjectLogicService.getProjectsByPk([this.selectedProject!.projectPk]).then((projects: Project[]) => {
      const status = projects[0]?.status;
      this.selectedProject!.status = status;
      this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status!);
      this.updateLoadingStatusForOutput(true);
      this.menuService.setSuccess(true);
    }).catch((error) => {
      console.warn(error)
      this.menuService.setSuccess(false);
    })
  }

  cleanRun(): Run[] {
    if (this.qcComponent.missingDataRows.length == 0)
      return this.runs;
    let runList: Run[] = [];

    this.runs.forEach((run, index) => {
      let runHasMissingData = this.qcComponent.missingDataRows.find((x: any) => x.run.id === run.id);
      if (!runHasMissingData)
        runList.push(run);
    });
    return runList;
  }

  updateLoadingStatusForOutput(status: boolean) {
    this.showLoadingForOutput.emit(status);
  }

  changeEEAMenuLoadingStatus(status: boolean) {
    this.updateEEAMenuLoadingStatus.emit(status);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.runQc = false;
      this.isProjectOwnershipValid = this.userService.verifyProjectOwnership(this.selectedProject?.createdBy);
      this.setLoadingState(true);
      this.fillInitValues(changes);
    }
  }

  public fillInitValues(changes: SimpleChanges): void {
    if (!this.selectedProject) return;

    let promises: Promise<any>[] = [
      this.runProjectLogicService.getDataSetsByProject(this.selectedProject.projectPk, Constants.FEATURE_ACRONYM.ENVIRONMENTAL_EXPOSURE_ASSESSMENT),
      this.gapLogicService.getCompartments(),
      this.gapLogicService.getDataFieldValues(),
      this.gapLogicService.getRegionCountry(changes['selectedProject'].currentValue),
      this.runProjectLogicService.getPreviousRunsByProject(this.selectedProject.projectPk)
    ];

    Promise.all(promises)
      .then(([dataSets, compartments, dataFieldValues, geographies, projectPreviousRun]) => {
        if (!this.selectedProject) return;
        this.selectedProject.dataSets = dataSets;
        this.runService.getPWCDataSetsByDataSetPks(dataSets.map((x: DataSet) => x.dataSetPk!)).subscribe((data: DataSet[]) => {
          this.selectedProject!.dataSets!.forEach((row: DataSet) => {
            const pwcDataSet = data.find((x: DataSet) => x.dataSetPk == row.dataSetPk);

            row.pwcXDataSetOptionalOutput = pwcDataSet?.pwcXDataSetOptionalOutput;
            row.pwcXDataSetXSimulationType = pwcDataSet?.pwcXDataSetXSimulationType?.length! > 0 ? pwcDataSet?.pwcXDataSetXSimulationType : undefined;
            row.useSprayDriftBuffersForTpez = pwcDataSet?.useSprayDriftBuffersForTpez;
          });

          this.compartments = Utils.createCompartments(compartments);
          this.setCompartments(compartments);
          this.dataFieldValues = dataFieldValues;
          this.geographiesList = geographies;
          this.selectedProject!.previousRuns = projectPreviousRun;
          this.createRuns();
        });
      })
      .catch(error => {
        console.error(`Error trying to get Data Error:  ${error}`);
      });
  }

  createRuns() {
    if (!this.selectedProject) return;
    this.runApiService.createRunsPWC(this.selectedProject, this.environmentalAssessment).subscribe({
      next: (runs: Run[]) => {
        this.runs = runs;
        this.getMolecules();
      },
      error: (error: Error) => {
        console.error(error);
      }
    });
  }

  getMolecules() {
    let activeIngredientsPk = this.runs?.map((x) => x.activeIngredientPk).flat().filter((value, index, self) => self.indexOf(value) === index);
    if (activeIngredientsPk !== undefined) {
      this.runProjectLogicService.getBamsMoleculePkAndNameByPks(activeIngredientsPk as number[]).then((molecules: MoleculePkAndName[]) => {
        this.molecules = molecules;
        this.getEndpointsForRuns();
      }).catch((error) => {
        console.error(error);
      })
    }
  }

  getEndpointsForRuns() {
    this.runProjectLogicService.getRunEndpointsForPWC(this.runs).then((endpoints: Endpoint[]) => {
      this.endpoints = endpoints;
      this.setEndpointsToRuns();
      this.setAppSchemesNamesForPWC();
      this.setLoadingState(false)
      this.runQc = true;
    }).catch((error) => {
      console.error(error);
    })
  }

  setAppSchemesNamesForPWC() {
    this.runs.forEach((run: Run) => {
      let names: string[] = [];
      run.applicationSchemeDataList.forEach((scheme: any) => {
        names.push(scheme.name);
      });
      run.applicationSchemeData = { name: names.join(', ') };
    })
  }

  setEndpointsToRuns(): void {
    this.runs.forEach((run: Run) => {
      let runCompartment = this.selectedProject?.compartments?.find(x => x.pk == run.compartmentPk)?.name;
      let endpointExist = this.endpoints.find((x: Endpoint) => x.dataSetPk == run.dataSet?.dataSetPk && x.moleculePk == run.activeIngredientPk && x.groupedEndpointFieldValues && x.groupedEndpointFieldValues.length > 0 && x.endpointTypePk == runCompartment);
      if (endpointExist) {
        let endpoint = JSON.parse(JSON.stringify(endpointExist));
        let runMetaboliteAsParent = run.dataSet?.substanceType === Constants.SUBSTANCE_TYPES.METABOLITE;
        this.setMoleculeNames(run);
        run.compartment = this.compartments.find((compartment: Compartment) => compartment.pk === run.compartmentPk)?.name;
        let metabolites = this.processMetabolites(endpoint.metabolites, run);
        run.metabolites = metabolites;
        this.setActiveIngredientEndpointsToRun(run, endpoint);
        runMetaboliteAsParent ? this.setMetaboliteEndpointsToRun(run, endpoint) : this.setActiveIngredientEndpointsToRun(run, endpoint);
      }
    });
  }

  setMetaboliteEndpointsToRun(run: Run, endpoint: Endpoint): void {
    let metabolite = run.metabolites.find((metabolite: any) => metabolite.metabolitePk === run.dataSet?.metabolitePk);
    if (metabolite) {
      run.molecularWeight = metabolite.molecularWeight;
      run.exponent = metabolite.exponent;
      run.substanceName = metabolite.substanceName;
      run.groupedEndpointFieldValues = metabolite.groupedEndpointFieldValues;
      run.precursors = metabolite.precursors;
      run.fne = metabolite.fne;
      run.kdes = metabolite.kdes;
      run.metabolites = run.metabolites;
      run.pwcXDataSetOptionalOutput = this.selectedProject?.dataSets?.find((x: DataSet) => x.dataSetPk == run.dataSet?.dataSetPk)?.pwcXDataSetOptionalOutput;
      run.pwcXDataSetXSimulationType = this.selectedProject?.dataSets?.find((x: DataSet) => x.dataSetPk == run.dataSet?.dataSetPk)?.pwcXDataSetXSimulationType;
      run.useSprayDriftBuffersForTpez = this.selectedProject?.dataSets?.find((x: DataSet) => x.dataSetPk == run.dataSet?.dataSetPk)?.useSprayDriftBuffersForTpez;
      Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpointValue) => {
        let endpoint = metabolite?.groupedEndpointFieldValues?.filter((x: any) => x.key.toLowerCase() == endpointValue.name)[0];
        let value = (endpointValue.name == Constants.DATA_VALUES_NAMES.pelmoLetter || endpointValue.name == Constants.DATA_VALUES_NAMES.kineticModel) ? endpoint?.textValue : endpoint?.value;
        if (value != null || value != undefined) {
          run[endpointValue.field as keyof Run] = value;
        }
        if (endpointValue.field == Constants.DATA_VALUES_NAMES.Kfoc)
          run.kom = Utils.getKomValue(run.kfoc ?? 0);
      });
    }
  }

  setActiveIngredientEndpointsToRun(run: Run, endpoint: Endpoint): void {
    run.molecularWeight = endpoint.molecularWeight;
    run.exponent = endpoint.exponent;
    run.substanceName = endpoint.substanceName;
    run.groupedEndpointFieldValues = endpoint.groupedEndpointFieldValues;
    run.precursors = endpoint.precursors;
    run.fne = endpoint.fne;
    run.kdes = endpoint.kdes;
    run.metabolites = run.metabolites;
    run.pwcXDataSetOptionalOutput = this.selectedProject?.dataSets?.find((x: DataSet) => x.dataSetPk == run.dataSet?.dataSetPk)?.pwcXDataSetOptionalOutput;
    run.pwcXDataSetXSimulationType = this.selectedProject?.dataSets?.find((x: DataSet) => x.dataSetPk == run.dataSet?.dataSetPk)?.pwcXDataSetXSimulationType;
    run.useSprayDriftBuffersForTpez = this.selectedProject?.dataSets?.find((x: DataSet) => x.dataSetPk == run.dataSet?.dataSetPk)?.useSprayDriftBuffersForTpez;
    Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpointValue) => {
      const filteredValues = endpoint.groupedEndpointFieldValues.filter((x: any) => x.key.toLowerCase() == endpointValue.name);
      let value;
      if (filteredValues.length > 0) {
        const fieldValue = filteredValues[0];
        value = fieldValue.value !== null ? fieldValue.value : fieldValue.textValue;
      }
      if (value || value === 0)
        run[endpointValue.field as keyof Run] = value;
      if (endpointValue.field == Constants.DATA_VALUES_NAMES.Kfoc)
        run.kom = Utils.getKomValue(run.kfoc ?? 0);
    });
  }

  processMetabolites(metabolites: any[], parentRun: any): any[] {
    let metabolitesByRun = parentRun.projectXCompoundXModel?.flatMap((x: ProjectXCompoundXModel) => x.MetabolitePk);
    let compartmentName = this.compartments.find((x: Compartment) => x.pk === parentRun.compartmentPk)?.name;
    let metabolitesToInclude = metabolites.filter((x: any) => metabolitesByRun?.includes(x.metabolitePk) && x.endpointTypePk === compartmentName);
    metabolitesToInclude?.forEach((row) => {
      Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpointValue) => {
        const filteredValues = row?.groupedEndpointFieldValues.filter((x: any) => x.key.toLowerCase() == endpointValue.name.toLowerCase());
        let value;
        if (filteredValues.length > 0) {
          const fieldValue = filteredValues[0];
          value = fieldValue.value !== null ? fieldValue.value : fieldValue.textValue;
        }
        if (value || value === 0)
          row[endpointValue.field as keyof Endpoint] = value;
        if (endpointValue.field == Constants.DATA_VALUES_NAMES.Kfoc)
          row.kom = Utils.getKomValue(row.kfoc ?? 0);
      });

      if (row.precursors != undefined) {
        row.precursors.forEach((precursor: { substanceNamePK: any; metabolitePk: any; moleculePk: any; }) => {
          precursor.substanceNamePK = precursor.metabolitePk ? precursor.metabolitePk : precursor.moleculePk;
        });
      }


    });
    return metabolitesToInclude;
  }

  setMoleculeNames(run: any): void {
    run.applicationSchemeDataList?.forEach((appScheme: any) => {
      appScheme.applicationSchemeXActiveIngredientRate?.forEach((item: ApplicationSchemeXActiveIngredientRate) => {
        let molecule = this.molecules.find((x: MoleculePkAndName) => x.moleculePk === item.moleculePk);
        if (molecule)
          item.substanceName = molecule.moleculeName;
      });
    });
  }

  setCompartments(compartments: any) {
    if (!this.selectedProject) return;
    let selectedModels = this.selectedProject?.projectXCompoundXModel?.map(model => model?.ModelName);
    if (selectedModels) {
      let modelsByCompartments = Constants.SPECIFIC_MODELS_BY_COMPARTMENT.filter(x => Utils.getFirstMatchBetweenTwoArrays(selectedModels, x.models));
      this.selectedProject.compartments = Utils.createCompartments(compartments.filter((x: any) => modelsByCompartments.map((m: any) => m.compartment).includes(x.compartment)));
    }
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
  }

  async CreatePwcObject(runs: Run[]): Promise<RunDetails[] | undefined> {
    await this.pwcScenariosLogicService.getCropsXRegion();
    let saveRuns: RunDetails[] = [];

    runs.forEach((run: Run) => {
      saveRuns.push({
        runId: run?.id,
        projectPk: this.selectedProject?.projectPk,
        model: Constants.RUN_DETAIL_PWC_VALUE.MODEL,
        modelVersion: Constants.RUN_DETAIL_PWC_VALUE.MODEL_VERSION,
        creationDate: new Date().toDateString(),
        runDetails: this.createPwcRunDetailsObject(run)
      } as RunDetails);
    });

    return saveRuns;
  }

  createPwcRunDetailsObject(run: Run): PWCInputs {
    let pwcInputs: PWCInputs = new PWCInputs();
    pwcInputs.runId = run?.id;
    pwcInputs.projectName = this.selectedProject?.name;
    pwcInputs.dataSetName = run?.dataSet?.description;
    pwcInputs.Parent = this.getParentChemicalInfo(run);
    pwcInputs.Daugther = this.getChildChemicalInfo(run, Constants.CHILD_LEVEL.DAUGHTER);
    pwcInputs.Granddaughter = this.getChildChemicalInfo(run, Constants.CHILD_LEVEL.GRANDDAUGHTER);
    pwcInputs.WaterShed = this.getWaterShedData(run);
    pwcInputs.OptionalOutputs = run.pwcXDataSetOptionalOutput;
    pwcInputs.applicationScheme = this.getApplicationSchemes(run);

    return pwcInputs;
  }

  getWaterShedData(run: Run): WaterShed {
    return { useSprayDrift: run.useSprayDriftBuffersForTpez ?? false, simulationTypes: run.pwcXDataSetXSimulationType };
  }

  getApplicationSchemes(run: Run): ApplicationSchemePWC[] {
    const result: ApplicationSchemePWC[] = [];

    run.applicationSchemeDataList.forEach((scheme: any) => {
      let appScheme: ApplicationSchemePWC = {
        name: scheme?.name,
        applications: this.getApplication(scheme, run.dataSet!.moleculePk!),
        scenarios: this.getScenarios(scheme.cropXRegionPks)
      }

      result.push(appScheme);
    });
    return result;
  }

  getScenarios(cropXRegionPks: any): Scenarios[] {
    const result: Scenarios[] = [];
    let selectedScenarios = this.pwcScenariosLogicService.cropXRegion.filter((c: any) => cropXRegionPks?.includes(c.crop_x_region_pk));

    selectedScenarios.forEach((scenario: CropXRegionUI) => {
      result.push({
        cropName: scenario.crop,
        regionName: scenario.region
      } as Scenarios)
    })
    return result;
  }

  getApplication(scheme: any, moleculePk: number): Applications {
    const result: Applications = {
      numberOfApplications: scheme?.numberOfApplications,
      absolute: scheme?.absolute === Constants.DATE_TYPE_VALUES.ABSOLUTE ? true : false,
      windowsDaysToWindowsDays: scheme?.windowsDaysToWindowsDays,
      stepDaysToStepDays: scheme?.stepDaysToStepDays,
      cropEvent: scheme?.cropEvent,
      useApplicationWindow: scheme?.useApplicationWindow ?? false,
      adjustApplicationDatesIfRaining: scheme?.adjustApplicationDatesIfRaining ?? false,
      intolerableRainCm: scheme?.intolerableRainCm,
      intolerableRainWindowDays: scheme?.intolerableRainWindowDays,
      optimumApplicationWindowDays: scheme?.optimumApplicationWindowDays,
      minimumDaysBetweenApplications: scheme?.minimumDaysBetweenApplications,
      applicationMethods: this.getApplicationMethod(scheme, moleculePk)
    }

    return result;
  }

  getApplicationMethod(scheme: any, moleculePk: number): ApplicationMethod[] {
    const result: ApplicationMethod[] = [];

    scheme.applicationSchemeXApplication.forEach((app: any) => {
      let applicationMethod: ApplicationMethod = {
        amountKgHa: scheme.applicationSchemeXActiveIngredientRate.find((rate: any) => rate.applicationNumber == app.application_number && rate.moleculePk === moleculePk).rate,
        date: app?.application_date,
        daySince: app?.days_since,
        method: this.getMethodValue(app.application_method),
        depthCm: app?.depth,
        tBandSplit: app?.t_band_split,
        driftType: this.getDriftType(app?.drift_type),
        driftBuffer: app.drift,
        period: app?.period,
        lag: app?.lag,
      }

      result.push(applicationMethod);
    });

    return result;
  }

  getDriftType(driftType: string): string | undefined {
    const foundID = Object.entries(Constants.PWC_DRIFT_TYPES_DICTIONARY).
      find(([_, value]) => value === driftType
      );
    return foundID ? foundID[0] : undefined;
  }

  getMethodValue(method: string): number {
    let result: number = 0;

    switch (method) {
      case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_BELOW_CROP:
        result = 1;
        break;
      case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_ABOVE_CROP:
        result = 2;
        break;
      case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_LINEARLY_UNIFORM:
        result = 3;
        break;
      case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_LINEARLY_INCREASING:
        result = 6;
        break;
      case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_LINEARLY_DECREASING:
        result = 7;
        break;
      case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_T_BAND_SPLIT:
        result = 5;
        break;
      case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_DEPTH:
        result = 4;
        break;
    }

    return result;
  }

  getParentChemicalInfo(run: Run): Chemical {    
    const result: Chemical = {
      compound: run?.substanceName,
      sorptionCoeffMlG: run?.kfoc,
      waterColumnMetabolismHalflifeDay: run?.halfLifeInWater,
      WaterReferenceTemperatureC: run?.measuredAtWater,
      benthicMetabolismHalflifeDay: run?.halfLifeinSediment,
      benthicReferenceTemperatureC: run?.measuredAtSediment,
      aqueousPhotolysisHalfLifeDay: run?.aqueousPhotolysisHalfLife,
      photolysisReferenceLatitudeN: run?.photolysisReferenceLatitude,
      hydrolysisHalflifeDay: run?.hydrolysisHalflifeDay,
      soilHalflifeDay: run?.halfLifeInSoil,
      soilReferenceTemperatureC: run?.measuredAtSoil,
      foliarHalflifeDay: run?.foliarHalflife,
      molecularWeightGMl: run?.molecularWeight,
      vaporPresureTorr: run?.saturatedVapourPressure,
      solubilityMgL: run?.solubilityInWater,
      henrysCoefficient: run?.henrysConstant,
      airDifusionCoefficientCm2Day: run?.airDiffusionCoefficient,
      isKoc: run?.isKoc != null ? Boolean(run?.isKoc) : false,
      heatOfHenry: run?.heatOfHenry,
      foliarWashOff: run?.foliarWashOfFactor,
    }
    return result;
  }

  getChildChemicalInfo(run: Run, childLevel: string): Chemical | undefined {
    let metPk = childLevel == Constants.CHILD_LEVEL.DAUGHTER ? run.dataSet?.daughterPk : run.dataSet?.granddaughterPk;
    if (metPk == null)
      return undefined;

    let child = run.metabolites.find((met: Endpoint) => met.metabolitePk == metPk);

    const result: Chemical = {
      compound: child?.substanceName,
      sorptionCoeffMlG: child?.kfoc,
      waterColumnMetabolismHalflifeDay: child?.halfLifeInWater,
      WaterReferenceTemperatureC: child?.measuredAtWater,
      soilReferenceTemperatureC: child?.measuredAtSoil,
      benthicReferenceTemperatureC: child?.measuredAtSediment,
      aqueousPhotolysisHalfLifeDay: child?.aqueousPhotolysisHalfLife,
      photolysisReferenceLatitudeN: child?.photolysisReferenceLatitude,
      foliarHalflifeDay: child?.foliarHalflife,
      henrysCoefficient: child?.henrysConstant,
      airDifusionCoefficientCm2Day: child?.airDiffusionCoefficient,
      benthicMetabolismHalflifeDay: child?.halfLifeinSediment,
      hydrolysisHalflifeDay: child?.hydrolysisHalflifeDay,
      soilHalflifeDay: child?.halfLifeInSoil,
      molecularWeightGMl: child?.molecularWeight,
      vaporPresureTorr: child?.saturatedVapourPressure,
      solubilityMgL: child?.solubilityInWater,
      heatOfHenry: child?.heatOfHenryJMol,
      foliarWashOff:child?.foliarWashOfFactor,
      molewaterColumnHalfLifePerParentDegraded: child?.molesWaterColumnDaughter,
      molewaterColumnHalfLifePerDaugtherDegraded: child?.molesWaterColumnGranddaughter,
      moleBethicHalflifeDaysPerParentDegraded: child?.molesBenthicDaughter,
      moleBethicHalflifeDaysPerDaugtherDegraded: child?.molesBenthicGranddaughter,
      molePhotolysisAqueousHalfLifePerParentDegraded: child?.molesAqueousPhotolysisDaughter,
      molePhotolysisAqueousHalfLifePerrDaugtherDegraded: child?.molesAqueousPhotolysisGranddaughter,
      moleHydrolysisHalfLifePerParentDegraded: child?.molesHydrolysisHalflifeDaughter,
      moleHydrolysisHalfLifePerrDaugtherDegraded: child?.molesHydrolysisHalflifeGranddaughter,
      moleSoilHalfLifePerParentDegraded: child?.molesSoilHalflifeDaughter,
      moleSoilHalfLifePerrDaugtherDegraded: child?.molesSoilHalflifeGranddaughter,
      moleFoliarHalfLifePerParentDegraded: child?.molesFoliarHalflifeDaughter,
      moleFoliarHalfLifePerDaugtherDegraded: child?.molesFoliarHalflifeGranddaughter,
    }
    return result;
  }
}
