import { Component, effect, EventEmitter, inject, Input, OnChanges, OnDestroy, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Accordion, AccordionTabCloseEvent } from 'primeng/accordion';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { ModelLibraryCollection } from 'src/app/shared/models/model-library';
import { Project } from 'src/app/shared/models/project';
import { Utils } from 'src/app/shared/utils/utils';
import { SetupLogicService } from 'src/app/shared/services/setup.logic.service';
import { RowDragEvent, RowNode } from 'ag-grid-community';

@Component({
  selector: 'app-eea-setup-models-to-be-run',
  templateUrl: './eea-setup-models-to-be-run.component.html',
  styleUrls: ['./eea-setup-models-to-be-run.component.css']
})
export class EEASetupModelsToBeRunComponent implements OnChanges, OnDestroy {

  @ViewChild('accordion') accordion!: Accordion;
  @ViewChild('grid') grid!: GridComponent;
  @Input() modelsToBeRun: any[] = [];
  @Input() columnsDefinition: any = [];
  @Input() selectedProject?: Project;
  @Input() modelsLoading: boolean = false;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() isEEA: boolean = false;
  @Input() modelsCollection: ModelLibraryCollection[] = [];
  @Output() areSetupModelsValidEvent = new EventEmitter<boolean>();
  @Output() areValidateAlternativeNameCompoundEvent = new EventEmitter<boolean>();
  @Output() showAlternativeNameCompoundLabel = new EventEmitter<boolean>();
  @Output() orderedModelsToBeRunRowData = new EventEmitter();
  gridStyle: string = 'width: auto; height: 600px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  rowHeight: number = 180;
  selected = false;
  activeIndex: number | null = null;
  isValid: boolean = true;

  public showModels:boolean = false;

  private setupLogicService = inject(SetupLogicService);

  selectedGeographyEffect = effect(() => {
    this.showModels = this.setupLogicService.selectedGeographyKey() !== undefined;
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modelsToBeRun']) {
      this.activeIndex = null;
      this.validateSelectedModels();
    }
  }

  onClose(event: AccordionTabCloseEvent) {
    this.activeIndex = null;
  }

  onOpen({ index, originalEvent }: AccordionTabCloseEvent) {
    if (!this.modelsToBeRun[index].selected) {
      originalEvent.stopPropagation();
      const tab = this.accordion.tabs.find(t => t.findTabIndex() == index);
      if (tab) tab.selected = false;
      this.activeIndex = null;
      return;
    }
    this.activeIndex = index;
  }

  modelsListBoxSelectionChanged({ data, selectedValues, compartment }: any) {
    if (!this.selectedProject) return;
    data[compartment] = selectedValues;
    this.setCompoundOrder();
    this.validateSelectedModels();
    this.onValidateAlternativeNameCompound();
    this.onValidateToShowAlternativeNameCompoundLabel();
    this.grid.gridApi.redrawRows();
  }

  validateSelectedModels() {
    this.isValid = false;
    this.modelsToBeRun.map(model => {
      model.rowData.map((row: any) => {
        this.modelsCollection.map(model => {
          if (row[model.name]?.length > 0) this.isValid = true;
        });
      });
    });
    this.areSetupModelsValidEvent.emit(this.isValid);
  }

  onValidateAlternativeNameCompound(event?: boolean) {
    let isValid: boolean = true;
    this.modelsToBeRun.filter(m => m.selected).forEach(model => {
      model.rowData.forEach((row: any) => {
        let rowIsValid = Utils.validateAlternativeNameCompound(row.metaboliteName!, row.alternativeNameCompound, row.surfacewater!, row.groundwater!);
        if (!rowIsValid) {
          isValid = false;
        }
      });
    });

    this.areValidateAlternativeNameCompoundEvent.emit(isValid);
  }

  onValidateToShowAlternativeNameCompoundLabel(event?: boolean) {
    let showLabel: boolean = false;

    this.modelsToBeRun.filter(m => m.selected).forEach(model => {
      model.rowData.forEach((row: any) => {
        if (Utils.showAlternativeName(row.metaboliteName!, row.surfacewater!, row.groundwater!)) {
          showLabel = true;
        }
      });
    });

    this.showAlternativeNameCompoundLabel.emit(showLabel);
  }

  ngOnDestroy(): void {
    this.selectedGeographyEffect.destroy();
  }

  onRowDragEnd(event: RowDragEvent) {
    this.setCompoundOrder();
    this.orderedModelsToBeRunRowData.emit();
  }

  setCompoundOrder() {
    let cont = 1;
    this.grid.gridApi.forEachNode((node: RowNode) => {
      node.data.compoundOrder = cont;
      cont++;
    });
  }
}
