import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { Project } from 'src/app/shared/models/project';
import { ProjectApiService } from 'src/app/shared/services/project.api.service';

@Component({
  selector: 'app-stop-button',
  templateUrl: './stop-button.component.html',
  styleUrls: ['./stop-button.component.css']
})
export class StopButtonComponent implements ICellRendererAngularComp {

  visible: boolean = true;
  params?: ICellRendererParams;

  constructor(private projectService: ProjectApiService, 
              private selectedProjectApi: SelectedProjectApiService){ }
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams) { return true; }
  
  buttonClicked() {
    this.visible = false;

    let project: Project = {
      projectPk: this.params?.data.projectPk,
      active: false,
      deletable: false,
      description: '',
      formulationPk: 0,
      name: '',
      createdDate: new Date(),
      createdBy: '',
      status: 0,
      featureAcronym: this.selectedProjectApi.featureAcronym
    };

    this.projectService.stopProject(project).subscribe({
      next: () => {
      },
      error: (error) => {
        console.warn(error);
      },
    })
  }
}