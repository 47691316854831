import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectSwanSwashResultSummaryColdef {

  constructor() { }

  public getColumnsDefinition(hideBufferDistance: boolean, isProjectOwnershipValid: boolean): any {
    return [
      {
        headerName: 'SME Selection',
        pinned: true,
        headerCheckbox: isProjectOwnershipValid,
        headerCheckboxSelection: isProjectOwnershipValid,
        checkboxSelection: isProjectOwnershipValid,
        editable: false,
        width: 130,
        hide: !isProjectOwnershipValid
      },
      {
        headerName: "Run ID",
        field: "runId",
        width: 150,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        maxWidth: 200,
        sortable: true,
        pinned : true
      },
      {
        headerName: "Application Scheme",
        field: "ApplicationScheme",
        width: 195,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        maxWidth: 250,
        sortable: true,
      },
      {
        headerName: "Crop",
        field: "cropName",
        width: 160,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: "Endpoints Data Set",
        field: "Dataset",
        width: 170,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: "Compound",
        field: "Compound",
        width: 150,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: `Scenario \n Focus`,
        field: "ScenarioFocus",
        width: 150,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: `Buffer Distance \n (m)`,
        field: "BufferDistance",
        width: 130,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        hide: hideBufferDistance
      },
      {
        headerName: "Scenarios",
        field: "Scenarios",
        width: 100,
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true
      },
      {
        headerName: `Water \n Type`,
        field: "WaterType",
        width: 100,
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true
      },
      {
        headerName:`Dominant entry \n route` ,
        field: "DominantEntryRoute",
        width: 130,
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true
      },
      {
        headerName:`Max PECsw \n (µg/L)` ,
        field: "MaxPecSw",
        width: 110,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: `7 d - PECsw, \n twa  (µg/L)`,
        field: "SevenDaysPecSwTwa",
        width: 125,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true
      },
      {
        headerName:`Max PECsed \n (µg/kg)` ,
        field: "MaxPecSed",
        width: 120,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true
      },
      {
        headerName:`21 d - PECsw \n twa  (µg/L)` ,
        field: "PecSwTwa21Days",
        width: 125,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: `Global Max \n SW Date`,
        field: "GlobalMaxSwDate",
        width: 110,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: `Application \n Date`,
        field: "ApplicationDate",
        width: 110,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true
      },
    ];
  }
}
