import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Constants } from '../../utils/constants';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { GapApplicationSchemeLogicService } from '../../services/gap-application-scheme.logic.service';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.css']
})
export class DeleteButtonRenderer implements ICellRendererAngularComp {
  @Output() onBtDeleteEvent : EventEmitter<any> = new EventEmitter<any>();
  params: any;
  parent: any;
  disabled: boolean = false;
  disabledByEvent:boolean = false;
  isFractionGrid: boolean = false;
  public isGroupRendered?: boolean;

  constructor() {}

  agInit(params: any): void {
    this.isGroupRendered = params.node.group;
    this.params = params;
    this.parent = params.context;
    this.disabled = params.disabled;
    this.isFractionGrid = this.isFractionGrid ? this.parent.isFractionGrid : false;
    if (this.parent.isDeleteEnabled$) {
        this.parent.isDeleteEnabled$.subscribe((isEnabled: boolean) => {
            if (isEnabled !== undefined) {
                this.disabledByEvent = !isEnabled; 
            }
        });
    }
}


  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }
  
  onDeleteClick(event:any): void {
    if (this.parent.isFractionGrid !== undefined && this.parent.isFractionGrid) {
      this.parent.setNode(this.params.node.id);
    }
    this.parent.showDialog(Constants.DeleteDialogElements, 'delete', this.params,this.params.node.id);

    this.onBtDeleteEvent.emit(this.params.node.id);
  }

  deleteRow() {
    if (this.parent.isFractionGrid !== undefined && this.parent.isFractionGrid) {
      this.parent.setNode(this.params.node.id);
    }
  }
}
