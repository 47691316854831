<app-selected-project [isESAUDL]="true"></app-selected-project>
<div *ngIf="!isProjectOwnershipValid && selectedProject" class="isProjectOwnershipValid">
  <p>You can not modify or run someone's project</p>
</div>
<div *ngIf="selectedProject" class="pt-3 pr-5 pb-5 pl-5">
  <app-wizard class="surface-200" [items]="ESALogicService.items" [message]="message" [loading]="loading" [isESAUDL]="true"
      [activeItem]="activeItem" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus" [isSetupValid]="isSetupValid"
      (activeIndexChangeEmitter)="onActiveIndexChange($event)"></app-wizard>
  <div class="shadow-8 surface-0 p-3">
      <ng-container *ngIf="activeItem?.index == 0">
          <app-esa-setup [menuService]="ESALogicService" [disciplinePk]="disciplinePk" (isSetupValidEvent)="validateSetup($event)"></app-esa-setup>
      </ng-container>

      <ng-container *ngIf="activeItem?.index == 1">
        <app-esa-run-project [selectedProject]="selectedProject" [menuService]="ESALogicService" [selectedProjectStatus]="selectedProjectStatus"></app-esa-run-project>
      </ng-container>

      <ng-container *ngIf="activeItem?.index == 2">
          <app-esa-output-project [selectedProject]="selectedProject"></app-esa-output-project>
      </ng-container>
  </div>
</div>
