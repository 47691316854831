import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from '../models/project';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProjectPreviousRun } from '../models/project-previous-run';
import { ProjectMetrics } from '../models/project-metrics';
import { Environmental_Assessments } from './user.logic.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectApiService {

  constructor(private http: HttpClient) { }

  getProjects(): Observable<Project[]> {
    const query = `${environment.BAMS_Api_URL}api/projects`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }

  saveData(pData: any): Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.BAMS_Api_URL}api/projects`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  getProjectsByPks(pPojectPks: number[]): Observable<Project[]> {
    const body = JSON.stringify(pPojectPks);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.BAMS_Api_URL}api/projectsByPks`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  downloadOutputFilesByOutputFolders(outputFiles: string[], downloadAllFiles: boolean): Observable<string[]> {
    const body = JSON.stringify(outputFiles);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.BAMS_Api_URL}api/downloadOutputFilesByOutputFolders/${downloadAllFiles}`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  stopProject(pProject: Project): Observable<any> {
    const body = JSON.stringify(pProject);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.BAMS_Api_URL}api/stopProject`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  getProjectMolecules(projectPk: number): Observable<number[]> {
    let endpoint = `${environment.BAMS_Api_URL}api/projects/molecules?projectPk=${projectPk}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .http.get(endpoint, { headers })
      .pipe(map((data: any) => data.Value));
  }

  getProjectStatus(projectPk: number, featureAcronym: string): Observable<number> {
    let endpoint = `${environment.BAMS_Api_URL}api/projects/projectStatus/${projectPk}/${featureAcronym}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.http.get(endpoint, { headers })
      .pipe(map((data: any) => data.Value));
  }

  getProjectStatusResult(projectPk: number): Observable<number> {
    let endpoint = `${environment.BAMS_Api_URL}api/projects/projectStatusResult/${projectPk}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.http.get(endpoint, { headers })
      .pipe(map((data: any) => data.Value));
  }

  getPreviousRunsByProject(projectPk: number, featureAcronym: string): Observable<ProjectPreviousRun[]> {
    const endpoint = `${environment.BAMS_Api_URL}api/projects/previousRunsByProject/${projectPk}/${featureAcronym}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.http.get(endpoint, { headers })
      .pipe(map((data: any) => data.Value));
  }

  getMetricsByProject(projectPk: number, featureAcronym?: Environmental_Assessments): Observable<ProjectMetrics[]> {
    const endpoint = `${environment.BAMS_Api_URL}api/projects/GetMetricsByProject/${projectPk}/${featureAcronym}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.http.get(endpoint, { headers })
      .pipe(map((data: any) => data.Value));
  }
}