<div>
  <app-grid #grid [columnSizeMode]="columnSizeMode" [columnDefs]="columsDef" [rowData]="rowData"
    [useAddAction]="isProjectOwnershipValid" addLabel="Add Scheme" [useDeleteAction]="isProjectOwnershipValid"
    [rowHeight]="rowHeight" [isMasterDetail]="true" [detailColumnDefs]="detailsColumnDef"
    [useDetailAddButton]="isProjectOwnershipValid" [detailAutoRowHeigth]="true"
    [useDeleteAction]="isProjectOwnershipValid" [isProjectOwnershipValid]="isProjectOwnershipValid"
    (saveEvent)="saveData($event)" (deleteEvent)="deleteRow($event)" (refreshEvent)="getData()"
    (listBoxSelectionChangeEvent)="geographiesListBoxSelectionChanged($event)"
    (dropDownSelectionChangeEvent)="onDropDownSelectionChanged($event)" (enableControlsEmit)="enableControls($event)"
    (onFirstDataRenderedEvent)="onFirstDataRendered($event)" (buttonExpandParams)="onButtonExpandClick($event)"
    (addEvent)="newRowAdded($event)" [paginationPageSize]="25" [pagination]="false"></app-grid>
</div>