import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Constants } from '../../utils/constants';
import { environment } from 'src/environments/environment';
import { name } from '@azure/msal-angular/packageMetadata';

@Component({
  selector: 'app-azure-batch-link-cell-renderer',
  templateUrl: './azure-batch-link-cell-renderer.component.html',
  styleUrls: ['./azure-batch-link-cell-renderer.component.css']
})
export class AzureBatchLinkCellRendererComponent implements ICellRendererAngularComp {
  private params: any;
  status: string | undefined;
  subscription: string | undefined;
  environment: string | undefined;
  azureBatchAccountName: string | undefined;
  jobId: string | undefined;
  azureBatchLink: string | undefined;
  POOL_MODELS_NAMES = [
    { key: 'MACRO_GW', label: 'MACROGW' },
    { key: 'FOCUS Step 4 (Swan)', label: 'PRZM' },
  ];
  MODELS_RUN_AZURE_BATCH = ['PELMO', 'MACRO_GW', 'PEARL', 'MACRO', 'PRZM', 'TOXSWA', 'FOCUS Step 4 (Swan)'];

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.status = params.value;
    this.getAzureBatchLink();
  }

  getAzureBatchLink() {
    if (this.params?.data) {
      this.jobId = `${this.getPoolModelName(this.params?.data?.name)}_${this.params?.data?.lastOutputFolder}`;
      this.azureBatchLink = `https://portal.azure.com/?pwa=1#view/Microsoft_Azure_Batch/JobMenuBlade/~/tasks/batchAccountId/%2Fsubscriptions%2${environment.azureBatchsubscription}%2FresourceGroups%2F${environment.researchEnvironment}%2Fproviders%2FMicrosoft.Batch%2FbatchAccounts%2F${environment.azureBatchAccountName}/jobId/${this.jobId}`;
    }
  }

  getPoolModelName(name: string): string {
    const poolModel = this.POOL_MODELS_NAMES.find(model => model.key === name);
    if (poolModel)
      return poolModel.label;
    return name;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    throw new Error('Method not implemented.');
  }

  showLink(): boolean {
    return this.status === Constants.PROJECT_STATUS.IN_PROGRESS && this.MODELS_RUN_AZURE_BATCH.includes(this.params?.data?.name);
  }

}
