import { Injectable } from '@angular/core';
import { ButtonExpandGridRenderer } from '../../renderers/button-expand-grid/button-expand-grid.component';
import { DropdownListRenderer } from '../../renderers/dropdown-list/dropdown-list.component';
import { InputTextRenderer } from '../../renderers/input-text/input-text.component';
import { ImportDatasetRenderer } from '../../renderers/import-dataset/import-dataset.component';
import { EndpointDatasetLogicService } from './endpoint-dataset.logic.service';
import { StatusFlagRenderer } from '../../renderers/status-flag/status-flag.component';
import { DeleteButtonRenderer } from '../../renderers/delete-button/delete-button.component';
import { DatasetReferenceIconRenderer } from '../../renderers/dataset-reference-icon/dataset-reference-icon.component';
import { Constants } from '../../utils/constants';
import { EndpointDatasetDdlRendererComponent } from './endpoint-dataset-ddl-renderer/endpoint-dataset-ddl-renderer.component';
import { UserLogicService } from '../../services/user.logic.service';
import { Project } from '../../models/project';
import { OrderingButtonRenderer } from '../../renderers/ordering-button/ordering-button.component';
import { ProjectXCompoundXModel } from '../../models/project-x-compound-x-model';
import { ViewButtonRenderer } from '../../renderers/view-button/view-button.component';
import { MultiSelectAdminRenderer } from 'src/app/administrator/renderers/multi-select/multi-select.component';
import { ListboxRenderer } from '../../renderers/listbox/listbox.component';
import { InputTextDatasetRenderer } from '../../renderers/input-text/input-text-dataset/input-text-dataset.component';

@Injectable({
  providedIn: 'root',
})

export class EndpointDatasetColDefService {

  constructor(private readonly logicService: EndpointDatasetLogicService,
    private readonly userLogicService: UserLogicService,
  ) { }

  public getColumnsDefinition(isProjectOwnershipValid: boolean, projects: Project[], selectedProject: Project, compartment:string , environmentalAssessment: string = '') {
    return [
      {
        colId: 'show',
        minWidth: 150,
        width: 150,
        cellRenderer: ButtonExpandGridRenderer,
        cellRendererParams: {
          context: this,
          dataComponent: this,
          showLabel: 'Enter Inputs',
          hideLabel: 'Hide Inputs',
          showIcon: 'pi pi-arrow-down',
          hideIcon: 'pi pi-arrow-right'
        },
        type: 'rightAligned',
        editable: false
      },
      {
        headerName: 'Active Ingredient',
        hide: (environmentalAssessment === Constants.FEATURE_ACRONYM.ENVIRONMENTAL_EXPOSURE_ASSESSMENT) && this.logicService.isInverseModeling === false,
        field: 'moleculePk',
        minWidth: 363,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'activeIngredient',
        },
        instance: this,
        filterable: true,
        headerCheckboxSelection: isProjectOwnershipValid,
        checkboxSelection: isProjectOwnershipValid,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.molecules,
            columnThatModifies: 'moleculePk',
            optionLabel: 'moleculeName',
            optionValue: 'moleculePk',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded(),
            isRequired: true
          }
        },
        editable: false,
      },
      {
        headerName: 'Parent',
        hide: (environmentalAssessment !== Constants.FEATURE_ACRONYM.ENVIRONMENTAL_EXPOSURE_ASSESSMENT) || this.logicService.isInverseModeling === true,
        field: 'compoundPk',
        minWidth: 363,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'compoundName',
        },
        instance: this,
        filterable: true,
        headerCheckboxSelection: isProjectOwnershipValid,
        checkboxSelection: isProjectOwnershipValid,
        cellRenderer: EndpointDatasetDdlRendererComponent,
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.parentDropDownOptions,
            columnThatModifies: 'compoundPk',
            optionValue: 'compoundPk',
            optionLabel: 'compoundName',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded(),
            isRequired: true,
            optionsToRemove: this.logicService.metabolitesToRemove,
            fieldToRemove: 'compoundPk',
            allMetabolites: this.logicService.allMetabolites,
            allMolecules: this.logicService.allMolecules
          }
        },
        editable: false,
      },
      {
        headerName: 'Data Set',
        headerComponent: 'infoGridHeaderComponentComponent',
        headerComponentParams: {
          name: 'Data Set',
          infoText: Constants.MESSAGES.DATA_SET_INFO,
          popupWidth: 350
        },
        field: 'description',
        minWidth: 563,
        cellRenderer: InputTextDatasetRenderer,
        cellRendererParams: (params: any) => {
          return {
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded(),
            isRequired: true
          }
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'description',
        },
        editable: false,
        type: 'default',
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        cellStyle: (params: { data: { automatic: any } }) => {
          if (!isProjectOwnershipValid) return { backgroundColor: '#f0f0f0' };
          return params.data.automatic ? { color: '#aaaaaa' } : '';
        },
      },
      {
        headerName: 'Daughter',
        field: 'daughterPk',
        type: 'select',
        resizable: true,
        width: 200,
        editable: false,
        hide: !this.logicService.isPwcProject(selectedProject),
        filter: 'agSetColumnfilter',
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.setDaughterMetaboliteList(params.data.compoundPk),
            optionValue: 'metabolitePk',
            optionLabel: 'metaboliteName',
            columnThatModifies: 'daughterPk',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || params.data.compoundPk == undefined || this.logicService.isDatasetExpanded(),
          }
        },
      },
      {
        headerName: 'Granddaughter',
        field: 'granddaughterPk',
        type: 'select',
        resizable: true,
        width: 200,
        editable: false,
        hide: !this.logicService.isPwcProject(selectedProject),
        filter: 'agSetColumnfilter',
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.setGranddaughterMetaboliteList(params.data.compoundPk, params.data.daughterPk),
            optionValue: 'metabolitePk',
            optionLabel: 'metaboliteName',
            columnThatModifies: 'granddaughterPk',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || params.data.daughterPk == undefined || this.logicService.isDatasetExpanded(),
          }
        },
      },
      {
        headerName: 'Simulation Type',
        field: 'pwcXDataSetXSimulationType',
        minWidth: 320,
        hide: !this.logicService.isPwcProject(selectedProject),
        type: 'select',
        editable: false,
        cellRenderer: ListboxRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.getPWCSimulationTypes(),
            optionValue: 'typeName',
            optionLabel: 'typeName',
            checkbox: true,
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded(),
            multiple: true,
          }
        },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Use spraydrift buffers for TPEZ',
        editable: false,
        hide: !this.logicService.isPwcProject(selectedProject),
        field: 'useSprayDriftBuffersForTpez',
        type: 'selected',
        resizable: true,
        width: 130,
        wrapText: true,
        cellRenderer: 'checkBoxRenderer',
        cellRendererParams: (params: any) => {
          return {
            instance: this,
            columnThatModifies: 'useSprayDriftBuffersForTpez',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded() || !this.logicService.disableSprayDriftBufferForTPEZ(params.data.pwcXDataSetXSimulationType),
          };
        }
      },
      {
        headerName: 'Optional Outputs',
        type: 'default',
        width: 100,
        editable: false,
        hide: !this.logicService.isPwcProject(selectedProject),
        resizable: false,
        cellRenderer: ViewButtonRenderer,
        cellRendererParams: (params: any) => {
          return {
            label: 'View',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded(),
            field: 'optionalOutputs'
          };
        }
      },
      {
        headerName: '',
        field: 'hasEndpoints',
        maxWidth: 40,
        cellRenderer: StatusFlagRenderer,
        cellRendererParams: {},
        editable: false,
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'Import Dataset',
        colId: 'importDataset',
        type: 'select',
        minWidth: 253,
        suppressSizeToFit: true,
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        editable: false,
        cellRenderer: ImportDatasetRenderer,
        cellRendererParams: (params: any) => {
          return {
            filterable: false,
            optionLabel: 'description',
            optionValue: 'dataSetPk',
            options: this.logicService.datasetsToImport,
            parent: this,
            selectedValue: 0,
            placeholder: 'Import Data Set',
            disabled: this.disableImportDatasetInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded(),
          }
        },
        instance: this,
      },
      {
        colId: 'Referenced DataSet',
        width: 60,
        minWidth: 60,
        editable: false,
        cellRenderer: DatasetReferenceIconRenderer,
        cellRendererParams: (params: any) => {
          let useIcon = params?.data?.originalProject ? params?.data?.originalProject !== this.logicService.projectPk ? 'pi pi-link' : undefined : undefined;
          return {
            disabled: !isProjectOwnershipValid,
            icon: useIcon,
            toolTipMessage: useIcon ? 'This is a referenced dataset' : undefined,
          }
        },
        type: 'rightAligned'
      },
      {
        headerName: 'MACRO GW processing order',
        colId: 'orderMetabolites',
        width: 150,
        minWidth: 150,
        editable: false,
        hide: !(selectedProject!.projectXCompoundXModel?.find((x: ProjectXCompoundXModel) => x.ModelName == Constants.MODELS.MACRO_GW) != undefined && 
                compartment === Constants.COMPARTMENTS.GROUND_WATER &&
                environmentalAssessment === Constants.FEATURE_ACRONYM.ENVIRONMENTAL_EXPOSURE_ASSESSMENT),
        cellRenderer: OrderingButtonRenderer,
        cellRendererParams:  (params: any) => {
          return {
            disabled: !isProjectOwnershipValid,
            instance: this,
          }
        },
      },
      {
        colId: 'delete',
        width: 125,
        minWidth: 125,
        editable: false,
        cellRenderer: DeleteButtonRenderer,
        cellRendererParams: {
          disabled: !isProjectOwnershipValid || this.logicService.isDatasetExpanded(),
        },
        type: 'rightAligned'
      }
    ]
  }

  public getColumnsDefinitionSubstancePropertiesOPEX(isProjectOwnershipValid: boolean, projects: Project[]) {
    return [
      {
        colId: 'show',
        minWidth: 150,
        width: 150,
        cellRenderer: ButtonExpandGridRenderer,
        cellRendererParams: {
          context: this,
          dataComponent: this,
          showLabel: 'Enter Inputs',
          hideLabel: 'Hide Inputs',
          showIcon: 'pi pi-arrow-down',
          hideIcon: 'pi pi-arrow-right'
        },
        type: 'rightAligned',
        editable: false
      },
      {
        headerCheckboxSelection: isProjectOwnershipValid,
        checkboxSelection: isProjectOwnershipValid,
        headerName: 'Calculator',
        field: 'calculatorGroup',
        width: 135,
        minWidth: 135,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterable: true,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: Constants.CALCULATOR_GROUPS,
            columnThatModifies: 'calculatorGroup',
            optionLabel: 'name',
            optionValue: 'name',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded(),
            isRequired: false,
          }
        },
        editable: false,
      },
      {
        headerName: 'Active Ingredient',
        field: 'moleculePk',
        minWidth: 363,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'activeIngredient',
        },
        instance: this,
        filterable: true,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: this.logicService.molecules,
            columnThatModifies: 'moleculePk',
            optionLabel: 'moleculeName',
            optionValue: 'moleculePk',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded(),
            isRequired: true
          }
        },
        editable: false,
      },
      {
        headerName: 'Data Set',
        field: 'description',
        width: 465,
        minWidth: 465,
        cellRenderer: InputTextDatasetRenderer,
        cellRendererParams:  (params: any) => {
          return {
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded(),
            isRequired: true
          }
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'description',
        },
        editable: false,
        type: 'default',
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        cellStyle: (params: { data: { automatic: any } }) => {
          if (!isProjectOwnershipValid) return { backgroundColor: '#f0f0f0' };
          return params.data.automatic ? { color: '#aaaaaa' } : '';
        },
      },
      {
        headerName: '',
        field: 'hasEndpoints',
        maxWidth: 40,
        cellRenderer: StatusFlagRenderer,
        cellRendererParams: {},
        editable: false,
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'Import Dataset',
        colId: 'importDataset',
        type: 'select',
        minWidth: 215,
        suppressSizeToFit: true,
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        editable: false,
        cellRenderer: ImportDatasetRenderer,
        cellRendererParams: (params: any) => {
          return {
            filterable: false,
            optionLabel: 'description',
            optionValue: 'dataSetPk',
            options: this.logicService.datasetsToImport,
            parent: this,
            selectedValue: 0,
            placeholder: 'Import Data Set',
            disabled: this.disableInput(isProjectOwnershipValid, params, projects) || this.logicService.isDatasetExpanded(),
          }
        },
        instance: this,
      },
      {
        colId: 'Referenced DataSet',
        width: 60,
        minWidth: 60,
        editable: false,
        cellRenderer: DatasetReferenceIconRenderer,
        cellRendererParams: (params: any) => {
          let useIcon = params?.data?.originalProject ? params?.data?.originalProject !== this.logicService.projectPk ? 'pi pi-link' : undefined : undefined;
          return {
            disabled: !isProjectOwnershipValid,
            icon: useIcon,
            toolTipMessage: useIcon ? 'This is a referenced dataset' : undefined,
          }
        },
        type: 'rightAligned'
      },
      {
        colId: 'delete',
        width: 125,
        minWidth: 125,
        editable: false,
        cellRenderer: DeleteButtonRenderer,
        cellRendererParams: {
          disabled: !isProjectOwnershipValid || this.logicService.isDatasetExpanded()
        },
        type: 'rightAligned'
      }
    ]
  }

  private disableInput(isProjectOwnershipValid: boolean, params: any, projects: Project[]): boolean {
    if (!isProjectOwnershipValid) return true;
    return params?.data?.newRow ? false :
      params?.data?.createdBy != null ? params?.data?.createdBy !== this.userLogicService?.profile?.displayName :
        projects.find((project) => project.projectPk === params?.data?.originalProject)?.createdBy !== this.userLogicService?.profile?.displayName;
  }

  private disableImportDatasetInput(isProjectOwnershipValid: boolean, params: any, projects: Project[]): boolean {
    return this.disableInput(isProjectOwnershipValid, params, projects) ||
      (params.data.newRow && params.data.moleculePk == null && params.data.metabolitePk == null);
  }
}
