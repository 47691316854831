import { Component, EventEmitter, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Constants } from '../../utils/constants';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-compound',
  templateUrl: './compound.component.html',
  styleUrls: ['./compound.component.css']
})
export class CompoundRenderer implements ICellRendererAngularComp, OnChanges {
  compound: string = '';
  alternativeCompoundName: string = '';
  isAlternativeNameValid: boolean = false;
  disabled: boolean = false;
  params: any;
  parent: any;
  useAlternativeName: boolean = false;
  maxlength: number = 14;
  originalLabel: string = '';
  label: string = 'Alternative name';
  isRequired: boolean = true;

  constructor() { }

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.parent = params.context;
    this.params = params;
    this.compound = params.value;
    this.disabled = params.disabled;
    this.alternativeCompoundName = params.data.alternativeNameCompound;
    this.isAlternativeNameValid = this.alternativeCompoundName != null && this.alternativeCompoundName.length > 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['label']) this.originalLabel = this.label;
    if (changes['isRequired']) {
      if (this.label && this.isRequired) this.label = `${this.label}*`;
      this.originalLabel = this.label;
    }
  }

  showAlternativeName(): boolean {
    this.useAlternativeName = Utils.showAlternativeName(this.compound, this.params.data.groundwater, this.params.data.surfacewater);
    return this.useAlternativeName;
  }

  omitIllegalCharactersInSubstanceNames(event: any) {
    Utils.omitIllegalCharacters(event,  Constants.illegalCharactersInSubstanceNames);
  }

  alternativeNameChanged(value: any) {
    this.params.data.alternativeNameCompound = value;
    this.isAlternativeNameValid = value.length > 0;
    this.validAlternativeName();
  }

  validAlternativeName() {
    this.parent.onValidateAlternativeNameCompound(this.isAlternativeNameValid);
  }
}
