import { Component } from "@angular/core";
import { inject } from "@angular/core";
import { MetricsColdef } from "src/app/shared/components/project-status/metrics-coldef";
import { PreviosRunsColdef } from "src/app/shared/components/project-status/previous-runs.coldef";
import { ProjectStatusComponent } from "src/app/shared/components/project-status/project-status.component";
import { SelectedProjectApiService } from "src/app/shared/components/selected-project/selected-project.api.service";
import { ProjectApiService } from "src/app/shared/services/project.api.service";
import { RunApiService } from "src/app/shared/services/run.api.service";
import { Environmental_Assessments } from "src/app/shared/services/user.logic.service";
import { Constants } from "src/app/shared/utils/constants";

@Component({
    selector: 'app-esa-project-status',
    templateUrl: '../../../../../src/app/shared/components/project-status/project-status.component.html',
    styleUrls: ['../../../../../src/app/shared/components/project-status/project-status.component.css']
})
export class EASProjectStatusComponenet extends ProjectStatusComponent {
    private selectedProjectApiService2: SelectedProjectApiService = inject(SelectedProjectApiService);
    override ngOnInit(): void {
        this.monitorProjectStatus();
        setTimeout(() => { this.monitorRunsHaveBeenSaved() }, 30000);
      }

    override showDownloadButton() {
        return true; // temporarily return true for implementation
    }
}