<app-grid #grid *ngIf="!isLoading"
[domLayout]="'autoHeight'"
[rowData]="rowData"
[columnDefs]="columnDefs"
[useAddAction]="isProjectOwnershipValid"
[isProjectOwnershipValid]="isProjectOwnershipValid"
[useDeleteAction]="isProjectOwnershipValid"
(deletedData)="onDelete($event)"
(dropDownSelectionChangeEvent)="onDropdownChanged($event)"
></app-grid>
<app-loading *ngIf="isLoading" class="flex align-items-center justify-content-center"></app-loading>
