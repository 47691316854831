import { Component, Input } from '@angular/core';
import { Project } from 'src/app/shared/models/project';

@Component({
  selector: 'app-eea-output-view',
  templateUrl: './eea-output-view.component.html',
  styleUrls: ['./eea-output-view.component.css']
})
export class EEAOutputViewComponent{

  @Input() selectedProject?: Project;
  @Input({required: true}) isProjectOwnershipValid: boolean = false;

  constructor() {}

}
