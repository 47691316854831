import { Injectable } from "@angular/core";
import { Utils } from "../utils/utils";
import { EEAGapLogicService } from "src/app/environmental-exposure-assessment/eea-gap/eea-gap.logic.service";

@Injectable({
    providedIn: 'root'
})
export class GapApplicationSchemeLogicService {

    constructor(private gapLogicService: EEAGapLogicService) {
    }

    public getTransactionOccurrences(rowData: any[]): Record<string, number> {
        let occurrences = rowData.reduce((acumulator: Record<string, number>, rowData) => {
            const key = `${rowData.name?.toLocaleLowerCase()}`;
            acumulator[key] = (acumulator[key] || 0) + 1;
            return acumulator;
        }, {} as Record<string, number>);
        return occurrences;
    }

    public duplicateDataInRecord(transactions: Record<string, number>): boolean {
        for (let transaction in transactions) {
            if (transactions[transaction] > 1) {
                return true;
            }
        }
        return false;
    }

    public async checkIfApplicationSchemeIsValid(parent: any, row: any): Promise<void> {
        const applicationSchemeAlreadyExists = this.applicationSchemeAlreadyExistsOnBlur(parent.parent, row.name)
        if (applicationSchemeAlreadyExists) {
            Utils.showErrorMessage('The Application Scheme name already exists.', 'Please use another name');
        }
    }

    public applicationSchemeAlreadyExistsOnKeyUp(parent: any, applicationSchemeName: string | undefined): boolean {
        const invalidRows = false;
        const gridApi = parent.gridOptions ? parent.gridOptions.api : parent.params.api;
        const currentFocusedCellIndex = gridApi.getFocusedCell().rowIndex;
        var rowData = [];
        if (parent.rowData) {
            rowData = parent.rowData;
        } else {
            gridApi.forEachNode((node:any) => {
                rowData.push(node.data);
            });
        } 
        var filteredRows = rowData.filter((_: any, index: any) => index !== currentFocusedCellIndex);
        filteredRows = this.shouldCheckOtherCompartments(parent)
        ? [
            ...filteredRows,
            ...(Array.isArray(this.gapLogicService.rowData)
                ? this.gapLogicService.rowData.filter(
                    (row: any) => !filteredRows.some(
                        (filteredRow: any) => row.applicationSchemePk === filteredRow.applicationSchemePk
                    )
                )
                : [])
        ]
        : filteredRows;
        const duplicateDatasetsInTransaction = filteredRows.find((row: any) =>
            row.name?.toLowerCase() === applicationSchemeName?.toLowerCase()
        );
        const applicationSchemeAlreadyExists: boolean = applicationSchemeName != undefined && applicationSchemeName != '' && duplicateDatasetsInTransaction != undefined
        this.enableControls(!(invalidRows || applicationSchemeAlreadyExists), parent);
        return applicationSchemeAlreadyExists;
    }

    public applicationSchemeAlreadyExistsOnBlur(parent: any, applicationSchemeName: string | undefined): boolean {
        const rowDataToEvaluate = this.shouldCheckOtherCompartments(parent)
        ? [
            ...(Array.isArray(parent.rowData) ? parent.rowData : []),
            ...(Array.isArray(this.gapLogicService.rowData)
                ? this.gapLogicService.rowData.filter(
                    (row: any) =>
                        !Array.isArray(parent.rowData) ||
                        !parent.rowData.some(
                            (filteredRow: any) =>
                                row.applicationSchemePk === filteredRow.applicationSchemePk
                        )
                )
                : [])
        ]
        : Array.isArray(parent.rowData) ? parent.rowData : [];
        const duplicateDatasetsInTransaction = rowDataToEvaluate.filter((row: any) => row.name?.toLowerCase() === applicationSchemeName?.toLowerCase()).length >= 2;
        const applicationSchemeAlreadyExists: boolean = applicationSchemeName != undefined && applicationSchemeName != '' && duplicateDatasetsInTransaction
        this.enableControls(!applicationSchemeAlreadyExists, parent);
        return applicationSchemeAlreadyExists;
    }

    private shouldCheckOtherCompartments(parent: any) {
        return this.isCore(parent) && !this.isModifyingExistingRowWithTheSameName(parent)
    }

    private isCore(parent: any): boolean {
        return parent.comparment == "" || parent.comparment == undefined
    }

    private isModifyingExistingRowWithTheSameName(parent: any): boolean {
        const currentFocusedCellIndex = parent.gridOptions?.api.getFocusedCell().rowIndex;
        if (!currentFocusedCellIndex) return false;
        var currentRow = parent.rowData.find((_: any, index: any) => index === currentFocusedCellIndex);
        return currentRow?.applicationSchemePk !== undefined;
    }

    private enableControls(value: boolean, parent: any) {
        if (typeof parent.enableControls === 'function') {
            parent.enableControls(value);
        } else if (parent.parent && typeof parent.parent.enableControls === 'function') {
            parent.parent.enableControls(value);
        } else {
            console.error('enableControls function not found on parent or parent.parent.');
        }
    }


}