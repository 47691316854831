import { Injectable } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';

import {  Dropdown } from 'src/app/shared/models/uiControllers';
import { Constants } from 'src/app/shared/utils/constants';
import { ERAConstants } from '../../environmental-risk-assessment.constants';
import { ERAPecEccAquaticBamsApiService } from './era-pec-ecc-aquatic-bams.api.service';
import { ERAPecEccAquaticEchoApiService } from './era-pec-ecc-aquatic-echo.api.service';
import { EraPecEccAquatic,
         EraPecEccAquaticDataTransaction,
         EraPecEccAquaticDiscipline,
         EraPecEccAquaticEEACompartment,
         EraPecEccAquaticModel,
         EraPecEccAquaticSubstancesPks} from './era-pec-ecc-aquatic.model';

@Injectable({
  providedIn: 'root'
})
export class ERAPecEccAquaticLogicService {

  public compartments: EraPecEccAquaticEEACompartment[];
  public substances: Dropdown[];
  public models: EraPecEccAquaticModel[];
  public modelsDropdown: Dropdown[];
  public pecEccTypes: Dropdown[];
  public geographies: Dropdown[];
  public waterbodies: Dropdown[];
  public crops: Dropdown[];

  private eFateDisciplinePk: number = 0;
  private ecotoxDisciplinePk: number = 0;


  constructor(private readonly bamsApiService: ERAPecEccAquaticBamsApiService,
              private readonly echoApiService: ERAPecEccAquaticEchoApiService) {
  this.compartments = [];
  this.substances = [];
  this.models = [];
  this.modelsDropdown = [];
  this.pecEccTypes = [];
  this.geographies = [];
  this.waterbodies = [];
  this.crops = [];
  }

  public getDisciplines(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.echoApiService.getDisciplines().pipe(take(1)).subscribe({
        next:(disciplines) =>{
          this.setEcotoxAndEfateDisciplinePk(disciplines);
          resolve();
        }
      });
    });
  }

  private setEcotoxAndEfateDisciplinePk(disciplines: EraPecEccAquaticDiscipline[]): void {
    const {ENVIRONMENTAL_E_FATE, ECOTOXICOLOGY} = Constants.DISCIPLINE_IDENTITIES;
     this.eFateDisciplinePk = disciplines.find(({disciplineName}) => disciplineName == ENVIRONMENTAL_E_FATE)!.disciplinePk;
     this.ecotoxDisciplinePk = disciplines.find(({disciplineName}) => disciplineName == ECOTOXICOLOGY)!.disciplinePk;
  }

  public getModelsAndCompounds(geographyPk: number, geographyType: string, projectPk: number, ): Promise<void>{
    return new Promise<void>((resolve) => {
      this.getDisciplines().then(() => {
        this.getModelsByCompartmentPkAndGeography(this.compartments.filter((a: EraPecEccAquaticEEACompartment) => a.name === Constants.COMPARTMENTS.SURFACE_WATER)[0]?.compartmentPk, geographyPk, geographyType);
        this.getCompounds(projectPk, this.ecotoxDisciplinePk).then(() => {
          resolve();
        });
      });
    });
  }

  public getModelsByDisciplinePkAndGeography(disciplinePk: number, geographyPk: number, geographyType: string): void {
    this.modelsDropdown.length = 0;
    this.bamsApiService.getModelsByDisciplinePkAndGeography(disciplinePk, geographyPk, geographyType).pipe(take(1)).subscribe({
      next: (models) =>{
        this.models = models;
        models.forEach(({modelPk, name}) => {
          this.modelsDropdown.push({
            optionLabel: name,
            optionValue: modelPk
          });
        });
      }
    });
  }

  public getModelsByCompartmentPkAndGeography(compartmentPk: number, geographyPk: number, geographyType: string): void {
    this.modelsDropdown.length = 0;
    this.bamsApiService.getModelsByCompartmentPkAndGeography(compartmentPk, geographyPk, geographyType, this.eFateDisciplinePk).pipe(take(1)).subscribe({
      next: (models) =>{
        this.models = models;
        models.forEach(({modelPk, name}) => {
          this.modelsDropdown.push({
            optionLabel: name,
            optionValue: modelPk
          });
        });
      }
    });
  }

  public getCompounds(projectPk: number, disciplinePk: number): Promise<void> {
    return new Promise<void>((resolve) => {
      this.getSubstancesPks(projectPk, disciplinePk).then((substancesPks) => {
        this.getSubstances(substancesPks).then(() =>{
            resolve();
        });
      });
    });
  }

  private getSubstancesPks(projectPk: number, disciplinePk: number): Promise<EraPecEccAquaticSubstancesPks> {
    const moleculesPks : number[] = [];
    const metabolitesPks : number[] = [];

    return new Promise<EraPecEccAquaticSubstancesPks> ((resolve, reject) => {
      this.bamsApiService.getCompoundsByProjectPkAndDisciplinePk(projectPk, disciplinePk).pipe(take(1)).subscribe({
        next: (compound) =>{
          compound.forEach(({compoundPk,isActive})=>{
            if(isActive)
              moleculesPks.push(compoundPk);
            else
              metabolitesPks.push(compoundPk);
            });
            const substances: EraPecEccAquaticSubstancesPks = {moleculesPks: moleculesPks, metabolitesPks: metabolitesPks};
          resolve(substances);
        },
        error: (error: Error)=>{
          reject(error);
        }
      });
    });
  }

  private getSubstances(substancesPks: EraPecEccAquaticSubstancesPks): Promise<void> {
    this.substances.length = 0;
    return new Promise<void> ((resolve) => {
      this.echoApiService.getSubstancesByPks(substancesPks).pipe(take(1)).subscribe({
        next: (Substances) =>{
          Substances.forEach((substance) => {
            this.substances.push({
              optionLabel: substance,
              optionValue: substance
            });
          });
          this.substances.sort((a, b) => {
            return (a.optionLabel as string).localeCompare((b.optionLabel as string));
          });
          resolve();
        },
      });
    });
  }

  public GetCropsByGeography(geopgraphy: string): void{
    this.crops.length = 0;
      this.bamsApiService.getCropsByGeography(geopgraphy).pipe(take(1)).subscribe({
        next:(crops) =>{
          crops.forEach(({crop_name, crop_list_pk, compartment}) =>{
            this.crops.push({
              optionValue: crop_list_pk,
              optionLabel: crop_name,
              type: compartment
            });
          });
          this.crops.sort((a, b) => {
            return (a.optionLabel as string).localeCompare((b.optionLabel as string));
          });
        }
      });
  }

  public async getCompartments(): Promise<void> {
    const source$ = this.echoApiService.getEEACompartments().pipe(take(1));
    this.compartments = await lastValueFrom(source$);
  }

  public getCountries(geography: string, geographyPk: number): void {
    if(geography == Constants.GEOGRAPHYS.USA)
        this.setRegionsOptions(geographyPk);
  }

  private setRegionsOptions(countryPk: number): void{
    this.geographies.length = 0;
    this.echoApiService.getRegionsByCountryPk(countryPk).pipe(take(1)).subscribe({
      next:(regions)=>{
        regions.forEach(({regionPk, name}) =>{
          this.geographies.push({
            optionValue: regionPk,
            optionLabel: name
          });
        });
      }
    });
  }

  public fillDropdownOptionsFromSingleList(list: string[] | number[]): Dropdown[] {
    const options: Dropdown[] = [];
    list.forEach(item => {
      options.push({optionValue:item, optionLabel:item});
    });
    return options;
  }

  public setWaterbodyOptions(geography: string): void {
    const waterbodies: Dropdown[] = [];
    ERAConstants.WATERBODY.forEach(item =>{
      const {geography: geographyName, value} = item;
      if(geographyName == geography)
        waterbodies.push({optionValue: value, optionLabel: value});
    });
    this.waterbodies = waterbodies;
  }

  public getCompartment(modelPk: number | undefined, geography: string): string {
    let compartment: string = '';
    if(modelPk && geography == Constants.GEOGRAPHYS.EUROPE_UNION){
      const compartmentPk = this.models.find(m => m.modelPk == modelPk)!.compartmentPk;
      compartment = this.compartments.find(c => c.compartmentPk == compartmentPk)!.name;
    }
    return compartment;
  }

  public transformGridDataToAquaticModel(gridData: any, geographyType: string, projectPk: number): EraPecEccAquatic[]{
    const aquaticModels: EraPecEccAquatic[] = [];
    gridData = gridData.filter((d: any) => d.modified || !d.resultSurfaceWaterPk);
    gridData.forEach((data: any) =>{
      const aquaticModel: EraPecEccAquatic = {
        resultSurfaceWaterPk: data.resultSurfaceWaterPk? data.resultSurfaceWaterPk : -1,
        projectPk: projectPk,
        modelPk: this.modelsDropdown.find(x => x.optionLabel == data.modelName)?.optionValue as number ?? undefined,
        scenarioFocus: data.scenarioFocus,
        scenarios: data.scenarios,
        bufferDistance: data.bufferDistance,
        nozzleDriftReduction: data.nozzleDriftReduction,
        dataSet: data.dataSet,
        cropListPk: data.cropListPk,
        applicationScheme: data.applicationScheme,
        geographyType: geographyType,
        regionOrCountry: data.regionOrCountry,
        region: data.region,
        period: data.period,
        waterbody: data.waterbody,
        maxPecSw: data.maxPecSw,
        pecSw21d: data.pecSw21d,
        maxPecSed: data.maxPecSed,
        avg1day: data.avg1day,
        avg21day: data.avg21day,
        avg60day: data.avg60day,
        dominantEnrtyRoute: data.dominantEnrtyRoute,
        globalMaxSwDate: data.globalMaxSwDate,
        applicationDate: data.applicationDate,
        substance: data.substance,
        bbchEarliest : data.bbchEarliest,
        deletable: true
      }
      aquaticModels.push(aquaticModel);
    });
    return aquaticModels;
  }

  public save(dataTransaction: EraPecEccAquaticDataTransaction): Promise<boolean> {
    return new Promise<boolean> ((resolve, reject) => {
      this.bamsApiService.insertUpdateAndDeleteEraPecEccAquatic(dataTransaction).subscribe({
        next: (success) =>{
          resolve(success);
        },
        error: () =>{
          reject(false);
        }
      });
    })
  }

  public getPecEccAquaticByProjectPk(projectPk: number): Promise<EraPecEccAquatic[]> {
    return new Promise<EraPecEccAquatic[]> ((resolve, reject) => {
      this.bamsApiService.getPecEccAquaticByProjectPk(projectPk).subscribe({
        next: (aquaticData) =>{
          resolve(aquaticData);
        },
        error: () =>{
          reject([]);
        }
      });
    })
  }

  public isDataFromRun(data: any) {
    return !data.newRow && data.runInputsPk !== null;
  }

  public getScenarioFocusListValues(modelName: string): string[]{
    let result: string[] = [];

    switch(modelName){
      case Constants.MODELS.STEP_1_2:
        result.push(...['Step 1', 'Step 2', 'Maximum']);
        break;
      case Constants.MODELS.SWASH:
        result.push(...['Step 3']);
        break;
      case Constants.MODELS.SWAN:
        result.push(...['Step 4']);
        break;
    }


    return result;
  }

  public enableColumnForStep3And4(stepFocus: string): boolean{
    let result: boolean = true;

    result = stepFocus == "Step 1" ||
              stepFocus == "Step 2" ||
              stepFocus == "Maximum" ||
              !stepFocus ? true : false;

    return result;
  }

  public enableColumnForStep2(stepFocus: string): boolean{
    let result: boolean = true;

    result = stepFocus != "Step 2" ? true : false;

    return result;
  }

  public enableColumnForStep4(stepFocus: string): boolean{
    let result: boolean = true;

    result = stepFocus != "Step 4" ? true : false;

    return result;
  }
}
