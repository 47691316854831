import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-master-details-calendar',
  templateUrl: './master-details-calendar.component.html',
  styleUrls: ['./master-details-calendar.component.css']
})
export class MasterDetailsCalendarComponent implements ICellRendererAngularComp {
  dateValue?: Date;
  params: any;
  parent: any;
  highlightDate!: Date;
  disabled: boolean = false;
  columnThatModifies!: string;
  showTodayDate?: Boolean;
  dateFormat?: string;

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.parent = params.context ? params.context : params.instance;
    this.dateFormat = params.format ? params.format : 'dd-M';
    this.disabled = params.disabled;
    this.columnThatModifies = params.colDef?.field;
    this.highlightDate = this.params?.highlightDate;
    if (this.params.node.data[this.columnThatModifies]) {
      this.dateValue = new Date(
        this.params.node.data[this.columnThatModifies]
      );
    }
    this.showTodayDate = params?.defaultDate;
  }

  public onSelect(): void {
    this.params.data[this.columnThatModifies] = this.dateValue;
    this.params.data.modified = true;
    this.parent.calendarDetailSelectionChanged({
      field: this.params.data[this.columnThatModifies],
      id: this.params.node.id,
      row: this.params.data,
      value:this.dateValue,
      params: this.params,

    });

  }

}
