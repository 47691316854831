import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InverseModelingEnvironmentalExposureAssesmentComponent } from './environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment.component';
import { SharedModule } from "../shared/shared.module";
import { EnvironmentalExposureAssessmentModule } from "../environmental-exposure-assessment/environmental-exposure-assessment.module";
import { InverseModelingEndpointsComponent } from './inverse-modeling-endpoints/inverse-modeling-endpoints.component';
import { EnvironmentalRiskAssessmentModule } from '../environmental-risk-assessment/environmental-risk-assessment.module';
import { InverseModelingRunRiskAssessmentComponent } from './run-project/run-risk-assessment/run-risk-assessment.component';
import { InverseRunProjectQcComponent } from './run-project/shared/inverse-modeling-run-project-qc/inverse-modeling-run-project-qc.component';
import { AquaticRunRiskAssessmentComponent } from './run-project/run-risk-assessment/aquatic-run-risk-assessment/run-risk-assessment/aquatic-run-risk-assessment.component';
import { InverseModelingRunProjectComponent } from './run-project/run-project/inverse-modeling-run-project.component';

@NgModule({
    declarations: [
        InverseModelingEnvironmentalExposureAssesmentComponent,
        InverseModelingEndpointsComponent,
        InverseModelingRunProjectComponent,
        InverseModelingRunRiskAssessmentComponent,
        InverseRunProjectQcComponent,
        AquaticRunRiskAssessmentComponent
    ],
    exports: [
        InverseModelingEnvironmentalExposureAssesmentComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        EnvironmentalExposureAssessmentModule,
        EnvironmentalRiskAssessmentModule
    ]
})
export class InverseModelingEnvironmentalExposureAssesmentModule { }
