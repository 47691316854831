import { Injectable } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { Header } from 'primeng/api';
import { ButtonExpandGridRenderer } from 'src/app/shared/renderers/button-expand-grid/button-expand-grid.component';
import { Constants } from "src/app/shared/utils/constants";

@Injectable({
    providedIn: 'root',
})

export class HhraRunRiskAssessmentUSHandlerResultsColDef {

    public getColumnsDefinition(): (ColDef | ColGroupDef)[] | null {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Worker activity',
                        field: 'WorkerActivity',
                        width: 155,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Exposure Scenario',
                        field: 'ExposureScenario',
                        width: 190,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Crop or Target',
                        field: 'CropOrTarget',
                        width: 200,
                        filter: 'agSetColumnFilter',
                        resizable: true,
                        sortable: true,
                        editable: false,
                    },
                    {
                        headerName: 'Dermal Unit Exposure (μg/lb ai)',
                        field: 'DermalUnitExposure',
                        width: 155,
                        filter: 'agSetColumnFilter',
                        resizable: true,
                        sortable: true,
                        editable: false,
                    },
                    {
                        headerName: 'Level of PPE or Engineering control',
                        field: 'DermalPPEOrEngineeringControl',
                        width: 270,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Inhalation Unit Exposure (μg/lb ai)',
                        field: 'InhalationUnitExposure',
                        width: 170,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Level of PPE or Engineering control',
                        field: 'InhalationPPEOrEngineeringControl',
                        width: 250,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Maximum Application Rate',
                        field: 'MaximumApplicationRate',
                        width: 150,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'App Rate Unit',
                        field: 'ApplicationRateUnit',
                        width: 120,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Area Treated or Amount Handled Daily',
                        field: 'AreaTreatedDaily',
                        width: 170,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Area Treated/Amount Handled Unit',
                        field: 'AreaTreatedUnit',
                        width: 170,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                ]
            },
            {
                headerName: 'Dermal',
                children: [
                    {
                        headerName: 'Dose (mg/kg/day)',
                        field: 'DermalDose',
                        width: 120,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'MOE',
                        field: 'DermalMOE',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Result',
                        field: 'DermalResult',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                        cellStyle: params => {
                            if (params.value === 'Fail') {
                                return { color: 'red', fontWeight: 'bold' };
                            }
                            if (params.value === 'Pass') {
                                return { color: 'green', fontWeight: 'bold' };
                            }
                            return null;
                        }
                    },
                    
                ]
            },
            {
                headerName: 'Inhalation',
                children: [
                    {
                        headerName: 'Dose (mg/kg/day)',
                        field: 'InhalationDose',
                        width: 120,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'MOE',
                        field: 'InhalationMOE',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'Result',
                        field: 'InhalationResult',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                        cellStyle: params => {
                            if (params.value === 'Fail') {
                                return { color: 'red', fontWeight: 'bold' };
                            }
                            if (params.value === 'Pass') {
                                return { color: 'green', fontWeight: 'bold' };
                            }
                            return null;
                        }
                    },
                ]
            },
            {
                headerName: 'Total',
                children: [
                    {
                        headerName: 'MOE',
                        field: 'TotalMOE',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'ARI',
                        field: 'TotalARI',
                        width: 120,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                    },
                    {
                        headerName: 'ARI Result',
                        field: 'TotalAriResult',
                        width: 130,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        resizable: true,
                        sortable: true,
                        cellStyle: params => {
                            if (params.value === 'Fail') {
                                return { color: 'red', fontWeight: 'bold' };
                            }
                            if (params.value === 'Pass') {
                                return { color: 'green', fontWeight: 'bold' };
                            }
                            return null;
                        }
                    },
                ]
            }
        ];
    }
}