import { Component, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectSwanSwashResultSummaryColdef } from './eeea-output-project-swan-swash-result-summary-coldef';
import { EEAOutputProjectSummaryResultSurfaceWaterApiService } from '../../../../../shared/services/out-put-project/eea-output-project-step1n2-summary-result.api.service';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { Constants } from 'src/app/shared/utils/constants';
import { EEAOutputProjectLogicService } from '../../eea-output-project.logic.service';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-eea-output-project-swash-swan-result-summary',
  templateUrl: './eea-output-project-swash-swan-result-summary.component.html',
  styleUrls: ['./eea-output-project-swash-swan-result-summary.component.css']
})
export class EEAOutputProjectSwashSwanResultSummaryComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() selectedModel?: string;
  @Input({required: true}) isProjectOwnershipValid: boolean = false;

  @ViewChild('grid') grid!: GridComponent;

  columnDef?: ColDef[];
  loading: boolean = true;
  rowData: any;
  summaryResult?: any[];

    private logicService = inject(EEAOutputProjectLogicService);

  constructor(
    private summaryresultColDef: EEAOutputProjectSwanSwashResultSummaryColdef,
    private summaryResultService: EEAOutputProjectSummaryResultSurfaceWaterApiService
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    let hideBufferDistance = true;
    if (this.selectedModel)
      hideBufferDistance = this.selectedModel == Constants.MODELS.SWASH ? true : false;

    this.columnDef = this.summaryresultColDef.getColumnsDefinition(hideBufferDistance, this.isProjectOwnershipValid);
    this.getSummaryResult(this.selectedProject?.projectPk);
  }

  getSummaryResult(projectPk?: number) {
    if (projectPk) {
      this.loading = true;
      this.summaryResultService
        .getSummariesResultsSurfaceWaterByModel(projectPk, this.selectedModel ?? "")
        .pipe(take(1))
        .subscribe((result: any) => {
          this.rowData = result.Value;
          this.refreshSelectedValuesTimeout();
          this.loading = false;
        });
    }
  }

    public onSelectedRowEvent( event: RowSelectedEvent ){
      this.logicService.setSmeSelection( event, Constants.FEATURE_ACRONYM.ENVIRONMENTAL_EXPOSURE_ASSESSMENT);
    }

    public onSelectedAllChanged( event: any ){
      if( event.source !== 'uiSelectAll' || !this.grid ) return;
      this.logicService.setSmeSelections( this.grid, Constants.FEATURE_ACRONYM.ENVIRONMENTAL_EXPOSURE_ASSESSMENT);
    }

    private refreshSelectedValuesTimeout(): void {
      setTimeout(() => {
        this.refreshSelectedValues();
      }, 75);
    }

    private refreshSelectedValues(): void {
      if( !this.grid ) return;
      this.grid.refreshSelectedValues( 'EeaSmeSelection' );
    }


}
