import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { Project } from 'src/app/shared/models/project';
import { RunRisk } from 'src/app/shared/models/run-risk';
import { Constants } from 'src/app/shared/utils/constants';
import { InverseModelingRunRiskAssessmentComponent } from '../../run-risk-assessment.component';
import { TriggerEcotox } from 'src/app/shared/models/trigger-ecotox';
import { RunRiskApiService } from 'src/app/shared/services/run-risk.api.service';
import { CropListService } from 'src/app/administrator/regulatory-models/crop-list/crop-list.service';
import { CropList } from 'src/app/shared/models/crop-list';
import { Utils } from 'src/app/shared/utils/utils';
import { EraAquaticRunRiskAssessmentGridLogicService } from '../../../../../environmental-risk-assessment/era-run-risk-assessment/era-aquatic-run-risk-assessment-grid/era-aquatic-run-risk-assessment-grid.logic.service';
import { Endpoint } from 'src/app/shared/models/endpoint';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { IMRunRiskAssessmentBeesColDef } from './aquatic-run-risk-assessment.coldef'

@Component({
  selector: 'app-aquatic-run-risk-assessment',
  templateUrl: './aquatic-run-risk-assessment.component.html',
  styleUrls: ['./aquatic-run-risk-assessment.component.css']
})
export class AquaticRunRiskAssessmentComponent {
  @Input() inverseRunRiskAssessmentComponent!: InverseModelingRunRiskAssessmentComponent;
  @Input() selectedProject?: Project;
  isInverseModule?: boolean = true;

  columnDefs: any = [];
  mainGreography?: string = '';
  data: RunRisk[] = [];
  rowData: any[] = [];
  cropList?: CropList[];
  isLoading!: boolean;
  taxonGroup: string = Constants.ECOTOXICOLOGY_COMPARMENTS.AQUATIC;
  public formulation: string = '';

  constructor(private runRiskApiService: RunRiskApiService,
              private cropListService: CropListService,
              private readonly logicService: EraAquaticRunRiskAssessmentGridLogicService,
              public dataSetService: DataSetApiService,
              private readonly imRunRiskAssessmentBeesColDef: IMRunRiskAssessmentBeesColDef
              ){}

  ngOnInit(): void {
    this.isLoading = true;
    this.getDatasetsEndpoints();
    this.getFormulation();
  }

  private getFormulation(): void {
    const formulationPk = this.selectedProject!.formulationPk!;
    this.logicService.getFormulationNameByPk(formulationPk).then(formulationName => {
      this.formulation = formulationName;
    });
  }

  onCellValueChanged(event: any) {}

  onSelectionChanged(event: any) {
  }

  getData(): void {
    this.setMainGeography();
    this.mainGreography !== Constants.GEOGRAPHYS.USA ? this.setEuEcotoxData() : this.setUsaEcotoxData();
    this.getRunRiskSummaryData();
  }

  getDatasetsEndpoints() {
    this.dataSetService.getEndpointsByDataSetsByTaxonGroup(this.inverseRunRiskAssessmentComponent.tempDatasets, this.taxonGroup).subscribe((endpointsDatasets: DataSet[]) => {
      this.filterAllRowData(endpointsDatasets);
      this.getData();
    })
  }

  filterAllRowData(data: DataSet[]) {
    data.forEach((x: DataSet) => {
      x.endpoints = x.endpoints?.filter((x: Endpoint) => x.disciplinePk === this.inverseRunRiskAssessmentComponent.mainDiscipline );
      this.inverseRunRiskAssessmentComponent.datasets.push(x);
    });
    this.processGroupedEndpoints();
  }

  processGroupedEndpoints() {
    this.inverseRunRiskAssessmentComponent.datasets.forEach((row: any) => {
      if (row.groupedEndpointFieldValues?.length > 0)
        Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpoint) => {
          if (
            row.groupedEndpointFieldValues.find(
              (x: any) => x.key.toLowerCase() == endpoint.name
            )
          )
            row[endpoint.field] = row.groupedEndpointFieldValues.filter(
              (x: any) => x.key.toLowerCase() == endpoint.name
            )[0].value;
        });
    });
  }

  getRunRiskSummaryData() {
    forkJoin([
      this.runRiskApiService.GetAquaticRiskRunsByProject(this.data, this.inverseRunRiskAssessmentComponent.selectedProject!.projectPk, this.mainGreography!, Constants.FEATURE_ACRONYM.INVERSE_MODELING_ENVIRONMENTAL_EXPOSURE_ASSESSMENT_ACRONYM),
      this.cropListService.getAllCropsList()
    ])
    .subscribe({
      next: ([runRisk, crops]) =>{
        this.cropList = Utils.sortObject((this.mainGreography == Constants.CROP_GEOGRAPHIES.USA) ? crops?.filter((x: CropList) => x.Geography === Constants.CROP_GEOGRAPHIES.USA) : crops, 'CropName'),
        this.data = runRisk;
        this.columnDefs = this.imRunRiskAssessmentBeesColDef.getColumnsDefinition(this.isInverseModule, this.selectedProject!, this.mainGreography);
        this.setDefaultValues();
        this.rowData = this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? this.data.sort((a: any, b:any) => b.etrValue-a.etrValue) : this.data.sort((a: any, b:any) => b.riskEquotient-a.riskEquotient).sort((a: any, b:any) => a.result.localeCompare(b.result));
        this.isLoading = false;
      }
    })
  }

  setMainGeography(){
    if(this.inverseRunRiskAssessmentComponent.selectedProject?.countryPk !== undefined){
      this.mainGreography = this.inverseRunRiskAssessmentComponent.geographies.filter((x: Catalog) => this.inverseRunRiskAssessmentComponent.selectedProject?.countryPk === x.key)[0].name;
    }
    if(this.inverseRunRiskAssessmentComponent.selectedProject?.regionPk !== undefined) {
      this.mainGreography = this.inverseRunRiskAssessmentComponent.geographies.filter((x: Catalog) => this.inverseRunRiskAssessmentComponent.selectedProject?.regionPk === x.key)[0].name;
    }
  }

  setUsaEcotoxData(){
    this.inverseRunRiskAssessmentComponent.datasets.forEach((dataset: DataSet) => {
      dataset.endpoints?.forEach((endpoint: any) => {
          Constants.RUN_RISK_LISTED_NON_LISTED_VALUES.forEach((value: string) => {
            const taxonExposureDuration = this.inverseRunRiskAssessmentComponent.taxonCatalogList.filter((x: Catalog) => endpoint.taxonExposureDuration === x.key)[0]?.name;
            const trigger = this.inverseRunRiskAssessmentComponent.triggers.filter((x: TriggerEcotox) => taxonExposureDuration === x.description)[0];

            let runRiskData: RunRisk = {
              metabolitePk: endpoint.metabolitePk,
              moleculePk: endpoint.moleculePk,
              activeIngredient: endpoint.activeIngredientName,
              ecotoxDataset: dataset.description,
              taxonExposureDuration: taxonExposureDuration,
              listedNoListed: value,
              species: endpoint.speciesScientificName,
              substanceType: endpoint.substanceType,
              substanceName: endpoint.substanceName,
              endpointType: endpoint.endpointTypePk,
              endpoint: endpoint.endpointValue,
              endpointValue: endpoint.value,
              endpointUnit: endpoint.unitValue,
              loc: value === Constants.RUN_RISK_LISTED_NON_LISTED_VALUES[0] ? trigger?.riskQuotientListed : trigger?.riskQuotientNonListed,
              eecPecType: trigger?.eecType,
            };
            this.data.push(runRiskData);
        });
      });
    });
  }

  setEuEcotoxData(){
    this.inverseRunRiskAssessmentComponent.datasets.forEach((dataset: DataSet) => {
      dataset.endpoints?.forEach((endpoint: any) => {
        const taxonExposureDuration = this.inverseRunRiskAssessmentComponent.taxonCatalogList.filter((x: Catalog) => endpoint.taxonExposureDuration === x.key)[0]?.name;
        const trigger = this.inverseRunRiskAssessmentComponent.triggers.filter((x: TriggerEcotox) => taxonExposureDuration === x.description)[0];
        let runRiskData: RunRisk = {
          metabolitePk: endpoint.metabolitePk,
          moleculePk: endpoint.moleculePk,
          activeIngredient: endpoint.activeIngredientName,
          ecotoxDataset: dataset.description,
          taxonExposureDuration: taxonExposureDuration,
          species: endpoint.speciesScientificName,
          substanceType: endpoint.substanceType,
          substanceName: endpoint.substanceName,
          endpointType: endpoint.endpointTypePk,
          endpoint: endpoint.endpointValue,
          endpointValue: endpoint.value,
          endpointUnit: endpoint.unitValue,
          af: trigger?.assessmentFactor,
          rac: (endpoint.value / trigger?.assessmentFactor),
          eecPecType: trigger?.pecType,
          etrTrigger: `${trigger?.triggerType} ${trigger?.triggerOperator} ${trigger?.triggerValue}`
        };
        this.data.push(runRiskData);
      });
    });
  }

  setDefaultValues(){     
    this.data.forEach((value: RunRisk) => {
      value.formulation = this.formulation;
      value.crop = this.cropList?.filter((x: CropList) => x.CropListPk === value.cropPk)[0]?.CropName;
    })
  }
}