import { Applications } from "../pwc/applications";
import { Chemical } from "../pwc/chemical";
import { OptionalOutputs } from "../pwc/optional-outputs";
import { SimulationType } from "../pwc/simulation-type";

export class PWCInputs {
  runId?: string;
  projectName?: string;
  dataSetName?: string;
  Parent?: Chemical;
  Daugther?: Chemical;
  Granddaughter?: Chemical;
  OptionalOutputs?: OptionalOutputs;
  applicationScheme?: ApplicationSchemePWC[];
  WaterShed?: WaterShed;
}

export class ApplicationSchemePWC {
  id?: number;
  name?: string;
  scenarios?: Scenarios[];
  applications?: Applications;
}

export class WaterShed {
  useSprayDrift?: boolean = false;
  simulationTypes?: SimulationType[];
}

export class Scenarios {
  cropName?: string;
  regionName?: string;
}