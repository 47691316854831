import { Injectable } from '@angular/core';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { Molecule, MoleculePkAndName } from 'src/app/shared/models/echo/molecule';
import { Endpoint } from 'src/app/shared/models/endpoint';
import { Project } from 'src/app/shared/models/project';
import { ProjectPreviousRun } from 'src/app/shared/models/project-previous-run';
import { Run } from 'src/app/shared/models/run/run';
import { ActiveIngredientApiService } from 'src/app/shared/services/echo/active-ingredient.api.service';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { EndpointApiService } from 'src/app/shared/services/echo/endpoint.api.service';
import { ProjectApiService } from 'src/app/shared/services/project.api.service';

@Injectable({
  providedIn: 'root'
})
export class EEARunProjectLogicService {

  constructor(
    private dataSetApiService: DataSetApiService,
    private endpointApiService: EndpointApiService,
    private projectApiService: ProjectApiService,
    private activeIngredientApiService: ActiveIngredientApiService,
    private selectedProjectApiService: SelectedProjectApiService,
  ) { }

  getDataSetsByProject(projectPk: number, source: string): Promise<DataSet[]> {
    return new Promise<DataSet[]>((resolve, reject) => {
      this.dataSetApiService.getDataSetsProjectByCompartment(projectPk, source).subscribe({
        next: (dataset: DataSet[]) => {
          resolve(dataset.filter((x) => x.useInProject === true));
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getRunsEndpoints(runs: Run[]): Promise<Endpoint[]> {
    return new Promise<Endpoint[]>((resolve, reject) => {
      this.endpointApiService.getRunEndpoints({
        activeIngredientPks: runs?.map((x) => x.activeIngredientPk).flat().filter((value, index, self) => self.indexOf(value) === index),
        dataSetPks: runs?.map((x) => x.dataSet!.dataSetPk).flat().filter((value, index, self) => self.indexOf(value) === index),
      })
        .subscribe({
          next: (endpoints: Endpoint[]) => {
            resolve(endpoints);
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  getRunEndpointsForPWC(runs: Run[]): Promise<Endpoint[]> {
    return new Promise<Endpoint[]>((resolve, reject) => {
      this.endpointApiService.getRunEndpointsForPWC({
        activeIngredientPks: runs?.map((x) => x.activeIngredientPk).flat().filter((value, index, self) => self.indexOf(value) === index),
        dataSetPks: runs?.map((x) => x.dataSet!.dataSetPk).flat().filter((value, index, self) => self.indexOf(value) === index),
      })
        .subscribe({
          next: (endpoints: Endpoint[]) => {
            resolve(endpoints);
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  getProjectsByPk(projectPks: number[]): Promise<Project[]> {
    return new Promise<Project[]>((resolve, reject) => {
      this.projectApiService.getProjectsByPks(projectPks).subscribe({
        next: (projects: Project[]) => {
          resolve(projects);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getBamsMoleculePkAndNameByPks(moleculePks: number[]): Promise<MoleculePkAndName[]> {
    return new Promise<MoleculePkAndName[]>((resolve, reject) => {
      if (moleculePks === undefined) resolve([]);
      this.activeIngredientApiService.getBamsMoleculePkAndNameByPks(moleculePks).subscribe({
        next: (molecules: MoleculePkAndName[]) => {
          resolve(molecules);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }
  
  getActiveIngredients(): Promise<Molecule[]> {
    return new Promise<Molecule[]>((resolve, reject) => {
      this.activeIngredientApiService.getActiveIngredients().subscribe({
        next: (activeIngredients: Molecule[]) => {
          resolve(activeIngredients);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getPreviousRunsByProject(projectPk: number): Promise<ProjectPreviousRun[]> {
    return new Promise<ProjectPreviousRun[]>((resolve, reject) => {
      this.projectApiService.getPreviousRunsByProject(projectPk, this.selectedProjectApiService.featureAcronym!).subscribe({
        next: (projectPreviousRun: ProjectPreviousRun[]) => {
          resolve(projectPreviousRun);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }
}