import { Component } from '@angular/core';
import { MetaboliteNPrecursors } from '../../models/echo/metabolite-n-precursors';
import { EndpointApiService } from '../../services/echo/endpoint.api.service';

@Component({
  selector: 'app-ordering-metabolite',
  templateUrl: './ordering-metabolite.component.html',
  styleUrls: ['./ordering-metabolite.component.css']
})
export class DragDropMetaboliteComponent {
  visible: boolean = false;
  widthSize: string = '500px';
  products!: any[];
  metabolitesNPrecursors: MetaboliteNPrecursors[] = [];
  isSetting: boolean = false;
  disabled = false;

  constructor(private endpointApiService: EndpointApiService) { }

  showDialog(params: any) {
    this.disabled = params.data.disabled;
    if (this.metabolitesNPrecursors && this.metabolitesNPrecursors.length > 0) {
      this.visible = true;
      return;
    }
    this.getPrecursorsByMetaboliteByDataSet(params.data.dataSetPk);
  }

  getPrecursorsByMetaboliteByDataSet(dataSetPk: number) {
    this.endpointApiService.getPrecursorsByMetaboliteByDataSet(dataSetPk)
      .subscribe({
        next: (metabolitesNPrecursors) => {
          this.metabolitesNPrecursors = this.processData(metabolitesNPrecursors);
          this.visible = true;
        },
        error: (error) => {
          console.error(error);
          this.visible = false;
        },
      })
  }
  processData(metabolitesNPrecursors: MetaboliteNPrecursors[]): MetaboliteNPrecursors[] {
    metabolitesNPrecursors.forEach((metaboliteNPrecursor, index) => {
      metaboliteNPrecursor.precursorsList = metaboliteNPrecursor.precursors.join(', ');
    });
    return metabolitesNPrecursors;
  }

  setOrder() {
    this.getDatasetsEndpoints();
  }

  getDatasetsEndpoints() {
    this.isSetting = true;
    this.endpointApiService.setOrderToMetabolites(this.metabolitesNPrecursors)
      .subscribe({
        next: (isSuccessful) => {
          if (isSuccessful)
            this.visible = false;
          this.isSetting = false;
        },
        error: (error) => {
          console.error(error);
          this.visible = false;
          this.isSetting = false;
        },
      })
  }

  cancel() {
    this.visible = false;
    this.metabolitesNPrecursors = [];
  }
}
