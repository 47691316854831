<ng-container *ngIf="!floating && !useTitle">
    <label *ngIf="label">{{label}}</label>
    <textarea pInputTextarea [rows]="rows" [cols]="cols" [id]="id" [style]="style" [disabled]="disabled" [(ngModel)]="model" (ngModelChange)="modelChanged($event)"></textarea>
</ng-container> 

<span *ngIf="floating" class="p-float-label">
    <textarea class="w-12" [id]="id" [rows]="rows" [cols]="cols" pInputTextarea  [disabled]="disabled" [(ngModel)]="model" (ngModelChange)="modelChanged($event)"></textarea>
    <label [for]="id">{{label}}</label>
</span>

<div *ngIf="useTitle && !useHelperNote" class="flex flex-column gap-2">
    <label [for]="id">{{label}}</label>
    <textarea class="w-12" pInputTextarea [id]="id" [rows]="rows" [cols]="cols"  [disabled]="disabled" [(ngModel)]="model" (ngModelChange)="modelChanged($event)"></textarea>
</div>

<div *ngIf="useTitle && useHelperNote" class="flex flex-column gap-2">
    <div class="label-with-icon">
        <label [for]="id">{{label}}</label>
        <img src="assets/img/infoIcon.png" (click)="showHelperNotesPopup('PROJECT_DESCRIPTION')"
            style="width: 16px; height: 16px; margin-left: 8px; cursor: pointer;">
    </div>
    <textarea class="w-12" pInputTextarea [id]="id" [rows]="rows" [cols]="cols"  [disabled]="disabled" [(ngModel)]="model" (ngModelChange)="modelChanged($event)"></textarea>
</div>