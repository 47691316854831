import { ChangeDetectorRef, Component, DestroyRef, OnDestroy, OnInit, effect, inject } from '@angular/core';
import { EEALogicService } from './environmental-exposure-assessment.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { Message } from 'primeng/api';
import { Project } from 'src/app/shared/models/project';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { Constants } from 'src/app/shared/utils/constants';
import { Environmental_Assessments, UserLogicService } from 'src/app/shared/services/user.logic.service';
import { ModelLibrary, ModelLibraryCollection } from 'src/app/shared/models/model-library';
import { firstValueFrom, take } from 'rxjs';
import { ModelLibraryApiService } from 'src/app/shared/services/model-library.api.service';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { EEASetupColDef } from '../eea-setup/eea-setup/eea-setup-coldef';
import { DisciplineApiService } from 'src/app/shared/services/echo/discipline.api.service';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { SetupLogicService } from 'src/app/shared/services/setup.logic.service';

@Component({
  selector: 'app-environmental-exposure-assessment',
  templateUrl: './environmental-exposure-assessment.component.html',
  styleUrls: ['./environmental-exposure-assessment.component.css']
})
export class EnvironmentalExposureAssessmentComponent implements OnInit, OnDestroy {
  activeItem!: CustomTabMenuItem;
  message!: Message;
  loading: boolean = false;
  nextIndex?: number;
  selectedProject?: Project;
  selectedProjectStatus?: number;
  isSetupValid: boolean = false;
  destroyRef = inject(DestroyRef)
  pwcFlag: boolean = false;
  isProjectOwnershipValid: boolean = true;
  environmentalAssessment: string = Constants.FEATURE_ACRONYM.ENVIRONMENTAL_EXPOSURE_ASSESSMENT;
  compartments: string[] = [Constants.COMPARTMENTS.SURFACE_WATER.toLowerCase().replace(" ", ""), Constants.COMPARTMENTS.GROUND_WATER.toLowerCase(), Constants.COMPARTMENTS.SOIL.toLowerCase(), Constants.COMPARTMENTS.CORE.toLowerCase()];
  columnDef: any[] = [];
  disciplinePk: number = 0;
  modelsCollection: ModelLibraryCollection[] = [];
  compartmentList: any[] = [];
  showLoadingForOutput: boolean = true;
  modelsSelected: string[] = [];
  featureAcronym = Environmental_Assessments.EEA;

  private setupLogicService = inject(SetupLogicService);

  constructor(public EEALogicService: EEALogicService,
    private selectedProjectApiService: SelectedProjectApiService,
    private userLogicService: UserLogicService,
    private orderByPipe: OrderByPipe,
    private modelLibraryApiService: ModelLibraryApiService,
    private EEASetupColDef: EEASetupColDef,
    private disciplineApiService: DisciplineApiService,
    private dataSetService: DataSetApiService,
    private cdr: ChangeDetectorRef) {
  }

  selectedGeographyEffect = effect(() => {
    if(this.setupLogicService.selectedGeographyKey())
      this.initColumnDef();
  });

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.initCompartemtList();
    await this.getDiscipline();
    await this.initModels();
    this.initSubscribes();
  }

  async getDiscipline(): Promise<void> {
    await firstValueFrom(this.disciplineApiService.getDisciplineByName(Constants.DISCIPLINE_IDENTITIES.ENVIRONMENTAL_E_FATE).pipe(take(1))).then((discipline: Discipline) => {
      this.disciplinePk = discipline?.disciplinePk ?? -1;
    });
  }

  async initCompartemtList(): Promise<void> {
    await firstValueFrom(this.dataSetService.getCompartments(Constants.CONST_COMPARTMENT).pipe(take(1))).then(((compartment: any) => {
      this.compartmentList = compartment;
    }));
  }

  async initModels() {
    const localCollection: ModelLibraryCollection[] = this.compartments.map(compartment => { return { name: compartment, library: [] } });
    let models: ModelLibrary[] = [];

    const swModels = localCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.SURFACE_WATER.replace(" ", "").toLowerCase());
    const gwModels = localCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.GROUND_WATER.toLowerCase());
    const soilModels = localCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.SOIL.toLowerCase());

    await firstValueFrom(this.modelLibraryApiService.getModels()).then((library: ModelLibrary[]) => {
      models = library.filter(f => f.compartmentXModel?.length && f.compartmentXModel?.length >= 0 && f.disciplineXModel?.some(d => d == this.disciplinePk));
      if (swModels) swModels.library = this.orderByPipe.transform(models.filter(m => m.compartmentXModel?.includes(this.compartmentList?.find(x => x.compartment.toLowerCase() === Constants.COMPARTMENTS.SURFACE_WATER.toLowerCase())?.endpointCompartmentPk)), 'nameVersion');
      if (gwModels) gwModels.library = this.orderByPipe.transform(models.filter(m => m.compartmentXModel?.includes(this.compartmentList?.find(x => x.compartment.toLowerCase() === Constants.COMPARTMENTS.GROUND_WATER.toLowerCase())?.endpointCompartmentPk)), 'nameVersion');
      if (soilModels) soilModels.library = this.orderByPipe.transform(models.filter(m => m.compartmentXModel?.includes(this.compartmentList?.find(x => x.compartment.toLowerCase() === Constants.COMPARTMENTS.SOIL.toLowerCase())?.endpointCompartmentPk)), 'nameVersion');
    });

    this.modelsCollection = localCollection;
  }

  initColumnDef() {
    const selectedGeography = this.setupLogicService.selectedGeographyKey();
    const swModels = this.modelsCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase())?.library.filter(m => m.geographyXModel?.some(g => this.setupLogicService.isSelectedGeography(g, selectedGeography)));
    const gwModels = this.modelsCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.GROUND_WATER.toLowerCase())?.library.filter(m => m.geographyXModel?.some(g => this.setupLogicService.isSelectedGeography(g, selectedGeography)));
    const soilModels = this.modelsCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.SOIL.toLowerCase())?.library.filter(m => m.geographyXModel?.some(g => this.setupLogicService.isSelectedGeography(g, selectedGeography)));
    this.columnDef = this.EEASetupColDef.getColumnsDefinition(swModels ?? [], gwModels ?? [], soilModels ?? [], this.isProjectOwnershipValid);
  }

  onActiveIndexChange(index: number) {
    this.nextIndex = index;
    this.EEALogicService.setActiveIndexChanged(true, index);
    if (index == Constants.TAB_INDEX.OUTPUT) {
      if (!this.showLoadingForOutput) {
        this.loading = false;
      }
    }
  }

  initSubscribes(): void {
    this.EEALogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activeItem => {
      this.activeItem = activeItem;
    });

    this.selectedProjectApiService.selectedProject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((project?: Project) => {
      this.selectedProject = project;
      this.isProjectOwnershipValid = this.selectedProject ? this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy) : true;
      this.modelsSelected = this.selectedProject?.projectXCompoundXModel?.map(a => a.ModelName).filter((name): name is string => name !== undefined) ?? [];
      this.onActiveIndexChange(0);
    });

    this.selectedProjectApiService.selectedProjectStatus.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status?: number) => {
      this.selectedProjectStatus = status;
    });

    this.EEALogicService.isLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isLoading => {
      this.loading = isLoading;
    });

    this.EEALogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(success => {
      this.loading = false;
      if (success) {
        const nextItem = this.EEALogicService.items.find(i => i.index == this.nextIndex);
        if (nextItem)
          this.EEALogicService.setActiveItem(nextItem);
      }
    });
  }

  validateSetup(event: boolean) {
    this.isSetupValid = event;
    this.cdr.detectChanges();
  }

  isPWC(): boolean {
    let pwcFlag = this.selectedProject?.projectXCompoundXModel?.map(a => a.ModelName).includes(Constants.MODELS.PWC) ?? false;
    return pwcFlag 
  }

  public getIsValidEndpoint(isValid: boolean): void {
    this.isSetupValid = isValid;
    this.cdr.detectChanges();
  }

  onUpdateLoadingStatus(loadingStatus: boolean) {
    this.loading = loadingStatus;
  }

  onShowLoadingForOutput(showLoadingForOutput: boolean) {
    this.showLoadingForOutput = showLoadingForOutput;
  }

  public getIsValidApplicationScheme(isValid: boolean): void {
    this.isSetupValid = isValid;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.selectedGeographyEffect.destroy();
  }
}