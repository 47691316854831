<div class="grid" *ngIf="!isLoading">
    <div class="col-12 p-3 mt-3">
      <app-grid #grid [domLayout]="'autoHeight'" 
      [columnDefs]="columnDefs"
      [style]="style"
      [isProjectOwnershipValid]="isProjectOwnershipValid"
      [rowData]="rowData">
      </app-grid>
    </div>
  </div>
  <app-loading class="mt-2 flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>