import Swal, { SweetAlertResult } from "sweetalert2";
import { Compartment } from "../models/compartment";
import { Constants } from "./constants";
import * as moment from "moment";
import { Project } from "../models/project";
import { ModelLibrary } from "../models/model-library";
import { RunModel } from "../models/run/run-model";

export abstract class Utils {


  static sortObject(pArray: any, pColumn: string): any {
    return pArray.sort(function (a: any, b: any) {
      if (a[pColumn]?.toLowerCase() < b[pColumn]?.toLowerCase()) {
        return -1;
      }
      if (a[pColumn]?.toLowerCase() > b[pColumn]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  static convertDate(pParams: any): string {
    if (pParams.value) {
      const date = new Date(pParams.value);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }
    return '';
  }

  static convertDateWithoutYear(pParams: any): string {
    if (pParams.value) {
      const date = new Date(pParams.value);
      const day: string = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate().toString();
      const monthNames = Constants.MONTH_SHORT_NAMES;
      const month = monthNames[date.getMonth()]
      return `${day}-${month}`;
    }
    return '';
  }

  static formatDashedStringDate(dashedDate: string | null | undefined): string {
    if (dashedDate) {
      let nextDay = this.getNextDay(dashedDate.substring(0, 10));
      const dateObj = new Date(nextDay);
      const monthNames = Constants.MONTH_SHORT_NAMES;
      const outputDate = dateObj.getDate() + '-' + monthNames[dateObj.getMonth()] + '-' + dateObj.getFullYear();

      return outputDate;
    }
    return "";
  }

  static getEndWindowTakingIntoAccountApplicationIntervals(pOriginalEndWindow: string, pRow: any): string {
    let daysToAdd: number = 0
    pRow.children?.forEach((value: any, index: any) => {
      if (index > 0 && value.activeIngredient === pRow.activeIngredient)
        daysToAdd += parseInt(value.minApplicationInterval)
    });
    return this.addDaysToDate(pOriginalEndWindow, daysToAdd)
  }

  static isEmptyValue(pValue: any) {
    return pValue === null || pValue === undefined || pValue === '';
  }

  static addDaysToDate(inputDate: string, numberOfDays: number): string {
    return moment(inputDate).add(numberOfDays, 'day').format();
  }

  static addDaysWithoutFormat(inputDate: string, numberOfDays: number): any {
    return moment(inputDate).add(numberOfDays, 'day');
  }

  static addDaysToDateFormated(inputDate: string, numberOfDays: number): string {
    return moment(inputDate).add(numberOfDays, 'day').format('l');
  }

  static convertDateToString = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().split('.')[0];
  };

  static getNextDay(dateString: string): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 2);
    if (date.getMonth() === 0 && date.getDate() === 1) {
      date.setFullYear(date.getFullYear() + 1);
    }

    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  static convertNumberToExponential(pValue: any) {
    var shouldConvertToExponential = (Number(pValue) < 1 && Number(pValue) > 0) && (pValue.toString().split(".")[1]?.length >= 3)
    return shouldConvertToExponential ? Number(pValue).toExponential() : pValue;
  }

  static convertExponentialToNumber(pValue: any) {
    if (typeof pValue === 'string' && pValue.includes('e')) {
      return Number(pValue);
    }
    return pValue;
  }

  static formatToExponential(pValue: any) {
    var hasDecimals = pValue?.toString().split(".")[1]?.length >= 3;
    var isZeroInterval = (Number(pValue) < 1 && Number(pValue) > 0);
    var shouldConvertToExponential = isZeroInterval && hasDecimals;
    return shouldConvertToExponential ? Number(Number(pValue).toFixed(4)).toExponential() : hasDecimals ? Number(pValue).toFixed(4) : pValue;
  }

  static getKomValue(pValue: number): number {
    if (pValue)
      return pValue / 1.724;
    return 0;
  }

  static getk1Value(Dt50: number): number | undefined {
    if (Dt50 !== undefined && Dt50 !== null && Dt50 !== 0)
      return Math.log(2) / Dt50;
    return undefined;
  }

  static getDegT90Value(k1: number) {
    if (k1 === undefined || k1 == null) return undefined;
    return this.formatToExponential(this.calculateDegT90(k1));
  }

  static calculateDegT90(k1: number | undefined): number | undefined {
    if (k1 !== undefined && k1 !== null && k1 !== 0) {
      return Math.log(10) / k1;
    }
    return undefined;
  }

  static getDateFormatToSaveData(): string {
    const estDate = this.getEasternTimeDate();
    const strDate = this.getServerStringDate(estDate);
    return strDate;
  }

  static getEasternTimeDate(): Date {
    const date = new Date();
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000); // (IST)
    const offset = -300; // Timezone offset for EST in minutes.
    const estDate = new Date(date.getTime() + offset * 60 * 1000);
    return estDate;
  }

  static getServerStringDate(date: Date): string {
    const datetime =
      date.getFullYear() +
      '-' +
      (date.getMonth() < 10 ? '0' : '') +
      date.getMonth() +
      '-' +
      (date.getDate() < 10 ? '0' : '') +
      date.getDate() +
      'T' +
      (date.getHours() < 10 ? '0' : '') +
      date.getHours() +
      ':' +
      (date.getMinutes() < 10 ? '0' : '') +
      date.getMinutes() +
      ':' +
      (date.getSeconds() < 10 ? '0' : '') +
      date.getSeconds();
    return datetime;
  }

  static formatFilterValues(pParams: any, items: any[], compareValue: any, valueToShow: any): string {
    let filterName = items.find((x: any) => x[compareValue] === pParams.value);
    return filterName ? filterName[valueToShow] : pParams.value ? pParams.value : '(Blanks)';
  }

  static percentageFormat(pParams: any) {
    return pParams.value ? `${pParams.value}  %` : '';
  }

  static getDayOfYear(pDate: Date): number {
    return moment(pDate).dayOfYear();
  }

  static getFirstMatchBetweenTwoArrays(array1: any, array2: any) {
    for (const element of array1) {
      if (array2.includes(element)) {
        return element;
      }
    }
    return null;
  }

  static CheckValueInRanges(rangesIput: string, valueToCheck: number): number {
    if (rangesIput == '')
      return valueToCheck;

    const ranges = rangesIput.split('-');
    const minRange: number = Number(ranges[0]);
    const maxRange: number = Number(ranges[1]);
    let value: number = valueToCheck;

    if (valueToCheck < minRange)
      value = minRange;

    if (valueToCheck > maxRange)
      value = maxRange;

    return value;
  }

  static createCompartments(compartments: any | undefined): Compartment[] {
    if (!compartments) {
      return [];
    }
    return compartments.map((x: any) => ({
      compartment: x.compartment,
      name: x.compartment,
      pk: x.endpointCompartmentPk
    }));
  }

  static convertToRange(number: number): number {
    if (number === 0) return 0.00;
    const exponent = Math.floor(Math.log10(Math.abs(number))) + 1;
    if (!isFinite(exponent)) return 0.00;
    return number / Math.pow(10, exponent);
  }

  static getFormatKomValue(pKfoc: number) {
    if (pKfoc === undefined || pKfoc == null) return undefined;
    return this.formatToExponential(this.getKomValue(pKfoc));
  }

  static getFormatKfocValue(kom: number) {
    if (kom === undefined || kom == null) return undefined;
    return this.formatKfoc(this.getKomValue(kom));
  }

  static formatKfoc(value: any) {
    var hasDecimals = value?.toString().split(".")[1]?.length >= 3;
    var isZeroInterval = (Number(value) < 1 && Number(value) > 0);
    var shouldConvertToExponential = isZeroInterval && hasDecimals;
    return shouldConvertToExponential ? Number(Number(value).toFixed(4)).toExponential() : hasDecimals ? Number(value).toFixed(4) : value;
  }

  static formatIsoDateToCustom(isoDate: string): string {
    const months = Constants.MONTH_SHORT_NAMES;

    // Parse the ISO date
    const date = new Date(isoDate);

    // Extract day, month, and year
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Format: dd-MMM-yyyy
    return `${day}-${month}-${year}`;
  }

  static getSaveString(value: string): string {
    let result: string = '';
    if (value)
      result = String(value);
    return result;
  }

  static getSaveNumber(value: string): number {
    let result: number = 0;
    if (!isNaN(result) && value)
      result = Number(value);
    return result;
  }

  static getSaveBoolean(value: string): boolean {
    let result: boolean = false;
    if (value)
      result = value == 'true' ? true : false;

    return result;
  }

  public static showErrorMessage(title: string, text: string): void {
    Swal.fire({
      title: title,
      text: text,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Ok',
      icon: 'error'
    });
  }

  public static safeStringify(obj: any) {
    const seen = new WeakSet();

    return JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return '[Circular]';
        }
        seen.add(value);
      }
      return value;
    });
  }

  public static showWarningMessage(title: string, text: string): void {
    Swal.fire({
      title: title,
      text: text,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Ok',
      icon: 'warning'
    });
  }

  public static showSuccessMessage(text: string): void {
    Swal.fire({
      text: text,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Ok',
    });
  }

  public static showConfirmationMessage(text: string): Promise<SweetAlertResult> {
    return Swal.fire({
      text: text,
      showCancelButton: true,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
  }

  public static showErrorMessageHtml(title: string, html: string): void {
    Swal.fire({
      title: title,
      html: html,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Ok',
      icon: 'error'
    });
  }

  public static GetEndDateByApplication(pStartDate: string, pApplicationInterval: number, pNumberOfApplications: number): string {
    if (!pApplicationInterval || !pNumberOfApplications)
      return 'undefined';
    return moment(pStartDate).add((30 + ((pNumberOfApplications - 1) * pApplicationInterval)), 'day').format();
  }

  static repalceIllegalCharactersInSubstanceName(substanceName: string | undefined): string | undefined {
    const regex = new RegExp(`[${Constants.illegalCharactersInSubstanceNames.map((char: any) => `\\${char}`).join('')}]`, 'g');
    return substanceName?.replace(regex, '_');
  }

  static getAlternativeCompoundName(activeIngredientPk: number | undefined, selectedProject: Project | undefined, modelName?: string, metabolitePk?: number | undefined) {
    let projectXCompoundXModel = selectedProject?.projectXCompoundXModel?.find(x =>
      x.MoleculePk === activeIngredientPk &&
      (modelName === undefined || x.ModelName === modelName) &&
      x.MetabolitePk === (metabolitePk === undefined ? null : metabolitePk)
    );
    return projectXCompoundXModel?.AlternativeNameCompound ?? undefined;
  }

  static getCompoundOrder(activeIngredientPk: number | undefined, selectedProject: Project | undefined, metabolitePk?: number | undefined) {
    let projectXCompoundXModel = selectedProject?.projectXCompoundXModel?.find(x =>
      x.MoleculePk === activeIngredientPk &&
      x.MetabolitePk === (metabolitePk == null ? null : metabolitePk)
    );
    return projectXCompoundXModel?.CompoundOrder ?? undefined;
  }

  static validateAlternativeNameCompound(metaboliteName: string, alternativeNameCompound: string | undefined, surfacewater: ModelLibrary[], groundwater: ModelLibrary[]): boolean {
    let modelsSelected: string[] = [
      ...(groundwater ? groundwater.map((x: { name: string; }) => x.name) : []),
      ...(surfacewater ? surfacewater.map((x: { name: string; }) => x.name) : [])
    ];

    if (metaboliteName?.length > 14 && modelsSelected.some(model => Constants.ALTERNATIVE_NAME_MODELS.includes(model))) {
      if (alternativeNameCompound === undefined || alternativeNameCompound.length === 0) {
        return false;
      }

      return true;
    }
    return true;
  }

  static showAlternativeName(compound: string | undefined, groundwater: ModelLibrary[] | undefined, surfacewater: ModelLibrary[] | undefined): boolean {
    let modelsSelected: string[] = [
      ...(groundwater ? groundwater.map((x: { name: string; }) => x.name) : []),
      ...(surfacewater ? surfacewater.map((x: { name: string; }) => x.name) : [])
    ];

    return (compound ?? '').length > 14 && modelsSelected.some(model => Constants.ALTERNATIVE_NAME_MODELS.includes(model));
  }

  static getDayMonthDate(pDate: Date): string {
    if (pDate) {
      const date = new Date(pDate);
      const day: string = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate().toString();
      const monthNames = Constants.MONTH_SHORT_NAMES;
      const month = monthNames[date.getMonth()]
      return `${day}-${month}`;
    }
    return '';
  }

  static omitIllegalCharacters(event: any, illegalCharacters:string[]) {
    const char = event.key;
    if (illegalCharacters.includes(char)) {
      event.preventDefault();
    }
  }

  static omitIllegalCharactersOnPaste(event: ClipboardEvent, illegalCharacters: string[]) {
    const pastedData = event.clipboardData?.getData('text') || '';  
    const escapedChars = illegalCharacters.map(Utils.escapeRegExp).join('');
    const illegalCharsPattern = new RegExp(`[${escapedChars}]`, 'g');
  
    if (illegalCharsPattern.test(pastedData)) {
      event.preventDefault();
    }
  }

  static orderDataByCompoundOrder(data: any[]): any[] {
    return data.sort((a: any, b: any) => {
      const aOrder = a?.compoundOrder ?? Infinity;
      const bOrder = b?.compoundOrder ?? Infinity;
      return aOrder - bOrder;
    });
  }

  static excludeMacroRunsByLimit(runModel: RunModel): number {
    let maxRuns = 0;

    if (runModel.swash) {
      const macroRuns = runModel.swash.filter(run => run.scenario?.startsWith('D'));

      if (macroRuns.length >= 199) {
        const includeTwoMetabolites = runModel.swash!.some(run => run.scenario!.startsWith('D') && run.metabolites && run.metabolites?.length >= 2);
        const includeOneMetabolites = runModel.swash!.some(run => run.scenario!.startsWith('D') && run.metabolites && run.metabolites?.length === 1);
        const noneMetabolites = runModel.swash!.some(run => run.scenario!.startsWith('D') && run.metabolites && run.metabolites?.length === 0);

        if (includeTwoMetabolites) {
          maxRuns = Constants.MAX_RUNS_LIMITS.TWO_METABOLITES;
        } else if (includeOneMetabolites) {
          maxRuns = Constants.MAX_RUNS_LIMITS.ONE_METABOLITE;
        } else if (noneMetabolites) {
          maxRuns = macroRuns.length > Constants.MAX_RUNS_LIMITS.NONE_METABOLTE ? Constants.MAX_RUNS_LIMITS.NONE_METABOLTE : 0;
        }
      }
    }
    return maxRuns;
  }

  static escapeRegExp(string: string) {
    return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
}
