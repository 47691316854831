import { Injectable } from "@angular/core";
import { Project } from "src/app/shared/models/project";
import { Constants } from "src/app/shared/utils/constants";

@Injectable({
    providedIn: 'root',
})

export class IMRunRiskAssessmentBeesColDef {

    public getColumnsDefinition(isInverseModule: any, selectedProject: Project, mainGreography: any) {
        return [{
            headerName: 'Rate (g/Ha)',
            editable: false,
            hide: isInverseModule ? selectedProject!.useRateGHA ? false : true : true,
            pinned: 'left',
            field: 'rate',
            width: 100,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },      
          {
            headerName: 'Rate (lb/acre)',
            editable: false,
            hide: isInverseModule ? selectedProject!.useRateLBACRE ? false : true : true,
            pinned: 'left',
            field: 'rate',
            width: 100,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },     
          {
            headerName: 'BBCH',
            editable: false,
            hide: isInverseModule ? selectedProject!.useBBCH ? false : true : true,
            pinned: 'left',
            field: 'bbch',
            width: 95,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Application window',
            editable: false,
            hide: isInverseModule ? selectedProject!.useApplicationWindow ? false : true : true,
            pinned: 'left',
            field: 'rate',
            width: 130,
            minWidth: 100,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Number of applications',
            editable: false,
            pinned: 'left',
            hide: isInverseModule ? selectedProject!.useNumberOfApplications ? false : true : true,
            field: 'applicationNumber',
            width: 140,
            minWidth: 100,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Interval between applications',
            editable: false,
            pinned: 'left',
            hide: isInverseModule ? selectedProject!.useIntervalBetweenApplications ? false : true : true,
            field: 'applicationInterval',
            width: 150,
            minWidth: 100,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          }, 
          {
            headerName: 'Result',
            pinned: isInverseModule ? 'left' : 'right',
            editable: false,
            field: 'result',
            width: 95,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
            cellStyle: (params: any) => {
              if (params.value === Constants.RUN_RISK_ASSESSMENT_RESULT_VALUE.Passed) {
                  return {color: 'green'};
              }
              else {
                return {color: 'red'};
              }
            }
          },
          {
            headerName: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? 'Indicator species/category' : 'Taxon/Exposure duration',
            editable: false,
            pinned: 'left',
            field: 'taxonExposureDuration',
            width: 275,
            minWidth: 275,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Active Ingredient',
            editable: false,
            field: 'activeIngredient',
            width: 160,
            minWidth: 160,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Substance type',
            editable: false,
            field: 'substanceType',
            width: 160,
            minWidth: 160,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Substance name',
            editable: false,
            pinned: isInverseModule ? '' : 'left',
            field: 'substanceName',
            width: 160,
            minWidth: 160,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },           
          {
            headerName: 'Ecotox Dataset',
            editable: false,
            field: 'ecotoxDataset',
            width: 180,
            minWidth: 180,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Listed / Non-listed',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? true : false,
            field: 'listedNoListed',
            width: 120,
            minWidth: 120,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Species',
            editable: false,
            field: 'species',
            width: 160,
            minWidth: 160,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },      
          {
            headerName: 'Endpoint type',
            editable: false,
            field: 'endpointType',
            width: 140,
            minWidth: 140,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Endpoint ',
            editable: false,
            field: 'endpoint',
            width: 95,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Endpoint value',
            editable: false,
            field: 'endpointValue',
            width: 95,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Endpoint unit',
            editable: false,
            field: 'endpointUnit',
            width: 95,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Application Scheme',
            editable: false,
            field: 'applicationScheme',
            minWidth: 300,
            width: 300,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Formulation',
            editable: false,
            field: 'formulation',
            width: 110,
            minWidth: 110,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Crop',
            editable: false,
            field: 'crop',
            minWidth: 170,
            width: 170,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? true : false,
          },
          {
            headerName: 'Crop (Interception)',
            editable: false,
            field: 'cropInterception',
            minWidth: 170,
            width: 170,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
            hide: mainGreography === Constants.CROP_GEOGRAPHIES.EUROPE_UNION ? false : true,
          },
          {
            headerName: 'Crop (AppDate)',
            editable: false,
            field: 'cropAppDate',
            minWidth: 170,
            width: 170,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
            hide: mainGreography === Constants.CROP_GEOGRAPHIES.EUROPE_UNION ? false : true,
          },
          {
            headerName: 'Crop (Drift)',
            editable: false,
            field: 'cropDrift',
            minWidth: 170,
            width: 170,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
            hide: mainGreography===Constants.CROP_GEOGRAPHIES.UK ? false : true,
          },
          {
            headerName: 'Crop (Spray)',
            editable: false,
            field: 'cropSpray',
            minWidth: 170,
            width: 170,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
            hide: mainGreography===Constants.CROP_GEOGRAPHIES.UK ? false : true,
          },
          {
            headerName: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? 'Rate (g/Ha)' : 'Rate (lb/acre)',
            field: 'rate',
            width: 95,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Model',
            editable: false,
            field: 'model',
            width: 145,
            minWidth: 155,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'FOCUS Step',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
            field: 'focusStep',
            width: 100,
            minWidth: 100,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Region',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
            field: 'region',
            width: 95,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Period',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
            field: 'period',
            width: 95,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Scenario',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
            field: 'scenario',
            width: 95,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Buffer (m)',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
            field: 'buffer',
            width: 90,
            minWidth: 90,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Nozzle Drfit Reduction (%)',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
            field: 'nozzleDriftReduction',
            width: 120,
            minWidth: 120,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Water body',
            editable: false,
            field: 'waterbody',
            width: 105,
            minWidth: 105,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? 'PEC type' : 'EEC type',
            editable: false,
            field: 'eecPecType',
            width: 100,
            minWidth: 100,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? 'PEC value' : 'EEC value',
            editable: false,
            field: 'eecPecValue',
            width: 80,
            minWidth: 80,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'Risk quotient',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? true : false,
            field: 'riskEquotient',
            width: 95,
            minWidth: 95,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'LOC',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? true : false,
            field: 'loc',
            width: 75,
            minWidth: 75,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'AF',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
            field: 'af',
            width: 75,
            minWidth: 75,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'RAC',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
            field: 'rac',
            width: 75,
            minWidth: 75,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'ETR value',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
            field: 'etrValue',
            width: 85,
            minWidth: 85,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },
          {
            headerName: 'ETR trigger',
            editable: false,
            hide: mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
            field: 'etrTrigger',
            width: 85,
            minWidth: 85,
            suppressSizeToFit: true,
            filter: 'agSetColumnFilter',
            type: 'default',
          },      
        ];
    }
}