<p-dialog [(visible)]="visible" [style]="{width: widthSize}" (onHide)="cancel()">
    <div  *ngIf="!disabled" style="font-size: 12px; margin-bottom: 10px;margin-left: 5px;">
        Drag and drop the rows below to setup the order
    </div>    
    <p-orderList [value]="metabolitesNPrecursors" [listStyle]="{ 'max-height': '30rem' }"
        header="Metabolites&emsp;&emsp;&emsp;=>&emsp;&emsp;&emsp;Precursors" [dragdrop]="true" [disabled]="disabled" appendTo="body">
        <ng-template let-metabolitesNPrecursor pTemplate="item">
            <div class="flex" style="height: 20px;">
                <div class="flex-none flex align-items-center justify-content-left" style="width: 110px;">
                    <span style="white-space: normal">{{ metabolitesNPrecursor.substancesName }}</span>
                </div>
                <div class="flex-grow-1 flex align-items-center justify-content-left px-5">
                    <span style="white-space: normal">{{ metabolitesNPrecursor.precursorsList }}</span>
                </div>
            </div>
        </ng-template>
    </p-orderList>
    <ng-template pTemplate="footer">
        <p-button *ngIf="!disabled" icon="{{isSetting ? 'pi pi-spin pi-spinner' : 'pi pi-check' }}" (click)="setOrder()"
            label="{{isSetting ? 'Setting' : 'Set' }}" pAutoFocus [autofocus]="true" [disabled]="isSetting"></p-button>
    </ng-template>
</p-dialog>