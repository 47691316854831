
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { firstValueFrom, map, switchMap, take } from 'rxjs';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { ActiveIngredient } from 'src/app/shared/models/active-ingridient';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { Formulation } from 'src/app/shared/models/formulation';
import { Project } from 'src/app/shared/models/project';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { FormulationApiService } from 'src/app/shared/services/echo/formulation.api.service';
import { GeographyApiService } from 'src/app/shared/services/echo/geography.api.service';
import { Environmental_Assessments, UserLogicService } from 'src/app/shared/services/user.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MetaboliteApiService } from 'src/app/shared/services/echo/metabolite.api.service';
import { Metabolite } from 'src/app/shared/models/echo/metabolite';
import { ModelLibrary, ModelLibraryCollection } from 'src/app/shared/models/model-library';
import { ListboxChangeEvent } from 'primeng/listbox';
import { Constants } from 'src/app/shared/utils/constants';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { ProjectXCompoundXModel } from 'src/app/shared/models/project-x-compound-x-model';
import Swal from 'sweetalert2';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { ModelToBeRun, ModelToBeRunRow } from 'src/app/shared/models/run/model-to-be-run';
import { EnviromentalRiskAssessmentService } from '../environmental-risk-assessment/environmental-risk-assessment.service';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-era-setup',
  templateUrl: './era-setup.component.html',
  styleUrls: ['./era-setup.component.css']
})
export class ERASetupComponent implements OnInit {

  @Input() menuService!: TabMenuLogicService;
  @Input() activeItem?: CustomTabMenuItem;
  @Input() columnDef: ColDef[] = [];
  @Input() disciplinePk: number = 0;
  @Input() modelsCollection: ModelLibraryCollection[] = [];

  @Output() isSetupValidEvent = new EventEmitter<any>();

  geographies: Catalog[] = [];
  filteredFormulations: Formulation[] = [];
  modelsToBeRun: ModelToBeRun[] = [];
  currentUserName: string = "";
  selectedProject?: Project;
  projects: Project[] = [];
  selectedOutputs: any = [];
  gapOuputs = Constants.GAP_OUTPUTS;
  modelsLoading: boolean = false;
  setupLoading: boolean = false;
  validSetup: boolean = false;
  validSetupModel: boolean = false;
  validSetupAquaticModel: boolean = false;
  validSetupPlantModel: boolean = false;
  destroyRef = inject(DestroyRef)
  isProjectOwnershipValid: boolean = false;

  constructor(public ERAService: EnviromentalRiskAssessmentService,
    private selectedProjectAPIService: SelectedProjectApiService,
    private geograpthyAPIService: GeographyApiService,
    private formulationAPIService: FormulationApiService,
    private orderByPipe: OrderByPipe,
    private userLogicService: UserLogicService,
    private metaboliteApiService: MetaboliteApiService,) {
  }

  ngOnInit(): void {
    this.setupLoading = true;
    this.getGeographies();
    this.getCreatedBy();
    this.initSubscribes();
  }

  getGeographies(){
    this.geograpthyAPIService.getRegionCountryCatalog().pipe(take(1)).subscribe(geographies => {
      this.geographies = this.geographies = this.orderByPipe.transform(geographies.filter(x => x.name == Constants.CROP_GEOGRAPHIES.USA || x.name == Constants.CROP_GEOGRAPHIES.EUROPE_UNION || x.name == Constants.CROP_GEOGRAPHIES.UK), 'name');
    });
  }

  initSubscribes() {
    this.selectedProjectAPIService.projects.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(projects => this.projects = projects);

    this.selectedProjectAPIService.selectedProject.pipe<Project | undefined>(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: async (project?: Project) => {
        this.getSelectedFormulation(project);
        await this.getProjectsXCompoundXModels(project);
      }
    });

    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((changed: boolean) => {
      if(changed) this.SaveSelectedProject()
    });
  }

  getSelectedFormulation(project?: Project){
    if(project)
      this.filteredFormulations = [{formulationPK: project.formulationPk!, tradeName: project.formulation ?? ''}];
  }

  initNewProject() {
    if(this.selectedProject) return;
    this.selectedProjectAPIService.initNewProject(this.currentUserName);
  }

  getCreatedBy() {
    this.currentUserName = this.userLogicService.profile.displayName ?? "";
  }

  onFormulationFilterChanged(query: string){
    this.formulationAPIService.getFormulationsByName(query).pipe(take(1))
    .subscribe((formulations: Formulation[])=>{
      this.filteredFormulations = this.orderByPipe.transform(formulations, 'tradeName');
    });
  }

  async onformulationSelectionChanged(formulation: Formulation) {
    if (!formulation) {
      this.modelsToBeRun = [];
      this.modelsLoading=false;
      return;
    }

    if (formulation.formulationPK > 0)
      await firstValueFrom(this.formulationAPIService.getFormulationByPk(formulation.formulationPK, true).pipe(take(1))).then(completeFormulation => formulation = completeFormulation);

    if (this.selectedProject)
      this.selectedProject.formulationType = formulation.formulationType;

    this.modelsLoading = true;
    let localModelList: ModelToBeRun[] = [];
    if (!formulation.activeIngredients) {
      this.modelsToBeRun = [];
      this.modelsLoading = false;
      return;
    }

     const selectedMets = [...new Set(this.selectedProject?.projectXCompoundXModel?.filter(PxCxM => PxCxM.MetabolitePk != undefined)
                                                                                .map(m => m.MetabolitePk))];

    await Promise.all(formulation?.activeIngredients.map(async (active: ActiveIngredient) => {
      let rowData: any = [];
      let selected = false;
      await this.getMetabolitesByMolecule(active.activeIngredientPk).then((metabolites: Metabolite[]) => {
        rowData = metabolites
          .filter( m => m.showInEra === 'Yes' ||
                        (selectedMets.includes(Number(m.metabolitePk)) &&
                         m.selectedInProjects.includes(this.selectedProject?.projectPk ?? 0))
                 )
          .map(metabolite => {
          let row: ModelToBeRunRow = { ...metabolite };
          row.moleculePk = active.activeIngredientPk;
          this.setRowCollectionForModel(row);
          if(this.selectedProject)
            row.disabled = this.isMetaboliteDisabled(metabolite);
          return row;
        });

        rowData = this.orderByPipe.transform(rowData, 'metaboliteName');

        let newRow: ModelToBeRunRow = {};
        newRow.moleculePk = active.activeIngredientPk;
        newRow.metaboliteName = active.activeIngredientName;
        newRow.compartments = this.modelsCollection.map(x => x.name);
        this.setRowCollectionForModel(newRow);
        rowData.unshift(newRow);
        let usedModels: string[] = [];
        if (this.selectedProject?.projectXCompoundXModel && this.selectedProject.projectXCompoundXModel?.length > 0) {
          if (this.selectedProject?.projectXCompoundXModel?.map(compound => compound.MoleculePk).includes(active.activeIngredientPk)) {
            selected = true;
            rowData.forEach((row: ModelToBeRunRow) => {
              this.setRowCollectionForModel(row, true, usedModels);
            });
            this.ERAService.SetUsedModels(usedModels);
          }
        }
      });

      localModelList.push({
        activeIngredient: active,
        rowData,
        selected: selected,
      });
    }));
    this.modelsToBeRun = localModelList;
    this.modelsLoading=false;

  }

  filterModel(model: ModelLibrary[], row: any) {
    let compounds = this.selectedProject?.projectXCompoundXModel?.filter(c => c.MoleculePk == row.moleculePk);
    if (!row.metabolitePk) compounds = compounds?.filter(c => !c.MetabolitePk);
    if (row.metabolitePk) compounds = compounds?.filter(c => c.MetabolitePk == row.metabolitePk);
    let models = model.filter(m => compounds?.map(c => c.ModelPk).includes(m.modelPk));
    return models;
  }

  getMetabolitesByMolecule(moleculePk: number) {
    return firstValueFrom(this.metaboliteApiService.getMetabolitesByMolecule(moleculePk))
  }

  async SaveSelectedProject() {
    let validatedName = await this.validateName();
    if(!this.selectedProject ||!validatedName) {
      this.menuService.setSuccess(true);
      return;
    }

    let currentProjectXCompoundXModel: ProjectXCompoundXModel[] = [];
    if(this.selectedProject.projectXCompoundXModel)
      currentProjectXCompoundXModel = [...this.selectedProject.projectXCompoundXModel];

    this.selectedProject.projectXCompoundXModel = [];
    let modelsToBeUsed: string[] = [];
    this.modelsToBeRun.filter(m => m.selected).forEach(model => {

      let usedModels: string[] = [];
      model.rowData.forEach((row: any) => {
        this.modelsCollection.forEach((collection: ModelLibraryCollection) => {
          if (row.surfacewater?.length! > 0) usedModels.push(collection.name);
          if (row.plant?.length! > 0) {
            row.plant!.forEach((rowPlant: any) => {
              if(!usedModels.includes(rowPlant.name)) {
                usedModels.push(rowPlant.name);
              }
            })
          }
          row[collection.name]?.forEach((model: any) => {
            let foundModel: ProjectXCompoundXModel | undefined = undefined;
            if (!row.metabolitePk) {
              foundModel = currentProjectXCompoundXModel.find(m => (m.ModelPk === model.modelPk && m.MoleculePk == row.moleculePk && !m.MetabolitePk));
            }
            if (row.metabolitePk) {
              foundModel = currentProjectXCompoundXModel.find(m => (m.ModelPk === model.modelPk && m.MoleculePk == row.moleculePk && m.MetabolitePk === row.metabolitePk));
            }

            if (foundModel) {
              foundModel.ModelName = model.name;
              foundModel.CompartmentPk = model.compartmentXModel[0];
              this.selectedProject?.projectXCompoundXModel?.push(foundModel);
            }

            if (!foundModel) {
              this.selectedProject?.projectXCompoundXModel?.push({
                ProjectPk: this.selectedProject.projectPk,
                MoleculePk: row.moleculePk,
                MetabolitePk: row.metabolitePk,
                ModelPk: model.modelPk,
                ModelName: model.name,
                CompartmentPk: model.compartmentXModel[0],
              });
            }
          });
          modelsToBeUsed.push(...usedModels);
        });
      })
    });
    this.ERAService.SetUsedModels([...new Set(modelsToBeUsed)]);
    if (this.selectedProject.projectXCompoundXModel.length <= 0) {
      this.menuService.setSuccess(true);
      return;
    }

    let savedProjectPk = -1;

    this.selectedProjectAPIService.saveProject().pipe(
      take(1),
      switchMap((projectPk: number) => {
        savedProjectPk = projectPk;
        return this.selectedProjectAPIService.saveProjectXCompoundXModels(savedProjectPk, this.disciplinePk).pipe(
          switchMap(() => this.selectedProjectAPIService.setProjectStatus(savedProjectPk)),
          map(() => projectPk)
        );
      })
    ).subscribe({
      next: (projectPk: number) => {
        if (!this.projects.map(p => p.projectPk).includes(projectPk)) {
          if (!this.selectedProject) return;
    
          this.selectedProject.projectPk = projectPk;
          this.selectedProjectAPIService.insertProject(this.selectedProject);
        }
        this.menuService.setSuccess(true);
      },
      error: (err) => {
        console.warn(err);
        this.menuService.setSuccess(false);
      }
    });
  }

  async validateName(){
    let isValid = true;
    if(!this.selectedProject?.name) isValid = false;
    await firstValueFrom(this.selectedProjectAPIService.projects).then(projects => {
      if(projects.find(p => p.name === this.selectedProject?.name && p.projectPk !== this.selectedProject?.projectPk)){
        isValid = false;
        this.menuService.setSuccess(true);
        Swal.fire({
          title: `${this.selectedProject?.name}, project name already exists.`,
          text: 'Please use another name',
          confirmButtonColor: '#0069be',
          confirmButtonText: 'Ok',
          icon: 'error',
        });
      }
    });
    this.validSetup = isValid;
    this.validateSetup();
    return isValid;
  }

  outputSelectionChanged({ value }: ListboxChangeEvent) {
    this.selectedOutputs = value;
  }

  async getProjectsXCompoundXModels(project?: Project): Promise<void>{
    if(!project) {
      this.selectedProject = project;
      this.setupLoading = false;
      return;
    }

    await firstValueFrom(this.selectedProjectAPIService.getProjectXCompoundXModels(project.projectPk, this.disciplinePk).pipe(take(1))).then(models => {
      project.projectXCompoundXModel = models;
    });

    this.selectedProject = project;
    this.isProjectOwnershipValid = this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy, Environmental_Assessments.ERA);
    this.setupLoading = false;
  }

  validateSetupInfoInProject(event: boolean) {
    this.validSetup = event;
    this.validateSetup();
  }

  validateSetupModelInProject(event: any) {
    this.validSetupAquaticModel = event.validSetupAquaticModel;
    this.validSetupPlantModel = event.validSetupPlantModel;
    this.validSetupModel = event.isValidSetup;
    this.validateSetup();
  }

  validateSetup() {
    this.isSetupValidEvent.emit({isValidSetup: this.validSetup && this.validSetupModel, isValidAquaticModel: this.validSetupAquaticModel, validSetupPlantModel: this.validSetupPlantModel})
  }

  setRowCollectionForModel(row: ModelToBeRunRow, useFilter: boolean = false, usedModels?: string[]) {
    this.modelsCollection.forEach((collection: ModelLibraryCollection) => {
      switch (collection.name) {
        case Constants.COMPARTMENTS.SURFACE_WATER.toLowerCase().replace(" ", ""):
          row.surfacewater = useFilter ? this.filterModel(collection.library, row) : [];
          if (useFilter && usedModels && row.surfacewater.length > 0) usedModels.push(collection.name);
          break;
        case Constants.COMPARTMENTS.PLANT.toLowerCase():
          row.plant = useFilter ? this.filterModel(collection.library, row) : [];
          if (useFilter && usedModels && row.plant.length > 0) {
              row.plant.forEach((rowPlant: ModelLibrary) => {
                if(!usedModels.includes(rowPlant.name)) {
                  usedModels.push(rowPlant.name);
                }
              })
          }
          break;
      }
    });
  }

  private isMetaboliteDisabled(metabolite: Metabolite): boolean {
    if( !this.selectedProject ) return false;
    return (metabolite.selectedInProjects.includes(this.selectedProject?.projectPk) &&
            metabolite.showInEra === 'No')
  }

}

