import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Project } from 'src/app/shared/models/project';
import { QcIconComponent } from 'src/app/shared/renderers/qc-icon/qc-icon.component';
import { Constants } from 'src/app/shared/utils/constants';
import { OPEXConstants } from 'src/app/shared/utils/opex-constants';
import { HHRAProjectQcColdef } from './hhra-project-qc-coldef';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HumanHealthOpexLogicService } from 'src/app/shared/services/human-health-opex-logic.service';
import { HHRALogicService } from '../../human-health-risk-assessment/human-health-risk-assessment.logic.service'
import { OpexInputs } from 'src/app/shared/models/opex-Inputs';
import { Utils } from 'src/app/shared/utils/utils';
import { ProjectXCompoundXModel } from 'src/app/shared/models/project-x-compound-x-model';
import { UsHandlerExposureLogicService } from 'src/app/shared/services/human-health-us-handler-exposure-logic.service';

@Component({
  selector: 'app-hhra-project-qc',
  templateUrl: './hhra-project-qc.component.html',
  styleUrls: ['./hhra-project-qc.component.css']
})
export class HhraProjectQcComponent {

  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Output() isProjectRunning: EventEmitter<boolean>;
  destroyRef = inject(DestroyRef);
  columnsToShow: string[] = [];
  missingDataRows: any[] = [];
  showGrid: Boolean = false;
  showWarningAlert: Boolean = false;
  warningAlertText: string = '';
  showAlert: Boolean = false;
  columnsDef: any;
  isLoading!: boolean;
  runs!: any[];
  isUSA: boolean = false;

  constructor(private qcColdef: HHRAProjectQcColdef,
    public HHRALogicService: HHRALogicService,
    private readonly humanHealthOpexLogicService: HumanHealthOpexLogicService,
    private readonly humanHealthUSHandlerService : UsHandlerExposureLogicService) {
    this.isProjectRunning = new EventEmitter<boolean>();
  }

  async ngOnInit(): Promise<void> {
    this.isUSA = this.selectedProject?.geography == Constants.GEOGRAPHYS.USA;
    this.isLoading = true;
    if(this.isUSA){  
     await this.runUSInputs();
     this.checkUSMissingDataByRow();
    }else{
      await this.runOpexInputs();
      this.checkOpexMissingDataByRow();
    }
    this.initSubscribes();
  }

  async initSubscribes() {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.menuService.setSuccess(true);
    });
    this.isLoading = false;
  }

  async runOpexInputs(): Promise<void> {
    await this.humanHealthOpexLogicService.fillOpexInputs(this.selectedProject!);
  }

  async runUSInputs(): Promise<void> {
    await this.humanHealthUSHandlerService.fillUsHandlerExposureInputs(this.selectedProject!);
  }

  checkOpexMissingDataByRow() {
    this.runs = this.humanHealthOpexLogicService.transformDataQC(this.humanHealthOpexLogicService.getOpexInputsCombinations());
    let missingData: any[] = [];
    const groupedRows: { [key: string]: OpexInputs[] } = this.groupRowsByModel();
    if (Object.keys(groupedRows).length > 0) {
      Object.entries(groupedRows).forEach(([key, value]) => {
        var requiredColumns = this.validateRequiredColumnsByModel(key, value)
        if (requiredColumns) {
          requiredColumns = [...requiredColumns];
          const rowsImcomplete = key.startsWith(OPEXConstants.MODELS.EU_OPEX) ? this.checkMissingData(value, requiredColumns) : '';
          missingData.push(...rowsImcomplete)
        }
      });
      this.columnsToShow = [...new Set(missingData.flatMap(obj => obj.allMissingColumns))];
      this.missingDataRows = missingData;
      this.insertDynamicColumnDefs();
      this.showQcResult();
    } else {
      this.createWarningAlert();
    }
  }

  checkUSMissingDataByRow() {
    this.runs = this.humanHealthUSHandlerService.transformDataQC(this.humanHealthUSHandlerService.getUSHandlerInputsCombinations());
    let missingData: any[] = [];
    const groupedRows: { [key: string]: OpexInputs[] } = this.groupRowsByModel();
    if (Object.keys(groupedRows).length > 0) {
      // TODO: Add US QC CHECK
      // Object.entries(groupedRows).forEach(([key, value]) => {
      //   var requiredColumns = this.validateRequiredColumnsByModel(key, value)
      //   if (requiredColumns) {
      //     requiredColumns = [...requiredColumns];
      //     const rowsImcomplete = key.startsWith(OPEXConstants.MODELS.EU_OPEX) ? this.checkMissingData(value, requiredColumns) : '';
      //     missingData.push(...rowsImcomplete)
      //   }
      // });
      // this.columnsToShow = [...new Set(missingData.flatMap(obj => obj.allMissingColumns))];
      this.missingDataRows = missingData;
      this.insertDynamicColumnDefs();
      this.showQcResult();
    } else {
      this.createWarningAlert();
    }
  }

  checkMissingData(runs: OpexInputs[], requiredColumns: string[]) {
    let missingDataByRunId: any[] = [];
    runs.forEach((run: OpexInputs) => {
      let metabolites: any;
      let applications: any;
      let PPE: any;
      let missingColumns = [];
      let columnsToValidate = [...requiredColumns];
      for (const column of columnsToValidate) {
        const properties = column.split('.');
        let nestedData: any = run;
        for (const property of properties) {
          if (nestedData && nestedData.hasOwnProperty(property)) {
            nestedData = (nestedData as any)[property];
          } else {
            missingColumns.push(column);
            break;
          }
        }
      }

      metabolites = this.checkMissingDataForMetabolites(run);
      applications = this.checkMissingDataForApplications(run);
      PPE = this.checkMissingDataForPPE(run);
      let allMissingColumns = [...new Set([...missingColumns, ...this.getAllColumnValues(metabolites), ...this.getAllColumnValues(applications), ...this.getAllColumnValues(PPE)])];
      if (allMissingColumns.length > 0) {
        missingDataByRunId.push({
          run,
          allMissingColumns,
          missingParentColumns: missingColumns,
          missingColumnsByMetabolites: metabolites,
          missingColumnsByApplications: applications,
          missingColumnsByPPE: PPE,
          componentQC: OPEXConstants.GENERAL.componentQC
        })
      }
    });
    return missingDataByRunId;
  }

  checkMissingDataForMetabolites(pData: any): { [key: string]: { column: string, message: string }[] }[] {
    let missingDataByEndpoint: { [key: string]: { column: string, message: string }[] }[] = [];
    if (pData) {
      let endpointColumns: string[] = OPEXConstants.ENDPOINTS_BY_MODEL[pData?.models?.find((x: { name: string; }) => x.name == OPEXConstants.MODELS.EU_OPEX)?.name!] ?? [];
      let columnsByEndpoint: { column: string, message: string }[] = [];
      for (const column of endpointColumns) {
        let value;
        if (column === OPEXConstants.ENDPOINTS_FIELD_NAMES.ACTIVE_INGREDIENT || column === OPEXConstants.ENDPOINTS_FIELD_NAMES.ENDPOINTS_DATASET || column === OPEXConstants.ENDPOINTS_FIELD_NAMES.CALCULATOR_GROUP) {
          value = pData.substancePropertiesDataSet[column as keyof any];
        } else {
          value = pData.substancePropertiesDataSet.endpoints[column as keyof any];
        }
        if (Utils.isEmptyValue(value) || value == undefined || value! <= 0) {
          columnsByEndpoint.push({ column: column, message: OPEXConstants.GENERAL.missingData });
        }
      }
      columnsByEndpoint = [...columnsByEndpoint];
      columnsByEndpoint.length > 0 && missingDataByEndpoint.push({ [pData.activeIngredient!]: columnsByEndpoint });
    };
    return missingDataByEndpoint;
  }

  checkMissingDataForApplications(run: any): { [key: string]: { column: string, message: string }[] }[] {
    let missingColumnsByAppScheme: { [key: string]: { column: string, message: string }[] }[] = [];
    let value: any;
    if (run) {
      let columnsByAppScheme: { column: string, message: string }[] = [];
      let appSchemeColumns: string[] = OPEXConstants.APPSCHEME_BY_MODEL[run?.models?.find((x: { name: string; }) => x.name == OPEXConstants.MODELS.EU_OPEX)?.name!] ?? [];
      for (const column of appSchemeColumns) {
        if (column == OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.RATE) {
          run.humanHealthApplicationScheme?.applicationSchemeXActiveIngredientRate?.forEach((item: any) => {
            value = item[column as keyof any];
            if (Utils.isEmptyValue(value) || value <= 0) {
              columnsByAppScheme.push({ column: column, message: OPEXConstants.GENERAL.missingData });
            }
          });
        } else {
          value = run.humanHealthApplicationScheme[column as keyof any];
          if (Utils.isEmptyValue(value) || value == undefined || value == null || value <= 0) {
            columnsByAppScheme.push({ column: column, message: OPEXConstants.GENERAL.missingData });
          }
        }
      }
      columnsByAppScheme = [...columnsByAppScheme];
      columnsByAppScheme.length > 0 && missingColumnsByAppScheme.push({ [run.activeIngredient!]: columnsByAppScheme });
    }
    return missingColumnsByAppScheme;
  }

  checkMissingDataForPPE(pData: any): { [key: string]: { column: string, message: string }[] }[] {
    let missingDataByPPE: { [key: string]: { column: string, message: string }[] }[] = [];
    let endpointColumns!: string[];
    if (pData) {
      if (this.isOPEXFormulationTypeSolid()) {
        endpointColumns = OPEXConstants.PPE_GRANULES_MODEL[pData?.models?.find((x: { name: string; }) => x.name == OPEXConstants.MODELS.EU_OPEX)?.name!] ?? [];
      } else {
        endpointColumns = OPEXConstants.PPE_BY_MODEL[pData?.models?.find((x: { name: string; }) => x.name == OPEXConstants.MODELS.EU_OPEX)?.name!] ?? [];
      }
      let columnsByEndpoint: { column: string, message: string }[] = [];
      for (const column of endpointColumns) {
        let value = pData.personalProtectionEquipmentScheme[column as keyof OpexInputs];
        if (Utils.isEmptyValue(value) || value == undefined || value == null || value < 0) {
          columnsByEndpoint.push({ column: column, message: OPEXConstants.GENERAL.missingData });
        }
      }
      columnsByEndpoint = [...columnsByEndpoint];
      columnsByEndpoint.length > 0 && missingDataByPPE.push({ [pData.activeIngredient!]: columnsByEndpoint });
    };
    return missingDataByPPE;
  }

  groupRowsByModel(): { [key: string]: OpexInputs[] } {
    let groupDataByModel: { [key: string]: OpexInputs[] } = {};
    this.runs.forEach((run) => {
      const groupKey = run.models?.find((x: { name: string; }) => x.name == Constants.MODELS.EU_OPEX)?.name!;
      if (!groupDataByModel[groupKey]) {
        groupDataByModel[groupKey] = [];
      }
      groupDataByModel[groupKey].push(run);
    })
    return groupDataByModel;
  }

  validateRequiredColumnsByModel(model: string, runs: OpexInputs[]): any {
    let columns = OPEXConstants.REQUIRED_COLUMNS_BY_MODEL[model];
    if (columns) {
      columns = this.includeColumnsByModel(model, columns, runs);
    }
    return columns;
  }

  includeColumnsByModel(model: string, pColumns: string[], runs: OpexInputs[]): string[] {
    let columns = [...pColumns];
    switch (model) {
      case Constants.MODELS.EU_OPEX:
        if (this.selectedProject!.projectXCompoundXModel?.some((x: ProjectXCompoundXModel) => x.ModelName === Constants.MODELS.EU_OPEX)) {
          columns = [...columns];
        }
        break;
    }
    return columns;
  }

  createWarningAlert(): void {
    if (this.selectedProject?.projectPk === -1) {
      this.warningAlertText = OPEXConstants.GENERAL.ProjectWarningAlertText;
    }
    else if (this.selectedProject?.dataSets == null) {
      this.warningAlertText = OPEXConstants.GENERAL.dataSetsWarningAlertText;
    }
    else if (this.humanHealthOpexLogicService.getOpexInputsCombinations()?.length === 0) {
      this.warningAlertText = OPEXConstants.GENERAL.noRunsWarningAlertText;
    }
    this.showWarningAlert = true;
  }

  getAllColumnValues(data: { [key: string]: { column: string, message: string }[] }[]): string[] {
    const columnValues: string[] = [];
    if (data) {
      for (const obj of data) {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            for (const item of obj[key]) {
              columnValues.push(item.column);
            }
          }
        }
      }
    }
    return columnValues;
  }

  convertFieldToHeaderName(field: string) {
    return OPEXConstants.HEADER_NAMES_BY_FIELD[field]
  }

  insertDynamicColumnDefs() {
    this.columnsDef = [...this.qcColdef.getQcColumnsDefinition(), ...this.createDynamicColDef()]
  }

  createDynamicColDef(): any[] {
    let columnsDef: ColDef[] = [];
    this.columnsToShow?.forEach((column: string) => {
      columnsDef.push(
        {
          headerName: this.convertFieldToHeaderName(column),
          field: column,
          type: 'default',
          filter: 'agSetColumnFilter',
          width: 150,
          hide: false,
          editable: false,
          wrapText: true,
          wrapHeaderText: true,
          cellRenderer: QcIconComponent,
          cellRendererParams: (params: any) => {
            return {
              instance: this,
            };
          },
        }
      );
    });
    return columnsDef;
  }

  showQcResult() {
    if (this.missingDataRows.length > 0) {
      this.showGrid = true;
      this.isProjectRunning.emit(true);
    } else {
      this.showAlert = true;
      this.isProjectRunning.emit(false);
    }
  }

  isOPEXFormulationTypeSolid() {
    return this.selectedProject?.OPEXFormulationType === Constants.FORMULATION_TYPE_GRANULES;
  }
}