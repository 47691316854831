import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout/layout.component';
import { EnvironmentalExposureAssessmentComponent } from './environmental-exposure-assessment/environmental-exposure-assessment/environmental-exposure-assessment.component';
import { ModelLibraryComponent } from './administrator/regulatory-models/model-library/model-library.component';
import { GeographyMatchingComponent } from './administrator/regulatory-models/geography-matching/geography-matching.component';
import { ApplicationMethodMatchingComponent } from './administrator/regulatory-models/application-method-matching/application-method-matching.component';
import { TriggersEcotoxComponent } from './administrator/regulatory-models/triggers-ecotox/triggers-ecotox.component';
import { TaxonEndpointsMatchingComponent } from './administrator/regulatory-models/taxon-endpoints-matching/taxon-endpoints-matching.component';
import { UDLOverlapToolComponent } from './esa-assessment/udl-overlap-tool/udl-overlap-tool/udl-overlap-tool.component';
import { ExternalComponent } from './administrator/external/external.component';
import { HomeComponent } from './layout/home/home.component';
import { CropListComponent } from './administrator/regulatory-models/crop-list/crop-list.component';
import { InverseModelingEnvironmentalExposureAssesmentComponent } from './inverse-modeling-environmental-exposure-assesment/environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment.component';
import { CropListMatchingComponent } from './administrator/regulatory-models/crop-list-matching/crop-list-matching.component';
import { EnvironmentalRiskAssessmentComponent } from './environmental-risk-assessment/environmental-risk-assessment/environmental-risk-assessment.component';
import { HumanHealthRiskAssessmentComponent } from './human-health-risk-assessments/human-health-risk-assessment/human-health-risk-assessment.component';
import { ProjectListComponent } from './misc/project-list/project-list.component';
import { MsalGuard } from '@azure/msal-angular';
import { Constants } from './shared/utils/constants';
import { RoleGuard } from './shared/utils/role-guard';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      { path: 'environmentalExposureAssessment', component: EnvironmentalExposureAssessmentComponent, data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'inverseEnvironmentalExposureAssessment', component: InverseModelingEnvironmentalExposureAssesmentComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'ModelLibrary', component: ModelLibraryComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'GeographyMatching', component: GeographyMatchingComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'CropMatching', component: CropListMatchingComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'CropList', component: CropListComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'ApplicationMethodMatching', component: ApplicationMethodMatchingComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'TriggersEcotox', component: TriggersEcotoxComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'TaxonEndpointsMatching', component: TaxonEndpointsMatchingComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'environmentalRiskAssessment', component: EnvironmentalRiskAssessmentComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'humanHealthRiskAssessment', component: HumanHealthRiskAssessmentComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'ProjectListComponent', component: ProjectListComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'UDLOverlapTool', component: UDLOverlapToolComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'ECHO/:ECHOModule', component: ExternalComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'ECHO/:ECHOModule/:Application', component: ExternalComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'GAP/:GAPModule', component: ExternalComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'GAP/:GAPModule/:Application', component: ExternalComponent , data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: 'unauthorized', component: UnauthorizedComponent , canActivate: [MsalGuard] },
      { path: 'Home', component: HomeComponent, data: { roles: [ Constants.APP_ROLES.EDITOR, Constants.APP_ROLES.READER ] }, canActivate: [MsalGuard, RoleGuard]},
      { path: '**', redirectTo: 'Home' }      
    ],canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
