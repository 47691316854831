<p-dialog
  header="Optional Outputs"
  [visible]="true"
  [modal]="true"
  [style]="{ width: '835px' }"
  [draggable]="false"
  [resizable]="false"
  (visibleChange)="onClose()"
  (onShow)="onShow()"
>
    <p-divider align="left" type="solid">
        <b>Optional Daily Field Output to a water.out File</b>
    </p-divider>
    <div class="grid">
        <div class="col-6">
            <div *ngFor="let item of upperLeftCheckbox" class="field-checkbox">
                <p-checkbox 
                    [(ngModel)]="pwcXDataSetOptionalOutput[item.field]"
                    [label]="item.label" 
                    [disabled]="!isProjectOwner"
                    [binary]="true"
                    />
                    <div *ngIf="item.hasInputs" class="grid ml-2">
                        <div *ngFor="let input of item.inputs" class="{{input.col}}">
                            <p-inputNumber 
                                [(ngModel)]="pwcXDataSetOptionalOutput[input.field]"
                                prefix="{{ input.prefix }}"
                                suffix="{{ input.suffix }}"
                                [disabled]="!isProjectOwner"
                                mode="decimal" [maxFractionDigits]="18"/> 
                        </div>
                    </div>
            </div>
        </div>
        <div class="col-6">
            <div *ngFor="let item of upperRightCheckbox" class="field-checkbox">
                <p-checkbox 
                    [(ngModel)]="pwcXDataSetOptionalOutput[item.field]"
                    [label]="item.label" 
                    [disabled]="!isProjectOwner"
                    [binary]="true"
                    />
                    <div *ngIf="item.hasInputs" class="ml-2">
                        <div *ngFor="let input of item.inputs">
                            <p-inputNumber  
                                [(ngModel)]="pwcXDataSetOptionalOutput[input.field]"
                                prefix="{{ input.prefix }}"
                                suffix="{{ input.suffix }}"
                                [disabled]="!isProjectOwner"
                                mode="decimal" [maxFractionDigits]="18"/> 
                        </div>
                    </div>
            </div>
        </div>     
    </div>
    <app-grid #grid 
    [columnDefs]="columnDefs" 
    [rowData]="rowData" 
    [useAddAction]="isProjectOwner"
    [useDeleteAction]="isProjectOwner"
    (deleteEvent)="deleteRow($event)"
    ></app-grid>
    <p-divider align="left" type="solid">
        <b>Optional Daily Waterbody Output to a water.out File</b>
    </p-divider>
    <div class="grid">
        <div class="col-6">
            <div *ngFor="let item of bottomLeftCheckbox" class="field-checkbox">
                <p-checkbox 
                    [(ngModel)]="pwcXDataSetOptionalOutput[item.field]"
                    [label]="item.label" 
                    [disabled]="!isProjectOwner"
                    [binary]="true"
                    />
            </div>
        </div>
        <div class="col-6">
            <div *ngFor="let item of bottomRightCheckbox" class="field-checkbox">
                <p-checkbox 
                    [(ngModel)]="pwcXDataSetOptionalOutput[item.field]"
                    [label]="item.label" 
                    [disabled]="!isProjectOwner"
                    [binary]="true"
                    />
            </div>
        </div>   
        <div class="col-12">
            <div class="flex justify-content-end flex-wrap">
                <app-button class="m-2"
                [label]="'Clear'"
                [disabled]="!isProjectOwner"
                (onClickEvent)="clearFields()"
                />
                <app-button class="m-2"
                [label]="'Close'"
                [disabled]="!isProjectOwner"
                (onClickEvent)="onClose()"
                />
            </div>
        </div>  
    </div>
</p-dialog>
