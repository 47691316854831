import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ViewButtonRenderer } from 'src/app/shared/renderers/view-button/view-button.component';
import { ViewPWcSchemesButtonComponent } from 'src/app/shared/renderers/view-pwc-schemes-button/view-pwc-schemes-button.component';

@Injectable({
    providedIn: 'root'
})
export class EEARunProjectQcColdef {

    constructor() { }

    public getQcColumnsDefinition(): ColDef[] {
        return [
            {
                headerName: 'Model',
                field: 'run.model.name',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Compartment',
                field: 'run.compartment',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Substance Name',
                field: 'run.substanceName',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Data Set',
                field: 'run.dataSet.description',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Application Scheme',
                field: 'run.applicationSchemeData.name',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                minWidth: 300,
                width: 300,
                tooltipValueGetter: (params) => params.value
            },
        ]
    }

    public getPWCQcColumnsDefinition(): ColDef[] {
        return [
            {
                headerName: 'Model',
                field: 'run.model.name',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Compartment',
                field: 'run.compartment',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Substance Name',
                field: 'run.substanceName',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Data Set',
                field: 'run.dataSet.description',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Application Schemes',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                cellRenderer: ViewPWcSchemesButtonComponent,
                cellRendererParams: (params: any) => {
                    return {
                        label: 'View',
                        disabled: false,
                        field: 'Application Schemes',
                        showOverlay: true,
                    };
                }
            },
        ]
    }
}
