import { Injectable } from "@angular/core";
import { Constants } from 'src/app/shared/utils/constants';
import { catchError, forkJoin } from "rxjs";
import { Project } from "src/app/shared/models/project";
import { ActiveIngredientApiService } from "src/app/shared/services/echo/active-ingredient.api.service";
import { DataSetApiService } from "src/app/shared/services/echo/data-set.api.service";
import { GeographyApiService } from "src/app/shared/services/echo/geography.api.service";
import { Catalog } from "src/app/shared/models/echo/catalog";
import { EraGapTerrestrialApiService } from "../../era-gap/era-gap-terrestrial/era-gap-terrestrial.api.service";
import { Avian, Chemical, Endpoints, Mammalian, TRexForRun } from "src/app/shared/models/trex/trex-inputs";
import { EraAquaticRunRiskAssessmentGridEchoApiService } from "../era-aquatic-run-risk-assessment-grid/era-aquatic-run-risk-assessment-grid-echo.api.service";
import { DataSet } from "src/app/shared/models/echo/data-set";
import { EraRunRiskAssessmentTerrestrialBamsApi } from "./era-run-risk-assessment-terrestrial.bams.api.service";
import { Compartment } from "src/app/shared/models/echo/compartment";
import { Country } from "src/app/shared/models/country";
import { Molecule } from "src/app/shared/models/echo/molecule";
import { Endpoint } from 'src/app/shared/models/endpoint';
import { TriggersEcotoxService } from "src/app/administrator/regulatory-models/triggers-ecotox/triggers-ecotox.service";
import { TriggerEcotox } from "src/app/shared/models/trigger-ecotox";
import { TrexEndpointsUnits, TrexEndpointTypes } from "../../shared/era-shared-Interfaces";



@Injectable({
    providedIn: 'root'
})
export class ERARunRiskAssessmentTerrestrialLogicService {
    geographies: Catalog[] = [];
    geographiesList: Catalog[] = [];
    mainCompartment: string = ''
        ;
    mainCompartmentPk: number = 0;
    isProjectOwnershipValid: boolean = false;
    mainGeography?: string = '';
    isLoading: boolean = false;


    constructor(private geographyApiService: GeographyApiService,
        private activeIngredientApiService: ActiveIngredientApiService,
        private datasetService: DataSetApiService,
        private _EraGapTerrestrialApiService: EraGapTerrestrialApiService,
        private echoApiService: EraAquaticRunRiskAssessmentGridEchoApiService,
        private TrexApiService: EraRunRiskAssessmentTerrestrialBamsApi,
        private triggersEcotoxService: TriggersEcotoxService) { }

    getCompartments(): Promise<Compartment[]> {
        return new Promise<Compartment[]>((resolve, reject) => {
            this.datasetService.getCompartments(Constants.CONST_COMPARTMENT).subscribe({
                next: (compartments: Compartment[]) => {
                    resolve(compartments);
                },
                error: (error: Error) => {
                    reject(error);
                }
            });
        });
    }

    getTaxonTriggers(): Promise<TriggerEcotox[]> {
        return new Promise<TriggerEcotox[]>((resolve, reject) => {
            this.triggersEcotoxService.getTaxonTriggerList().subscribe({
                next: (taxonTriggers: TriggerEcotox[]) => {
                    resolve(taxonTriggers);
                },
                error: (error: Error) => {
                    reject(error);
                }
            });
        });
    }

    private matchRegionsCountries(regions: any, countries: Country[], currentProject?: Project) {
        this.geographies = [];
        if (currentProject?.regionPk) {
            let selectedRegion = regions.filter((x: any) => x.regionPk == currentProject.regionPk);
            selectedRegion[0].countries?.forEach((c: any) => {
                const country = countries.find((n: any) => n.countryPk == c);
                if (country) {
                    this.geographies.push({
                        key: c,
                        source: 'Country',
                        name: country.name,
                        active: true,
                        restricted: false,
                    });
                }
            });
        }
        else if (currentProject?.countryPk) {
            if (countries.find((c: any) => c.countryPk == currentProject.countryPk)?.name == Constants.CROP_GEOGRAPHIES.USA) {
                let regionsXCountry = regions.filter((x: any) => x.countries.includes(currentProject.countryPk));
                regionsXCountry.forEach((x: any) => {
                    this.geographies.push({
                        key: x.regionPk,
                        source: 'Region',
                        name: x.name,
                        active: true,
                        restricted: false,
                    });
                });
            }
            if (countries.find((c: any) => c.countryPk == currentProject.countryPk)?.name == Constants.CROP_GEOGRAPHIES.UK) {
                this.geographies.push({
                    key: currentProject.countryPk,
                    source: 'Country',
                    name: 'UK',
                    active: true,
                    restricted: false,
                });
            }
        }

        return this.geographies;
    }

    public getRegionCountry(currentProject?: Project): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            forkJoin({
                regions: this.geographyApiService.getRegionWithCountries(),
                countries: this.geographyApiService.getCountries(),
            })
                .pipe(
                    catchError((error) => {
                        console.warn(error);
                        reject(error);
                        return [];
                    })
                )
                .subscribe({
                    next: ({ regions, countries }) => {
                        resolve(this.matchRegionsCountries(regions, countries, currentProject));
                    },
                });
        });
    }

    getApplicationSchemesByProjectAndCompartment(project?: Project, mainCompartment?: number): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            this._EraGapTerrestrialApiService.getApplicationSchemesByProjectAndCompartment(project!.projectPk, mainCompartment!).subscribe({
                next: (applicationSchemes: any[]) => {
                    resolve(applicationSchemes);
                },
                error: (error: Error) => {
                    reject(error);
                }
            });
        });
    }

    getMoleculeByMoleculePks(activeIngredientsRates: number[]): Promise<Molecule[]> {
        return new Promise<Molecule[]>((resolve, reject) => {
            this.activeIngredientApiService.getMoleculeByMoleculePks(activeIngredientsRates).subscribe({
                next: (activeIngredients: Molecule[]) => {
                    resolve(activeIngredients);
                },
                error: (error: Error) => {
                    reject(error);
                }
            });
        });
    }

    getEndpointsByDataSetsTaxon(dataset: DataSet): Promise<DataSet[]> {
        return new Promise<DataSet[]>((resolve, reject) => {
            this.datasetService.getEndpointsByDataSetsTaxon([dataset], Constants.TAXON_GROUPS.TERRESTRIAL_VERTEBRATE).subscribe({
                next: (data: DataSet[]) => {
                    resolve(data);
                },
                error: (error: Error) => {
                    reject(error);
                }
            });
        });
    }

    async RunTREX(runObjectTREX: TRexForRun[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (runObjectTREX) {
                this.TrexApiService.runTREX(runObjectTREX).subscribe({
                    next: (data: any) => {
                        resolve(data);
                    },
                    error: (error: Error) => {
                        reject(error);
                    }
                });
            }
        });
    }

    async getTRexRuns(dataset: DataSet[], project: Project, pCompartmentPk: number, taxonTriggerList: TriggerEcotox[]): Promise<TRexForRun[]> {
        let result: TRexForRun[] = [];
        let activeIngredients: Chemical[] = [];
        if (dataset.length > 0 && project != null) {
            let endPoint: DataSet[] = [];

            await this.datasetService.getEndpointsByDataSetsByTaxonGroup(dataset, Constants.TAXON_GROUPS.TERRESTRIAL_VERTEBRATE).toPromise().then(async (data: DataSet[] | undefined) => {
                endPoint = data ?? [];
            });

            await this.getMoleculeByMoleculePks(project.projectXCompoundXModel?.flatMap(x => x.MoleculePk!) ?? []).then((active: Molecule[]) => {
                activeIngredients = active.map((x: Molecule) => {
                    const chemical: Chemical = {
                        moleculePk: x.moleculePk,
                        chemicalName: x.moleculeName

                    };
                    return chemical;
                });
            });
            endPoint?.forEach((item: DataSet) => {
                if (item.useInProject) { //filter only data with flag use in project true
                    let TRexForRun: TRexForRun = {} as TRexForRun;
                    TRexForRun.projectFullName = project.name;
                    TRexForRun.IdProject = project.projectPk;
                    TRexForRun.formulationName = project.formulation!;
                    TRexForRun.compartmentPk = pCompartmentPk;
                    TRexForRun.dataSets = dataset?.filter((x: DataSet) => x.dataSetPk == item.dataSetPk)[0];
                    const mammalianData = item?.endpoints?.filter((g: Endpoint) => Constants.DEFAULT_VALUE_SPECIES_LIST_TERRESTRIAL_VERTEBRATES.includes(g.speciesScientificName));
                    const avianData = item?.endpoints?.filter((x: Endpoint) => !Constants.DEFAULT_VALUE_SPECIES_LIST_TERRESTRIAL_VERTEBRATES.includes(x.speciesScientificName));
                    TRexForRun.mammalian = this.getMammalianData(mammalianData || [], taxonTriggerList);
                    TRexForRun.avian = this.getAvianData(avianData || [], taxonTriggerList);
                    TRexForRun.actives = activeIngredients;
                    result.push(TRexForRun);
                }
            });
        }

        return result;

    }

    getRiskQuotientValue(taxonTriggerList: TriggerEcotox[], listed: boolean, taxonExposureDuration: number): number {
        let riskQuotientValue: number = 0;

        let taxonTrigger = taxonTriggerList.find((x: TriggerEcotox) => x.id == taxonExposureDuration);

        if (taxonTrigger) {
            riskQuotientValue = listed ? taxonTrigger.riskQuotientListed : taxonTrigger.riskQuotientNonListed;
        }

        return riskQuotientValue;
    }

    getFormulationNameByPk(formulationPk: number): Promise<string> {
        return new Promise((resolve, reject) => {
            this.echoApiService.getFormulationNameByPk(formulationPk).subscribe({
                next: (formulation) => {
                    resolve(formulation);
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    }

    async getFormulation(project: Project): Promise<string> {
        const formulationPk = project!.formulationPk!;
        return await this.getFormulationNameByPk(formulationPk);
    }


    getMammalianData(data: Endpoint[], taxonTriggerList: TriggerEcotox[]): Mammalian {
        let mammalianDTO: Mammalian = {} as Mammalian;
        let endpointMammalian: Endpoints = {} as Endpoints;

        data.map((mammalianData: any) => {
            switch (mammalianData.specificEndpointType) {
                case TrexEndpointTypes.MammalianLD50:
                    endpointMammalian.LD50 = mammalianData?.value != undefined ? mammalianData?.value : 0;
                    endpointMammalian.LD50SpeciesCommonName = mammalianData?.speciesCommonName != null ? mammalianData?.speciesCommonName : mammalianData?.speciesScientificName;
                    endpointMammalian.LD50Unit = TrexEndpointsUnits.MgKgBw;
                    mammalianDTO.acuteStudy = mammalianData?.speciesBodyWeight != undefined ? mammalianData?.speciesBodyWeight : 0;
                    endpointMammalian.LD50riskQuotientListed = this.getRiskQuotientValue(taxonTriggerList, true, mammalianData.taxonExposureDuration);
                    endpointMammalian.LD50riskQuotientNonListed = this.getRiskQuotientValue(taxonTriggerList, false, mammalianData.taxonExposureDuration);
                    break;
                case TrexEndpointTypes.MammalianLC50:
                    endpointMammalian.LC50 = mammalianData?.value != undefined ? mammalianData?.value : 0;
                    endpointMammalian.LC50SpeciesCommonName = mammalianData?.speciesCommonName != null ? mammalianData?.speciesCommonName : mammalianData?.speciesScientificName;
                    endpointMammalian.LC50Unit = TrexEndpointsUnits.MgKgDiet;
                    mammalianDTO.acuteStudy = mammalianData?.speciesBodyWeight != undefined ? mammalianData?.speciesBodyWeight : 0;
                    endpointMammalian.LC50riskQuotientListed = this.getRiskQuotientValue(taxonTriggerList, true, mammalianData.taxonExposureDuration);
                    endpointMammalian.LC50riskQuotientNonListed = this.getRiskQuotientValue(taxonTriggerList, false, mammalianData.taxonExposureDuration);
                    break;
                case TrexEndpointTypes.MammalianChronicBw:
                    endpointMammalian.Chronic = mammalianData?.value != undefined ? mammalianData?.value : 0;
                    endpointMammalian.ChronicSpeciesCommonName = mammalianData?.speciesCommonName != null ? mammalianData?.speciesCommonName : mammalianData?.speciesScientificName;
                    endpointMammalian.ChronicUnit = TrexEndpointsUnits.MgKgBw;
                    mammalianDTO.chronicStudy = mammalianData?.speciesBodyWeight != undefined ? mammalianData?.speciesBodyWeight : 0;
                    endpointMammalian.ChronicriskQuotientListed = this.getRiskQuotientValue(taxonTriggerList, true, mammalianData.taxonExposureDuration);
                    endpointMammalian.ChronicriskQuotientNonListed = this.getRiskQuotientValue(taxonTriggerList, false, mammalianData.taxonExposureDuration);
                    break;
                case TrexEndpointTypes.MammalianChronicDiet:
                    endpointMammalian.Chronic = mammalianData?.value != undefined ? mammalianData?.value : 0;
                    endpointMammalian.ChronicSpeciesCommonName = mammalianData?.speciesCommonName != null ? mammalianData?.speciesCommonName : mammalianData?.speciesScientificName;
                    endpointMammalian.ChronicUnit = TrexEndpointsUnits.MgKgDiet;
                    mammalianDTO.chronicStudy = mammalianData?.speciesBodyWeight != undefined ? mammalianData?.speciesBodyWeight : 0;
                    endpointMammalian.ChronicriskQuotientListed = this.getRiskQuotientValue(taxonTriggerList, true, mammalianData.taxonExposureDuration);
                    endpointMammalian.ChronicriskQuotientNonListed = this.getRiskQuotientValue(taxonTriggerList, false, mammalianData.taxonExposureDuration);
                    break;
            }
        });

        const dietaryConcentrationRow = data.find((data: any) => data?.dietaryConcentrationReported == 'Yes') as any;
        mammalianDTO.isDietaryConcentrationReportedFromTheAvailableChronicStudy = dietaryConcentrationRow != undefined ? 'yes' : 'no';

        if(dietaryConcentrationRow)
          mammalianDTO.dietaryConcentrationMgKgDiet = dietaryConcentrationRow.dietaryConcentration;

        mammalianDTO.endpoint = endpointMammalian;

        return mammalianDTO;
    }

    getAvianData(data: Endpoint[], taxonTriggerList: TriggerEcotox[]): Avian {
        let AvianDTO: Avian = {} as Avian;
        let endpointAvian: Endpoints = {} as Endpoints;
        data.map((avianData: any) => {
            switch (avianData.specificEndpointType) {
                case TrexEndpointTypes.AvianLD50:
                    endpointAvian.LD50 = avianData?.value != undefined ? avianData?.value : 0;
                    endpointAvian.LD50SpeciesCommonName = avianData?.speciesCommonName != null ? avianData?.speciesCommonName : avianData?.speciesScientificName;
                    endpointAvian.LD50Unit = TrexEndpointsUnits.MgKgBw;
                    endpointAvian.LD50OptionalTestBodyWeightG = avianData?.speciesBodyWeight != undefined ? avianData?.speciesBodyWeight : 0;
                    endpointAvian.LD50riskQuotientListed = this.getRiskQuotientValue(taxonTriggerList, true, avianData.taxonExposureDuration);
                    endpointAvian.LD50riskQuotientNonListed = this.getRiskQuotientValue(taxonTriggerList, false, avianData.taxonExposureDuration);
                    break;
                case TrexEndpointTypes.AvianLC50:
                    endpointAvian.LC50 = avianData?.value != undefined ? avianData?.value : 0;
                    endpointAvian.LC50SpeciesCommonName = avianData?.speciesCommonName != null ? avianData?.speciesCommonName : avianData?.speciesScientificName;
                    endpointAvian.LC50Unit = TrexEndpointsUnits.MgKgDiet;
                    endpointAvian.LC50OptionalTestBodyWeightG = avianData?.speciesBodyWeight != undefined ? avianData?.speciesBodyWeight : 0;
                    endpointAvian.LC50riskQuotientListed = this.getRiskQuotientValue(taxonTriggerList, true, avianData.taxonExposureDuration);
                    endpointAvian.LC50riskQuotientNonListed = this.getRiskQuotientValue(taxonTriggerList, false, avianData.taxonExposureDuration);
                    break;
                case TrexEndpointTypes.AvianNOAEC:
                    endpointAvian.NOAEC = avianData?.value != undefined ? avianData?.value : 0;
                    endpointAvian.NOAECSpeciesCommonName = avianData?.speciesCommonName != null ? avianData?.speciesCommonName : avianData?.speciesScientificName;
                    endpointAvian.NOAECUnit = TrexEndpointsUnits.MgKgDiet;
                    endpointAvian.NOAECOptionalTestBodyWeightG = avianData?.speciesBodyWeight != undefined ? avianData?.speciesBodyWeight : 0;
                    endpointAvian.NOAECriskQuotientListed = this.getRiskQuotientValue(taxonTriggerList, true, avianData.taxonExposureDuration);
                    endpointAvian.NOAECriskQuotientNonListed = this.getRiskQuotientValue(taxonTriggerList, false, avianData.taxonExposureDuration);
                    break;
                case TrexEndpointTypes.AvianNOAEL:
                    endpointAvian.NOAEL = avianData?.value != undefined ? avianData?.value : 0;
                    endpointAvian.NOAELSpeciesCommonName = avianData?.speciesCommonName != null ? avianData?.speciesCommonName : avianData?.speciesScientificName;
                    endpointAvian.NOAELUnit = TrexEndpointsUnits.MgKgBw;
                    endpointAvian.NOAELOptionalTestBodyWeightG = avianData?.speciesBodyWeight != undefined ? avianData?.speciesBodyWeight : 0;
                    endpointAvian.NOAELriskQuotientListed = this.getRiskQuotientValue(taxonTriggerList, true, avianData.taxonExposureDuration);
                    endpointAvian.NOAELriskQuotientNonListed = this.getRiskQuotientValue(taxonTriggerList, false, avianData.taxonExposureDuration);
                    break;
            }
        });

        AvianDTO.endpoint = endpointAvian;
        return AvianDTO;
    }

}



