<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel class="mb-2" [disabled]="disabledInputs" header="Inputs used in calculation">
    <app-eea-output-project-swan-run-inputs-use-in-calculation *ngIf="!disabledInputs"
      [selectedProject]="selectedProject" [selectedModel]="selectedModel"></app-eea-output-project-swan-run-inputs-use-in-calculation>
  </p-tabPanel>
  <p-tabPanel class="mb-2" [disabled]="disabledSummary" header="Results Summary">
    <app-eea-output-project-swash-swan-result-summary
      *ngIf="!disabledSummary"
      [selectedModel]="selectedModel"
      [selectedProject]="selectedProject"
      [isProjectOwnershipValid]="isProjectOwnershipValid"
    />
  </p-tabPanel>
</p-tabView>
