<p-inputNumber *ngIf="!isGroupRendered && !disabled"
    [(ngModel)]="value"
    [maxFractionDigits]="maxDecimals" 
    inputId="locale-us" 
    mode="decimal" 
    locale="en-US" 
    [useGrouping]="useGrouping"  
    [min]="min" 
    [max]="max" 
    (onBlur)="onBlur($event)">
</p-inputNumber>

<span *ngIf="disabled" class="text-600">{{value}}</span>
