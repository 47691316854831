
<div *ngIf="!disabled" class="row" style="max-height: 50px!important;"[ngClass]="disabled ? 'disabled': ''" [style.pointer-events]="disabled ? 'none': 'auto'" >
  <div style="max-height: 50px!important;display: flex;align-items: center; max-width: 40px;" class="col">
      <p-dropdown #dropdownList
      styleClass="ddlSelection"
      appendTo="body"
      [placeholder]="placeholder"
      [options]="options"
      [optionLabel]="optionLabel"
      [optionValue]="optionValue"
      [filter]="filterable"
      [(ngModel)]="selectedValue"
      (onChange)="onChange($event)"
      ></p-dropdown>
  </div>
</div>

<span *ngIf="disabled">{{selectedValue ? selectedValue[optionLabel] : ''}}</span>
