import { Component, DestroyRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { PersonalProtectionEquipmentSchemeApiService } from './personal-protection-equipment-scheme.api.service';
import { catchError, of, switchMap, take } from 'rxjs';
import { PPESGranulesColdef } from './personal-protection-equipment-scheme-coldefs/personal-protection-equipment-scheme-granules-coldef';
import { PPESSolublesColdef } from './personal-protection-equipment-scheme-coldefs/personal-protection-equipment-scheme-solubles-coldef';
import { PPESInterface } from 'src/app/shared/models/personal-protection-equipment-scheme';
import { GridTransaction } from 'src/app/shared/components/grid/grid-transaction';
import { HHRAConstants } from '../hhra-constants';
import { PersonalProtectionEquipmentSchemeLogicService } from './personal-protection-equipment-scheme.logic.service';
import { Utils } from 'src/app/shared/utils/utils';
import { GridLogicService } from 'src/app/shared/components/grid/grid.logic.service';
import { RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { HumanHealthOpexLogicService } from 'src/app/shared/services/human-health-opex-logic.service';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';

@Component({
  selector: 'app-personal-protection-equipment-scheme',
  templateUrl: './personal-protection-equipment-scheme.component.html',
  styleUrls: ['./personal-protection-equipment-scheme.component.css']
})
export class PersonalProtectionEquipmentSchemeComponent {

  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;
  @Output() public isValid = new EventEmitter<boolean>();

  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  destroyRef = inject(DestroyRef);
  saveRef?: string;
  columnDefs: any[] = [];
  rowData: any[] = [];
  isLoading!: boolean;
  rowHeight: number = 45;

  @ViewChild('grid') grid!: GridComponent;
  isEmpty: boolean = false;

  public blockUi: boolean = false;
noteToUse: string = "PPE";

  constructor(private PPESGranulesColdef: PPESGranulesColdef,
    private PPESSolublesColdef: PPESSolublesColdef,
    private ppesApiService: PersonalProtectionEquipmentSchemeApiService,
    private personalProtectionEquipmentSchemeLogicService: PersonalProtectionEquipmentSchemeLogicService,
    private gridserviceLogic: GridLogicService,
    private readonly humanHealthOpexLogicService: HumanHealthOpexLogicService,
    private readonly gapApplicationSchemeLogicService:GapApplicationSchemeLogicService
  ) {
  }

  ngOnInit(): void {
    this.humanHealthOpexLogicService.setOpexInputsCombinations([]);
    this.isLoading = true;
    this.initSubscribes();
    this.getData();
  }
  validateScenarios() {
    if (!this.isOPEXFormulationTypeSolid()) {
      if (this.isEmpty) {
        this.autoPopulateInitialScenariosSolubles();
      }
    }
    if (this.isOPEXFormulationTypeSolid()) {
      if (this.isEmpty) {
        this.autoPopulateInitialScenariosGranules();
      }
    }
  }
  createPPESObject(name: string, mlClothing: string | undefined, appClothing: string | undefined, mlGloves: boolean | undefined, appGloves: boolean | undefined, mlBody: string | undefined, appBody: string | undefined): PPESInterface {
    var ppe: PPESInterface = {
      personalProtectionEquipmentSchemePK: 0,
      projectPK: this.selectedProject?.projectPk || 0,
      mixingLoadingClothing: mlClothing,
      applicationClothing: appClothing,
      mixingLoadingGloves: mlGloves,
      applicationGloves: appGloves,
      applicationType: this.isOPEXFormulationTypeSolid() ? "solid" : "liquid",
      mixingLoadingBody: mlBody,
      applicationBody: appBody,
      name: name
    }
    return ppe;
  }

  autoPopulateInitialScenariosSolubles() {
    let scenarios = [];
    const scenario1 = this.createPPESObject("Potential exposure", HHRAConstants.HHRA_PPES_CLOTHING[0].name, HHRAConstants.HHRA_PPES_CLOTHING[0].name, undefined, undefined, undefined, undefined);
    const scenario2 = this.createPPESObject("Standard workwear", HHRAConstants.HHRA_PPES_CLOTHING[1].name, HHRAConstants.HHRA_PPES_CLOTHING[1].name, undefined, undefined, undefined, undefined);
    const scenario3 = this.createPPESObject("Workwear & gloves for M and L", HHRAConstants.HHRA_PPES_CLOTHING[1].name, HHRAConstants.HHRA_PPES_CLOTHING[1].name, true, undefined, undefined, undefined);
    const scenario4 = this.createPPESObject("Workwear & gloves for M and L & A", HHRAConstants.HHRA_PPES_CLOTHING[1].name, HHRAConstants.HHRA_PPES_CLOTHING[1].name, true, true, undefined, undefined);
    scenarios.push(scenario1, scenario2, scenario3, scenario4);
    this.addAutoPopulatedScenariosToTransactionsList(scenarios);
  }

  autoPopulateInitialScenariosGranules() {
    let scenarios = [];
    const scenario1 = this.createPPESObject("Potential exposure", HHRAConstants.HHRA_PPES_CLOTHING[0].name, HHRAConstants.HHRA_PPES_CLOTHING[0].name, undefined, undefined, HHRAConstants.HHRA_PPES_BODY[0].name, HHRAConstants.HHRA_PPES_BODY[0].name);
    scenarios.push(scenario1);
    this.addAutoPopulatedScenariosToTransactionsList(scenarios);
  }

  initSubscribes() {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.menuService.saveRef;
      this.saveData(this.grid?.transactionList)
      this.menuService.setSuccess(true)
    });
  }

  getData() {
    this.ppesApiService.getPersonalProtectionEquipmentScheme(this.selectedProject!.projectPk).subscribe({
      next: (ppes: PPESInterface[]) => {
        if (this.isOPEXFormulationTypeSolid()) {
          this.columnDefs = this.PPESGranulesColdef.getColumnsDefinition(this.isProjectOwnershipValid);
          this.rowData = ppes.filter(ppe => ppe.applicationType == "solid");
          this.isEmpty = this.rowData.length == 0;
          this.validateScenarios();

        }
        else {
          this.columnDefs = this.PPESSolublesColdef.getColumnsDefinition(this.isProjectOwnershipValid);
          this.rowData = ppes.filter(ppe => ppe.applicationType == "liquid");
          this.isEmpty = this.rowData.length == 0;
          this.validateScenarios();

        }
        this.isLoading = false;
        this.refreshSelectedValuesTimeout();
      }
    })
  }

  isOPEXFormulationTypeSolid() {
    return this.selectedProject?.OPEXFormulationType === Constants.FORMULATION_TYPE_GRANULES;
  }

  saveData(dataTransaction: any) {

    if (!dataTransaction || dataTransaction.length <= 0) {
      this.menuService.setSuccess(true);
      return;
    }

    this.rowData = this.rowData.filter((r: any) => r.deleted === undefined || r.deleted === false);
    const deletedRow = dataTransaction.find( (row: any) => row.transactionType === Constants.TRANSACTION_TYPE.DELETE );
    this.gapApplicationSchemeLogicService.checkIfApplicationSchemeIsValid(this,deletedRow);


    dataTransaction.map((data: any) => {
      data.row.ApplicationType = this.isOPEXFormulationTypeSolid() ? 'solid' : 'liquid';
      data.row.ProjectPk = this.selectedProject?.projectPk;
    });
    this.ppesApiService.savePersonalProtectionEquipmentScheme(dataTransaction).pipe(take(1)).subscribe({
      next: () => {
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.menuService.setSuccess(true);
      },
      error: (err) => {
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(false) : this.menuService.setSuccess(false);
      }
    });

    this.refreshSelectedValuesTimeout();

  }

  saveAutoPopulatedData(dataTransaction: any) {
    dataTransaction.map((data: any) => {
      data.row.ApplicationType = this.isOPEXFormulationTypeSolid() ? 'solid' : 'liquid';
      data.row.ProjectPk = this.selectedProject?.projectPk;
    });
    return this.ppesApiService.savePersonalProtectionEquipmentScheme(dataTransaction).pipe(
      take(1),
      switchMap(() => {
        this.menuService.setSuccess(true);
        return of(null);
      }),
      catchError((err) => {
        this.menuService.setSuccess(false);
        return of(null);
      })
    );
  }
  private addAutoPopulatedScenariosToTransactionsList(scenarios: PPESInterface[]) {
    var addTransactions: GridTransaction[] = [];
    scenarios.forEach(row => {
      var transaction: GridTransaction = new GridTransaction();
      transaction.setTransactionType(2);
      transaction.setRow(row);
      addTransactions.push(transaction);
    });

    this.isLoading = true;
    this.saveAutoPopulatedData(addTransactions).subscribe(() => {
      this.getData();
      this.isLoading = false;
    });
  }

  removeDeletedRow(dataTransaction: any) {
    dataTransaction.forEach((transaction: any) => {
      if (transaction.transactionType == 3) {
        this.rowData = this.rowData.filter(row => row.PersonalProtectionEquipmentSchemePK != transaction.row.PersonalProtectionEquipmentSchemePK)
      }
    });
  }

  newRowAdded(newItems: any[]) {
    const itemsToProcess = Array.isArray(newItems) ? newItems : [newItems];      
    itemsToProcess.forEach(item => {
      if (!item.isDuplicate) {
        this.enableControls(false);
      }
    });
    this.grid.setLastRowSelected('useInProject');
    this.refreshSelectedValues();
  }

  public onBlurInputText({ row }: { row: any }): void {
    this.gapApplicationSchemeLogicService.checkIfApplicationSchemeIsValid(this,row);
  }

  public enableControls(enable: boolean): void {
    this.isValid.emit(enable);
    this.grid.useAddAction = enable;
  }

  private refreshSelectedValuesTimeout(): void {
    setTimeout(() => {
      this.refreshSelectedValues();
    }, 75);
  }

  private refreshSelectedValues(): void {
    if(! this.grid ) return;
    this.grid.refreshSelectedValues('useInProject');
  }

  public onSelectedRow (event: RowSelectedEvent): void {
    if( !this.grid ) return;
    this.createTransactionForSelectedRow( event.node );
  }

  public onSelectionChanged ( event: SelectionChangedEvent ){
    if( event.source !== 'uiSelectAll' || !this.grid ) return;
    this.grid.gridApi.forEachNode((node: any) => {
     this.createTransactionForSelectedRow(node);
    });
  }

  private createTransactionForSelectedRow( node: any ): void {
    const { id, data } = node;
    data.useInProject = node.isSelected();
    this.grid.CreateTransaction(id, id, data.useInProject, data);
  }

}
