<div *ngIf="!loading">
    <app-esa-project-status [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus"></app-esa-project-status>
    <div class="d-flex justify-content-between mb-6">
        <div style="left: 30px; display: flex; width: 200px;">
            <div class="mt-2 col-3 strong">Model:</div>
            <div class="col-10">
                <app-dropdown [options]="getModelValues()" [model]="selectedModel.long_value" (onChangeEvent)="onSeletedModelChange($event)" class="w-10"></app-dropdown>
            </div>
        </div>
    </div>
    <div class="border-bottom-1"></div>
    <div *ngIf="selectedModel != null">
        <p-scrollPanel [style]="{ width: '100%', height: '80vh' }">
            <!-- to do the html for incorperating the backend with frontend. -->
        </p-scrollPanel>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<div *ngIf="models.length<= 0 && !loading">
    <h4>No models where found</h4>
</div>