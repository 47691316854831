import { Component, DestroyRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin } from 'rxjs';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { CustomDatafieldGroup } from 'src/app/shared/models/custom-datafield-group';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { EndpointParameterCompound, EndpointParameterDetail } from 'src/app/shared/models/endpoint-parameter';
import { Project } from 'src/app/shared/models/project';
import { Species } from 'src/app/shared/models/species';
import { CustomDatafieldGroupApiService } from 'src/app/shared/services/custom-datafield-group.api.service';
import { DisciplineApiService } from 'src/app/shared/services/echo/discipline.api.service';
import { EcotoxicologyApiService } from 'src/app/shared/services/echo/ecotoxicology.api.service';
import { RoleApiService } from 'src/app/shared/services/role.api.service';
import { SpeciesApiService } from 'src/app/shared/services/species.api.service';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { Constants } from 'src/app/shared/utils/constants';

@Component({
  selector: 'app-era-ecotox-datasets',
  templateUrl: './era-ecotox-datasets.component.html',
  styleUrls: ['./era-ecotox-datasets.component.css']
})
export class ERAEcotoxDatasetsComponent {
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() taxonGroup?: string;
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() isValid = new EventEmitter<boolean>();

  eraEcotoxDatasetsComponent = this;
  public dataset: DataSet;
  public projectPk: number;
  public moleculePk: number;
  public selectedModels: string[];
  public showDetail: boolean;
  public selectedCompound: EndpointParameterCompound | undefined;
  public selectedMetabolitePk?: number;
  public coreDefaultValues: EndpointParameterDetail[];
  public isLoading: boolean;
  public savedDatasetPk?: number;
  public isProjectDataSetOwnershipValid: boolean;
  private projects: Project[] = [];

  taxonCatalogList: Catalog[];
  activeIngredientList: Catalog[] = [];
  substances: Catalog[] = [];
  units:Catalog[]=[];
  customDatafieldGroups: CustomDatafieldGroup[] = [];
  endpointTypes: Catalog[]=[];
  endpoints: Catalog[]=[];
  disciplines: Discipline[] = [];
  speciesCatalog: Catalog[] = [];
  substanceNames: Catalog[] = [];
  mainDiscipline!: number;
  regionPk:number=0;

  destroyRef = inject(DestroyRef)


  private datasetPk: number;
  readonly environmentalAssessment = Constants.FEATURE_ACRONYM.ENVIRONMENTAL_RISK_ASSESSMENT;

  constructor(private roleApiService: RoleApiService,
              private customDatafieldGroupApiService: CustomDatafieldGroupApiService,
              private disciplineApiService: DisciplineApiService,
              private speciesApiService: SpeciesApiService,
              private ecotoxicologyApiService: EcotoxicologyApiService,
              private readonly userLogicService: UserLogicService,
              private readonly selectedProjectAPIService: SelectedProjectApiService,){
    this.dataset = {} as DataSet;
    this.datasetPk = 0;
    this.projectPk = 0;
    this.moleculePk = 0;
    this.selectedModels = [];
    this.showDetail = false;
    this.coreDefaultValues = [];
    this.isLoading = false;
    this.taxonCatalogList = [];
    this.isProjectDataSetOwnershipValid = false;
  }

  ngOnInit(): void {
    this.initSubscribes();
  }

  public getDatasetDataOutput(dataset: any): void{
    this.resetCatalogs();
    this.dataset = dataset;
    this.projectPk = dataset.projectPk;
    this.moleculePk = dataset.moleculePk;
    this.datasetPk = this.dataset.dataSetPk ? this.dataset.dataSetPk : 0;
    this.regionPk = this.selectedProject?.countryPk !== undefined ? this.selectedProject.countryPk : (this.selectedProject?.regionPk as number);
    this.isLoading = true;
    this.isProjectDataSetOwnershipValid = this.isProjectDataSetOwnership(dataset);
    this.getRiskEndpointsData();
  }

  private isProjectDataSetOwnership(dataSet: any): boolean {
    if(!this.isProjectOwnershipValid) return false;
    return dataSet?.createdBy != null ? dataSet?.createdBy === this.userLogicService?.profile?.displayName :
    this.projects.find((project) => project.projectPk === dataSet.originalProject)?.createdBy === this.userLogicService?.profile?.displayName;
  }

  private initSubscribes(): void {
    this.selectedProjectAPIService.projects.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(projects => this.projects = projects);
  }

  getRiskEndpointsData(): void {
    forkJoin([
      this.disciplineApiService.getDisciplineInformation(),
      this.customDatafieldGroupApiService.GetCustomDatafieldGroupByDisciplineName(Constants.DISCIPLINE_IDENTITIES.ECOTOXICOLOGY),
      this.speciesApiService.GetSpeciesByType(Constants.SPECIES_TYPES.STANDARD_TEST_SPECIES)
    ]).subscribe({
      next: ([disciplines, customDatafieldGroup, species]) => {
        this.disciplines = disciplines;
        this.mainDiscipline = this.disciplines.filter(x => x.name === Constants.DISCIPLINE_IDENTITIES.ECOTOXICOLOGY)[0]?.disciplinePk;
        this.customDatafieldGroups = customDatafieldGroup
        this.setSpeciesCatalog(species);
        this.filterSubstanceNameByActiveIngredient(this.dataset);
        this.setCustomValuesCatalog();
      },
      error: (error) => {
        console.warn('There was an error retrieving the data');
      },
    });
  }

  setSpeciesCatalog(data: any){
    data.forEach((specie:Species) => {
      let scientificSpicie : Catalog = {
        key: specie.id,
        name: specie.latinName,
          };
      this.speciesCatalog.push(scientificSpicie);
    })
    this.speciesCatalog.sort((a, b) => (a.name && b.name) ? a.name.localeCompare(b.name) : 0);
  }

  filterSubstanceNameByActiveIngredient(pDataset: any) {
    if (pDataset.activeIngredient != null) {
      this.substanceNames = this.substances.filter(
        (x) => (x.key == this.activeIngredientList.filter(x => x.name == pDataset.activeIngredient)[0]?.key && x.source == 'Active') ||
          (x.parent != null && x.parent.indexOf(this.activeIngredientList.filter((x: any) => x.name == pDataset.activeIngredient)[0].key!) != -1)
      );
    } else {
      if (pDataset.chemicalType != undefined) {
        this.substanceNames = this.substances.filter(
          (x) => x.chemical == pDataset.chemicalType
        );
      }
      else {
        this.substanceNames = this.substances;
      }
    }
  }

  setCustomValuesCatalog() {
    this.customDatafieldGroupApiService
    .GetAllDatafieldValuesByDiscipline(
      this.mainDiscipline
    ).subscribe(
      (data)=>{
        data?.Endpoint.forEach((endpoint: any) =>{
          let endpointCatalog : Catalog = {
            key: endpoint.key,
            name: endpoint.name,
          };
          this.endpoints.push(endpointCatalog);

        })
        data['Endpoint Type'].forEach((endpointType: any) => {
          let endpointTypeCatalog : Catalog = {
            key: endpointType['key'],
            name: endpointType['name']
          };
          this.endpointTypes.push(endpointTypeCatalog);

        });
        data?.Unit.forEach((unit: any)=>{
          let unitCatalog : Catalog = {
            key: unit.key,
            name: unit.name
          };
          this.units.push(unitCatalog);

        });

        this.isLoading = false;
      });
    }

  public getSelectedModels(models: string[]): void{
    this.selectedModels = models;
  }

  public getSelectedMetabolite(metabolitePk: number): void{
    this.selectedCompound = {
      compoundPk: metabolitePk,
      compoundType: 'Metabolite',
      datasetPk: this.datasetPk,
      projectPk: this.selectedProject?.projectPk ?? -1,
      activePk: this.moleculePk};
    this.selectedMetabolitePk = metabolitePk;
  }

  public getSelectedActiveIngredient(activeIngredientPk: number): void{
    if(activeIngredientPk == undefined)
      this.selectedCompound = undefined;
    else
      this.selectedCompound = {
        compoundPk: activeIngredientPk,
        compoundType: 'Active',
        datasetPk: this.datasetPk,
        projectPk: this.selectedProject?.projectPk ?? -1,
        activePk: activeIngredientPk};
    this.selectedMetabolitePk = undefined;
  }

  public showDetailOutput(show: boolean): void{
    this.showDetail = show;
  }

  saveDetailData(data: any) {
    if(!this.showDetail){
      this.ecotoxicologyApiService.saveData(data).subscribe({
        next: () => {
          this.resetCatalogs();
        },
        error: (err: any) => {
          console.warn(err);
          this.resetCatalogs();
        }
      })
    }
  }

  resetCatalogs(){
    this.disciplines = [];
    this.customDatafieldGroups = [];
    this.speciesCatalog = [];
    this.substances = [];
    this.activeIngredientList = [];
    this.taxonCatalogList = [];
    this.units = [];
  }

  public getIsDatasetValid(isValid: boolean): void {
    this.isValid.emit(isValid);
  }

  public getEmittedDatasetPk(datasetPk: number){
    this.savedDatasetPk = datasetPk;
  }
}

