<div class="card grid" *ngIf="!activeItem?.disabled && !setupLoading">
    <div class="mt-2 ml-2 col-12">
        <app-eea-setup-inputs [selectedProject]="selectedProject" [isProjectOwnershipValid]="isProjectOwnershipValid"
            [filteredFormulations]="filteredFormulations" [geographies]="geographies"
            [currentUserName]="currentUserName" [isHHRA]="isHHRA" [isInverseModeling]="isInverseModeling"
            (onformulationSelectionChangeEvent)="onformulationSelectionChanged($event)"
            (onQueryFilterChangeEvent)="onFormulationFilterChanged($event)"
            (isSetupValidEvent)="validateSetupInfoInProject($event)"></app-eea-setup-inputs>            
    </div>
    <div class="mt-5 ml-2 col-8">
        <label class="lbl-valid label-right-Aling" *ngIf="showAlternativeNameLabel">With a limit of 14 characters. For SWASH, PELMO and MACRO*</label>
        <app-eea-setup-models-to-be-run [modelsLoading]="modelsLoading"
            [isProjectOwnershipValid]="isProjectOwnershipValid" [selectedProject]="selectedProject"
            [modelsToBeRun]="modelsToBeRun" [columnsDefinition]="columnDef" [isEEA]="isEEA" [modelsCollection]="modelsCollection"
            (areValidateAlternativeNameCompoundEvent)="validateAlternativeNameCompoundState($event)"
            (showAlternativeNameCompoundLabel)="showAlternativeNameCompoundLabel($event)"
            (areSetupModelsValidEvent)="validateSetupModelInProject($event)" (orderedModelsToBeRunRowData)="orderedModelsToBeRunRowData()"></app-eea-setup-models-to-be-run>
    </div>
    <div *ngIf="isInverseModeling" class="mt-5 ml-2 col-3">
        <app-eea-setup-gap-outputs [selectedOutputs]="selectedOutputs"
            [isProjectOwnershipValid]="isProjectOwnershipValid" [gapOutputs]="gapOuputs"
            (onOutputSelectionChangeEvent)="outputSelectionChanged($event)"></app-eea-setup-gap-outputs>
    </div>
</div>

<app-loading class="flex align-items-center justify-content-center" *ngIf="setupLoading"></app-loading>