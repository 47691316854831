import { Component, Input, SimpleChanges } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ApplicationSchemeXActiveIngredientRate, ApplicationSchemeXApplication } from 'src/app/shared/models/application-scheme';
import { Precursor } from 'src/app/shared/models/echo/precursor';
import { Endpoint } from 'src/app/shared/models/endpoint';
import { Project } from 'src/app/shared/models/project';
import { Run } from 'src/app/shared/models/run/run';
import { QcIconComponent } from 'src/app/shared/renderers/qc-icon/qc-icon.component';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { EEARunProjectQcColdef } from './eea-run-project-qc.coldef';
import { ProjectXCompoundXModel } from 'src/app/shared/models/project-x-compound-x-model';

@Component({
  selector: 'app-eea-run-project-qc',
  templateUrl: './eea-run-project-qc.component.html',
  styleUrls: ['./eea-run-project-qc.component.css']
})
export class EEARunProjectQCComponent {

  @Input() runs!: Run[];
  @Input() selectedProject?: Project;
  @Input() isPWC!: boolean;
  columnsToShow: string[] = []
  missingDataRows: any[] = []
  showGrid: Boolean = false;
  showWarningAlert: Boolean = false;
  warningAlertText: string = '';
  showAlert: Boolean = false;
  columnsDef: any;
  rowData: any;


  constructor(private qcColdef: EEARunProjectQcColdef,) { }

  ngOnInit(): void {
    this.isPWC ? this.checkMissingDataByRowForPWC() : this.checkMissingDataByRow();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['runs'].currentValue) {
      this.showGrid = false;
      this.showWarningAlert = false;
      this.isPWC ? this.checkMissingDataByRowForPWC() : this.checkMissingDataByRow();
    }
  }

  groupRowsByModel(): { [key: string]: Run[] } {
    let groupDataByModel: { [key: string]: Run[] } = {};
    this.runs.forEach(run => {
      const groupKey = run!.model!.name;
      if (!groupDataByModel[groupKey]) {
        groupDataByModel[groupKey] = [];
      }
      groupDataByModel[groupKey].push(run);
    });
    return groupDataByModel;
  }

  groupRowsByModelForPWC(): { [key: string]: Run[] } {
    let groupDataByModel: { [key: string]: Run[] } = {};
    this.runs.forEach(run => {
      const groupKey = run!.model!.name;
      if (!groupDataByModel[groupKey]) {
        groupDataByModel[groupKey] = [];
      }
      groupDataByModel[groupKey].push(run);
    });
    return groupDataByModel;
  }

  checkMissingDataByRow() {
    let missingData: any[] = [];
    const groupedRows: { [key: string]: Run[] } = this.groupRowsByModel();
    if (Object.keys(groupedRows).length > 0) {
      Object.entries(groupedRows).forEach(([key, value]) => {
        var requiredColumns = this.validateRequiredColumnsByModel(key)
        if (requiredColumns) {
          requiredColumns = [...requiredColumns];
          const rowsImcomplete = this.checkMissingData(value, requiredColumns);
          missingData.push(...rowsImcomplete)
        }
      });
      this.columnsToShow = [...new Set(missingData.flatMap(obj => obj.allMissingColumns))];
      this.missingDataRows = missingData;
      this.insertDynamicColumnDefs();
      this.showQcResult();
    } else {
      this.createWarningAlert();
    }
  }

  checkMissingDataByRowForPWC() {
    let missingData: any[] = [];
    const groupedRows: { [key: string]: Run[] } = this.groupRowsByModelForPWC(); 
    if (Object.keys(groupedRows).length > 0) {
      Object.entries(groupedRows).forEach(([key, value]) => {
        var requiredColumns = this.validateRequiredColumnsByModel(key)
        if (requiredColumns) {
          requiredColumns = [...requiredColumns];
          const rowsImcomplete = this.checkMissingDataPWC(value, requiredColumns);
          missingData.push(...rowsImcomplete)
        }
      });   
      this.columnsToShow = [...new Set(missingData.flatMap(obj => obj.allMissingColumns))];
      this.missingDataRows = missingData;
      this.insertPWCDynamicColumnDefs();
      this.showQcResult();
    } else {
      this.createWarningAlert();
    }
  }

  createWarningAlert(): void {
    if (this.selectedProject?.projectPk === -1) {
      this.warningAlertText = 'There is no a selected project.'
    }
    else if (this.selectedProject?.dataSets == null) {
      this.warningAlertText = 'There are no datasets for this project.'
    }
    else if (this.runs.length === 0) {
      this.warningAlertText = 'There are no runs to be process by the QC'
    }
    this.showWarningAlert = true;
  }

  checkMissingDataForMetabolites(pData: any): { [key: string]: { column: string, message: string }[] }[] {
    let missingDataByMetabolites: { [key: string]: { column: string, message: string }[] }[] = [];
    pData?.metabolites?.forEach((metabolite: Endpoint) => {
      let metaboliteColumns: string[] = Constants.METABOLITES_BY_MODEL[pData?.model?.name] ?? [];
      metaboliteColumns = this.includeColumnsByConditions(metabolite, metaboliteColumns, pData?.model?.name);
      let columnsByMetabolite: { column: string, message: string }[] = [];
      for (const column of metaboliteColumns) {
        let value = metabolite[column as keyof Endpoint];        
        if (this.metaboliteOrderMissing(value,column) )
        {
          columnsByMetabolite.push({ column: column, message: 'MACRO GW mets ordering is missing ' });
        }
        else if (this.metaboliteOrderIsInvalid(pData,value,column,metabolite))
        {
          columnsByMetabolite.push({ column: column, message: 'MACRO GW mets ordering is wrong, a precursor is not up in the ordering ' });
        }
        else if (Utils.isEmptyValue(value)) {
          columnsByMetabolite.push({ column: column, message: 'Missing Data' });
        }

      }
      let metaboliteFormationFractions = this.checkMissingDataForFractions(metabolite, pData.model.name);
      columnsByMetabolite = [...columnsByMetabolite, ...metaboliteFormationFractions];
      columnsByMetabolite.length > 0 && missingDataByMetabolites.push({ [metabolite.substanceName!]: columnsByMetabolite });
    });
    return missingDataByMetabolites;
  }

  metaboliteOrderMissing(value:any,column:string) : boolean
  {
    return column == Constants.FIELD_NAMES.ORDER  && value == 0;
  }

  metaboliteOrderIsInvalid(data:any, value:any,column:string,metabolite: Endpoint) : boolean
  {
    if (column == Constants.FIELD_NAMES.ORDER)
    {
      if (value == 0) return false;
  
      let allPrecursorsValid = true;
  
      for (const precursor of metabolite.precursors) {
        let metaboliteHasRightOrdering = precursor.moleculePk !== null || data.metabolites.some(
          (m:Endpoint) =>
            m.substanceName === precursor.substanceName &&
            m.order && metabolite.order && m.order < metabolite.order
        );
          
        if (!metaboliteHasRightOrdering)
        {
          return true
        }
      }
    }
    return false; 
  }

  checkMissingDataForFractions(metabolite: Endpoint, pModelName: string): { column: string, message: string }[] {
    let missingColumns: { column: string, message: string }[] = [];
    if (!Constants.MODELS_THAT_REQUIRE_FORMATION_FRACTION.find(x => x == pModelName)) {
      return [];
    }
    missingColumns = [...this.validateExistencePrecursorsInFormationFractions(metabolite, pModelName)];
    this.calculateMissingDataForPrecursors(metabolite, missingColumns, pModelName);
    return [...new Set(missingColumns)];
  }

  // TODO: This code is commented out, to prevent QC from detecting it, in future stories it will be addressed.
  private calculateMissingDataForPrecursors(metabolite: Endpoint, missingColumns: { column: string; message: string; }[], pModelName: string) {
    // let sumFormationFraction = 0;
    metabolite?.precursors?.forEach((precursor: Precursor) => {
      for (const column of Constants.COLUMS_BY_FRACTIONS) {
        let value = precursor[column as keyof Precursor];
        if (Utils.isEmptyValue(value)) {
          missingColumns.push({ column: precursor.precursorType, message: 'Missing Data' });
          continue;
        }
        // if (pModelName === Constants.MODELS.PELMO && column === Constants.FIELD_NAMES.FORMATION_FRACTION) {
        //   sumFormationFraction += Number(precursor[column as keyof Precursor]);
        // }
      }
    });
    // if (pModelName === Constants.MODELS.PELMO && sumFormationFraction > 1) {
    //   missingColumns.push({ column: Constants.FIELD_NAMES.FRACTION_IN_SOIL, message: 'The sum of the fraction transformed in soil must be equal or less than 1' });
    // }
  }

  validateExistencePrecursorsInFormationFractions(metabolite: Endpoint, pModelName: string): { column: string, message: string }[] {
    let fractions = metabolite?.precursors?.flatMap((precursor: Precursor) => precursor.precursorType) || [];
    return Constants.REQUIRED_FRACTIONS_BY_METABOLITES_BY_MODEL[pModelName]
      .filter((x: string) => !fractions.includes(x))
      .map((missingFraction: string) => ({ column: missingFraction, message: 'Missing Data' }));
  }

  validateRequiredColumnsByModel(model: string): any {
    let columns = Constants.REQUIRED_COLUMNS_BY_MODEL[model];
    if (columns) {
      columns = this.includeColumnsByModel(model, columns);
    }
    return columns;
  }

  validateCropEvent(run: Run, pColumns: string[]): any {
    let columns = pColumns;
    if (Constants.CROP_EVENT_EXCEPTION_MODELS.includes(run.model!.name)) {
      let dateType = run?.applicationSchemeData?.dateType;
      if (!Utils.isEmptyValue(dateType)) {
        columns = dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE ?
          columns.filter((field: any) => !([Constants.FIELD_NAMES.APP_SCHEME_CROP_EVENT, Constants.FIELD_NAMES.APP_SCHEME_DAYS_SINCE].includes(field))) :
          columns;
      } else {
        columns = columns.filter((field: any) => !([Constants.FIELD_NAMES.APP_SCHEME_CROP_EVENT, Constants.FIELD_NAMES.APP_SCHEME_DAYS_SINCE].includes(field)));
      }
    }
    return columns;
  }

  validateVariableRates(run: Run, pColumns: string[]): any {
    let columns = pColumns;
    if (Constants.DATE_TYPE_EXCEPTION_MODELS.includes(run.model!.name)) {
      if (run?.applicationSchemeData?.hasVariableRates) {
        columns = columns.filter((field: any) => !(Constants.MAIN_APPLICATIONS_FIELDS.includes(field)))
      } 
    }
    return columns;
  }

  validateCropPk(run: Run, pColumns: string[]): any {
    let columns = [...pColumns];
    if (Utils.isEmptyValue(run?.applicationSchemeData?.coreApplicationSchemePk)) {
      columns = columns.filter((field: string) => field !== Constants.FIELD_NAMES.GLOBAL_CROP);
    }
    return columns;
  }

  validateApplicationNumberPWC(run: Run, pColumns: string[]) {
    let columns = [...pColumns];
    if (run.applicationSchemeDataList?.filter((scheme: any) => Utils.isEmptyValue(scheme?.applicationSchemeXApplication))) {
      columns = columns.filter((field: string) => field !== Constants.PWC_FIELD_NAMES.NUMBER_OF_APPLICATIONS);
    }      
    
    return columns;
  }

  includeSwanColumns(model: string, pColumns: string[]): string[] {
    let columns = [...pColumns];
    let swanColumns = [Constants.FIELD_NAMES.APP_SCHEME_NOZZLE_DRIFT_REDUCTION_10M, Constants.FIELD_NAMES.APP_SCHEME_NOZZLE_DRIFT_REDUCTION_20M];
    if (model === Constants.MODELS.SWASH) {
      let runSwanModel = this.selectedProject!.projectXCompoundXModel?.some((x: ProjectXCompoundXModel) => x.ModelName === Constants.MODELS.SWAN);
      if (runSwanModel) {
        columns = [...columns, ...swanColumns];
      }
    }
    return columns;
  }

  includeColumnsByModel(model: string, pColumns: string[]): string[] {
    let columns = [...pColumns];
    switch (model) {
      case Constants.MODELS.SWASH:
        if (this.selectedProject!.projectXCompoundXModel?.some((x: ProjectXCompoundXModel) => x.ModelName === Constants.MODELS.SWAN)) {
          columns = [...columns, Constants.FIELD_NAMES.APP_SCHEME_NOZZLE_DRIFT_REDUCTION_10M, Constants.FIELD_NAMES.APP_SCHEME_NOZZLE_DRIFT_REDUCTION_20M];
        }
        break;
      case Constants.MODELS.PWC:
        if (this.selectedProject!.projectXCompoundXModel?.some((x: ProjectXCompoundXModel) => x.ModelName === Constants.MODELS.PWC)) {
          columns = [...columns];
        }
        break;
    }
    return columns;
  }

  excludeColumnsByConditions(run: Run, pColumns: string[]): any {
    let columns = [...pColumns];
    columns = this.validateVariableRates(run, columns);
    columns = this.validateCropEvent(run, columns);
    columns = this.validateCropPk(run, columns);
    return columns;
  }

  excludeColumnsByConditionsForPWC(run: Run, pColumns: string[]): any {
    let columns = [...pColumns];
    columns = this.validateCropEvent(run, columns);
    columns = this.validateApplicationNumberPWC(run, columns);
    return columns;
  }

  includeColumnsByConditions(run: Run, pColumns: string[], modelName: string): any {
    let columns = [...pColumns];
    columns = this.includeColumnsByKineticModel(run, columns, modelName);
    return columns;
  }

  includeColumnsByKineticModel(run: Run, pColumns: string[], modelName: string): any {
    if (Constants.SOIL_MODELS.includes(modelName) && run.kineticModel != Constants.KINETIC_MODELS.SFO) {
      return [...pColumns, ...(Constants.REQUIRED_COLUMNS_BY_KINETIC_MODEL[run.kineticModel as string] ?? [])];
    }
    return pColumns;
  }

  checkMissingData(runs: Run[], requiredColumns: string[]) {
    let missingDataByRunId: any[] = [];
    runs.forEach((run: Run) => {
      let missingColumns = [];
      let columnsToValidate = [...requiredColumns];
      columnsToValidate = this.excludeColumnsByConditions(run, columnsToValidate);
      columnsToValidate = this.includeColumnsByConditions(run, columnsToValidate, run.model!.name);
      for (const column of columnsToValidate) {
        const properties = column.split('.');
        let nestedData = run;
        for (const property of properties) {
          if (nestedData && nestedData.hasOwnProperty(property)) {
            nestedData = (nestedData as any)[property];
          } else {
            missingColumns.push(column);
            break;
          }
        }
        if (Utils.isEmptyValue(nestedData)) {
          missingColumns.push(column);
        }
      }
      let metabolites = this.checkMissingDataForMetabolites(run);
      let rates = this.checkMissingDataForRates(run);
      let applicationsxApplications = this.checkMissingDataForApplicationsXApplications(run, rates);
      let applicationScheme = this.checkMissingDataForApplicationScheme(run);
      let allMissingColumns = [...new Set([...missingColumns, ...applicationsxApplications, ...applicationScheme.map(item => item.column), ...this.getAllColumnValues(metabolites), ...Object.values(rates).flat()])]
      if (allMissingColumns.length > 0) {
        missingDataByRunId.push({
          run,
          allMissingColumns,
          missingParentColumns: missingColumns,
          missingColumnsByMetabolites: metabolites,
          missingColumnsByApplications: rates,
          missingColumnsMessages: [...new Set([...applicationScheme])]
        })
      }
    });
    return missingDataByRunId;
  }
  
  checkMissingDataPWC(runs: Run[], requiredColumns: string[]) {
    let missingDataByRunId: any[] = [];
    runs.forEach((run: Run) => {
      let missingColumns = [];
      let columnsToValidate = [...requiredColumns];
      columnsToValidate = this.excludeColumnsByConditionsForPWC(run, columnsToValidate);
      for (const column of columnsToValidate) {
        const properties = column.split('.');
        let nestedData = run;
        for (const property of properties) {
          if (nestedData && nestedData.hasOwnProperty(property)) {
            nestedData = (nestedData as any)[property];
          } else {
            missingColumns.push(column);
            break;
          }
        }
        if (Utils.isEmptyValue(nestedData)) {
          missingColumns.push(column);
        }
      }
      let rates = this.checkMissingDataForRatesForPWC(run);
      let metabolites = this.checkMissingDataForMetabolites(run);
      let applicationScheme = this.checkMissingDataForApplicationSchemeForPWC(run);
      let applicationsxApplications = this.checkMissingDataForApplicationsXApplicationsForPWC(run, rates);
      let allMissingColumns = [...new Set([...missingColumns, ...applicationsxApplications, ...applicationScheme.map(item => item.column), ...this.getAllColumnValues(metabolites), ...Object.values(rates).flat()])]
      if (allMissingColumns.length > 0) {
        missingDataByRunId.push({
          run,
          allMissingColumns,
          missingParentColumns: missingColumns,
          missingColumnsByMetabolites: metabolites,
          missingColumnsByApplications: rates,
          missingColumnsMessages: [...new Set([...applicationScheme])]
        })
      }
    });
    return missingDataByRunId;
  }

  checkMissingDataForApplicationScheme(run: Run): { column: string, message: string }[] { 
    let columns = []; 
    if (run.model?.name === Constants.MODELS.SWASH && run.applicationSchemeData?.numberOfApplications > 5) { 
      columns.push({ column: Constants.FIELD_NAMES.NUMBER_OF_APPLICATIONS, message: 'Number of applications exceeds 5 Applications.' }); 
    } 
    if ((run.model?.name === Constants.MODELS.PEARL && (run.applicationSchemeData?.repeatInterval < 1 || run.applicationSchemeData?.repeatInterval > 3)) || 
      (run.model?.name === Constants.MODELS.PELMO && run.applicationSchemeData?.repeatInterval < 1)) { 
      columns.push({ column: Constants.FIELD_NAMES.APP_SCHEME_REPEAT_INTERVAL, message: run.model?.name === Constants.MODELS.PELMO ? 'The value must be greater than 0.' : 'The value must be in the range of 1 to 3.' }); 
    } 
    return columns; 
  }

  checkMissingDataForApplicationSchemeForPWC(run: Run): { column: string, message: string }[] { 
    let columns = []; 
    if(run.applicationSchemeDataList?.filter((scheme: any) => Utils.isEmptyValue(scheme?.numberOfApplications)).length > 0){ 
      columns.push({ column: Constants.FIELD_NAMES.NUMBER_OF_APPLICATIONS, message: 'Missing applications' });
    }
    if(run.applicationSchemeDataList?.filter((scheme: any) => Utils.isEmptyValue(scheme?.dateType)).length > 0){ 
      columns.push({ column: Constants.PWC_FIELD_NAMES.DATE_TYPE, message: 'Missing Date Type' });
    }
    if(run.applicationSchemeDataList?.filter((scheme: any) => !scheme?.cropXRegionPks.length).length > 0){
      columns.push({ column: Constants.PWC_FIELD_NAMES.SCENARIOS, message: 'Missing Scenarios' });
    }
    return columns; 
  }

  getAllColumnValues(data: { [key: string]: { column: string, message: string }[] }[]): string[] {
    const columnValues: string[] = [];

    for (const obj of data) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          for (const item of obj[key]) {
            columnValues.push(item.column);
          }
        }
      }
    }
    
    return columnValues;
  }

  checkMissingDataForRates(run: Run): { [key: string]: string[] } {
    if(!this.validateRatesFromRun(run)) return {};
    let missingColumnsByMolecule: { [key: string]: string[] } = {};
    run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.forEach((item: ApplicationSchemeXActiveIngredientRate) => {
      let missingColumns: string[] = [];
      Constants.REQUIRED_FIELDS_BY_RATES.forEach((field: string) => {
        if (Utils.isEmptyValue(item[field as keyof ApplicationSchemeXActiveIngredientRate])) {
          missingColumns.push(field)
        }
      });
      if (!missingColumnsByMolecule[item.substanceName!]) {
        missingColumnsByMolecule[item.substanceName!] = [...missingColumns]
      } else {
        missingColumnsByMolecule[item.substanceName!] = [...missingColumnsByMolecule[item.substanceName!], ...missingColumns]
      };
    });
    return missingColumnsByMolecule;
  }

  checkMissingDataForRatesForPWC(run: Run): { [key: string]: string[] } {
    if(!this.validateRatesFromRunForPWC(run)) return {};
    let missingColumnsByMolecule: { [key: string]: string[] } = {};
    run.applicationSchemeDataList?.forEach((scheme: any) => {
      scheme?.applicationSchemeXActiveIngredientRate?.forEach((item: ApplicationSchemeXActiveIngredientRate) => {
        let missingColumns: string[] = [];
        Constants.REQUIRED_FIELDS_BY_RATES.forEach((field: string) => {
          if (Utils.isEmptyValue(item[field as keyof ApplicationSchemeXActiveIngredientRate])) {
            missingColumns.push(field)
          }
        });
        if (!missingColumnsByMolecule[item.substanceName!]) {
          missingColumnsByMolecule[item.substanceName!] = [...missingColumns]
        } else {
          missingColumnsByMolecule[item.substanceName!] = [...missingColumnsByMolecule[item.substanceName!], ...missingColumns]
        };
      });      
    });
    return missingColumnsByMolecule;
  }

  validateRatesInApplication(application:ApplicationSchemeXApplication, rates:{ [key: string]: string[] }): void {
    application.applicationSchemeXActiveIngredientRate?.forEach((item: ApplicationSchemeXActiveIngredientRate) => {
      let missingColumns: string[] = [];
      Constants.REQUIRED_FIELDS_BY_RATES.forEach((field: string) => {
        if (Utils.isEmptyValue(item[field as keyof ApplicationSchemeXActiveIngredientRate])) {
          missingColumns.push(field)
        }
      });
      if (!rates[item.substanceName!]) {
        rates[item.substanceName!] = [...missingColumns]
      } else {
        rates[item.substanceName!] = [...rates[item.substanceName!], ...missingColumns]
      };
    });
  }

  validateRatesInApplicationForPWC(application:ApplicationSchemeXApplication, rates:{ [key: string]: string[] }): void {
    application.applicationSchemeXActiveIngredientRate?.forEach((item: ApplicationSchemeXActiveIngredientRate) => {
      let missingColumns: string[] = [];
      Constants.REQUIRED_FIELDS_BY_RATES.forEach((field: string) => {
        if (Utils.isEmptyValue(item[field as keyof ApplicationSchemeXActiveIngredientRate])) {
          missingColumns.push(field)
        }
      });
      if (!rates[item.substanceName!]) {
        rates[item.substanceName!] = [...missingColumns]
      } else {
        rates[item.substanceName!] = [...rates[item.substanceName!], ...missingColumns]
      };
    });
  }

  validateRatesFromRun(run: Run): boolean {
    if(run.applicationSchemeData?.hasVariableRates){
      if([Constants.MODELS.STEP_1_2, Constants.MODELS.UK, Constants.MODELS.EU_SOIL, Constants.MODELS.GERMAN_SOIL, Constants.MODELS.NEU_SOIL].includes(run.model!.name)){
        return true;
      }
      if(Constants.MODELS_FOR_VARIABLE_RATES.includes(run.model!.name)){
        return false;
      }
    }
    return true;
  }

  validateRatesFromRunForPWC(run: Run): boolean {
    if(run.applicationSchemeDataList?.filter((x: any) => x.hasVariableRates).length > 0){
      if([Constants.MODELS.STEP_1_2, Constants.MODELS.UK, Constants.MODELS.EU_SOIL, Constants.MODELS.GERMAN_SOIL, Constants.MODELS.NEU_SOIL].includes(run.model!.name)){
        return true;
      }
      if(Constants.MODELS_FOR_VARIABLE_RATES.includes(run.model!.name)){
        return false;
      }
    }
    return true;
  }

  GetRateColumnsToBeValidated(run: Run): string[] {
    if (Constants.DATE_TYPE_EXCEPTION_MODELS.includes(run.model!.name)) {
      let dateType = run?.applicationSchemeData?.dateType;
      if (!Utils.isEmptyValue(dateType)) {
        return dateType === Constants.DATE_TYPE_VALUES.RELATIVE ? Constants.REQUIRED_FIELDS_BY_RATES : Constants.REQUIRED_FIELDS_BY_RATES.filter((field: string) => field !== Constants.FIELD_NAMES.APPLICATION_INTERVAL);
      }
    }
    return Constants.REQUIRED_FIELDS_BY_RATES;
  }

  checkMissingDataForApplicationsXApplications(run: Run, rates:{ [key: string]: string[] }): string[] {
    let missingColumnsByApplications:  string[] = [];
    if(run.applicationSchemeData?.hasVariableRates && Constants.MODELS_FOR_VARIABLE_RATES.includes(run.model!.name)){
      let columnsToValidate = this.getApplicationColumnsToBeValidated(run);
      run.applicationSchemeData?.applicationSchemeXApplication?.forEach((application: ApplicationSchemeXApplication) => {
        columnsToValidate.forEach((field: string) => {
          if (Utils.isEmptyValue(application[field as keyof ApplicationSchemeXApplication])) {
            missingColumnsByApplications.push(field)
          }
        });
        this.validateRatesInApplication(application, rates);
      });
    }
    return missingColumnsByApplications;
  }

  checkMissingDataForApplicationsXApplicationsForPWC(run: Run, rates:{ [key: string]: string[] }): string[] {
    let missingColumnsByApplications:  string[] = [];
    run.applicationSchemeDataList?.forEach((scheme: any) => {
      if(scheme?.hasVariableRates && Constants.MODELS_FOR_VARIABLE_RATES.includes(run.model!.name)){
        let columnsToValidate = this.getApplicationColumnsToBeValidated(run);
        run.applicationSchemeData?.applicationSchemeXApplication?.forEach((application: ApplicationSchemeXApplication) => {
          columnsToValidate.forEach((field: string) => {
            if (Utils.isEmptyValue(application[field as keyof ApplicationSchemeXApplication])) {
              missingColumnsByApplications.push(field)
            }
          });
          this.validateRatesInApplicationForPWC(application, rates);
        });
      }      
    });
    return missingColumnsByApplications;
  }

  getApplicationColumnsToBeValidated(run: Run): string[] {
    if (Constants.DATE_TYPE_EXCEPTION_MODELS.includes(run.model!.name)) {
      let dateType = run?.applicationSchemeData?.dateType;
      let appColumns = run.model!.name === Constants.MODELS.MACRO_GW || run.model!.name === Constants.MODELS.PEARL || run.model!.name === Constants.MODELS.PELMO ? Constants.REQUIRED_APPLICATIONS_FIELDS.filter((field: string) => field !== Constants.EEA_APPLICATIONS_FIELDS.DAYS_SINCE) : Constants.REQUIRED_APPLICATIONS_FIELDS;
      if (!Utils.isEmptyValue(dateType)) {
        return dateType === Constants.DATE_TYPE_VALUES.RELATIVE ? appColumns : appColumns.filter((field: string) => field !== Constants.EEA_APPLICATIONS_FIELDS.DAYS_SINCE);
      }
    }
    return [];
  }

  validateAppSchemeRootFields(run: Run): string[] {
    let rootFields: string[] = [];

    if (Utils.isEmptyValue(run.applicationSchemeData?.numberOfApplications)) {
      rootFields.push(Constants.FIELD_NAMES.NUMBER_OF_APPLICATIONS);
    }

    return rootFields;
  }

  convertFieldToHeaderName(field: string) {
    return Constants.HEADER_NAMES_BY_FIELD[field]
  }

  insertDynamicColumnDefs() {
    this.columnsDef = [...this.qcColdef.getQcColumnsDefinition(), ...this.createDynamicColDef()]
  }

  insertPWCDynamicColumnDefs() {
    this.columnsDef = [...this.qcColdef.getPWCQcColumnsDefinition(), ...this.createDynamicColDef()]
  }

  createDynamicColDef(): any[] {
    let columnsDef: ColDef[] = [];
    this.columnsToShow?.forEach((column: string) => {
      columnsDef.push(
        {
          headerName: this.convertFieldToHeaderName(column),
          field: column,
          type: 'default',
          filter: 'agSetColumnFilter',
          width: 100,
          maxWidth: 110,
          hide: false,
          editable: false,
          wrapText: true,
          wrapHeaderText: true,
          cellRenderer: QcIconComponent,
          cellRendererParams: (params: any) => {
            return {
              instance: this,
            };
          },
        }
      );
    });
    return columnsDef;
  }

  showQcResult() {
    this.missingDataRows.length > 0 ? this.showGrid = true : this.showAlert = true;
  }
}