import { Component, DestroyRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Project } from '../../models/project';
import { CustomTabMenuItem } from '../../models/custom-tab-menu-item';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.css'],
  providers: [MessageService]
})
export class WizardComponent implements OnChanges {

  @Input() items: CustomTabMenuItem[] = []
  @Input() message!: Message;
  @Input() loading: boolean = false;
  @Input() activeItem!: any;
  @Input() selectedProject?: Project;
  @Input() selectedProjectStatus?: number;
  @Input() isSetupValid: boolean = false;
  @Input() isESAUDL: boolean = false;


  @Output() activeIndexChangeEmitter: EventEmitter<number> = new EventEmitter<number>();

  destroyRef = inject(DestroyRef)

  constructor(private messageService: MessageService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['message']?.currentValue) {
      this.messageService.add(this.message);
    }
  }

  onActiveIndexChange({ index }: any) {
    this.activeIndexChangeEmitter.emit(index);
  }
}
