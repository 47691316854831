
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0&icon_names=delete" />
<div class="border-bottom-1 font-bold mb-2">
    <label>Models to be run*</label>
</div>
<p-accordion #accordion [multiple]="false" [activeIndex]="activeIndex" (onClose)="onClose($event)" (onOpen)="onOpen($event)">
    <p-accordionTab [selected]="true">
        <ng-template pTemplate="icon">
            <app-button [text]="true" [plain]="true" [size]="'small'" [icon]="'pi pi-angle-down'" [iconPos]="'right'" [disabled]=true></app-button>
        </ng-template>
        <!-- Header -->
        <ng-template class="flex" pTemplate="header">
            <div class="ml-2 flex align-items-center text-color">
                <span class="vertical-align-middle">{{modelHeader}}</span>
            </div>
            <div class="flex align-items-end">
                <app-button [text]="true" [disabled]="true" [plain]="true" [icon]="'pi pi-check-square'" [iconPos]="'right'"></app-button>
            </div>
        </ng-template>

        <!-- Content -->
        <ng-template pTemplate="content">
          <div class="formgrid grid" >
            <div class="col-3 font-bold mr-4">
                <app-input-text  [disabled]="!selectedProject || !isProjectOwnershipValid" [label]="'Chemical'" [useTitle]="true" [model]="chemicalName" [isRequired]="true" [isValid]="isChemicalNameValid" (onChangeEvent)="chemicalNameChanged($event)"></app-input-text>
            </div>
            <div class="col-3 font-bold mr-4">
              <app-dropdown
                [label]="'Species Habitat'"
                [options]="getOptionsByModel(modelHeader, speciesHabitatOptions)"
                optionLabel="long_value"
                [model]="selectedSpeciesHabitat"
                [isRequired]="true"
                [hardDisabled]="!selectedProject || !isProjectOwnershipValid"
                [showClear]="true"
                [placeholder]="'Select a Species Habitat'"
                [isValid]="isSpeciesHabitatValid"
                (onChangeEvent)="speciesHabitatSelectionChanged($event)">
              </app-dropdown>
            </div>
            <div class="col-2 font-bold mr-4">
              <app-dropdown
                [label]="'Include Drift'"
                [isRequired]="true"
                [hardDisabled]="!selectedProject || !isProjectOwnershipValid"
                [model]="includeDrift"
                [options]="['Yes', 'No']"
                [showClear]="true"
                [isValid]="isIncludeDriftValid"
                [placeholder]="'Select'"
                (onChangeEvent)="includeDriftSelectionChanged($event)">
              </app-dropdown>
            </div>
            <div class="col-3 font-bold">
              <label [ngClass]="!isRegionsToIncludeValid ? 'lbl-invalid' : 'lbl-valid'">Regions to Include*</label>
              <div>
                <p-multiSelect
                  class="w-12"
                  [ngClass]="!isRegionsToIncludeValid ? 'dd-invalid' : 'dd-valid'"
                  [disabled]="!selectedProject || !isProjectOwnershipValid"
                  [options]="getOptionsByModel(modelHeader, regionsToIncludeOptions)"
                  appendTo="body"
                  [placeholder]="regionsToIncludePlaceholder"
                  (onChange)="regionsToIncludeSelectionChanged($event)"
                  (onSelectAllChange)="regionsToIncludeSelectAllChanged($event)"
                  [selectAll]="selectAllRegions"
                  [(ngModel)]="selectedRegionsToInclude"
                  [optionLabel]="'short_value'"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="28"></p-multiSelect>
              </div>
            </div>
          </div>

          <div class="formgrid grid mt-6 mb-6">
            <div class="col-3 font-bold mr-4">
              <label [ngClass]="!isStatesToIncludeValid && isStatesToIncludeRequired ? 'lbl-invalid' : 'lbl-valid'">{{statesToIncludeLabel}}</label>
              <div>
                <p-multiSelect
                  class="w-12"
                  [ngClass]="!isStatesToIncludeValid && isStatesToIncludeRequired ? 'dd-invalid' : 'dd-valid'"
                  [disabled]="!selectedProject || !isProjectOwnershipValid || !isStatesToIncludeRequired"
                  [options]="getOptionsByModel(modelHeader, statesToIncludeOptions)"
                  appendTo="body"
                  [placeholder]="statesToIncludePlaceholder"
                  (onChange)="statesToIncludeSelectionChanged($event)"
                  (onSelectAllChange)="statesToIncludeSelectAllChanged($event)"
                  [selectAll]="selectAllStates"
                  [(ngModel)]="selectedStatesToInclude"
                  [optionLabel]="'long_value'"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="28"></p-multiSelect>
              </div>
            </div>
            <div class="col-3 font-bold mr-4">
              <label [ngClass]="!isBufferDistancesValid ? 'lbl-invalid' : 'lbl-valid'">Buffer Distances (m)*</label>
              <div>
                <p-multiSelect
                  class="w-12"
                  [ngClass]="!isBufferDistancesValid ? 'dd-invalid' : 'dd-valid'"
                  [options]="getOptionsByModel(modelHeader, bufferDistancesOptionsWithSettings)"
                  [disabled]="!selectedProject || !isProjectOwnershipValid"
                  appendTo="body"
                  [placeholder]="bufferDistancesPlaceholder"
                  (onChange)="bufferDistancesChanged($event.value)"
                  (onSelectAllChange)="bufferDistancesSelectAllChanged($event)"
                  [selectAll]="selectAllBufferDistances"
                  [(ngModel)]="selectedBufferDistances"
                  [optionLabel]="'short_value'"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="28"></p-multiSelect>
              </div>
            </div>
            <div class="col-3 font-bold mr-4">
              <label [ngClass]="!isOnOffFieldConsiderationsValid ? 'lbl-invalid' : 'lbl-valid'">On/Off Field Considerations*</label>
              <div>
                <p-multiSelect
                  class="w-12"
                  [ngClass]="!isOnOffFieldConsiderationsValid ? 'dd-invalid' : 'dd-valid'"
                  [options]="getOptionsByModel(modelHeader, onOffFieldConsiderationsOptions)"
                  [disabled]="!selectedProject || !isProjectOwnershipValid"
                  appendTo="body"
                  [placeholder]="onOffFieldConsiderationsPlaceholder"
                  (onChange)="onOffFieldConsiderationsChanged($event.value)"
                  (onSelectAllChange)="onOffFieldConsiderationsSelectAllChanged($event)"
                  [selectAll]="selectAllOnOffFieldConsiderations"
                  [(ngModel)]="selectedOnOffFieldConsiderations"
                  [optionLabel]="'short_value'"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="30"></p-multiSelect>
              </div>
            </div>
            <div class="col-2 font-bold">
              <app-dropdown
                [label]="'Adjust for PCT'"
                [isRequired]="true"
                [hardDisabled]="!selectedProject || !isProjectOwnershipValid"
                [model]="adjustForPCT"
                [options]="['Yes', 'No']"
                [showClear]="true"
                [isValid]="isAdjustForPCTValid"
                [placeholder]="'Select'"
                (onChangeEvent)="adjustForPCTSelectionChanged($event)">
              </app-dropdown>
            </div>
          </div>
          <div class="formgrid grid mt-6 mb-6">
            <div class="col-3 font-bold">
              <label [ngClass]="!isCropUseFileValid ? 'lbl-invalid' : 'lbl-valid'">Crop Use* (This must be a .csv file)</label>
              <div class="file-upload-container mt-2 mb-2">
                <input type="file" (change)="onFileSelected($event, '.csv', 'isCropUseFileValid')" accept=".csv" [disabled]="!selectedProject || !isProjectOwnershipValid" #cropUseFileSelected>
              </div>
              <div *ngIf="uploadedCropFilename != undefined && uploadedCropFilename.length > 0" class="mt-2 mb-2 row-1">
                <button class="p-button p-button-link" type="button" role="button" (click)="this.downloadProjectFile(uploadedCropFilename)" [disabled]="!selectedProject || !isProjectOwnershipValid">{{uploadedCropFilename}}</button>
                <button class="p-button p-component p-button-danger" (click)="this.markProjectFileForDeletion(uploadedCropFilename)" [disabled]="!selectedProject || !isProjectOwnershipValid">
                  <i class="pi pi-trash"></i>
                </button>
              </div>
              <p-button styleClass="standard-button" class="p-button-outlined" label="Download Crop Use" (click)="udlOverlapToolContainerService.downloadDefaultCropUseFile()" [disabled]="!selectedProject || !isProjectOwnershipValid"></p-button>
            </div>
            <div class="col-3 font-bold">
              <label [ngClass]="isPCT ? (!isPCTValuesFileValid ? 'lbl-invalid' : 'lbl-valid') : 'lbl-valid'">PCT Values{{ isPCT ? '*' :'' }} (This must be a .csv file)</label>
              <div class="file-upload-container mt-2 mb-2">
                <input type="file" (change)="onFileSelected($event, '.csv', 'isPCTValuesFileValid')" accept=".csv" [disabled]="!selectedProject || !isProjectOwnershipValid || !isPCT" #pctValuesFileSelected>
              </div>
              <div *ngIf="uploadedPCTFilename != undefined && uploadedPCTFilename.length > 0" class="mt-2 mb-2 row-1">
                <button class="p-button p-button-link" type="button" role="button" (click)="this.downloadProjectFile(uploadedPCTFilename)" [disabled]="!selectedProject || !isProjectOwnershipValid">{{uploadedPCTFilename}}</button>
                <button class="p-button p-component p-button-danger" (click)="this.markProjectFileForDeletion(uploadedPCTFilename)" [disabled]="!selectedProject || !isProjectOwnershipValid">
                  <i class="pi pi-trash"></i>
                </button>
              </div>
              <p-button styleClass="standard-button" class="p-button-outlined" label="Download PCT" [disabled]="!selectedProject || !isProjectOwnershipValid || !isPCT" (click)="udlOverlapToolContainerService.downloadDefaultPCTFile()"></p-button>
            </div>
            <div class="col-3 font-bold">
              <label [ngClass]="this.isOnAndOffConsiderationsFileSelected ? (!isOnAndOffConsiderationsFileValid ? 'lbl-invalid' : 'lbl-valid') : 'lbl-valid'">On/Off Considerations (This must be a .xlsx file)</label>
              <div class="file-upload-container mt-2 mb-2">
                <input type="file" (change)="onFileSelected($event, '.xlsx', 'isOnAndOffConsiderationsFileValid', 'OnAndOffConsiderationsFile')" accept=".xlsx" [disabled]="!selectedProject || !isProjectOwnershipValid" #onAndOffConsiderationsFileSelected>
              </div>
              <div *ngIf="uploadedOnOffConsiderationsFilename != undefined && uploadedOnOffConsiderationsFilename.length > 0" class="mt-2 mb-2 row-1">
                <button class="p-button p-button-link" type="button" role="button" (click)="this.downloadProjectFile(uploadedOnOffConsiderationsFilename)" [disabled]="!selectedProject || !isProjectOwnershipValid">{{uploadedOnOffConsiderationsFilename}}</button>
                <button class="p-button p-component p-button-danger" (click)="this.markProjectFileForDeletion(uploadedOnOffConsiderationsFilename)" [disabled]="!selectedProject || !isProjectOwnershipValid">
                  <i class="pi pi-trash"></i>
                </button>
              </div>
              <p-button styleClass="standard-button" class="p-button-outlined" label="Download On/Off" (click)="udlOverlapToolContainerService.downloadDefaultOnOffConsiderationsFile()" [disabled]="!selectedProject || !isProjectOwnershipValid"></p-button>
            </div>
          </div>
          <div class="border-bottom-1 font-bold mb-2">
            <label>Summary Report Specifications</label>
          </div>
          <div class="formgrid grid mt-6 mb-6">
            <div class="col-3 font-bold mr-4">
              <app-dropdown
                [label]="'Buffer Distance (m) to use in the Summary Report'"
                [options]="getOptionsByModel(modelHeader, bufferDistanceInSummaryReportOptions)"
                optionLabel="short_value"
                [hardDisabled]="!selectedProject || !isProjectOwnershipValid"
                [model]="selectedBufferDistanceInSummaryReport"
                [isRequired]="true"
                [showClear]="true"
                [placeholder]="'Select a Buffer Distance'"
                [isValid]="isBufferDistanceInSummaryReportValid"
                (onChangeEvent)="bufferDistanceInSummaryReportSelectionChanged($event.value)">
              </app-dropdown>
            </div>
            <div class="col-3 font-bold">
              <label [ngClass]="isObligateAndIndirectRelationshipFileSelected ? (!isObligateAndIndirectRelationshipFileValid ? 'lbl-invalid' : 'lbl-valid') : 'lbl-valid'">Obligate/Indirect Relationship (This must be a .csv file)</label>
              <div class="file-upload-container mt-2 mb-2">
                <input type="file" (change)="onFileSelected($event, '.csv', 'isObligateAndIndirectRelationshipFileValid', 'ObligateAndIndirectRelationshipFile')" accept=".csv" [disabled]="!selectedProject || !isProjectOwnershipValid" #obligateAndIndirectRelationshipFileSelected>
              </div>
              <div *ngIf="uploadedObligateAndIndirectRelationshipFilename != undefined && uploadedObligateAndIndirectRelationshipFilename.length > 0" class="mt-2 mb-2 row-1">
                <button class="p-button p-button-link" type="button" role="button" (click)="this.downloadProjectFile(uploadedObligateAndIndirectRelationshipFilename)" [disabled]="!selectedProject || !isProjectOwnershipValid">{{uploadedObligateAndIndirectRelationshipFilename}}</button>
                <button class="p-button p-component p-button-danger" (click)="this.markProjectFileForDeletion(uploadedObligateAndIndirectRelationshipFilename)" [disabled]="!selectedProject || !isProjectOwnershipValid">
                  <i class="pi pi-trash"></i>
                </button>
              </div>
              <p-button styleClass="standard-button" class="p-button-outlined" label="Download Relationships" (click)="udlOverlapToolContainerService.downloadDefaultIndirectRelationshipsFile()" [disabled]="!selectedProject || !isProjectOwnershipValid"></p-button>
            </div>
          </div>
          <div class="formgrid grid mt-6 mb-6 clear-button">
            <div class="col-12">
              <p-button [disabled]="false" styleClass="standard-button" class="p-button-outlined mr-6" label="Clear Model Inputs" (onClick)="clearModelInputs()" [disabled]="!selectedProject || !isProjectOwnershipValid"></p-button>
            </div>
          </div>
        </ng-template>
    </p-accordionTab>
</p-accordion>
<app-loading class="flex align-items-center justify-content-center" *ngIf="modelsLoading"></app-loading>
