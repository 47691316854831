<div class="status-container grid" style="margin-bottom: -40px;">
    <div class="col-7"></div>
    <div class="col-5" style="align-items: end; padding-top: 13px; padding-left: 80px; display: contents;">
        <div class="col-2" style="display: grid;">
            <span>Project Status: &nbsp; <strong>{{ statusName }}</strong> &nbsp;
                <p-badge pTooltip="{{selectedProject?.description}}" tooltipPosition="top"
                    styleClass="{{badgeStyle}}"></p-badge>
            </span>&nbsp;
            <span *ngIf="showElapsedTime()">Elapsed Time (HH:MM): &nbsp; <strong>{{
                    projectElapsedTime }}</strong></span>&nbsp;
        </div>
        <div class="col" style="margin-left: 20px;">
            <app-button *ngIf="showMetricsButton()" label="Metrics" iconPos="right" [outlined]="true"
                severity="secondary" icon="pi pi-chart-bar" (onClickEvent)="onMetricsClick()"></app-button>
            &nbsp;
            <app-button iconPos="right" [outlined]="true" severity="secondary" tooltipPosition="top"
                pTooltip="Stop the project" [icon]="isStoping ? 'pi pi-spin pi-spinner' : 'pi pi-stop-circle'"
                *ngIf="showStopProjectButton()" (onClickEvent)="onStopProjectClick()"></app-button>
            &nbsp;
            <app-button label="Summary" iconPos="right" [outlined]="true" severity="secondary"
                [icon]="isSummaryDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'" *ngIf="showDownloadButton()"
                (onClickEvent)="onDownloadOutputFilesClick(false)"></app-button>
            &nbsp;
            <app-button label="All files" iconPos="right" [outlined]="true" severity="secondary"
                [icon]="isAllFilesDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'" *ngIf="showDownloadButton()"
                (onClickEvent)="onDownloadOutputFilesClick(true)"></app-button>
        </div>
    </div>
    <p-dialog header="Select the output data files to download" [(visible)]="downloadOutputFilesVisible" [modal]="true"
        [style]="{ width: '29vw'}" [draggable]="true" [resizable]="true">
        <app-grid #grid [columnDefs]="columnDefs" [rowData]="rowData" [rowSelection]="'multiple'" [style]="gridStyle"
            [paginationPageSize]="paginationPageSize">
        </app-grid>
        <ng-template pTemplate="footer">
            <p-button label="Download File(s)" iconPos="right" icon="pi pi-download"
                (click)="onDownloadSelectedRowsClick()" pAutoFocus [autofocus]="true"></p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="metricsVisible" [modal]="true" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
        [style]="{ width: '45vw' }" [draggable]="true" [resizable]="true">
        <ng-template pTemplate="header">
            <div class="inline-flex items-center justify-center gap-2">
                <img src="assets/img/infoIcon.png" (click)="showInfoPopup()"
                    style="width: 16px; height: 16px; margin-left: 8px; cursor: pointer;">
                <span class="font-bold" style="font-size: 1.25rem;">
                    Metrics
                </span>
            </div>
        </ng-template>
        <div id="myGrid" style="width: 100%; height: 350px;">
            <app-grid #metricsGrid [columnDefs]="metricsColumnDefs" [rowData]="metricsRowData"
                [style]="metricsGridStyle" [height]="350" [paginationPageSize]="metricsPaginationPageSize"
                [autoGroupColumnDef]="autoGroupColumnDef" [groupDisplayType]="groupDisplayType" [domLayout]="domLayout">
            </app-grid>
        </div>

    </p-dialog>
</div>