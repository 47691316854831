<div class="centerInGrid">
    <p-overlayPanel #op>
        <ng-template pTemplate="content">
            <ol class="two-column-list">
              <li *ngFor="let value of values">{{value}}</li>
            </ol>
        </ng-template>
      </p-overlayPanel>

    <app-button 
        [label]="label" 
        [severity]="'warning'" 
        [disabled]="disabled" [outlined]="false" 
        (onClickEvent)="onClick()"
        (mouseenter)="op.toggle($event); onMouseEnter()"
        (mouseleave)="op.hide()"
    ></app-button>
</div>