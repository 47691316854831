<p-listbox 
    class="w-12" 
    [options]="options" 
    [optionLabel]="optionLabel" 
    [(ngModel)]="selectedValues" 
    [checkbox]="checkbox" 
    [filter]="filter"
    [multiple]="multiple" 
    [showToggleAll]="showToggleAll" [disabled]="disabled" [listStyle]="{'height':'175px'}"
    (onChange)="onChange()">
</p-listbox>