<p-tabMenu [model]="items" [activeItem]="activeItem" (activeItemChange)="onActiveItemChange($event)">
    <ng-template *ngIf="useTemplate" pTemplate="item" let-item>
        <a class="p-menuitem-link flex justify-content-between align-items-center p-2 pr-4 text-xl" [style]="item.styleIndex">
            <div class="mr-2 ml-3">
                <span class="material-symbols-rounded">{{item.icon}}</span>
            </div>
            <div class="mr-3">
                <span> {{ item.label }}</span>
            </div>
        </a>
    </ng-template>
    <ng-template *ngIf="!useTemplate" pTemplate="item" let-item>
        <a class="p-menuitem-link flex justify-content-between align-items-center p-2 pr-4 text-xl" [style]="item.styleIndex">
            <div class="mr-3">
                <span> {{ item.label }}</span>
            </div>
        </a>
    </ng-template>
</p-tabMenu>