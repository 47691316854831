<div *ngIf="!isNotStarted">
    <div class="grid" *ngIf="!isLoading" style="margin-bottom: -11px;">
        <p-accordion class="col-12">
            <p-accordionTab header="{{headerNonDietary}}">
                <ng-template pTemplate="content" *ngIf="!isUSA">
                    <div class="col-12">
                        <app-hhra-run-risk-assessment-general-buttons [isUSA]="isUSA" [selectedProject]="selectedProject"
                            (reRun)="refreshData()" (downloadFiles)="onDownloadClick()"
                            [isRefreshingIcon]="isRefreshingIcon" [isAllFilesDownloading]="isAllFilesDownloading"
                            [projectStatusStyle]="projectStatusStyle"></app-hhra-run-risk-assessment-general-buttons>
                        <app-hhra-run-risk-assessment-opex-result (isProjectRunning)="isProjectRunningQC()" (status)="closeModal($event)"
                            [selectedProject]="selectedProject" [subTabMenuService]="menuService"
                            [isProjectOwnershipValid]="isProjectOwnershipValid"></app-hhra-run-risk-assessment-opex-result>
                    </div>
                </ng-template>
                <p-tabView *ngIf="isUSA">
                    <p-tabPanel class="mb-2" header="{{occupationalHandlerExposure}}">
                        <app-hhra-run-risk-assessment-general-buttons [isUSA]="isUSA" [selectedProject]="selectedProject"
                            (reRun)="refreshData()" (downloadFiles)="onDownloadClick()"
                            [isRefreshingIcon]="isRefreshingIcon" [isAllFilesDownloading]="isAllFilesDownloading"
                            [projectStatusStyle]="projectStatusStyle"></app-hhra-run-risk-assessment-general-buttons>
                        <app-hhra-run-risk-assessment-occupational-handler-exposure (isProjectRunning)="isProjectRunningQC()" (status)="closeModal($event)"
                            [selectedProject]="selectedProject" [subTabMenuService]="menuService"
                            [isProjectOwnershipValid]="isProjectOwnershipValid"
                           ></app-hhra-run-risk-assessment-occupational-handler-exposure>
                    </p-tabPanel>
                    <p-tabPanel class="mb-2" header="{{occupationalReEntryExposure}}">

                    </p-tabPanel>
                    <p-tabPanel class="mb-2" header="{{ResidentialExposure}}">

                    </p-tabPanel>
                </p-tabView>
            </p-accordionTab>
            <p-accordionTab header="{{headerDietary}}">
                <ng-template pTemplate="content">
                    <div class="col-12">

                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
<div *ngIf="isNotStarted">
    <p-card *ngIf="showWarningAlert" [style]="{ width: '30%' }" class="flex align-items-center justify-content-center">
        <ng-template pTemplate="header">
            <span class="pi pi-info flex align-items-center justify-content-center mt-3"
                style="color: #F59E0B; font-size: 4.5em;"></span>
        </ng-template>
        <ng-template pTemplate="title" class="flex align-items-center justify-content-center">
            <p class="flex align-items-center justify-content-center">{{warningAlertText}}</p>
        </ng-template>
    </p-card>
</div>
<app-loading class="mt-2 flex align-items-center justify-content-center" *ngIf="isLoading && !isWarningAlertShown"></app-loading>