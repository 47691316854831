import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Project } from '../../models/project';
import { Constants } from '../../utils/constants';
import { CustomTabMenuItem } from '../../models/custom-tab-menu-item';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.css']
})
export class TabMenuComponent implements OnChanges {

  @Input() items: CustomTabMenuItem[] = [];
  @Input() selectedProject?: Project;
  @Input() selectedProjectStatus?: number;
  @Input() isSetupValid: boolean = false;
  @Input() activeItem: MenuItem | undefined;
  @Input() isESAUDL:boolean = false;

  useTemplate = false;

  @Output() onActiveIndexChangeEvent = new EventEmitter<MenuItem>();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['items'])
    {
      if(this.items.filter(item => item.visible).some(item=> item.useNumberIcon)){
        this.useTemplate = true;
        this.setNumericIcons();
      }else{
        this.setColorFormat();
      }
    }
    if(changes[Constants.SELECTED_PROJECT])
    {
      this.validateSelectedProject();
    }
    if (changes[Constants.IS_SETUP_VALID]) {
      this.validateSetup();
    }
    if (changes[Constants.SELECTED_PROJECT_STATUS]) {
      this.validateSelectedProject();
    }
  }

  setColorFormat(){
    this.items = this.items.map((item) => {
      item.styleIndex = `background: none; background-color: unset !important; color: #707070 !important;`
      return item;
    });

  }

  setNumericIcons(){
    let counter = this.items.length;
    this.items = this.items.filter(item => item.visible).map((item, index) => {
      const number = index + 1
      item.icon = `counter_${number}`;
      item.styleIndex = `z-index: 9${counter}`
      counter--;
      return item;
    });
  }

  onActiveItemChange(item: MenuItem)
  {
    this.onActiveIndexChangeEvent.emit(item);
  }

  validateSelectedProject() {
    if (this.selectedProject) {
      const originalItems = [...this.items];
      let setupItem = originalItems.find(x => x.label === Constants.EEA_MENU_LABELS.Setup);
      if (setupItem)
        setupItem.disabled = false;
      this.items = originalItems;
      this.validateRunProjectTab();
    }
  }

  validateSetup() {
    const originalItems = [...this.items];
    originalItems.map(item => {
      if (this.isESAUDL && item.label?.localeCompare("Setup", undefined, { sensitivity: 'base' }) === 0){
        return item;
      }
      item.disabled = !this.isSetupValid;
      return item;
    });
    this.items = originalItems;
    this.validateRunProjectTab();
  }

  validateRunProjectTab(): void {
    const originalItems = [...this.items];
    let runProjectItem = originalItems.find(x => x.label === Constants.EEA_MENU_LABELS.Run_Project);
    if (runProjectItem)
      runProjectItem.disabled = (this.selectedProject && (this.selectedProject.status === Constants.PROJECT_STATUS_ID.IN_PROGRESS || this.selectedProject.status === Constants.PROJECT_STATUS_ID.IN_QUEUE)) || !this.isSetupValid;
    this.items = originalItems;
  }
}
