<div class="pt-3 pr-5 pb-5 pl-5">
    <div class="col-12 w-12">
        <app-wizard class="surface-200" [items]="tabsToDisplay" [message]="message" [loading]="wizardLoading" [activeItem]="activeItem"  [isSetupValid]="isEraEcotoxDatasetValid" (activeIndexChangeEmitter)="onActiveIndexChange($event)"></app-wizard>
    </div>

    <div class="p-3 col-12 w-12">
        <ng-container *ngIf="activeItem.index == 0">
            <app-era-ecotox-datasets [subTabMenuService]="ERAEndpointsLogicService" [menuService]="menuService" [selectedProject]="selectedProject" [taxonGroup]="taxonGroups.AQUATIC" [isProjectOwnershipValid]="isProjectOwnershipValid" (isValid)="getIsEraEcotoxDatasetValid($event)"></app-era-ecotox-datasets>
        </ng-container>
        <ng-container *ngIf="activeItem.index == 1">
            <app-era-ecotox-datasets [subTabMenuService]="ERAEndpointsLogicService" [menuService]="menuService"  [selectedProject]="selectedProject" [taxonGroup]="taxonGroups.TERRESTRIAL_VERTEBRATE" [isProjectOwnershipValid]="isProjectOwnershipValid" (isValid)="getIsEraEcotoxDatasetValid($event)"></app-era-ecotox-datasets>
        </ng-container>
        <ng-container *ngIf="activeItem.index == 2">
            <app-era-ecotox-datasets [subTabMenuService]="ERAEndpointsLogicService" [menuService]="menuService" [selectedProject]="selectedProject" [taxonGroup]="taxonGroups.BEES" [isProjectOwnershipValid]="isProjectOwnershipValid" (isValid)="getIsEraEcotoxDatasetValid($event)"></app-era-ecotox-datasets>
        </ng-container>
        <ng-container *ngIf="activeItem.index == 3">
            <app-era-ecotox-datasets [subTabMenuService]="ERAEndpointsLogicService" [menuService]="menuService" [selectedProject]="selectedProject" [taxonGroup]="taxonGroups.ARTHROPODS" [isProjectOwnershipValid]="isProjectOwnershipValid" (isValid)="getIsEraEcotoxDatasetValid($event)"></app-era-ecotox-datasets>
        </ng-container>
       
        <div *ngIf="activeItem.index === undefined && tabsToDisplay.length > 0" class="flex justify-content-center text-orange-500 text-xl mt-5">
            <span><i class="pi pi-exclamation-triangle" style="font-size: 1.5rem"></i> Select a tab above <i class="pi pi-arrow-up" style="font-size: 1rem"></i></span>
        </div>
        <div *ngIf="!tabsToDisplay || tabsToDisplay.length == 0" class="flex justify-content-center text-orange-500 text-xl">
            <span><i class="pi pi-exclamation-triangle" style="font-size: 1.5rem"></i>  No options to diplay, please change the project setup.</span>
        </div>
    </div>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading>
