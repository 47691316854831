export enum TrexEndpointTypes {
  AvianLD50            = 'Avian LD50 (mg/kg-bw)',
  AvianLC50            = 'Avian LC50 (mg/kg-diet)',
  AvianNOAEL           = 'Avian NOAEL (mg/kg-bw)',
  AvianNOAEC           = 'Avian NOAEC (mg/kg-diet)',
  MammalianLD50        = 'Mammalian LD50 (mg/kg-bw)',
  MammalianLC50        = 'Mammalian LC50 (mg/kg-diet)',
  MammalianChronicBw   = 'Mammalian Chronic (mg/kg-bw)',
  MammalianChronicDiet = 'Mammalian Chronic (mg/kg-diet)'
}

export enum TrexEndpointsUnits {
  MgKgBw            = 'mg/kg-bw',
  MgKgDiet          = 'mg/kg-diet'
}

export enum BeeRexEndpointTypes {
  AdultContactLD50 = 'Adult contact LD50',
  AdultOralLD50    = 'Adult oral LD50',
  AdultOralNOAEL   = 'Adult oral NOAEL',
  LarvalLD50       = 'Larval LD50',
  LarvalNOAEL      = 'Larval NOAEL'
}
