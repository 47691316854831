import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarRenderer implements ICellRendererAngularComp {
  dateValue?: Date;
  params: any;
  parent: any;
  highlightDate!: Date;
  disabled: boolean = false;
  columnThatModifies!: string;
  showTodayDate?: Boolean;
  dateFormat?: string;
  showCopyToAll:boolean = false;
  readOnlyDateFormat?: string;

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.parent = params.context ? params.context : params.instance;
    this.dateFormat = params.format ? params.format : 'dd-M';
    this.readOnlyDateFormat = params.readOnlyFormat ? params.readOnlyFormat : 'dd-MMM';
    this.disabled = params.disabled;
    this.columnThatModifies = params.colDef?.field;
    this.highlightDate = this.params?.highlightDate;
    if (this.params.node.data[this.columnThatModifies]) {
      this.dateValue = new Date(
        this.params.node.data[this.columnThatModifies]
      );
    }
    this.showTodayDate = params?.defaultDate;
    this.showCopyToAll = params?.showCopyToAll;
  }

  public onSelect(): void {
    this.params.data[this.columnThatModifies] = this.dateValue;
    this.params.data.modified = true;
    this.params.api.refreshCells({
      rowNodes: [this.params.node], 
      columns: ['julian'], 
      force: true,
    });
    this.parent.calendarSelectionChanged({
      field: this.columnThatModifies,
      id: this.params.node.id,
      row: this.params.data,
      value: this.dateValue,
      parentId: this.params.node.parent.id,
      
    });
   
  }

  public onCopyAllClick(): void {
    if (!this.dateValue) {
      return;
    }
    this.params.api.forEachNode((node: any) => {
      node.data[this.columnThatModifies] = this.dateValue;
      node.data.modified = true; 
    });
  
    this.params.api.refreshCells({ force: true });
  }
}
