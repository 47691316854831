import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
  computed,
} from '@angular/core';
import { EEAGapPWCSurfaceWaterColdef } from './eea-gap-surfacewater-pwc.coldef';

import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { ColDef } from 'ag-grid-community';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { CropEvents } from 'src/app/shared/models/pwc/crop-events';
import { firstValueFrom, take } from 'rxjs';

import {
  ApplicationScheme,
  ApplicationSchemeCropState,
  ApplicationSchemeXApplication,
} from 'src/app/shared/models/application-scheme';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridLogicService } from 'src/app/shared/components/grid/grid.logic.service';
import { SimulationType } from 'src/app/shared/models/pwc/simulation-type';
import { CropInterceptionStep1And2 } from 'src/app/shared/models/crop-interception';
import { CropListMatching } from 'src/app/shared/models/crop-list-matching';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { EEAGapLogicService } from '../eea-gap.logic.service';
import { EEAGapApiService } from '../eea-gap.api.service';
import { EEAGAPMenuLogicService } from '../eea-gap/eea-gap-menu.logic.service';
import { Utils } from 'src/app/shared/utils/utils';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';
import { SaveStructure } from 'src/app/shared/models/transaction-type';
import { EeaGapSurfacewaterPwcScenariosLogicService } from './eea-gap-surfacewater-pwc-scenarios/eea-gap-surfacewater-pwc-scenarios.logic.service';
import { SelectedScenariosByRow } from 'src/app/shared/models/pwc/scenarios.interface';
import { DriftTypes } from 'src/app/shared/models/pwc/drift-types';
import { ApplicationMethods } from 'src/app/shared/models/pwc/application-methods';

@Component({
  selector: 'app-eea-gap-surfacewater-pwc',
  templateUrl: './eea-gap-surfacewater-pwc.component.html',
  styleUrls: ['./eea-gap-surfacewater-pwc.component.css'],
})
export class EEAGapSurfacewaterPwcComponent implements OnInit, OnChanges {
  @ViewChild('grid') grid!: GridComponent;

  @Input() menuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() useModels!: boolean;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() selectedProject?: Project;

  @Output() public isValid = new EventEmitter<boolean>();

  public detailsColumnDef: any = [];
  public defaultColDef: ColDef = { flex: 2 };
  public selectedRow?: ApplicationScheme;
  public showScenarios = computed(() => this.scenariosLogicService.visible());

  columnsDef: any;
  saveRef?: string;
  rowData: any;
  compartmentList!: any[];
  datafieldOptions!: any[];
  geographies!: Catalog[];
  cropInterceptionsStep1And2!: CropInterceptionStep1And2[];
  applicationSchemeCropsStates: ApplicationSchemeCropState[] = [];
  missingDataRows: any[] = [];
  cropListMatchings!: CropListMatching[];
  numbersOfApplication!: any[];
  cropEvents: CropEvents[] = [];
  simulationTypes: SimulationType[] = [];
  drifTypes: DriftTypes[] = [];
  applicationMethods: ApplicationMethods[] = [];
  isMasterDetail: boolean = true;
  isloading: boolean = false;
  compartment: string = Constants.COMPARTMENTS.SURFACE_WATER;
  show: boolean = true;
  pwcFlag: boolean = true;
  selectedGeography: string | undefined = '';
  destroyRef = inject(DestroyRef);
  isRuning: boolean = true;
  compartmentPk!: number;
  childrens: any;
  highlightRow: boolean = false;
  qcFinish: boolean = false;
  columnSizeMode: string = "fit";
  rowHeight: number = 80;
  autoGroupColDef: any = {
    headerValueGetter: (params: { colDef: { headerName: any } }) =>
      `${params.colDef.headerName}`,
    cellRendererParams: {
      suppressCount: true,
      checkbox: true,
    },
  };

  constructor(
    private gapLogicSWService: EEAGapLogicService,
    private gapLogicApi: EEAGapApiService,
    private gridService: GridLogicService,
    private EEAGAPMenuLogicService: EEAGAPMenuLogicService,
    private gapApplicationSchemeLogicService: GapApplicationSchemeLogicService,
    private eEAGapPWCSurfaceWaterColdef: EEAGapPWCSurfaceWaterColdef
  ) { }

  private scenariosLogicService = inject(EeaGapSurfacewaterPwcScenariosLogicService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.initSubscribes();
      this.IniValues(changes);
    }
  }
  async ngOnInit(): Promise<void> {

    this.detailsColumnDef = EEAGapPWCSurfaceWaterColdef.getPWCDetailsColumnsDefination(this, this.isProjectOwnershipValid);
    this.initSubscribes();
    this.isloading = true;

    await this.scenariosLogicService.getCropsXRegion();
  }

  initSubscribes() {
    this.menuService.activeIndexChanged
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.saveRef = this.menuService.saveRef;
        this.saveData(this.grid?.transactionList);
        this.menuService.setSuccess(true);
      });
    this.subTabMenuService.activeIndexChanged
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.saveRef = this.subTabMenuService.saveRef;
        this.saveData(this.grid?.transactionList);
        this.subTabMenuService.setSuccess(true);
      });
  }

  dropDownSelectionChanged(event: any) {

    if( Constants.GAP_PWC_DETAIL_FIELDS.includes(event.field) )
      event.params.data.isChild = true;

    if (event.field === Constants.FIELD_NAMES.DATE_TYPE) {
      event.params.data[event.field] = event.value;
      event.row[event.field] = event.value;
      let parentRowNode = this.grid?.gridApi.getRowNode(event.params?.node.id)
      if (parentRowNode?.data?.children) {
        let children = parentRowNode?.data?.children;
        children.map((x: any) => {
          x.dateType = event.value;
        });
        this.grid?.gridApi.refreshCells({ force: true });
      }

      var node1 = event.params.api.getDisplayedRowAtIndex(event?.params?.node?.id);
      const childrens = this.detailsColumnDef?.flatMap(
        (node: { children: any }) => node.children
      );
      let appDate = childrens?.find(
        (x: { headerName: string }) =>
          x.headerName === Constants.PWCControlsValidations.ApplicationDate
      ) as any;
      let Days = childrens?.find(
        (x: { headerName: string }) =>
          x.headerName === Constants.COLUMN_HEADER_NAMES.DAYS_SINCE
      ) as any;
      if (event.value === Constants.DATE_TYPE_VALUES.RELATIVE) {
        if (appDate) {
          appDate.editable = false;
          Days.editable = true;
        }
      }
      if (event.value === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
        event.row.cropEvent = null;
        appDate.editable = true;
        Days.editable = false;
      }
      this.grid?.gridApi.refreshCells({ force: true });
      this.grid?.gridApi.redrawRows();
    }
    if (event.field === 'numberOfApplications') {
      event.params.data[event.field] = event.value;
      this.addApplicationRowsByApplicationNumber(event, event.value);

    }
    this.grid?.detailGridApi?.redrawRows();

  }

  addApplicationRowsByApplicationNumber(event: any, numberApplication: number) {
    let row = event.row;
    let childrenValues: any[] = this.getApplicationRows(numberApplication, event.row.children, event);
    event.row.children = [];
    event.row.children = childrenValues;
    this.grid.gridApi.redrawRows();
  }

  private getApplicationRows(applicationNumber: number, existingChildren: ApplicationSchemeXApplication[], event: any): ApplicationSchemeXApplication[] {
    const adjustedChildren = this.adjustChildren(existingChildren, applicationNumber);
    let applicationNumberToCreate: number = adjustedChildren !== undefined && applicationNumber > adjustedChildren.length ? applicationNumber - adjustedChildren.length : 0;
    let newChildren: ApplicationSchemeXApplication[] = [];
    if (adjustedChildren?.length > 0) {
      newChildren = this.createNewChildren(applicationNumberToCreate, adjustedChildren.length, event);
      return [...adjustedChildren, ...newChildren];
    }

    if (existingChildren === undefined) {
      return newChildren = this.newChildrenToCreate(applicationNumber, event);
    }
    return newChildren;
  }
  private createNewChildren(numberOfNewChildren: number, lastItemIndex: number, data: any): ApplicationSchemeXApplication[] {
    return Array.from({ length: numberOfNewChildren }, (_, index) => this.createApplicationSchemeXApplication(lastItemIndex + index + 1, data));
  }


  private adjustChildren(children: ApplicationSchemeXApplication[], applicationNumber: number): ApplicationSchemeXApplication[] {
    if (children !== undefined && children.length > applicationNumber) {
      return children.slice(0, applicationNumber);
    }
    return children;
  }

  private newChildrenToCreate(applicationNumber: number, event: any): ApplicationSchemeXApplication[] {
    let childrenValues: ApplicationSchemeXApplication[] = [];
    for (let index = 0; index < applicationNumber; index++) {
      const newChildren: ApplicationSchemeXApplication = {
        application_scheme_x_application_pk: 0,
        application_date: undefined,
        application_method: 0,
        application_number: index + 1,
        application_scheme_pk: 0,
        crop_interception: 0,
        day: undefined,
        days_since: 0,
        depth: undefined,
        drift: 0,
        earliest_bbch: '0',
        efficiency: 0,
        min_application_interval: 0,
        month: 0,
        t_band_split: undefined,
        period: 1,
        lag: 0,
        year: 0,
        dateType: event.row.dateType,
      };
      childrenValues.push(newChildren);
    }
    return childrenValues;
  }
  private createApplicationSchemeXApplication(applicationNumber: number, data: any): ApplicationSchemeXApplication {
    return {
      application_scheme_x_application_pk: 0,
      application_date: undefined,
      application_method: 0,
      application_number: applicationNumber,
      application_scheme_pk: 0,
      crop_interception: 0,
      day: undefined,
      days_since: 0,
      depth: undefined,
      drift: 0,
      earliest_bbch: '0',
      efficiency: 0,
      min_application_interval: 0,
      month: 0,
      t_band_split: undefined,
      period: 1,
      lag: 0,
      year: 0,
      dateType: data.row.dateType,
    };
  }

  getApplicationsByApplication(data: any): ApplicationSchemeXApplication[] {
    let childrenValues: ApplicationSchemeXApplication[] = [];
    if (data.length > 0) {
      const childrens = data.map((x: ApplicationSchemeXApplication) => {
        return {
          application_scheme_x_application_pk: x.application_scheme_x_application_pk,
          application_date: x.application_date,
          application_method: x.application_method,
          application_number: x.application_number,
          application_scheme_pk: x.application_scheme_pk,
          crop_interception: x.crop_interception,
          day: x.day,
          days_since: x.days_since,
          depth: x.depth,
          drift: x.drift,
          earliest_bbch: x.earliest_bbch,
          efficiency: x.efficiency,
          min_application_interval: x.min_application_interval,
          month: x.month,
          t_band_split: x.t_band_split,
          period: x.period,
          lag: x.lag,
          year: x.year,
          dateType: x.dateType
        };
      });
    }
    return childrenValues;
  }
  cropEvent(event: any) {
    const childrens = this.detailsColumnDef?.flatMap(
      (node: { children: any }) => node.children
    );
    let daySince = childrens?.find(
      (x: { headerName: string }) =>
        x.headerName === Constants.COLUMN_HEADER_NAMES.DAYS_SINCE
    ) as any;
    let crop = this.columnsDef?.find(
      (x: { headerName: string }) =>
        x.headerName === Constants.COLUMN_HEADER_NAMES.CROP_EVENT
    ) as any;
    let appDate = childrens?.find(
      (x: { headerName: string }) =>
        x.headerName === Constants.PWCControlsValidations.ApplicationDate
    ) as any;
    var node1 = event.params.api.getDisplayedRowAtIndex(event?.id)!;
    if (
      event.dateType === Constants.FIELD_NAMES.DATE_TYPE ||
      (event.field === Constants.FIELD_NAMES.DATE_TYPE &&
        event.value === Constants.DATE_TYPE_VALUES.RELATIVE)
    ) {
      if (crop && appDate) {
        this.grid?.gridColumnApi.setColumnVisible(crop?.field, true);
        appDate.hide = true;
        daySince.hide = false;

        this.isExpanded(node1);
      }
    } else {
      appDate.hide = false;
      daySince.hide = true;

      this.grid?.gridColumnApi.setColumnVisible(crop?.field, false);
      this.isExpanded(node1);
    }
    this.grid?.gridApi.refreshCells();
    this.grid.gridApi.redrawRows();
  }
  isExpanded(node: any) {
    if (node?.expanded) {
      this.grid.gridApi.setRowNodeExpanded(node, false);
    } else {
      this.grid.gridApi.setRowNodeExpanded(node, true);
    }
  }

  saveData(dataTransaction: any[]) {
    if (dataTransaction === undefined) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    if (dataTransaction.length <= 0) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    if (dataTransaction.length == 0) {
      this.EEAGAPMenuLogicService.setSuccess(true);
      return;
    }

    if (!this.selectedProject) return;
    this.gapLogicSWService.setParametersToNewRowsPWC(
      dataTransaction,
      this.compartmentPk,
      false,
      this.selectedProject?.projectPk
    );
    this.gapLogicSWService.transformPWCDetailsObjectToApplicationSchemeXApp(
      dataTransaction
    );
    const transformedData: SaveStructure[] =
      this.gapLogicSWService.transformToSaveStructureObject(dataTransaction);
    this.gapLogicApi
      .savePWC(transformedData)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.grid.afterSaveSuccess();
          this.saveRef === Constants.WIZARD_MENU_REF.Master
            ? this.menuService.setSuccess(true)
            : this.subTabMenuService.setSuccess(true);
        },
        error: (err: any) => {
          console.warn(err);
          this.saveRef === Constants.WIZARD_MENU_REF.Master
            ? this.menuService.setSuccess(false)
            : this.subTabMenuService.setSuccess(false);
        },
      });
    this.grid.transactionList = [];
  }

  getColumnDefPwc(option: any) {
    EEAGapPWCSurfaceWaterColdef.getPWCDetailsColumnsDefination(
      option,
      this.isProjectOwnershipValid
    );
    return this.eEAGapPWCSurfaceWaterColdef.getPWCColumnsDefinition(
      option,
      this.isProjectOwnershipValid
    );
  }

  onModelOptionsChanged(models: string[]) {
    if (models.map((x: string) => x === Constants.MODELS.PWC)[0]) {
      this.pwcFlag = true;
      return;
    }

    if (this.grid) {
      const geographiesToFilter = [
        Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
        Constants.CROP_GEOGRAPHIES.UK,
        Constants.CROP_GEOGRAPHIES.USA,
      ];
      let selectedGeography = this.geographies
        .filter((x: Catalog) => geographiesToFilter.includes(x.name ?? ''))
        ?.find(
          (x: Catalog) =>
            this.selectedProject?.countryPk === x.key ||
            this.selectedProject?.regionPk === x.key
        )?.name;
      this.selectedGeography = selectedGeography;
      let columns: any[] = this.gapLogicSWService.getDisplayedColumnsByModel(
        models,
        selectedGeography!
      );
      let displayedColumns = this.grid?.gridColumnApi.getColumns();
      let fixedColumns =
        models.includes(Constants.MODELS.SWASH) ||
          models.includes(Constants.MODELS.SWAN) ||
          models.includes(Constants.MODELS.MACRO_GW) ||
          models.includes(Constants.MODELS.PEARL) ||
          models.includes(Constants.MODELS.PELMO)
          ? ['deleteButton', 'appWindow']
          : ['deleteButton'];
      let columnsToShow: string[] = [
        ...Constants.GAP_CORE_FIELDS,
        ...new Set(columns),
        ...this.gapLogicSWService.getFixedColumns(
          displayedColumns,
          fixedColumns
        ),
      ];
      columnsToShow = this.removeColumnsByModel(models, columnsToShow);
      displayedColumns.map((column: any) => {
        this.grid?.gridColumnApi.setColumnVisible(
          column.colId,
          columnsToShow.includes(column.getColDef().field)
        );
      });
      this.grid?.gridApi.refreshCells();
    }
  }

  removeColumnsByModel(models: string[], columnsToShow: string[]) {
    return this.removeUKColumns(models, columnsToShow);
  }

  removeUKColumns(models: string[], columnsToShow: string[]) {
    return JSON.stringify(models) ===
      JSON.stringify(new Array(Constants.MODELS.UK))
      ? columnsToShow.filter((x: string) => x !== 'geographies')
      : columnsToShow;
  }

  IniValues(changes: SimpleChanges) {
    let promises = [
      this.gapLogicSWService.getCompartments(),
      this.gapLogicSWService.getStep1n2Interceptions(),
      this.gapLogicSWService.getCropListMatchings(),
      this.gapLogicSWService.getPWCCropEvents(),
      this.gapLogicSWService.getPWCApplicationMethods(),
      this.gapLogicSWService.numerOfApplications,
      this.gapLogicSWService.getPWCDriftTypes()
    ];
    Promise.all(promises).then(async (results) => {
      this.compartmentList = results[0];
      this.cropInterceptionsStep1And2 = results[1];
      this.cropListMatchings = results[2];
      this.cropEvents = results[3].map((crop: any) => {
        return { id: crop, name: crop };
      });
      this.applicationMethods = results[4].map((x) => {
        return { id: x, name: x };
      });
      this.numbersOfApplication = results[5];
      this.drifTypes = results[6].map((x) => {
        return { id: x, name: x };
      });
      await this.getApplicationSchemesByProjectAndCompartment(
        changes['selectedProject'].currentValue.projectPk,
        this.getCompartmentPk()
      );


    });
  }
  onCellChanged(event: any) {
  }
  setColumnEditableAndStyle(
    columnField: string,
    editable: boolean,
    cellStyle: any
  ) {
    const childrens = this.detailsColumnDef?.flatMap(
      (node: { children: any }) => node.children
    );
    let column = childrens?.find(
      (x: { headerName: string }) => x.headerName === columnField
    ) as any;

    if (column) {
      column.editable = editable;
      column.cellStyle = cellStyle;
      this.grid.detailGridApi.refreshCells({ force: true });
      this.grid.detailGridApi.redrawRows(childrens);
    }
  }

  setApplicationMethodEditable2(event: any) {
    const childrens = this.detailsColumnDef?.flatMap((node: { children: any; }) => node.children);
    let depth = childrens?.find((x: { headerName: string; }) => x.headerName === Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_DEPTH) as any;
    let tband = childrens?.find((x: { headerName: string; }) => x.headerName === Constants.PWCControlsValidations.TBAND) as any;

    if (event.field === Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD) {
      switch (event.value) {
        case Constants.FIELD_NAMES.APPLICATION_METHOD_DEPTH:
          tband.cellStyle = { 'background-color': '#f0f0f0', 'pointer-events': 'auto', 'fontWeight': 'bold' };
          this.setColumnEditableAndStyle(depth.headerName, true, { 'background-color': 'none' });
          this.setColumnEditableAndStyle(tband.headerName, false, { 'background-color': '#f0f0f0', 'pointer-events': 'auto', 'fontWeight': 'bold' });
          break;
        case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_ABOVE_CROP:
          this.setColumnEditableAndStyle(depth.headerName, true, { 'background-color': 'none' });
          this.setColumnEditableAndStyle(tband.headerName, false, { 'background-color': '#f0f0f0', 'pointer-events': 'auto', 'fontWeight': 'bold' });
          break;
        case Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_BELOW_CROP:
          this.setColumnEditableAndStyle(depth.headerName, true, { 'background-color': 'none' });
          this.setColumnEditableAndStyle(tband.headerName, false, { 'background-color': '#f0f0f0', 'pointer-events': 'auto', 'fontWeight': 'bold' });
          break;
      }
    }
    this.grid.detailGridApi.refreshCells({ force: true });
    this.grid.detailGridApi.redrawRows(event.row);
  }

  onCellValueChanged(event: any) {
  }
  getColDefFilteredBySelectedModels() {
    if (!this.selectedProject) return;
    const geographiesToFilter = [
      Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
      Constants.CROP_GEOGRAPHIES.UK,
      Constants.CROP_GEOGRAPHIES.USA,
    ];
    let selectedGeography = this.geographies
      .filter((x: Catalog) => geographiesToFilter.includes(x.name ?? ''))
      ?.find(
        (x: Catalog) =>
          this.selectedProject?.countryPk === x.key ||
          this.selectedProject?.regionPk === x.key
      )?.name;
    const colDefParams = {
      applicationMethods: this.applicationMethods,
      geographySelected: selectedGeography,
      cropListMatching: this.cropListMatchings,
    };
    return this.getColumnDefPwc(colDefParams);
  }

  listBoxSelectionChanged(params: any) {
    if (!this.selectedProject) return;
    params.data.simulationTypes = params.selectedValues.map((x: any) => { return { typeName: x.typeName } });

    let isTpezWpezSelected = params.selectedValues?.find((x: any) => x.typeName == Constants.PWC_SIMULATION_TYPES.USEPA_TPEZ_WPEZ);
    if (!isTpezWpezSelected) {
      params.data.useSpraydriftBuffersForTpez = false;
    }

    this.grid.gridApi.redrawRows();
  }

  public async onCheckboxSelectedChanged(event: any): Promise<void> {
    if (event.field == Constants.PWC_FIELD_NAMES.ADJUST_APPLICATION_DATES_IF_RAINING && event.value == false) {
      event.row.intolerableRainCm = null;
      event.row.intolerableRainWindowDays = null;
      event.row.optimumApplicationWindowDays = null;
      event.row.minimumDaysBetweenApplications = null;
    }
    this.grid.gridApi.redrawRows();
  }

  getCompartmentPk() {
    this.compartmentPk = Number(
      this.compartmentList
        ?.filter(
          (x: any) => x.compartment == Constants.COMPARTMENTS.SURFACE_WATER
        )
        ?.map((x) => x.endpointCompartmentPk)
        .flat()
    );
    return this.compartmentPk;
  }

  onDelete() {
    this.gridService.removeTransaction(this.grid.transactionList);
  }
  onDeleteDetails(event: any) {
    let deletePKs = event.detail.map(
      (x: { application_scheme_x_application_pk: any }) =>
        x.application_scheme_x_application_pk
    );
    event.parent.children = event.parent.children.filter(
      (x: { PK: any }) => !deletePKs.includes(x.PK)
    );
    let indexToRemove = event.parent.children.findIndex((x: { PK: any }) =>
      deletePKs.includes(x.PK)
    );
    event.parent.haveChanges = true;
    event.parent.children.splice(indexToRemove, 1);
    this.gridService.removeDetailTransaction(event.detail);
    this.removedDetailsRow(deletePKs, event);
    this.grid.gridApi.redrawRows();
  }

  deleteRow(event: any) {
    this.gridService.removeTransaction(event);
    if (event) {
      this, this.gapLogicApi.savePWC(event).pipe(take(1))
        .subscribe(() => { });
    }
    if (this.grid.gridApi.getDisplayedRowCount() === 1) {
      this.grid.gridApi.redrawRows();
    }
  }

  transformDataPWC(data: any, isInverseModeling: boolean) {
    data?.map((row: any) => {
      row.tillage = row.tillage
        ? 'Yes'
        : row.tillage != undefined
          ? 'No'
          : null;
      row.geographies = [];
      if (row?.applicationSchemeXGeography)
        row?.applicationSchemeXGeography.map((geography: any) => {
          const geo = this.geographies.find(
            (x: Catalog) => x.key == geography.geographyPk
          );
          if (geo) {
            row.geographies.push(geo);
          }
        });
      if (!isInverseModeling) {
        if (row.aplicationSchemeXApplication)
          row?.applicationSchemeXActiveIngredientRate.map((rate: any) => {
            let application = row?.applicationSchemeXApplication.find(
              (a: any) =>
              (a.application_scheme_x_application_pk =
                rate.application_scheme_x_application_pk)
            );
            if (application) {
              application[`rate${rate.unitOfMeasure}${rate.moleculePk}`] =
                rate.rate;
            }
          });
        row.children = row.applicationSchemeXApplication;
      }
    });

    this.rowData = data;
  }

  onCalendarSelectionChanged(event: any): void {
    event.params.data[event.field] = event.value;
    event.row[event.field] = event.value;
  }

  removedDetailsRow(pks: number[], row: any) {
    pks.map((x: number) => {
      this.gapLogicApi
        .DeleteDetailsPWC(x)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.grid.afterSaveSuccess();
            this.EEAGAPMenuLogicService.setSuccess(true);
            this.grid.gridApi.redrawRows([x]);
          },
          error: (err: any) => {
            console.warn(err);
            this.EEAGAPMenuLogicService.setSuccess(false);
          },
        });
    });
  }

  getApplicationDetails(
    applicationSchemePk: number
  ): ApplicationSchemeXApplication[] {
    let app: ApplicationSchemeXApplication[] = [];
    this.gapLogicSWService
      .getPwcApplicationSchemeXApplicationPk(applicationSchemePk)
      .then((appSchemeXApp: ApplicationSchemeXApplication[]) => {
        app = appSchemeXApp;
      });
    return app;
  }

  async getApplicationSchemesByProjectAndCompartment(
    projectPk: number,
    compartmentPk: number
  ) {
    let children: ApplicationSchemeXApplication[] = [];

    if (projectPk != 0) {
      this.isloading = true;
      await firstValueFrom(
        this.gapLogicApi
          .getApplicationSchemesByProjectAndCompartmentPWC(
            projectPk,
            compartmentPk
          )
          .pipe(take(1))
      ).then((applicationSchemes) => {
        if (!this.selectedProject) return;
        this.gapLogicSWService
          .addActiveIngredientsRatesToGridPWC(
            applicationSchemes,
            this.detailsColumnDef,
            false,
            this.selectedProject,
            this.isProjectOwnershipValid,
            this.compartment
          )
          .then(() => {
            if (applicationSchemes != undefined)
              this.rowData = this.gapLogicSWService.rowPWC;

            this.columnsDef =
              this.eEAGapPWCSurfaceWaterColdef.getPWCColumnsDefinition(
                this,
                this.isProjectOwnershipValid
              );

            this.show = false;
            this.grid?.gridApi.redrawRows();
          });
      });
    }
  }

  newRowAdded(newItems: any[]) {
    const itemsToProcess = Array.isArray(newItems) ? newItems : [newItems];      
    itemsToProcess.forEach(item => {
      if (!item.isDuplicate) {
        this.grid.enableControls(false);
      }
    });
  }

  public onBlurInputText({ row }: { row: any }): void {
    this.gapApplicationSchemeLogicService.checkIfApplicationSchemeIsValid(this, row.name);
  }

  public enableControls(enable: boolean): void {
    this.isValid.emit(enable);
    this.grid.useAddAction = enable;
  }

  public onClickButtonEvent(event: any): void {
    if (event.field == Constants.PWC_SHOW_COMPONENT_BUTTONS.SCENARIOS) {
      this.scenariosLogicService.currentRowId.set(event.rowNode.id);
      this.scenariosLogicService.visible.set(true);
      this.selectedRow = event.data;
    }
  }

  public onSelectScenarios(selectedScenariosByRow: SelectedScenariosByRow): void {
    if (!this.grid) return;
    const { rowId, selectedScenariosIds } = selectedScenariosByRow;
    const rowNode = this.grid.gridApi.getRowNode(rowId);
    const { id } = rowNode;
    rowNode.data.cropXRegionPks = selectedScenariosIds;
    this.grid.CreateTransaction(id, id, undefined, rowNode.data);
  }
}
