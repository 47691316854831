import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, ColGroupDef, RowGroupingDisplayType } from 'ag-grid-community';
import { GridComponent } from '../../../shared/components/grid/grid.component';
import { Project } from '../../../shared/models/project';
import { TabMenuLogicService } from '../../../shared/services/tab-menu.logic.service';
import {HhraRunRiskAssessmentUSHandlerResultsColDef  } from './hhra-run-risk-assessment-occupational-handler-exposure-coldef';
import { UsHandlerExposureInputs } from '../../../shared/models/us-handler-exposure-Inputs';
import { RunRiskApiService } from '../../../shared/services/run-risk.api.service';
import { Subscription, switchMap, take, timer } from 'rxjs';
import { ProjectApiService } from '../../../shared/services/project.api.service';
import { Constants } from '../../../shared/utils/constants';
import { SelectedProjectApiService } from '../../../shared/components/selected-project/selected-project.api.service';

@Component({
  selector: 'app-hhra-run-risk-assessment-occupational-handler-exposure',
  templateUrl: './hhra-run-risk-assessment-occupational-handler-exposure.component.html',
  styleUrls: ['./hhra-run-risk-assessment-occupational-handler-exposure.component.css']
})

export class HhraRunRiskAssessmentOccupationalHandlerExposureComponent implements OnInit {

  @Input() selectedProject?: Project;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;
  @Output() status: EventEmitter<any>;
  @Output() isProjectRunning: EventEmitter<boolean>;

  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  columnDefs: (ColDef | ColGroupDef)[] | null = [];
  rowData: any[] = [];
  isLoading!: boolean;
  subscription!: Subscription;
  projectStatusStyle = {
    statusId: this.selectedProject?.status,
    statusName: "",
    badgeStyle: ""
  };


  @ViewChild('grid') grid!: GridComponent;

  constructor(private readonly hhraRunRiskAssessmentUSHandlerResultsColDef: HhraRunRiskAssessmentUSHandlerResultsColDef,
    private readonly runRiskApiService: RunRiskApiService,
    private projectApiService: ProjectApiService,
    private selectedProjectApiService: SelectedProjectApiService) {
    this.status = new EventEmitter<boolean>();
    this.isProjectRunning = new EventEmitter<boolean>();
  }

  ngOnInit() {
    this.rowData = [];
    this.isLoading = true;
    this.monitorProjectStatus();
  }

  getData() {
    this.columnDefs = this.hhraRunRiskAssessmentUSHandlerResultsColDef.getColumnsDefinition();
    this.runRiskApiService.GetUsHandlerExposureRunList(this.selectedProject?.projectPk!).subscribe((data: any[]) => {
      this.rowData = data;
      this.isLoading = false;
    });
  }

  monitorProjectStatus(): void {
      this.subscription = timer(5000, 10000).pipe(
        switchMap(() => this.projectApiService.getProjectStatusResult(this.selectedProject!.projectPk).pipe(take(1)))
      ).subscribe({
        next: (status) => {
          this.selectedProject!.status = status;
          if (this.selectedProject!.status == Constants.PROJECT_STATUS_ID.COMPLETED) {
            this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
            this.isProjectRunning.emit(true);
            this.statusStyle(status, Constants.PROJECT_STATUS.COMPLETED, Constants.BADGE_STYLE.COMPLETED);
            this.getData();
            this.subscription.unsubscribe();
          }
          if (this.selectedProject!.status == Constants.PROJECT_STATUS_ID.FAILED) {
            this.statusStyle(status, Constants.PROJECT_STATUS.FAILED, Constants.BADGE_STYLE.FAILED);
            this.isLoading = false;
            this.subscription.unsubscribe();
          }
          if (this.selectedProject!.status == Constants.PROJECT_STATUS_ID.CALCULATING) {
            this.statusStyle(status, Constants.PROJECT_STATUS.CALCULATING, Constants.BADGE_STYLE.IN_PROGRESS);
          }
        },
        error: (error) => {
          console.warn(error);
        }
      });
   
  }

  statusStyle(statusId: number, status: string, badgeStyle: string) {
    this.projectStatusStyle = {
      badgeStyle: badgeStyle,
      statusId: statusId,
      statusName: status.concat("\u00A0")
    };
    this.status.emit(this.projectStatusStyle);
  }
}
