import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ESALogicService } from '../udl-overlap-tool/udl-overlap-tool.logic.service';
import { Run } from 'src/app/shared/models/run/run';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { UdlOverlapToolContainerService } from 'src/app/shared/services/udl-overlap-tool-container.service';

@Component({
  selector: 'app-esa-run-project',
  templateUrl: './esa-run-project.component.html',
  styleUrls: ['./esa-run-project.component.css']
})
export class ESARunProjectComponent {
  runs: Run[] = [];
  @Input() selectedProject?: Project;
  @Input() selectedProjectStatus?: number;
  @Input() menuService!: TabMenuLogicService;
  @Input() environmentalAssessment!: string;
  @Output() showLoadingForOutput = new EventEmitter<boolean>();

  runQc: Boolean = false;
  loading: boolean = false;
  isInputsModelFilesValidationPassed: boolean = false;
  isProjectOwnershipValid: boolean = false;

  columnDefs: any;
  rowData: any[] | null | undefined;
  defaultColDef = { sortable: true, filter: true, resizable: true };

  constructor(public ESALogicService: ESALogicService,
    private userService: UserLogicService,
    public selectedProjectApiService: SelectedProjectApiService,
    private udlOverlapToolContainerService: UdlOverlapToolContainerService,
    private cdr: ChangeDetectorRef ) { }

  ngOnInit(): void {
    this.toggleSetupTab(false);
    this.toggleOutputTab(false);

    this.getColumnDef();
    this.getFilesValidationErrorList();
  }

  private getColumnDef(): void {
    this.columnDefs = [
      { headerName: 'File Name', field: 'fileName', width: 350 },
      { headerName: 'File Error', field: 'fileError', width: 900 },
      { headerName: 'Column', field: 'column', width: 150 },
      { headerName: 'Row', field: 'row', width: 150 },
      { headerName: 'Value', field: 'value', width: 250 }
    ];
  }

  getFilesValidationErrorList(): void {
    this.loading = true;

    if (!this.selectedProject?.projectPk) {
      return;
    }

    this.udlOverlapToolContainerService
      .getFilesValidationErrors(this.selectedProject.projectPk)
      .subscribe({
        next: (response) => {
          this.rowData = this.parseValidationErrors(response);
        },
        error: (err) => {
          console.error('Error - fetching files validation errors:', err);
          this.rowData = [];
        },
        complete: () => {
        }
    });

    this.loading = false;
    this.toggleSetupTab(true);
  }

  private parseValidationErrors(response: any): any[] {
    if (!response?.Value) {
      return [];
    }

    if (response?.Value?.toString().trim().toLowerCase() === "epa model input files validated successfully".toLowerCase()) {
      this.isInputsModelFilesValidationPassed = true;
      this.toggleOutputTab(true);

      return [];
    }
    else {
      this.isInputsModelFilesValidationPassed = false;
    }

    const rows: any[] = [];
    response.Value.forEach((file: any) => {
      const fileName = file.fileName || 'Unknown File';
      const errorDetails = file.errorDetails || [];

      errorDetails.forEach((error: any) => {
        rows.push({
          fileName: fileName,
          fileError: error.errorMessage || 'Unknown Error',
          column: error.column || 'N/A',
          row: error.row || 'N/A',
          value: error.rawValue || 'N/A',
        });
      });
    });

    return rows;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.runQc = false;
      this.isProjectOwnershipValid = this.userService.verifyProjectOwnership(this.selectedProject?.createdBy);
    }
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
  }

  toggleSetupTab(enable: boolean): void {
    this.ESALogicService.toggleTab(0, enable);
    this.cdr.detectChanges();
  }

  toggleOutputTab(enable: boolean): void {
    this.ESALogicService.toggleTab(2, enable);
    this.cdr.detectChanges();
  }

  GoToOutput() {
    const nextItem = this.ESALogicService.items.find(i => i.index === 2);
    if (nextItem) {
      this.ESALogicService.setActiveItem(nextItem);
    }
  }

  BackToSetup(){
    const nextItem = this.ESALogicService.items.find(i => i.index == 0);
    if (nextItem) {
      this.ESALogicService.setActiveItem(nextItem);
    }
  }
}
