import { Injectable } from "@angular/core";
import { HttpApiService } from "./http.api.service";
import { environment } from 'src/environments/environment';
import { PWCInputs } from "../models/run/pwc-inputs";
import { HttpHeaders } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { DataSet } from "../models/echo/data-set";
import { ImportDataSet } from "../models/echo/import-data-set";
@Injectable({
    providedIn: 'root'
})
export class PWCApiService extends HttpApiService {
    override apiUrl: string = environment.BAMS_Api_URL;


    savePWC(pData: any): Observable<any> {
        const body = JSON.stringify(pData);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        let endpoint = `api/PWC/SaveDataSet`;
        return this
            .put(endpoint, body, headers).
            pipe(map((data: any) => data.Value));
    }

    getPWCDataSetsByDataSetPks(dataSetPks: number[]): Observable<DataSet[]> {
        const body = JSON.stringify(dataSetPks);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        let endpoint = `api/PWC/GetDataSet`;
        return this
            .put(endpoint, body, headers)
            .pipe(map((data: any) => data.Value));
    }

    deletePWCDatasets(dataSetPk: number): Observable<DataSet[]> {
        const body = JSON.stringify(dataSetPk);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        let endpoint = `api/PWC/DeleteDataSet`;
        return this
            .put(endpoint, body, headers)
            .pipe(map((data: any) => data.Value));
    }

    importDataSetInfo(datasetToCopy: ImportDataSet): Observable<any> {
        const body = JSON.stringify(datasetToCopy);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        const query = `api/PWC/importDataSetInfo`;
        return this.post(query, body, headers).pipe(map((data: any) => data.Value));
    }

    copyDataSet(dataSetPks: any): Observable<any> {
        const body = JSON.stringify(dataSetPks);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        const query = `api/PWC/CopyDataSet`;
        return this.post(query, body, headers).pipe(map((data: any) => data.Value));
    }

    async runPwc(projectPk: number): Promise<any> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        const query = `api/runPwc?projectPK=${projectPk}`;
        try {
          const response = await this.post(query,{ headers }).toPromise();
          return response;
        } catch (error) {
          console.error('Error running PWC', error);
          throw error;
        }
      }
}