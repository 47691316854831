import { Component, EventEmitter, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { GapApplicationSchemeLogicService } from '../../services/gap-application-scheme.logic.service';

@Component({
  selector: 'app-duplicate-button',
  templateUrl: './duplicate-button.component.html',
  styleUrls: ['./duplicate-button.component.css']
})

export class DuplicateButtonRenderer implements ICellRendererAngularComp {
  disabled = false;
  isGroupRendered = false;
  params: any;
  parent: any;
  mainTablePkName: string = "";
  disabledByEvent:boolean = false;
  

  constructor(private gapApplicationSchemeService: GapApplicationSchemeLogicService) { }
  agInit(params: any): void {
    this.isGroupRendered = params.node.group;
    this.params = params;
    this.disabled = params.disabled ?? false;
    this.parent = params.context;
    this.mainTablePkName = params.mainTablePkName;
    if (this.parent.isDeleteEnabled$) {
      this.parent.isDeleteEnabled$.subscribe((isEnabled: boolean) => {
          if (isEnabled !== undefined) {
              this.disabledByEvent = !isEnabled; 
          }
      });
    }
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onDuplicateClick(params: any) {
    this.parent.duplicateRow(this.params.node.id, this.mainTablePkName);
  }
}
