import { Component, EventEmitter, Input, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { HELPER_NOTES_CONSTANTS } from '../../utils/constants';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.css']
})
export class InputTextareaComponent {

  
  @Input() model?: string = '';
  @Input() style: string = '';
  @Input() rows: number = 2;
  @Input() cols: number = 30;
  @Input() autoResize: boolean = true;
  @Input() disabled: boolean = false;
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() floating: boolean = false;
  @Input() useTitle: boolean = false;
  @Input() useHelperNote:boolean = false;

  @Output() onChangeEvent= new EventEmitter<string>();

  modelChanged(value: string){
    this.onChangeEvent.emit(value);
  }
  showHelperNotesPopup(note:string) { 
    Swal.fire({
      title: 'Helper notes',
      html: HELPER_NOTES_CONSTANTS.MESSAGES[note]||"No helper note found",
      icon: 'info',
      width:350     
    });
  } 
}
