<app-grid
  *ngIf="!loading"
  #grid
  [columnDefs]="columDef"
  [rowData]="rowData"
  [selectedProject]="selectedProject"
  [rowSelection]="'multiple'"
  (onSelectedRowEvent)="onSelectedRowEvent($event)"
  (onRowSelectedEmit)="onSelectedAllChanged($event)"
/>

<app-loading *ngIf="loading"/>
