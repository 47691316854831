import { Constants } from "../shared/utils/constants";
import { PurposeByGeography } from "./environmental-risk-assessment.model";
import { BeeRexEndpointTypes, TrexEndpointsUnits, TrexEndpointTypes } from "./shared/era-shared-Interfaces";

export abstract class ERAConstants {
  public static readonly SCENARIOS: string[] = [
    'D1',
    'D2',
    'D3',
    'D4',
    'D5',
    'D6',
    'R1',
    'R2',
    'R3',
    'R4'
  ]

  public static readonly BUFFER: number[] = [
    10,
    20
  ]

  public static readonly WATERBODY =[
    {geography: Constants.GEOGRAPHYS.EUROPE_UNION, value: 'Pond'},
    {geography: Constants.GEOGRAPHYS.EUROPE_UNION, value: 'Stream'},
    {geography: Constants.GEOGRAPHYS.EUROPE_UNION, value: 'Ditch'},
    {geography: Constants.GEOGRAPHYS.USA,          value: 'Pond'},
    {geography: Constants.GEOGRAPHYS.USA,          value: 'Reservoir'},
    {geography: Constants.GEOGRAPHYS.USA,          value: 'User defined'}
  ]

  public static readonly DOMINANT_MIGRATION_GROUP: string[] = [
    'Drainage',
    'Drift',
    'Runoff'
  ]

  public static readonly PURPOSES_BY_GEOGRAPHY: PurposeByGeography[] = [
    {geography: Constants.GEOGRAPHYS.USA, purpose: ''},
    {geography: Constants.GEOGRAPHYS.EUROPE_UNION, purpose: 'Interception'}
  ]

  public static readonly GEOGRAPHY_TYPE = {
    COUNTRY: 'Country',
    REGION: 'Region'
  }

  public static readonly REGIONS: string[] = [
    'North Europe',
    'South Europe',
    'Maximum',
    '/',
    '-',
    'No Run-off/drainage'
  ]

  public static readonly PERIODS: string[] = [
    ' Jun. - Sep.',
    ' Mar. - May',
    ' Oct. - Feb.',
    '/',
    '-',
    'No Run-off/drainage'
  ]

  public static readonly T_REX_ENDPOINTS_TYPES = [
    'N/A',
    TrexEndpointTypes.AvianLD50,
    TrexEndpointTypes.AvianLC50,
    TrexEndpointTypes.AvianNOAEL,
    TrexEndpointTypes.AvianNOAEC,
    TrexEndpointTypes.MammalianLD50,
    TrexEndpointTypes.MammalianLC50,
    TrexEndpointTypes.MammalianChronicBw,
    TrexEndpointTypes.MammalianChronicDiet
  ]

  public static readonly BEE_REX_ENDPOINTS_TYPES = [
    'N/A',
    BeeRexEndpointTypes.AdultContactLD50,
    BeeRexEndpointTypes.AdultOralLD50,
    BeeRexEndpointTypes.AdultOralNOAEL,
    BeeRexEndpointTypes.LarvalLD50,
    BeeRexEndpointTypes.LarvalNOAEL
  ]

  public static readonly MODELS_WITH_SPECIFIC_ENDPOINT_TYPES = [
    Constants.MODELS.T_REX,
    Constants.MODELS.BEE_REX
  ]
}
