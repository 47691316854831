import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-crop-dropdown-list',
  templateUrl: './crop-dropdown-list.component.html',
  styleUrls: ['./crop-dropdown-list.component.css']
})
export class CropDropdownListRenderer implements ICellRendererAngularComp {

  public params: any;
  public options: Array<any> = [];
  public optionsParam: Array<any> = [];
  public optionValue!: string;
  public optionLabel!: string;
  public disabled: boolean = false;
  public defaultItem: any;
  public filterable: boolean = true;
  public selectedValue: any;
  public columnThatModifies!: string;
  public parent: any;
  width!: number;
  public placeholder: string = '';

  constructor() { }

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.defaultItem = params?.defaultItem
    this.parent = params.context ? params.context : params.instance;
    this.options = this.filterOptionsByPurpose(params);
    this.selectedValue = this.setItemIfOnlyOne(params);
    this.placeholder = params.placeholder !== undefined ? params.placeholder : this.placeholder;
    this.optionValue = params.optionValue;
    this.optionLabel = params.optionLabel;
    this.disabled = params.disabled ?? this.disabled;
    this.columnThatModifies = params.columnThatModifies;
  }

  public filterOptionsByPurpose(params: any) {
    return params?.options?.filter((x: any) => x.Purpose === params?.purpose && x.Geography === params?.geography && x.Compartment === params?.compartment);
  }

  getReadOnlySelectedValue()
  {
    let value = '';
    if(!this.optionValue || !this.optionValue){
      value = this.selectedValue;
    }else{
      const option = this.options?.find(option => option[this.optionValue] === this.selectedValue);
      if(option) value = option[this.optionLabel];
    }
    
    return value;
  }

  public setItemIfOnlyOne(params: any) {
    if ((params.data.coreApplicationSchemePk != undefined) && 
        (Utils.isEmptyValue(params.data?.cropDriftInterceptionPk) || Utils.isEmptyValue(params.data?.cropAppDatePk)) &&
        (Utils.isEmptyValue(params?.value) || params?.reloadCore)) {
      let matching = params?.matchings?.find((x: any) => x.geography == params?.geography && x.compartment == params?.compartment && x.gapCrops.includes(params?.data?.cropPk));
      switch (params?.purpose) {
        case Constants.CROP_PURPOSES.APP_DATE:
          params.value = matching?.appDateCrops.length > 0 ? matching?.appDateCrops[0] : params.value;
          break;
        case Constants.CROP_PURPOSES.DRIFT_INTERCEPTION:
          params.value = matching?.driftInterceptionCrops.length > 0 ? matching?.driftInterceptionCrops[0] : params.value;
          break;
        case Constants.CROP_PURPOSES.INTERCEPTION:
          params.value = matching?.interceptionCrops.length > 0 ? matching?.interceptionCrops[0] : params.value;
          break;
        case Constants.CROP_PURPOSES.SPRAY:
          params.value = matching?.sprayCrops.length > 0 ? matching?.sprayCrops[0] : params.value;
          break;
      }
      this.params.data[(this.columnThatModifies != undefined ? this.columnThatModifies : params.columnThatModifies)] = params.value;
      this.parent.dropDownSelectionChanged({ field: params.columnThatModifies, id: this.params.node.id, row: this.params.data, sourceValues: this.options, value: params.value });
    }
    return params?.value;
  }

  public onChange({ value }: DropdownChangeEvent): void {
    this.params.data[(this.columnThatModifies != undefined ? this.columnThatModifies : this.params.columnThatModifies)] = value;
    this.parent.dropDownSelectionChanged({
      field: this.columnThatModifies,
      id: this.params.node.id,
      row: this.params.data,
      sourceValues: this.options,
      value: this.selectedValue
    });
  }

  onShow(event: any) { }
}
