import { Component } from '@angular/core';
import { InputTextRenderer } from '../input-text.component';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-input-text-dataset',
  templateUrl: './input-text-dataset.component.html',
  styleUrls: ['./input-text-dataset.component.css']
})
export class InputTextDatasetRenderer extends InputTextRenderer {
  omitIllegalCharacters(event: any) {
    Utils.omitIllegalCharacters(event,Constants.illegalCharacters);
  }

  omitIllegalCharactersOnPaste(event: any) {
    Utils.omitIllegalCharactersOnPaste(event, Constants.illegalCharacters)
  }

}
