import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { Project } from 'src/app/shared/models/project';
import { RunRisk } from 'src/app/shared/models/run-risk';
import { Constants } from 'src/app/shared/utils/constants';
import { ERARunRiskAssessmentComponent } from '../era-run-risk-assessment.component';
import { TriggerEcotox } from 'src/app/shared/models/trigger-ecotox';
import { RunRiskApiService } from 'src/app/shared/services/run-risk.api.service';
import { CropListService } from 'src/app/administrator/regulatory-models/crop-list/crop-list.service';
import { CropList } from 'src/app/shared/models/crop-list';
import { Utils } from 'src/app/shared/utils/utils';
import { EraAquaticRunRiskAssessmentGridLogicService } from './era-aquatic-run-risk-assessment-grid.logic.service';
import { Endpoint } from 'src/app/shared/models/endpoint';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';

@Component({
  selector: 'app-era-aquatic-run-risk-assessment-grid',
  templateUrl: './era-aquatic-run-risk-assessment-grid.component.html',
  styleUrls: ['./era-aquatic-run-risk-assessment-grid.component.css']
})
export class ERAAquaticRunRiskAssessmentGridComponent implements OnInit {
  @Input() eraRunRiskAssessmentComponent!: ERARunRiskAssessmentComponent;
  @Input() selectedProject?: Project;
  @Input() isInverseModule?: boolean;

  columnDefs: any = [];
  mainGreography?: string = '';
  data: RunRisk[] = [];
  rowData: any[] = [];
  cropList?: CropList[];
  isLoading!: boolean;
  taxonGroup: string = Constants.ECOTOXICOLOGY_COMPARMENTS.AQUATIC;

  public formulation: string = '';

  constructor(private runRiskApiService: RunRiskApiService,
              private cropListService: CropListService,
              private readonly logicService: EraAquaticRunRiskAssessmentGridLogicService,
              public dataSetService: DataSetApiService,
              ){}

  ngOnInit(): void {
    this.isLoading = true;
    this.getDatasetsEndpoints();
    this.getFormulation();
  }

  private getFormulation(): void {
    const formulationPk = this.selectedProject!.formulationPk!;
    this.logicService.getFormulationNameByPk(formulationPk).then(formulationName => {
      this.formulation = formulationName;
    });
  }

  onCellValueChanged(event: any) {}

  onSelectionChanged(event: any) {
  }

  getData(): void {
    this.setMainGeography();
    this.mainGreography !== Constants.GEOGRAPHYS.USA ? this.setEuEcotoxData() : this.setUsaEcotoxData();
    this.getRunRiskSummaryData();
  }

  getDatasetsEndpoints() {
    this.dataSetService.getEndpointsByDataSetsByTaxonGroup(this.eraRunRiskAssessmentComponent.tempDatasets, this.taxonGroup).subscribe((endpointsDatasets: DataSet[]) => {
      this.filterAllRowData(endpointsDatasets);
      this.getData();
    })
  }

  filterAllRowData(data: DataSet[]) {
    data.forEach((x: DataSet) => {
      x.endpoints = x.endpoints?.filter((x: Endpoint) => x.disciplinePk === this.eraRunRiskAssessmentComponent.mainDiscipline );
      this.eraRunRiskAssessmentComponent.datasets.push(x);
    });
    this.processGroupedEndpoints();
  }

  processGroupedEndpoints() {
    this.eraRunRiskAssessmentComponent.datasets.forEach((row: any) => {
      if (row.groupedEndpointFieldValues?.length > 0)
        Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpoint) => {
          if (
            row.groupedEndpointFieldValues.find(
              (x: any) => x.key.toLowerCase() == endpoint.name
            )
          )
            row[endpoint.field] = row.groupedEndpointFieldValues.filter(
              (x: any) => x.key.toLowerCase() == endpoint.name
            )[0].value;
        });
    });
  }

  getRunRiskSummaryData() {
    forkJoin([
      this.runRiskApiService.GetAquaticRiskRunsByProject(this.data, this.eraRunRiskAssessmentComponent.selectedProject!.projectPk, this.mainGreography!, Constants.FEATURE_ACRONYM.ENVIRONMENTAL_RISK_ASSESSMENT),
      this.cropListService.getAllCropsList()
    ])
    .subscribe({
      next: ([runRisk, crops]) =>{
        this.cropList = Utils.sortObject((this.mainGreography == Constants.CROP_GEOGRAPHIES.USA) ? crops?.filter((x: CropList) => x.Geography === Constants.CROP_GEOGRAPHIES.USA) : crops, 'CropName'),
        this.data = runRisk;
        this.setDefaultValues()
        this.rowData = this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? this.data.sort((a: any, b:any) => b.etrValue-a.etrValue) : this.data.sort((a: any, b:any) => b.riskEquotient-a.riskEquotient).sort((a: any, b:any) => a.result.localeCompare(b.result));
        this.configureGridRiskAssessment();
        this.isLoading = false;
      }
    })
  }

  configureGridRiskAssessment(): void {
    this.columnDefs = [
      {
        headerName: 'Rate (g/Ha)',
        editable: false,
        hide: this.isInverseModule ? this.selectedProject!.useRateGHA ? false : true : true,
        pinned: 'left',
        field: 'rate',
        width: 100,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },      
      {
        headerName: 'Rate (lb/acre)',
        editable: false,
        hide: this.isInverseModule ? this.selectedProject!.useRateLBACRE ? false : true : true,
        pinned: 'left',
        field: 'rate',
        width: 100,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },     
      {
        headerName: 'BBCH',
        editable: false,
        hide: this.isInverseModule ? this.selectedProject!.useBBCH ? false : true : true,
        pinned: 'left',
        field: 'bbch',
        width: 95,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Application window',
        editable: false,
        hide: this.isInverseModule ? this.selectedProject!.useApplicationWindow ? false : true : true,
        pinned: 'left',
        field: 'rate',
        width: 130,
        minWidth: 100,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Number of applications',
        editable: false,
        pinned: 'left',
        hide: this.isInverseModule ? this.selectedProject!.useNumberOfApplications ? false : true : true,
        field: 'applicationNumber',
        width: 140,
        minWidth: 100,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Interval between applications',
        editable: false,
        pinned: 'left',
        hide: this.isInverseModule ? this.selectedProject!.useIntervalBetweenApplications ? false : true : true,
        field: 'applicationInterval',
        width: 150,
        minWidth: 100,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      }, 
      {
        headerName: 'Result',
        pinned: this.isInverseModule ? 'left' : 'right',
        editable: false,
        field: 'result',
        width: 95,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
        cellStyle: (params: any) => {
          if (params.value === Constants.RUN_RISK_ASSESSMENT_RESULT_VALUE.Passed) {
              return {color: 'green'};
          }
          else {
            return {color: 'red'};
          }
        }
      },
      {
        headerName: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? 'Indicator species/category' : 'Taxon/Exposure duration',
        editable: false,
        pinned: 'left',
        field: 'taxonExposureDuration',
        width: 275,
        minWidth: 275,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Active Ingredient',
        editable: false,
        field: 'activeIngredient',
        width: 160,
        minWidth: 160,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Substance type',
        editable: false,
        field: 'substanceType',
        width: 160,
        minWidth: 160,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Substance name',
        editable: false,
        pinned: this.isInverseModule ? '' : 'left',
        field: 'substanceName',
        width: 160,
        minWidth: 160,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },           
      {
        headerName: 'Ecotox Dataset',
        editable: false,
        field: 'ecotoxDataset',
        width: 180,
        minWidth: 180,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Listed / Non-listed',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? true : false,
        field: 'listedNoListed',
        width: 120,
        minWidth: 120,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Species',
        editable: false,
        field: 'species',
        width: 160,
        minWidth: 160,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },      
      {
        headerName: 'Endpoint type',
        editable: false,
        field: 'endpointType',
        width: 140,
        minWidth: 140,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Endpoint ',
        editable: false,
        field: 'endpoint',
        width: 95,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Endpoint value',
        editable: false,
        field: 'endpointValue',
        width: 95,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Endpoint unit',
        editable: false,
        field: 'endpointUnit',
        width: 95,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Application Scheme',
        editable: false,
        field: 'applicationScheme',
        minWidth: 300,
        width: 300,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Formulation',
        editable: false,
        field: 'formulation',
        width: 110,
        minWidth: 110,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Crop',
        editable: false,
        field: 'crop',
        minWidth: 170,
        width: 170,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? true : false,
      },
      {
        headerName: 'Crop (Interception)',
        editable: false,
        field: 'cropInterception',
        minWidth: 170,
        width: 170,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
        hide: this.mainGreography === Constants.CROP_GEOGRAPHIES.EUROPE_UNION ? false : true,
      },
      {
        headerName: 'Crop (AppDate)',
        editable: false,
        field: 'cropAppDate',
        minWidth: 170,
        width: 170,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
        hide: this.mainGreography === Constants.CROP_GEOGRAPHIES.EUROPE_UNION ? false : true,
      },
      {
        headerName: 'Crop (Drift)',
        editable: false,
        field: 'cropDrift',
        minWidth: 170,
        width: 170,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
        hide: this.mainGreography===Constants.CROP_GEOGRAPHIES.UK ? false : true,
      },
      {
        headerName: 'Crop (Spray)',
        editable: false,
        field: 'cropSpray',
        minWidth: 170,
        width: 170,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
        hide: this.mainGreography===Constants.CROP_GEOGRAPHIES.UK ? false : true,
      },
      {
        headerName: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? 'Rate (g/Ha)' : 'Rate (lb/acre)',
        field: 'rate',
        width: 95,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Model',
        editable: false,
        field: 'model',
        width: 145,
        minWidth: 155,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'FOCUS Step',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
        field: 'focusStep',
        width: 100,
        minWidth: 100,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Region',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
        field: 'region',
        width: 95,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Period',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
        field: 'period',
        width: 95,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Scenario',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
        field: 'scenario',
        width: 95,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Buffer (m)',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
        field: 'buffer',
        width: 90,
        minWidth: 90,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Nozzle Drfit Reduction (%)',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
        field: 'nozzleDriftReduction',
        width: 120,
        minWidth: 120,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Water body',
        editable: false,
        field: 'waterbody',
        width: 105,
        minWidth: 105,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? 'PEC type' : 'EEC type',
        editable: false,
        field: 'eecPecType',
        width: 100,
        minWidth: 100,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? 'PEC value' : 'EEC value',
        editable: false,
        field: 'eecPecValue',
        width: 80,
        minWidth: 80,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'Risk quotient',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? true : false,
        field: 'riskEquotient',
        width: 95,
        minWidth: 95,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'LOC',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? true : false,
        field: 'loc',
        width: 75,
        minWidth: 75,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'AF',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
        field: 'af',
        width: 75,
        minWidth: 75,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'RAC',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
        field: 'rac',
        width: 75,
        minWidth: 75,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'ETR value',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
        field: 'etrValue',
        width: 85,
        minWidth: 85,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },
      {
        headerName: 'ETR trigger',
        editable: false,
        hide: this.mainGreography !== Constants.CROP_GEOGRAPHIES.USA ? false : true,
        field: 'etrTrigger',
        width: 85,
        minWidth: 85,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        type: 'default',
      },      
    ];
  }

  setMainGeography(){
    if(this.eraRunRiskAssessmentComponent.selectedProject?.countryPk !== undefined){
      this.mainGreography = this.eraRunRiskAssessmentComponent.geographies.filter((x: Catalog) => this.eraRunRiskAssessmentComponent.selectedProject?.countryPk === x.key)[0].name;
    }
    if(this.eraRunRiskAssessmentComponent.selectedProject?.regionPk !== undefined) {
      this.mainGreography = this.eraRunRiskAssessmentComponent.geographies.filter((x: Catalog) => this.eraRunRiskAssessmentComponent.selectedProject?.regionPk === x.key)[0].name;
    }
  }

  setUsaEcotoxData(){
    this.eraRunRiskAssessmentComponent.datasets.forEach((dataset: DataSet) => {
      dataset.endpoints?.forEach((endpoint: any) => {
          Constants.RUN_RISK_LISTED_NON_LISTED_VALUES.forEach((value: string) => {
            const taxonExposureDuration = this.eraRunRiskAssessmentComponent.taxonCatalogList.filter((x: Catalog) => endpoint.taxonExposureDuration === x.key)[0]?.name;
            const trigger = this.eraRunRiskAssessmentComponent.triggers.filter((x: TriggerEcotox) => taxonExposureDuration === x.description)[0];

            let runRiskData: RunRisk = {
              metabolitePk: endpoint.metabolitePk,
              moleculePk: endpoint.moleculePk,
              activeIngredient: endpoint.activeIngredientName,
              ecotoxDataset: dataset.description,
              taxonExposureDuration: taxonExposureDuration,
              listedNoListed: value,
              species: endpoint.speciesScientificName,
              substanceType: endpoint.substanceType,
              substanceName: endpoint.substanceName,
              endpointType: endpoint.endpointTypePk,
              endpoint: endpoint.endpointValue,
              endpointValue: endpoint.value,
              endpointUnit: endpoint.unitValue,
              loc: value === Constants.RUN_RISK_LISTED_NON_LISTED_VALUES[0] ? trigger?.riskQuotientListed : trigger?.riskQuotientNonListed,
              eecPecType: trigger?.eecType,
            };
            this.data.push(runRiskData);
        });
      });
    });
  }

  setEuEcotoxData(){
    this.eraRunRiskAssessmentComponent.datasets.forEach((dataset: DataSet) => {
      dataset.endpoints?.forEach((endpoint: any) => {
        const taxonExposureDuration = this.eraRunRiskAssessmentComponent.taxonCatalogList.filter((x: Catalog) => endpoint.taxonExposureDuration === x.key)[0]?.name;
        const trigger = this.eraRunRiskAssessmentComponent.triggers.filter((x: TriggerEcotox) => taxonExposureDuration === x.description)[0];
        let runRiskData: RunRisk = {
          metabolitePk: endpoint.metabolitePk,
          moleculePk: endpoint.moleculePk,
          activeIngredient: endpoint.activeIngredientName,
          ecotoxDataset: dataset.description,
          taxonExposureDuration: taxonExposureDuration,
          species: endpoint.speciesScientificName,
          substanceType: endpoint.substanceType,
          substanceName: endpoint.substanceName,
          endpointType: endpoint.endpointTypePk,
          endpoint: endpoint.endpointValue,
          endpointValue: endpoint.value,
          endpointUnit: endpoint.unitValue,
          af: trigger?.assessmentFactor,
          rac: (endpoint.value / trigger?.assessmentFactor),
          eecPecType: trigger?.pecType,
          etrTrigger: `${trigger?.triggerType} ${trigger?.triggerOperator} ${trigger?.triggerValue}`
        };
        this.data.push(runRiskData);
      });
    });
  }

  setDefaultValues(){     
    this.data.forEach((value: RunRisk) => {
      value.formulation = this.formulation;
      value.crop = this.cropList?.filter((x: CropList) => x.CropListPk === value.cropPk)[0]?.CropName;
    })
  }
}
