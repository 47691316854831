import { Component, EventEmitter, Input, Output, SimpleChanges, OnInit, inject, DestroyRef } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { MetabolitePkAndName } from 'src/app/shared/models/echo/metabolite';
import { EEAEndpointsByCompartmentLogicService } from '../../eea-endpoints-by-compartment/eea-endpoints-by-compartment.logic.service';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { Constants } from 'src/app/shared/utils/constants';
import { EndpointDatasetLogicService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.logic.service';

@Component({
  selector: 'app-eea-endpoints-select-compound',
  templateUrl: './eea-endpoints-select-compound.component.html',
  styleUrls: ['./eea-endpoints-select-compound.component.css']
})
export class EEAEndpointsSelectCompoundComponent implements OnInit {
  @Input() projectPk: number;
  @Input() moleculePk: number;
  @Input() compartment!: string;
  @Input() metabolitesOptions!: MetabolitePkAndName[];
  @Input() disabled: boolean = false;
  @Input() isDaugtherSelected: boolean = false;
  @Input() dataset: DataSet | null = null;
  @Output() selectedMetabolitePk = new EventEmitter<number>();
  @Output() selectedActiveIngredientPk = new EventEmitter<number>();
  public metaboliteSelected: boolean;
  public activeButtonColor: string;
  public metaboliteButtonColor: string;
  public selectedMetabolitePkValue?: number;
  private unSelectedButtonColor: string;
  private selectedButtonColor: string;
  public excludeMetaboliteFromAI: boolean | undefined;
  public buttonLabel: string;
  public daugtherOptionSelected: boolean | undefined;
  public granddaugtherOptionSelected: boolean | undefined;
  public showDaughter: boolean | undefined;
  public showGranddaughter: boolean | undefined;
  public isMetaboliteUseAsPrecursor: boolean | undefined;
  destroyRef = inject(DestroyRef);

  constructor(private readonly endpointByCompartmentLogicService: EEAEndpointsByCompartmentLogicService,
    private readonly endpointDataSetLogicService: EndpointDatasetLogicService,
  ) {
    this.projectPk = 0;
    this.moleculePk = 0;
    this.metaboliteSelected = false;
    this.unSelectedButtonColor = 'secondary';
    this.selectedButtonColor = 'warning';
    this.activeButtonColor = this.selectedButtonColor;
    this.metaboliteButtonColor = this.unSelectedButtonColor;
    this.selectedMetabolitePkValue = 0;
    this.excludeMetaboliteFromAI = undefined;
    this.buttonLabel = 'Active Ingredient';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const metabolitesOptions = changes['metabolitesOptions'];
    if (metabolitesOptions && metabolitesOptions.previousValue !== metabolitesOptions.currentValue) {
      this.metaboliteSelected = false;
      this.selectedMetabolitePkValue = 0;
    }
  }

  ngOnInit() {
    this.excludeMetabolite();
    this.buttonLabel = this.getButtonLabel();
    this.emitCompoundSelection();
  }

  excludeMetabolite() {
    this.endpointByCompartmentLogicService.excludeMetabolite$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value != undefined) {
        this.excludeMetaboliteFromAI = value;
      }
    });
  }

  public onActiveIngredientClick(): void {
    this.endpointByCompartmentLogicService.excludeMetaboliteFromAI(undefined);
    this.metaboliteSelected = false;
    this.selectedMetabolitePkValue = 0;
    this.selectedActiveIngredientPk.emit(this.moleculePk);
    this.activeButtonColor = this.selectedButtonColor;
    this.metaboliteButtonColor = this.unSelectedButtonColor;
    this.endpointByCompartmentLogicService.runSaveObservable();
  }

  public onMetaboliteClick(): void {
    this.selectedActiveIngredientPk.emit(undefined);
    this.metaboliteSelected = true;
    this.selectedMetabolitePkValue = 0;
    this.activeButtonColor = this.unSelectedButtonColor;
    this.metaboliteButtonColor = this.selectedButtonColor;
    this.endpointByCompartmentLogicService.runSaveObservable();
  }

  public getSelectedMetabolite(dropdownEvent: DropdownChangeEvent): void {
    this.selectedMetabolitePkValue = dropdownEvent.value.metabolitePk;
    this.selectedActiveIngredientPk.emit(undefined);
    this.selectedMetabolitePk.emit(dropdownEvent.value.metabolitePk);
    this.establishIfMetaboliteIsUsedAsPrecursor(dropdownEvent.value.metabolitePk);
    this.endpointByCompartmentLogicService.runSaveObservable();
  }

  establishIfMetaboliteIsUsedAsPrecursor(metabolitePk: number): void {
    let metabolite = this.dataset?.endpoints?.find((ep: any) => ep.precursors && ep.precursors.some((precursor: any) => precursor.metabolitePk == metabolitePk));
    if (metabolite && this.dataset?.datasetXMetabolites!.find(x => x.metabolitePk === metabolite?.metabolitePk && x.excludeMetabolite)) {
      this.isMetaboliteUseAsPrecursor = false;
      return;
    }
    this.isMetaboliteUseAsPrecursor = this.dataset?.endpoints?.some((ep: any) => ep.precursors && ep.precursors.some((precursor: any) => precursor.metabolitePk == metabolitePk));
  }

  public onChangeExcludeMetabolite(checkBoxEvent: CheckboxChangeEvent): void {
    this.endpointByCompartmentLogicService.excludeMetaboliteFromAI(checkBoxEvent.checked);
  }

  private emitCompoundSelection(): void {
    if (this.dataset) {
      this.dataset.substanceType == Constants.SUBSTANCE_TYPES.ACTIVE ?
        this.selectedActiveIngredientPk.emit(this.dataset.moleculePk) :
        this.selectedMetabolitePk.emit(this.dataset.metabolitePk);
    } else {
      this.selectedActiveIngredientPk.emit(this.moleculePk);
    }
  }

  private getButtonLabel(): string {
    return (this.dataset != null) ? !this.endpointDataSetLogicService.isInverseModeling ? 'Parent' : 'Active Ingredient' : 'Active Ingredient';
  }

  isDisabled() {
    return !(this.disabled && !this.isMetaboliteUseAsPrecursor);
  }
}
