import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UdlControlledVocabulary } from '../models/udl-controlled-vocabulary.model';
import { EsaUdlOverlapToolService } from './esa-udl-overlap-tool.service';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UdlOverlapToolContainerService {

  private CropUseSampleFileKey = 'CropUseSampleFile';
  private PCTSampleFileKey = 'PCTSampleFile';
  private OnOffConsiderationsSampleFileKey = 'OnOffConsiderationsSampleFile';
  private IndirectRelationshipsSampleFileKey = 'IndirectRelationshipsSampleFile';
  private modelVersion: number = 0;
  private filesMarkedForDeletion: string[] | undefined = undefined;
  private filesToBeUploaded: File[] | undefined = undefined;

  @Output() deleteMarkedForDeletionFilesEvent: EventEmitter<number> = new EventEmitter<number>();
  public uploadProjectFilesEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor(private http: HttpClient, private udlOverlapToolService: EsaUdlOverlapToolService) {
    this.deleteMarkedForDeletionFilesEvent.subscribe(projectPk =>
      this.deleteMarkedForDeletionFiles(projectPk)
    );
    this.uploadProjectFilesEvent.subscribe(projectPk =>
      this.uploadProjectFiles(projectPk)
    );
  }

  setFilesToBeUploaded(modelVersion: number, filesToBeUploaded: File[]) {
    this.modelVersion = modelVersion;
    this.filesToBeUploaded = filesToBeUploaded;
  }

  uploadProjectFiles(projectPk: number): void {
    if (this.filesToBeUploaded) {
      const url = `${environment.BAMS_Api_URL}api/projects/${projectPk}/epa/model/v${this.modelVersion}/files`;
      const formData = new FormData();
      for (let file of this.filesToBeUploaded) {
        formData.append('files', file, file.name);
      }
      this.http.put(url, formData).subscribe();
    }
  }

  downloadDefaultCropUseFile() {
    return this.getFileByKey(this.CropUseSampleFileKey);
  }

  downloadDefaultPCTFile() {
    return this.getFileByKey(this.PCTSampleFileKey);
  }

  downloadDefaultOnOffConsiderationsFile() {
    return this.getFileByKey(this.OnOffConsiderationsSampleFileKey);
  }

  downloadDefaultIndirectRelationshipsFile() {
    return this.getFileByKey(this.IndirectRelationshipsSampleFileKey);
  }

  public downloadProjectFile(projectPk: number, modelVersion: number, filename: string) {
    const url = `${environment.BAMS_Api_URL}api/projects/${projectPk}/epa/model/v${modelVersion}/files/${filename}`;
    this.downloadFile(url);
  }

  private getFileByKey(key: string) {
    return this.udlOverlapToolService.getControlledVocabularyItemsByKey(key).subscribe((data: UdlControlledVocabulary[]) => {
      const filename = data[0].long_value || data[0].short_value || '';
      const url = `${environment.BAMS_Api_URL}api/downloaddefaultfile/${filename}`;
      this.downloadFile(url);
    });
  }

  private downloadFile(url: string) {
    this.http.get(url, { responseType: 'json' }).subscribe((result: any) => {
      if (result.StatusCode) // when returns a StatusCodeResult object of either NotFoundResult, BadRequestResult instead of FileContentResult
        console.log(result);
      else {
        this.downloadFileFromFileConentResult(result);
      }
    });
  }

  private downloadFileFromFileConentResult(fileContentResult: any) {
    const base64String = atob(fileContentResult['FileContents']);
    const base64Uint8Array = new Uint8Array(base64String.length);
    for (let i = 0; i < base64String.length; i++) {
        base64Uint8Array[i] = base64String.charCodeAt(i);
    }

    // get the file contents as a blob
    const blob = new Blob([base64Uint8Array], { type: fileContentResult['ContentType'] });

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileContentResult['FileDownloadName'];
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  public setSelectedMarkedFilesForDeletion(modelVersion: number, filesMarkedForDeletion: string[]) {
    this.modelVersion = modelVersion;
    this.filesMarkedForDeletion = filesMarkedForDeletion;
  }

  private deleteMarkedForDeletionFiles(projectPk: number) {
    if (!this.filesMarkedForDeletion || this.filesMarkedForDeletion.length === 0) return;

    const url = `${environment.BAMS_Api_URL}api/projects/${projectPk}/epa/model/v${this.modelVersion}/files`;
    const filesMarkedForDeletion = this.filesMarkedForDeletion;
    const filesMarkedForDeletionJson = JSON.stringify(filesMarkedForDeletion);
    this.http.delete(url, { body: filesMarkedForDeletionJson } ).subscribe();
  }

  public getFilesValidationErrors(projectPk: number){
    const url = `${environment.BAMS_Api_URL}api/projects/${projectPk}/epa/model/v${this.modelVersion}/validatefiles`;
    return this.http.get<any[]>(url);
  }
}
