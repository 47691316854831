<div [hidden]="disabled">
  <p-dropdown #dropdownList
    *ngIf="!isGroupRendered"
    appendTo="body"
    [styleClass]="isValid ? 'ddlSelection' : 'ddlSelection-invalid'"
    [placeholder]="placeholder"
    [options]="options"
    [optionLabel]="optionLabel"
    [filter]="filterable"
    [filterBy]="optionLabel"
    [scrollHeight]="height"
    [optionValue]="optionValue"
    [showClear]="showClear"
    [(ngModel)]="selectedValue"
    (onChange)="onChange($event)"
    (onClick)="onClick()"
  ></p-dropdown>
</div>
<span *ngIf="disabled" class="text-600">{{getReadOnlySelectedValue()}}</span>
