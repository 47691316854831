import { Injectable } from '@angular/core';
import { Constants } from 'src/app/shared/utils/constants';
import { ActiveIngredientApiService } from 'src/app/shared/services/echo/active-ingredient.api.service';
import { Compartment } from 'src/app/shared/models/echo/compartment';
import { Project } from 'src/app/shared/models/project';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { CropListService } from 'src/app/administrator/regulatory-models/crop-list/crop-list.service';
import { CropList } from 'src/app/shared/models/crop-list';
import { DatafieldValueApiService } from 'src/app/shared/services/echo/data-field-value.api.service';
import { catchError, firstValueFrom, forkJoin, take } from 'rxjs';
import { GeographyApiService } from 'src/app/shared/services/echo/geography.api.service';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { CropInterceptionApiService } from 'src/app/shared/services/crop-interception.api.service';
import { CropInterceptionStep1And2 } from 'src/app/shared/models/crop-interception';
import { CropInterception } from 'src/app/shared/models/crop-interception';
import { CropListMatching } from 'src/app/shared/models/crop-list-matching';
import { CropListMatchingService } from 'src/app/administrator/regulatory-models/crop-list-matching/crop-list-matching.service';
import { ColGroupDef, RowNode } from 'ag-grid-enterprise';
import { ApplicationDateApiService } from 'src/app/shared/services/application-date.api.service';
import { ApplicationDateGroundWater, ApplicationDateSurfaceWater } from 'src/app/shared/models/application-date';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { ApplicationSchemeXActiveIngredientRate, ApplicationSchemeXApplication } from 'src/app/shared/models/application-scheme';
import { PWCApplicationSchemeXApplicationService } from 'src/app/shared/services/pwc-application-scheme-x-application.api.service';
import { ApplicationScheme, applicationSchemeXGeography, SaveStructure } from 'src/app/shared/models/pwc/application-scheme';
import { ApplicationMethod } from 'src/app/shared/models/pwc/application-method';
import { GapXRun } from 'src/app/shared/models/run/gap-x-run';
import { FractionTransformedXEndpoint } from 'src/app/shared/models/run/fraction-transformed-x-endpoint';
import { Utils } from 'src/app/shared/utils/utils';
import { Molecule } from 'src/app/shared/models/echo/molecule';
import * as moment from "moment";
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { EEAGapApiService } from './eea-gap.api.service';


@Injectable({
  providedIn: 'root'
})

export class EEAGapLogicService {

  numerOfApplications: any[] = [];
  bbch: any[] = [];
  appMethods: any[] = [];
  chemicalAppMethods: any[] = [];
  loading: boolean = false;
  columsDef: any;
  rowData: any;
  rowPWC: any;
  geographies: Catalog[] = [];
  cropInterceptions: CropInterception[] = [];
  step1n2CropInterceptions: any = [];
  cropListMatchings: CropListMatching[] = [];
  simulatorYear: any[] = [];
  applicationDateSurfaceWater: ApplicationDateSurfaceWater[] = [];
  applicationDateGroundWater: ApplicationDateGroundWater[] = [];
  activeIngredientRatesColDef: any[] = [];
  aplicationSchemeXApplication: ApplicationSchemeXApplication[] = [];
  detailsColumnDef: any;
  activeIngredientList: Molecule[] = [];
  selectedProject?: Project;

  constructor(private activeIngredientApiService: ActiveIngredientApiService,
    private datasetService: DataSetApiService,
    private datafieldValueService: DatafieldValueApiService,
    private cropListService: CropListService,
    private geographyApiService: GeographyApiService,
    private interceptionApiService: CropInterceptionApiService,
    private cropListMatchingService: CropListMatchingService,
    private applicationDateApiService: ApplicationDateApiService,
    private pwcApplicationSchemeXAppService: PWCApplicationSchemeXApplicationService,
    private gapAService: EEAGapApiService,) {
    this.getNumberOfApplications();
    this.getBBCH();
    this.getSimulationYear();
  }

  getNumberOfApplications() {
    for (let index = 1; index <= 10; index++) {
      this.numerOfApplications.push({ value: index });
    }
  }

  getBBCH() {
    for (let index = 0; index <= 99; index++) {
      var value = index.toString();
      if (value.toString().length == 1) value = "0" + value;
      this.bbch.push({ value: value });
    }
  }

  getSimulationYear() {
    let simulatorInterval: number[] = [10, 20, 30];
    simulatorInterval.forEach((x: any) => {
      this.simulatorYear.push({ value: x })
    });
  }

  addActiveIngredientsRatesToGrid(data: any, columsDef: any, isInverseModeling: boolean, selectedProject: Project, isProjectOwnershipValid: boolean, compartment: string): Promise<boolean> {
    this.selectedProject = selectedProject;
    return new Promise<boolean>((resolve, reject) => {

      const compoundMoleculePks = this.getCompoundMoleculePks(data, selectedProject);

      this.activeIngredientApiService.getBamsMoleculePkAndNameByPks(compoundMoleculePks).pipe(take(1)).subscribe({
        next: (activeIngredients: any) => {
          this.activeIngredientList = activeIngredients;
          this.transformData(data, isInverseModeling);
          const ghaColDefs = this.createActiveIngredientsAsColDef(activeIngredients, isInverseModeling, selectedProject, 'gha', isProjectOwnershipValid, compartment);
          const lbacrColDefs = this.createActiveIngredientsAsColDef(activeIngredients, isInverseModeling, selectedProject, 'lbacr', isProjectOwnershipValid, compartment);
          this.insertRatesTocolumnDefs(columsDef, ghaColDefs, lbacrColDefs, false);
          resolve(true);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  addActiveIngredientsRatesToDetailGrid(data: any, columsDef: any, isInverseModeling: boolean, selectedProject: Project, isProjectOwnershipValid: boolean, compartment: string): void {
    this.transformDetailData(data);
    const ghaColDefs = this.createActiveIngredientsAsColDef(this.activeIngredientList, isInverseModeling, selectedProject, 'gha', isProjectOwnershipValid, compartment);
    const lbacrColDefs = this.createActiveIngredientsAsColDef(this.activeIngredientList, isInverseModeling, selectedProject, 'lbacr', isProjectOwnershipValid, compartment);
    this.insertRatesTocolumnDefs(columsDef, ghaColDefs, lbacrColDefs, true);
  }

  transformData(rowData: any, isInverseModeling: boolean) {
    rowData?.map((row: any) => {
      row.tillage = row.tillage ? 'Yes' : (row.tillage != undefined ? 'No' : null);
      row.geographies = [];
      row.applicationSchemeXGeography.map((geography: any) => {
        const geo = this.geographies.find((x: Catalog) => x.key == geography.geographyPk);
        if (geo) {
          row.geographies.push(geo);
        }
      });
      row.applicationSchemeXApplication.filter((x: ApplicationSchemeXApplication) => Utils.isEmptyValue(x.application_number)).map((app: any) => {
        app.applicationSchemeXActiveIngredientRate.map((rate: any) => {
          row[`rate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.rate;
          row[`minRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.minRate;
          row[`maxRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.maxRate;
          row[`incrementRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.incrementRate;
        });
      });
    });
    this.rowData = rowData;
  }

  transformDetailData(rowData: any) {
    rowData?.map((row: any) => {
      row.children = [];
      row.applicationSchemeXApplication.filter((x: ApplicationSchemeXApplication) => !Utils.isEmptyValue(x.application_number)).map((app: any) => {
        app.applicationSchemeXActiveIngredientRate.map((rate: any) => {
          app[`rate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.rate;
          app[`minRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.minRate;
          app[`maxRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.maxRate;
          app[`incrementRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.incrementRate;
          app.unitOfMeasure = rate.unitOfMeasure;
        });
        app.cropAppDatePk = row.cropAppDatePk;
        app.cropInterceptionPk = row.cropInterceptionPk;
        app.isChild = true;
      });
      row.children = row.applicationSchemeXApplication.filter((x: ApplicationSchemeXApplication) => !Utils.isEmptyValue(x.application_number));
    });
    this.rowData = rowData !== null ? rowData : [];
  }

  createActiveIngredientsAsColDef(activeIngredients: any[], isInverseModeling: boolean, selectedProject: Project, unitOfMeasure: string, isProjectOwnershipValid: boolean, compartment: string): any[] {
    return this.createColDef(activeIngredients, isInverseModeling, selectedProject.useRateGHA ?? false, selectedProject.useRateLBACRE ?? false, unitOfMeasure, isProjectOwnershipValid, compartment);
  }
  createActiveIngredientsAsColDefPWC(activeIngredients: any[], isInverseModeling: boolean, selectedProject: Project, unitOfMeasure: string, isProjectOwnershipValid: boolean, compartment: string): any[] {
    return this.createColDefPWC(activeIngredients, isInverseModeling, selectedProject.useRateGHA ?? false, selectedProject.useRateLBACRE ?? false, unitOfMeasure, isProjectOwnershipValid, compartment);
  }
  createColDefPWC(activeIngredients: any, isInverseModeling: boolean, useRateGHA: boolean, useRateLBACRE: boolean, unitOfMeasure: string, isProjectOwnershipValid: boolean, compartment: string): any[] {
    let rateColdef: any[] = [];
    activeIngredients?.forEach((activeIngredient: any) => {
      if (isInverseModeling) {
        if ((useRateGHA && unitOfMeasure == 'gha') || (useRateLBACRE && unitOfMeasure == 'lbacr')) {
          rateColdef.push(
            {
              headerName: activeIngredient.moleculeName,
              children: [{
                headerName: 'Min',
                field: `minRate${unitOfMeasure}${activeIngredient.moleculePk}`,
                type: 'default',
                filter: 'agSetColumnFilter',
                width: 90,
                resizable: true,
                editable: false,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                      disabled: !isProjectOwnershipValid,
                }, 
                cellStyle: (params: any) => { 
                    return !isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null; 
                } 
              },
              {
                headerName: 'Max',
                field: `maxRate${unitOfMeasure}${activeIngredient.moleculePk}`,
                type: 'default',
                filter: 'agSetColumnFilter',
                width: 90,
                resizable: true,
                editable: false,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                      disabled: !isProjectOwnershipValid,
                }, 
                cellStyle: (params: any) => { 
                    return !isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null; 
                } 
              },
              {
                headerName: 'Increment',
                field: `incrementRate${unitOfMeasure}${activeIngredient.moleculePk}`,
                type: 'default',
                filter: 'agSetColumnFilter',
                width: 100,
                resizable: true,
                editable: false,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                      disabled: !isProjectOwnershipValid,
                }, 
                cellStyle: (params: any) => { 
                    return !isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null; 
                } 
              }]
            }
          );
        }
        if (!useRateGHA && unitOfMeasure == 'gha') {
          rateColdef.push(
            {
              headerName: activeIngredient.moleculeName,
              children: [{
                headerName: '',
                field: `rate${unitOfMeasure}${activeIngredient.moleculePk}`,
                type: 'default',
                filter: 'agSetColumnFilter',
                wrapText: true,
                wrapHeaderText: true,
                width: 150,
                resizable: true,
                hide: false,
                editable: false,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                      disabled: !isProjectOwnershipValid,
                }, 
                cellStyle: (params: any) => { 
                    return !isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null; 
                } 
              }]
            }
          );
        }
      }

      if (!isInverseModeling) {
        rateColdef.push(
          {
            headerName: activeIngredient.moleculeName,
            field: `rate${unitOfMeasure}${activeIngredient.moleculePk}`,
            type: 'default',
            filter: 'agSetColumnFilter',
            minWidth: 150,
            width: 150,
            maxWidth: 150,
            hide: false,
            editable: true,
            cellDataType: 'number',
            onCellValueChanged: (params: any) => {
            },
            cellRendererParams: (params: any) => {
              return {
                disabled: !isProjectOwnershipValid || params.data.hasVariableRates && compartment == Constants.COMPARTMENTS.GROUND_WATER
              }
            }, 
            cellStyle: (params: any) => { 
                return !isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null; 
            } 
          }
        );
      }
    });
    return rateColdef;
  }
  createColDef(activeIngredients: any, isInverseModeling: boolean, useRateGHA: boolean, useRateLBACRE: boolean, unitOfMeasure: string, isProjectOwnershipValid: boolean, compartment: string): any[] {
    let rateColdef: any[] = [];
    activeIngredients?.forEach((activeIngredient: any) => {
      if (isInverseModeling) {
        if ((useRateGHA && unitOfMeasure == 'gha') || (useRateLBACRE && unitOfMeasure == 'lbacr')) {
          rateColdef.push(
            {
              headerName: activeIngredient.moleculeName,
              children: [{
                headerName: 'Min',
                field: `minRate${unitOfMeasure}${activeIngredient.moleculePk}`,
                type: 'default',
                filter: 'agSetColumnFilter',
                width: 90,
                resizable: true,
                editable: false,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                      disabled: !isProjectOwnershipValid,
                }, 
                cellStyle: (params: any) => { 
                    return !isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null; 
                } 
              },
              {
                headerName: 'Max',
                field: `maxRate${unitOfMeasure}${activeIngredient.moleculePk}`,
                type: 'default',
                filter: 'agSetColumnFilter',
                width: 90,
                resizable: true,
                editable: false,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                      disabled: !isProjectOwnershipValid,
                }, 
                cellStyle: (params: any) => { 
                    return !isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null; 
                } 
              },
              {
                headerName: 'Increment',
                field: `incrementRate${unitOfMeasure}${activeIngredient.moleculePk}`,
                type: 'default',
                filter: 'agSetColumnFilter',
                width: 100,
                resizable: true,
                editable: false,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                      disabled: !isProjectOwnershipValid,
                }, 
                cellStyle: (params: any) => { 
                    return !isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null; 
                } 
              }]
            }
          );
        }
        if (!useRateGHA && unitOfMeasure == 'gha') {
          rateColdef.push(
            {
              headerName: activeIngredient.moleculeName,
              children: [{
                headerName: '',
                field: `rate${unitOfMeasure}${activeIngredient.moleculePk}`,
                type: 'default',
                filter: 'agSetColumnFilter',
                wrapText: true,
                wrapHeaderText: true,
                width: 150,
                resizable: true,
                hide: false,
                editable: false,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                      disabled: !isProjectOwnershipValid,
                }, 
                cellStyle: (params: any) => { 
                    return !isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null; 
                } 
              }]
            }
          );
        }
      }

      if (!isInverseModeling) {
        rateColdef.push(
          {
            headerName: activeIngredient.moleculeName,
            field: `rate${unitOfMeasure}${activeIngredient.moleculePk}`,
            type: 'default',
            filter: 'agSetColumnFilter',
            minWidth: 150,
            width: 150,
            maxWidth: 150,
            hide: false,
            editable: false,
            cellRenderer: InputNumberRenderer,
            cellRendererParams: (params: any) => {
              return {
                  disabled: (params.data.hasVariableRates && compartment == Constants.COMPARTMENTS.GROUND_WATER) || !isProjectOwnershipValid,
              }
            }, 
            cellStyle: (params: any) => { 
                return !isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null; 
            } 
          }
        );
      }
    });
    return rateColdef;
  }

  insertRatesTocolumnDefs(columnsDef: ColGroupDef[], rateGHAcolumnDefs: ColGroupDef[], rateLBACREcolumnDefs: ColGroupDef[], isDetail: boolean) {
    columnsDef.map((columnDef: any) => {
      if (columnDef.headerName === Constants.COLUMN_NAMES.RATEGHA) {
        this.activeIngredientRatesColDef = rateGHAcolumnDefs;
        columnDef.children = rateGHAcolumnDefs;
        if (!this.selectedProject?.useRateGHA) {
          columnDef = rateGHAcolumnDefs;
        }
      }
      if (columnDef.headerName === Constants.COLUMN_NAMES.RATELBACRE)
        columnDef.children = rateLBACREcolumnDefs;
    });
    if (isDetail)
      return this.detailsColumnDef = columnsDef;
    return this.columsDef = columnsDef;
  }

  setParametersToNewRows(dataTransaction: any[], compartmentPk: number, isInverseModeling: boolean, projectPk: number, isCore: boolean, compartment: string | null = null, cropListMatchings: CropListMatching[] = [], selectedGeography: string | undefined = undefined) {
    dataTransaction.forEach(x => {
      if (x.transactionType == 1 || x.transactionType == 2) {
        x.row.cropPk = isCore ? x.row.cropPk : this.setInterceptionAsCropPk(x, cropListMatchings, compartment, selectedGeography);
        x.row.compartmentPk = compartmentPk;
        x.row.projectPk = projectPk;
        x.row.applicationSchemeXActiveIngredientRate = this.setApplicationSchemeXActiveIngredientRate(x.row, isInverseModeling);
        x.row.applicationSchemeXActiveIngredientRate = [...x.row.applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(x.row, isInverseModeling)]
        x.row.applicationSchemeXApplication = this.setApplicationSchemeXApplication(x.row, isInverseModeling, compartment);
        x.row.applicationSchemeXActiveIngredientRate = this.includeParentRatesForGW(x.row, compartment);
        x.row.applicationSchemeXGeography = selectedGeography == Constants.CROP_GEOGRAPHIES.UK ? [{ geographyPK: this.geographies.find((x: Catalog) => x.name == Constants.CROP_GEOGRAPHIES.UK)?.key }] : x.row.applicationSchemeXGeography;
      }
    })
  }

  includeParentRatesForGW(row: any, compartment: string | null,): any {
    if (this.excludeParentRates(row, compartment)) {
      return [];
    }
    return row.applicationSchemeXActiveIngredient;
  }

  excludeParentRates(row: any, compartment: string | null): boolean {
    return compartment === Constants.COMPARTMENTS.GROUND_WATER && row.hasVariableRates;
  }

  setApplicationSchemeXApplication(data: any, isInverseModeling: boolean, compartment: string | null): any[] {
    let applications: any[] = [];
    applications.push({
      application_number: null,
      min_application_interval: data.applicationInterval,
      days_since: 0,
      application_interval: 0,
      earliest_bbch: data.bbchEarliest,
      crop_interception: 0,
      application_scheme_pk: data.applicationSchemePk,
      applicationSchemeXActiveIngredientRate: data.applicationSchemeXActiveIngredientRate,
      applicationSchemeXApplicationWindow: data.applicationSchemeXApplicationWindow,
    });
    if (data.hasVariableRates) {
      data?.children?.forEach((element: any, index: number) => {
        applications.push({
          application_number: index + 1,
          min_application_interval: element.min_application_interval,
          application_interval: element.application_interval,
          days_since: element.days_since,
          earliest_bbch: element.earliest_bbch,
          crop_interception: element.crop_interception,
          application_scheme_pk: data.applicationSchemePk,
          applicationSchemeXActiveIngredientRate: this.getAIRatesFromApplicationChildren(element),
          applicationSchemeXApplicationWindow: element.applicationSchemeXApplicationWindow,
        });
      });
    }
    return applications;
  }

  setInterceptionAsCropPk(transaction: any, cropListMatchings: CropListMatching[], compartment: string | null, selectedGeography: string | undefined): number | null {
    if (transaction.transactionType == 2) {
      return this.getCropPkFromMatching(transaction, cropListMatchings, compartment, selectedGeography);
    } else if (transaction.transactionType == 1) {
      if (transaction.row.coreApplicationSchemePk) {
        return transaction.row.cropPk;
      }
      else {
        return this.getCropPkFromMatching(transaction, cropListMatchings, compartment, selectedGeography);
      }
    }
    return null;
  }

  getCropPkFromMatching(transaction: any, cropListMatchings: CropListMatching[], compartment: string | null, selectedGeography: string | undefined): number | null {
    let matching = null;
    let isEu = true;
    if (compartment === Constants.COMPARTMENTS.SURFACE_WATER && selectedGeography === Constants.GEOGRAPHYS.EUROPE_UNION) {
      matching = cropListMatchings.find((x: CropListMatching) => (x.compartment === compartment) && (x.geography === selectedGeography) && (x.driftInterceptionCrops.includes(transaction?.row?.cropDriftInterceptionPk)));
    } else {
      isEu = false;
      matching = cropListMatchings.find((x: CropListMatching) => (x.geography === selectedGeography) && (x.interceptionCrops.includes(transaction?.row?.cropInterceptionPk)));
    }
    if (matching) {
      return isEu ? matching.driftInterceptionCrops[0] : matching.interceptionCrops[0];
    }
    return null;
  }

  setActiveIngredientsRate(dataTransaction: any[], isCore: boolean, compartments: Compartment[], isInverseModeling: boolean, selectedCompartment: string) {
    dataTransaction?.forEach(data => {
      data.row.isCore = isCore;
      data.row.compartments = this.getGapCompartments(compartments);
      if (selectedCompartment === Constants.COMPARTMENTS.GROUND_WATER) {
        if (data?.row?.hasVariableRates) {
          data.row.applicationSchemeXActiveIngredientRate = this.getAIRatesChildren(data, isInverseModeling);
        }
        else {
          data.row.applicationSchemeXActiveIngredientRate?.map((x: any) => {
            x.rate = data.row[`rate${x.unitOfMeasure}${x.moleculePk}`];
            x.minRate = data.row[`minRate${x.unitOfMeasure}${x.moleculePk}`];
            x.maxRate = data.row[`maxRate${x.unitOfMeasure}${x.moleculePk}`];
            x.incrementRate = data.row[`incrementRate${x.unitOfMeasure}${x.moleculePk}`];
            x.applicationInterval = data.row.applicationInterval;
          });
        }
      }
      else {
        data.row.applicationSchemeXActiveIngredientRate?.filter((f: any) => Utils.isEmptyValue(f.applicationNumber)).map((x: any) => {
          x.rate = data.row[`rate${x.unitOfMeasure}${x.moleculePk}`];
          x.minRate = data.row[`minRate${x.unitOfMeasure}${x.moleculePk}`];
          x.maxRate = data.row[`maxRate${x.unitOfMeasure}${x.moleculePk}`];
          x.incrementRate = data.row[`incrementRate${x.unitOfMeasure}${x.moleculePk}`];
          x.applicationInterval = data.row.applicationInterval;
        });
      }
    });
  }

  setApplicationWindow(dataTransaction: any[], compartment: string) {
    dataTransaction.forEach(x => {
      if ((x.transactionType == 1 || x.transactionType == 2)) {
        x.row.applicationSchemeXApplicationWindow?.forEach((date: any) => {
          date.firstDate = Utils.formatIsoDateToCustom(date.firstDate);
          if (compartment === Constants.COMPARTMENTS.SURFACE_WATER) {
            date.endDate = Utils.formatIsoDateToCustom(Utils.GetEndDateByApplication(date.firstDate, x.row.applicationInterval, x.row.numberOfApplications));
          }
        })
        x.row.applicationSchemeXApplication?.forEach((app: any) => {
          app.applicationSchemeXApplicationWindow?.forEach((date: any) => {
            date.firstDate = Utils.formatIsoDateToCustom(date.firstDate);
          })
        });
      };
    })
  }

  getAIRatesChildren(data: any, isInverseModeling: boolean): ApplicationSchemeXActiveIngredientRate[] {
    let rates: ApplicationSchemeXActiveIngredientRate[] = [];
    data.row.children?.forEach((child: any) => {
      Object.entries(child).forEach(([key, value]) => {
        child.unitOfMeasure = child.unitOfMeasure == undefined ? 'gha' : child.unitOfMeasure;
        if (key.startsWith(Constants.GAP_FIELD_NAMES.RATE + child.unitOfMeasure)) {
          let moleculePk = key.substring((Constants.GAP_FIELD_NAMES.RATE + child.unitOfMeasure).length);
          rates.push({
            moleculePk: Number(moleculePk),
            rate: value,
            minRate: child[`minRate${child.unitOfMeasure}${moleculePk}`],
            maxRate: child[`maxRate${child.unitOfMeasure}${moleculePk}`],
            incrementRate: child[`incrementRate${child.unitOfMeasure}${moleculePk}`],
            unitOfMeasure: child.unitOfMeasure,
          } as ApplicationSchemeXActiveIngredientRate);
        }
      });
    });
    return rates;
  }

  getAIRatesFromApplicationChildren(data: any): ApplicationSchemeXActiveIngredientRate[] {
    let rates: ApplicationSchemeXActiveIngredientRate[] = [];
    Object.entries(data).forEach(([key, value]) => {
      data.unitOfMeasure = data.unitOfMeasure == undefined ? 'gha' : data.unitOfMeasure;
      if (key.startsWith(Constants.GAP_FIELD_NAMES.RATE + data.unitOfMeasure)) {
        let moleculePk = key.substring((Constants.GAP_FIELD_NAMES.RATE + data.unitOfMeasure).length);
        rates.push({
          moleculePk: Number(moleculePk),
          rate: value,
          minRate: data[`minRate${data.unitOfMeasure}${moleculePk}`],
          maxRate: data[`maxRate${data.unitOfMeasure}${moleculePk}`],
          incrementRate: data[`incrementRate${data.unitOfMeasure}${moleculePk}`],
          unitOfMeasure: data.unitOfMeasure,
        } as ApplicationSchemeXActiveIngredientRate);
      }
    });
    return this.addMissingActiveIngredientRatesToApplication(rates);
  }

  setMissingApplicationSchemeXActiveIngredientRate(data: any, isInverseModeling: boolean): any[] {
    let activeIngredients: any[] = []
    this.activeIngredientRatesColDef.forEach((x: any) => {
      const fieldName = isInverseModeling && this.selectedProject?.useRateGHA ? "minRategha" : "rategha";
      let moleculePk = x.field?.toLowerCase().replace(fieldName.toLowerCase(), "");
      if (moleculePk) {
        let existMolecule = data.applicationSchemeXActiveIngredientRate.find((x: any) => x.moleculePk == moleculePk);
        if (!existMolecule) {
          activeIngredients.push({
            //TODO-GEORGE-This line was removed in order to save the data as there is an ongoing issue with the Rate save procedure.
            //moleculePk,
            rate: null,
            unitOfMeasure: 'gha'
          });
        }
      }
    })
    return activeIngredients;
  }

  addMissingActiveIngredientRatesToApplication(rates: ApplicationSchemeXActiveIngredientRate[]): ApplicationSchemeXActiveIngredientRate[] {
    let activeIngredients: any[] = []
    this.activeIngredientRatesColDef.forEach((x: any) => {
      const fieldName = "rategha";
      let moleculePk = x.field?.toLowerCase().replace(fieldName.toLowerCase(), "");
      if (moleculePk) {
        let existMolecule = rates.find((x: any) => x.moleculePk == moleculePk);
        if (!existMolecule) {
          activeIngredients.push({
            moleculePk,
            rate: null,
            unitOfMeasure: 'gha'
          });
        }
      }
    })
    return [...rates, ...activeIngredients];
  }

  setApplicationSchemeXActiveIngredientRate(data: any, isInverseModeling: boolean): any[] {
    let activeIngredients: any[] = [];
    if (isInverseModeling) {
      Object.keys(data).forEach((key) => {
        if (key.startsWith('minRategha') && this.selectedProject?.useRateGHA) {
          const moleculePk = key.replace('minRategha', '');
          activeIngredients.push({
            moleculePk,
            rate: data[`rategha${moleculePk}`],
            minRate: data[`minRategha${moleculePk}`],
            maxRate: data[`maxRategha${moleculePk}`],
            incrementRate: data[`incrementRategha${moleculePk}`],
            unitOfMeasure: 'gha'
          });
        }

        if (key.startsWith('minRatelbacr') && this.selectedProject?.useRateLBACRE) {
          const moleculePk = key.replace('minRatelbacr', '');
          activeIngredients.push({
            moleculePk,
            rate: data[`ratelbacr${moleculePk}`],
            minRate: data[`minRatelbacr${moleculePk}`],
            maxRate: data[`maxRatelbacr${moleculePk}`],
            incrementRate: data[`incrementRatelbacr${moleculePk}`],
            unitOfMeasure: 'lbacr'
          });
        }

        if (key.startsWith('rategha') && !this.selectedProject?.useRateGHA) {
          const moleculePk = key.replace('rategha', '');
          activeIngredients.push({
            moleculePk,
            rate: data[key],
            minRate: data[`minRategha${moleculePk}`],
            maxRate: data[`maxRategha${moleculePk}`],
            incrementRate: data[`incrementRategha${moleculePk}`],
            unitOfMeasure: 'gha',
            applicationNumber: data.applicationNumber,
            applicationInterval: data.applicationInterval,
          });
        }

        if (key.startsWith('ratelbacr') && !this.selectedProject?.useRateLBACRE) {
          const moleculePk = key.replace('ratelbacr', '');
          activeIngredients.push({
            moleculePk,
            rate: data[key],
            minRate: data[`minRatelbacr${moleculePk}`],
            maxRate: data[`maxRatelbacr${moleculePk}`],
            incrementRate: data[`incrementRatelbacr${moleculePk}`],
            unitOfMeasure: 'lbacr'
          });
        }
      });
    }

    if (!isInverseModeling) {
      Object.keys(data).forEach((key) => {
        if (key.startsWith('rategha')) {
          const moleculePk = key.replace('rategha', '');
          activeIngredients.push({
            moleculePk,
            rate: data[key],
            minRate: data[`minRategha${moleculePk}`],
            maxRate: data[`maxRategha${moleculePk}`],
            incrementRate: data[`incrementRategha${moleculePk}`],
            unitOfMeasure: 'gha',
            applicationNumber: data.applicationNumber,
            applicationInterval: data.applicationInterval,
          });
        }
        if (key.startsWith('ratelbacr')) {
          const moleculePk = key.replace('ratelbacr', '');
          activeIngredients.push({
            moleculePk,
            rate: data[key],
            minRate: data[`minRatelbacr${moleculePk}`],
            maxRate: data[`maxRatelbacr${moleculePk}`],
            incrementRate: data[`incrementRatelbacr${moleculePk}`],
            unitOfMeasure: 'lbacr'
          });
        }
      });
    }
    return activeIngredients;
  }

  setApplicationSchemeXActiveIngredientRatePWC(data: any, isInverseModeling: boolean): any[] {
    let activeIngredients: any[] = [];
    if (!isInverseModeling) {
      Object.keys(data).forEach((key) => {
        if (key.startsWith('ratekg/ha')) {
          activeIngredients.push({
            moleculePk: key.replace('ratekg/ha', ''),
            rate: data[key],
            unitOfMeasure: 'kg/ha'
          });
        }
        if (key.startsWith('ratelbacr')) {
          activeIngredients.push({
            moleculePk: key.replace('ratelbacr', ''),
            rate: data[key],
            unitOfMeasure: 'lbacr'
          });
        }
      });
    }
    return activeIngredients;
  }

  getGapCompartments(compartments: Compartment[]) {
    let gapCompartments = [Constants.COMPARTMENTS.GROUND_WATER, Constants.COMPARTMENTS.SOIL, Constants.COMPARTMENTS.SURFACE_WATER];
    return compartments.filter(x => gapCompartments.includes(x.compartment))?.map(x => {
      return {
        pk: x.endpointCompartmentPk,
        name: x.compartment
      };
    });
  }

  getCompartments(): Promise<any[]> {
    return new Promise<number[]>((resolve, reject) => {
      this.datasetService.getCompartments(Constants.CONST_COMPARTMENT).subscribe({
        next: (comparments: any) => {
          resolve(comparments);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getPWCCropEvents() {
    return Constants.CROP_EVENTS_PWC;
  }

  getPWCApplicationMethods() {
    return Constants.PWC_APPLICATION_METHODS;
  }

  getPWCDriftTypes() {
    return Constants.PWC_APPLICATION_DRIFT_TYPES;
  }
  getDataFieldValueByListName(dataFieldList: string[]): Promise<any[]> {
    return new Promise<number[]>((resolve, reject) => {
      forkJoin({
        dataFields: this.datafieldValueService.getDatafieldData(Constants.THIS_APPLICATION),
        dataFieldValues: this.datafieldValueService.getDatafieldValueInformation(),
      })
        .pipe(
          catchError((error) => {
            console.warn(error);
            reject(error);
            return [];
          })
        )
        .subscribe({
          next: ({ dataFields, dataFieldValues }) => {
            resolve(this.matchDatafields(dataFields, dataFieldValues, dataFieldList))
          },
        });
    });
  }

  getDataFieldValues(): Promise<DatafieldValue[]> {
    return new Promise<DatafieldValue[]>((resolve, reject) => {
      forkJoin({
        dataFieldValues: this.datafieldValueService.getDatafieldValueInformation(),
      })
        .pipe(
          catchError((error) => {
            console.warn(error);
            reject(error);
            return [];
          })
        )
        .subscribe({
          next: ({ dataFieldValues }) => {
            resolve(dataFieldValues)
          },
        });
    });
  }

  private matchDatafields(dataFields: any[], dataFieldValues: any[], dataFieldList: string[]): any {
    let fields = dataFields.filter(x => dataFieldList.includes(x?.value));
    let dataFieldsMatched = {};
    fields.forEach(field => {
      let values = dataFieldValues.filter(x => x.datafieldPk === field.datafieldPk);
      dataFieldsMatched = { ...dataFieldsMatched, ...{ [field.value]: values } }
    });
    return dataFieldsMatched;
  }

  getDisplayedColumnsByModel(models: any, selectedGeography: string) {
    let columns: any[] = [];
    models.forEach((x: string) => {
      let columnModel = Constants.GAP_FIELDS_BY_MODEL.find((m: any) => m.model === x);
      if (columnModel) {
        let geoColumns = columnModel.geographies.find((g: any) => g.name === selectedGeography)?.columns ?? [];
        columns = [...columns, ...columnModel.columns, ...geoColumns];
      }
    });
    return columns;
  }

  getFixedColumns(columns: any[], columnsToFix: any[]): any[] {
    let fixedColumns: string[] = [];
    columns?.forEach(column => {
      let columnField = column.getColDef().field;
      if (columnsToFix.includes(columnField) || columnField.startsWith('rate')) {
        fixedColumns.push(columnField);
      }
    });
    return fixedColumns;
  }

  getModelsByCompartment(compartment: string, project?: Project): any {
    if (project != null) {
      let selectedModels = project?.projectXCompoundXModel?.map(model => model?.ModelName);
      let modelsByCompartment = Constants.SPECIFIC_MODELS_BY_COMPARTMENT.find(x => x.compartment === compartment)?.models;
      return modelsByCompartment?.filter(model => selectedModels?.includes(model)) ?? [];
    }
  }

  getFilteredColumns(columnsToShow: any[], colDef: any[]): any[] {
    colDef.forEach(group => {
      group?.children?.forEach((child: any) => {
        if (!columnsToShow.includes(child.field)) {
          child.hide = true;
        }
        if (child.children?.length > 0) {
          child.children?.forEach((grandChild: any) => {
            if (!columnsToShow.includes(grandChild.field)) {
              grandChild.hide = true;
            }
          });
        }
      })
    })
    return colDef;
  }

  getCropList(Geography: string, pCompartment?: string, pPurpose?: string): Promise<CropList[]> {
    return new Promise<CropList[]>((resolve, reject) => {
      this.cropListService.getCropList(Geography, pCompartment, pPurpose).subscribe({
        next: (cropList: CropList[]) => {
          resolve(cropList);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getAllCropList(): Promise<CropList[]> {
    return new Promise<CropList[]>((resolve, reject) => {
      this.cropListService.getAllCropsList().subscribe({
        next: (cropList: CropList[]) => {
          resolve(cropList);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getRegionCountry(currentProject: Project): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      forkJoin({
        regions: this.geographyApiService.getRegionWithCountries(),
        countries: this.geographyApiService.getCountries(),
      })
        .pipe(
          catchError((error) => {
            console.warn(error);
            reject(error);
            return [];
          })
        )
        .subscribe({
          next: ({ regions, countries }) => {
            resolve(this.matchRegionsCountries(regions, countries, currentProject));
          },
        });
    });
  }

  getGeographies(): Promise<Catalog[]> {
    return new Promise<Catalog[]>((resolve, reject) => {
      this.geographyApiService.getRegionCountryCatalog()
        .subscribe({
          next: (geographies: Catalog[]) => {
            resolve(geographies);
          },
          error: (error: Error) => {
            reject(error);
          }
        });
    });
  }

  getStep1n2Interceptions(): Promise<CropInterceptionStep1And2[]> {
    return new Promise<CropInterceptionStep1And2[]>((resolve, reject) => {
      this.interceptionApiService.getCropInterceptionStep1And2()
        .subscribe({
          next: (interceptions: CropInterceptionStep1And2[]) => {
            this.step1n2CropInterceptions = interceptions;
            resolve(interceptions);
          },
          error: (error: Error) => {
            reject(error);
          }
        });
    });
  }

  matchRegionsCountries(regions: any, countries: any, currentProject: Project) {
    this.geographies = [];
    if (currentProject.regionPk) {
      let selectedRegion = regions.filter((x: any) => x.regionPk == currentProject.regionPk);
      selectedRegion[0].countries?.forEach((c: any) => {
        this.geographies.push({
          key: c,
          source: 'Country',
          name: countries.find((n: any) => n.countryPk == c).name,
          active: true,
          restricted: false,
        });
      });
    }
    else if (currentProject.countryPk) {
      if (countries.find((c: any) => c.countryPk == currentProject.countryPk).name == Constants.CROP_GEOGRAPHIES.USA) {
        let regionsXCountry = regions.filter((x: any) => x.countries.includes(currentProject.countryPk));
        regionsXCountry.forEach((x: any) => {
          this.geographies.push({
            key: x.regionPk,
            source: 'Region',
            name: x.name,
            active: true,
            restricted: false,
          });
        });
      }
      if (countries.find((c: any) => c.countryPk == currentProject.countryPk).name == Constants.CROP_GEOGRAPHIES.UK) {
        this.geographies.push({
          key: currentProject.countryPk,
          source: 'Country',
          name: 'UK',
          active: true,
          restricted: false,
        });
      }
    }

    return this.geographies;
  }

  getCropInterceptions(): Promise<CropInterception[]> {
    return new Promise<CropInterception[]>((resolve, reject) => {
      this.interceptionApiService.getCropInterception().subscribe({
        next: (cropInterceptions: CropInterception[]) => {
          this.cropInterceptions = cropInterceptions;
          resolve(cropInterceptions);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  setCropInterceptionByCropAndBBCH(row: any, compartment: string) {
    if (compartment === Constants.COMPARTMENTS.GROUND_WATER || compartment === Constants.COMPARTMENTS.SOIL)
      this.setCropInterception(row);
    else if (compartment === Constants.COMPARTMENTS.SURFACE_WATER) {
      if (row.selectedInterceptionModel === Constants.MODELS.UK) {
        this.setCropInterception(row);
      }
      else {
        this.setCropInterceptionStep1And2(row);
      }
    }
  }

  setCropInterception(row: any) {
    if (row.cropInterceptionPk) {
      let interception;
      let bbch = row.bbchEarliest ? row.bbchEarliest : row.earliest_bbch;
      interception = this.cropInterceptions.find(x => (x.cropListPk === row.cropInterceptionPk && (x.minBbch <= bbch && x.maxBbch >= bbch)));
      if (interception) {
        row.crop_interception = interception.interceptionPercentage;
        row.cropInterception = interception.interceptionPercentage;
        row.cropCoverage = interception.stageName;
      }
    }
  }

  getCropCoverageObject(pNumber: number) {
    for (const range in Constants.STEP1N2_INTERCEPTION_RANGES) {
      const [start, end] = range.split("-");
      if (pNumber >= parseInt(start) && pNumber <= parseInt(end)) {
        return Constants.STEP1N2_INTERCEPTION_RANGES[range];
      }
    }
    return null;
  }

  setCropInterceptionStep1And2(pRow: any) {
    let cropCoverageObject = this.getCropCoverageObject(parseInt(pRow.bbchEarliest, 10));
    if (cropCoverageObject) {
      pRow.cropCoverage = cropCoverageObject.rangeDescription;
      let interceptionValue = this.step1n2CropInterceptions.find((x: CropInterceptionStep1And2) => x.cropListPk == pRow.cropDriftInterceptionPk);
      if (interceptionValue) {
        pRow.cropInterception = Number(interceptionValue[cropCoverageObject.rangeColumnName]) * 100;
      }
    }
  }

  setCropCoverage(pRow: any) {
    if (pRow.bbchEarliest) {
      let cropCoverageObject = this.getCropCoverageObject(parseInt(pRow.bbchEarliest, 10))
      if (cropCoverageObject) {
        pRow.cropCoverage = cropCoverageObject.rangeDescription;
      }
    }
  }

  getPwcApplicationSchemeXApplicationPk(applicationSchemePk: number): Promise<ApplicationSchemeXApplication[]> {
    return new Promise<ApplicationSchemeXApplication[]>((resolve, reject) => {
      this.pwcApplicationSchemeXAppService.getApplicationSchemeXApplicationPK(applicationSchemePk).subscribe({
        next: (appSchemeXApp: ApplicationSchemeXApplication[]) => {
          this.aplicationSchemeXApplication = appSchemeXApp;
          resolve(appSchemeXApp);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getCropListMatchings(): Promise<CropListMatching[]> {
    return new Promise<CropListMatching[]>((resolve, reject) => {
      this.cropListMatchingService.getCropListMatchings().subscribe({
        next: (cropListMatchings: CropListMatching[]) => {
          this.cropListMatchings = cropListMatchings;
          resolve(cropListMatchings);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getApplicationDatesSurfaceWaterByCropListPkAndBBCH(cropAppDatePk: number, bbch: number): Promise<ApplicationDateSurfaceWater[]> {
    return new Promise<ApplicationDateSurfaceWater[]>((resolve, reject) => {
      this.applicationDateApiService.getApplicationDatesSurfaceWaterByCropListPkAndBBCH(cropAppDatePk, bbch).subscribe({
        next: (appDatesSW: ApplicationDateSurfaceWater[]) => {
          this.applicationDateSurfaceWater = appDatesSW;
          resolve(appDatesSW);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getApplicationDatesGroundWaterByCropListPkAndBBCH(cropAppDatePk: number, bbch: number, cropInterceptionPk: number): Promise<ApplicationDateGroundWater[]> {
    return new Promise<ApplicationDateGroundWater[]>((resolve, reject) => {
      this.applicationDateApiService.getApplicationDatesGroundWaterByCropListPkAndBBCH(cropAppDatePk, bbch, cropInterceptionPk).subscribe({
        next: (appDatesGW: ApplicationDateGroundWater[]) => {
          this.applicationDateGroundWater = appDatesGW;
          resolve(appDatesGW);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  createTransactionsForRates(dataTransaction: any[], grid: GridComponent, isInverseModeling: boolean) {
    grid.gridApi.forEachNode((node: RowNode) => {
      let transaction = dataTransaction.find((x: any) => x.rowUniqueID === node.id);
      if (transaction) {
        if ((transaction.transactionType == 1 || transaction.transactionType == 2) && transaction.rowUniqueID === node.id) {
          if (!transaction.row?.applicationSchemeXActiveIngredientRate) {
            transaction.row.applicationSchemeXActiveIngredientRate = [];
          }
          transaction.row.applicationSchemeXActiveIngredientRate = [...transaction.row.applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(transaction.row, isInverseModeling)]
        }
      } else {
        if (!node.data?.applicationSchemeXActiveIngredientRate) {
          node.data.applicationSchemeXActiveIngredientRate = [];
        }
        let rates = [...node.data.applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(node.data, isInverseModeling)];
        if (this.validateRateChanges(node, rates)) {
          node.data.applicationSchemeXActiveIngredientRate = rates;
          grid.CreateTransaction(node.id, node.id, null, node.data);
        }
      }
    })
  }

  transformPWCDetailsObjectToApplicationSchemeXApp(dataTransaction: any[]) {
    let applicationSchemeXApp: ApplicationSchemeXApplication[] = [];
    let applicationSchemeRates: ApplicationSchemeXActiveIngredientRate[] = [];
    dataTransaction?.forEach(data => {
      let children: ApplicationSchemeXApplication[] = data.row.children;
      if (children !== undefined && children.length > 0) {

        applicationSchemeXApp = [...children];
        data.row.applicationSchemeXApplication = [...new Set(applicationSchemeXApp)];
        applicationSchemeRates = this.setAIRateFromPWCObject(children);
        data.row.windowsDaysToWindowsDays = Number(data.row.windowsDaysToWindowsDays == undefined ? 0 : data.row.windowsDaysToWindowsDays);
        data.row.stepDaysToStepDays = Number(data.row.stepDaysToStepDays);
        data.row.applicationSchemeXActiveIngredientRate = [...new Set(applicationSchemeRates)];

      }
    });
  }

  mapDataTransactionToApplicationSchemePWC(dataTransaction: any[]): ApplicationSchemeXApplication[] {
    let applicationSchemeXApp: ApplicationSchemeXApplication[] = [];

    dataTransaction.forEach(data => {
      let children: ApplicationSchemeXApplication[] = data.row.children;
      let RatesByApplications: ApplicationSchemeXActiveIngredientRate[] = [];
      if (children && children.length > 0) {
        applicationSchemeXApp = [...applicationSchemeXApp, ...children];
        if (applicationSchemeXApp) {
          applicationSchemeXApp.forEach(app => {
            RatesByApplications = this.setAIRateFromPWCObject(app);
            data.row.applicationSchemeXActiveIngredientRate = [...new Set(RatesByApplications)];
          });
        }
      }
    });

    return applicationSchemeXApp;
  }
  addActiveIngredientsRatesToGridPWC(data: any, columsDef: any, isInverseModeling: boolean, selectedProject: Project, isProjectOwnershipValid: boolean, compartment: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const compoundMoleculePks = this.getCompoundMoleculePks(data, selectedProject);
      this.activeIngredientApiService.getMoleculeByMoleculePks(compoundMoleculePks).pipe(take(1)).subscribe({
        next: (activeIngredients: any) => {
          this.transformDataPWC(data, isInverseModeling);
          const ghaColDefs = this.createActiveIngredientsAsColDefPWC(activeIngredients, isInverseModeling, selectedProject, 'kg/ha', isProjectOwnershipValid, compartment);
          this.insertRatesTocolumnDefsPWC(columsDef, ghaColDefs);
          resolve(true);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  insertRatesTocolumnDefsPWC(columDef: ColGroupDef[], rateGHAcolumnDefs: ColGroupDef[]) {
    columDef.map((columDef: any) => {
      if (columDef.headerName === Constants.COLUMN_NAMES.RATEKGHA)
        columDef.children = rateGHAcolumnDefs;

    });
    this.columsDef = columDef;
  }

  transformDataPWC(data: any, isInverseModeling: boolean) {
    data = data?.map((row: any) => {
      row.tillage = row.tillage ? 'Yes' : (row.tillage != undefined ? 'No' : null);
      row.geographies = [];
      if (row?.applicationSchemeXGeography) {
        row.applicationSchemeXGeography.forEach((geography: any) => {
          const geo = this.geographies.find((x: Catalog) => x.key == geography.geographyPk);
          if (geo) {
            row.geographies.push(geo);
          }
        });
      }
      if (!isInverseModeling) {
        let children: any[] = [];
        row?.applicationSchemeXApplication.forEach((application: any) => {
          if (application) {
            application.dateType = row?.dateType;
            let ratetemp = row?.applicationSchemeXActiveIngredientRate.filter((r: any) => r.application_scheme_x_application_pk == application.application_scheme_x_application_pk);
            if (ratetemp) {
              ratetemp.forEach((rate: any) => {
                application[`rate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.rate;
              });
              children.push(application);
            }
          }
        });
        row.children = children;
      }
      return row;
    });

    this.rowPWC = data;
  }

  setAIRateFromPWCObject(data: any): any {
    let activeIngredients: any[] = [];
    Object.keys(data).forEach((key) => {
      if (key.startsWith('ratekg/ha')) {
        activeIngredients.push({
          moleculePk: key.replace('ratekg/ha', ''),
          rate: data[key],
          unitOfMeasure: 'kg/ha',
          applicationNumber: data.application_number,
          application_scheme_x_application_pk: data.application_scheme_x_application_pk
        });
      }
    });
    return activeIngredients;
  }

  transFormAIRateFromPWCObject(data: any): any {
    let activeIngredients: any;
    Object.keys(data).forEach((key) => {
      if (key.startsWith('ratekg/ha')) {
        activeIngredients.push({
          moleculePk: key.replace('ratekg/ha', ''),
          rate: data[key],
          unitOfMeasure: 'kg/ha',
          number_of_applications: data.number_of_applications,
          application_scheme_x_application_pk: data.application_scheme_x_application_pk
        });
      }
    });
    return activeIngredients;
  }

  transformPwcGeography(row: any) {
    let geographyList: any[] = [];
    if (row?.applicationSchemeXGeography)
      row?.map((geography: any) => {
        const geo = this.geographies.find((x: Catalog) => x.key == geography.geographyPk);
        if (geo) {
          geographyList.push(geo);
        }
      });
    return geographyList || [];
  }

  transformPwcApplicationToAIRates(dataTransaction: any[]) {
    let temp: ApplicationSchemeXActiveIngredientRate[] = [];
    dataTransaction?.forEach(data => {
      data.row?.children?.map((r: any) => {
        temp = this.setAIRateFromPWCObject(r);
        data.row.applicationSchemeXActiveIngredientRate = [...new Set(temp)];
        return r;
      });
    });
  }

  setParametersToNewRowsPWC(dataTransaction: any[], compartmentPk: number, isInverseModeling: boolean, projectPk: number) {
    dataTransaction.forEach(x => {
      if (x.transactionType == 1 && x.row !== undefined || x.transactionType == 2 && x.row !== undefined) {
        x.row.compartmentPk = compartmentPk;
        x.row.projectPk = projectPk;
        if (x.row?.children)
          x.row.numberOfApplications = x.numberOfApplications;
        x.row.windowsDaysToWindowsDays = x.row.windowsDaysToWindowsDays;
        x.row.stepDaysToStepDays = x.row.stepDaysToStepDays;
        x.row.applicationSchemeXActiveIngredientRate = this.setApplicationSchemeXActiveIngredientRatePWC(x.row, isInverseModeling);
        x.row.numberOfApplications = x.row?.application_number !== undefined ? x.row.application_number : x.row?.children?.length;
      }


    });
  }

  transformToSaveStructureObject(dataTransaction: any[]): SaveStructure[] {
    let saveStructures: SaveStructure[] = [];
    dataTransaction.forEach(x => {
      if (x?.transactionType == 1 && x.row !== undefined || x?.transactionType == 2 && x.row !== undefined) {
        if (x.row !== undefined) {
          let saveStructure: SaveStructure = {
            transactionType: x?.transactionType,
            rowUniqueID: x?.rowUniqueID,
            row: this.setApplicationScheme(x?.row),
          };
          saveStructures.push(saveStructure);
        }
      }
    });
    return saveStructures;
  }



  setApplicationScheme(data: any): any {
    let values: ApplicationScheme;
    let applicationSchemeXApp: ApplicationSchemeXApplication[] = [];
    let applicationSchemeXGeographies: applicationSchemeXGeography[] = [];
    let applicationAiRates: ApplicationSchemeXActiveIngredientRate[] = [];

    if (data?.children != undefined) {
      data?.children.map((app: ApplicationSchemeXApplication) => {
        let appl: ApplicationSchemeXApplication = {

          application_scheme_x_application_pk: app.application_scheme_x_application_pk,
          application_scheme_pk: app.application_scheme_pk, application_number: app.application_number,
          min_application_interval: app.min_application_interval, application_date: app.application_date,
          day: app.day, month: app.month, year: app.year, days_since: app.days_since, application_interval: app.application_interval,
          drift_type: app.drift_type, period: app.period, lag: app.lag,
          application_method: app.application_method, depth: app.depth, t_band_split: app.t_band_split, efficiency: app.efficiency,
          drift: app.drift, earliest_bbch: app.earliest_bbch, crop_interception: app.crop_interception
        };
        applicationAiRates.push(...[...this.setAIRateFromPWCObject(app)]);
        applicationSchemeXApp.push(appl);

      });

    }
    if (data?.applicationSchemeXGeography != undefined) {
      data?.applicationSchemeXGeography.map((geo: applicationSchemeXGeography) => {
        applicationSchemeXGeographies.push(geo);
      });
    }
    if (data?.name != undefined) {
      values = {
        applicationSchemePk: data?.applicationSchemePk,
        projectPk: data?.projectPk,
        compartmentPk: data?.compartmentPk,
        name: data?.name,
        dateType: data?.dateType,
        cropEvent: data?.cropEvent,
        ApplicationSchemeXApplication: applicationSchemeXApp,
        applicationSchemeXGeography: applicationSchemeXGeographies,
        ApplicationSchemeXActiveIngredientRate: applicationAiRates,
        specifyYear: data?.specifyYear,
        coreApplicationSchemePk: 0,
        regionPk: data?.regionPk,
        cropPk: data?.cropPk,
        isCore: false,
        numberOfApplications: data?.children?.length,
        cropXRegionPks: data?.cropXRegionPks,
        windowsDaysToWindowsDays: data?.windowsDaysToWindowsDays,
        stepDaysToStepDays: data?.stepDaysToStepDays,
        useApplicationWindow: data?.useApplicationWindow,
        adjustApplicationDatesIfRaining: data?.adjustApplicationDatesIfRaining,
        intolerableRainCm: data?.intolerableRainCm,
        intolerableRainWindowDays: data?.intolerableRainWindowDays,
        optimumApplicationWindowDays: data?.optimumApplicationWindowDays,
        minimumDaysBetweenApplications: data?.minimumDaysBetweenApplications,
      };
      return values;
    }
  }

  validateRateChanges(node: RowNode, rates: any): boolean {
    let appSchemeAIR = node?.data?.applicationSchemeXActiveIngredientRate;
    for (var rate of rates) {
      let existsMolecule = appSchemeAIR.find((x: any) => x.moleculePk === rate.moleculePk && x.rate === rate.rate);
      if (!existsMolecule) {
        return true;
      }
    };
    return false;
  }

  pwcCreateApplicationMethodObject(application: any): ApplicationMethod {
    const date = new Date(application.application_date);
    const applicationMethod: ApplicationMethod = {
      daySince: Utils.getSaveNumber(application.days_since),
      depthCm: Utils.getSaveNumber(application.depth),
      tBandSplit: Utils.getSaveNumber(application.t_band_split),
      amountKgHa: Utils.getSaveNumber(application.rate),
      eff: Utils.getSaveNumber(application.efficiency),
      driftBuffer: Utils.getSaveNumber(application.drift),
      method: this.pwcGetApplicationMethodType(application.application_method),
      date: date.toISOString(),
    }
    return applicationMethod;
  }

  pwcGetApplicationMethodType(applicationMethod: string): number {
    let applicationMethodType!: number;
    switch (applicationMethod) {
      case 'Below Crop':
        applicationMethodType = 1;
        break;
      case 'Above Crop':
        applicationMethodType = 2;
        break;
      case 'Uniform':
        applicationMethodType = 3;
        break;
      case '@ Depth':
        applicationMethodType = 4;
        break;
      case 'T Band':
        applicationMethodType = 5;
        break;
      case 'Linearly increasing with depth':
        applicationMethodType = 6;
        break;
      case 'Linearly decreasing with depth':
        applicationMethodType = 7;
        break;
    }
    return applicationMethodType;
  }

  setGapXRunPWC(pNode: any): void {
    const data = pNode.data;
    data.gapXRun = {
      applicationMethodPk: data.applicationMethodPk,
      runPk: data.runPk,
      gapApplicationMethodPk: data.gapApplicationMethodPk,
      chemicalApplicationMethodPk: data.chemicalApplicationMethodPk,
      rotationPk: data.rotationPk,
      soilDepthIncorporated: data.soilDepthIncorporated,
      dateType: data.dateType,
      cropEvent: data.cropEvent,
      cropDrain: data.cropDrain,
      earliestBbch: data.minBBCH,
      latestBbch: data.maxBBCH,
      cropCoverage: data.cropCoverage,
      cropInterception: data.cropInterception,
      firstApplicationDate: data.firstApplicationDate,
      beginningDate: data.beginningDate,
      endDate: data.endDate,
      emergenceDay: data.emergenceDate,
      harvestDay: data.harvestDate,
      nozzleDriftReduction10m: data.nozzleDriftReduction10m,
      nozzleDriftReduction20m: data.nozzleDriftReduction20m,
      formulation: data.formulation,
      simulationType: data.simulationType,
      applicationOccurance: data.application_occurrance,
      applicationOccuranceFromYear: data.application_ocurrance_from_year,
      applicationOccuranceToYear: data.application_ocurrance_to_year,
      applyPesticideWindows: data.apply_pesticide_over_a_time_windows,
      applyPesticideSteps: data.apply_pesticide_over_a_time_windows_steps,
      specifyYears: data.specifyYear
    } as GapXRun;
  }

  setPrecursors(pPrecursors: any[]): FractionTransformedXEndpoint[] {
    let fractionTransformed: FractionTransformedXEndpoint[] = [];
    pPrecursors?.forEach(precursor => {
      fractionTransformed.push({
        endpointPrecursorPk: precursor.endpointPrecursorPk,
        moleculePk: precursor.moleculePk,
        metabolitePk: precursor.metabolitePk,
        formationFraction: precursor.formationFraction,
        precursorType: precursor.precursorType,
        substanceName: precursor.substanceName
      } as FractionTransformedXEndpoint);
    });
    return fractionTransformed
  }

  public getCompoundMoleculePks(data: any, selectedProject: Project): number[] {
    if (data?.length > 0) {
      return data?.map((x: any) => x.applicationSchemeXActiveIngredientRate.map((x: any) => x.moleculePk)).flat()
        .filter((value: any, index: any, array: string | any[]) => array.indexOf(value) === index);
    }
    return selectedProject.projectXCompoundXModel?.flatMap(x => x.MoleculePk!) ?? [];
  }

  setInnerGridValues(event: any, grid: GridComponent) {
    if (event.row.numberOfApplications === 1) {
      event.row.children = [];
      event.row.hasVariableRates = false;
      event.row.isExpanded = false;
      this.toggleDetailGrid(event, grid);
      return;
    }

    if (event.row.children === undefined || event.row.children.length === 0) {
      event.row.hasVariableRates = event.row.hasVariableRates ?? false;
      event.row.children = [];
      return this.setChildrenValues(event, event.row.numberOfApplications, grid);
    }

    if (event.row.children.length < event.row.numberOfApplications) {
      event.row.hasVariableRates = event.row.hasVariableRates ?? false;
      const newNumberApplication = event.row.numberOfApplications - event.row.children.length;
      let actualNumberApplication = event.row.children.length + 1;
      for (let index = 0; index < newNumberApplication; index++) {
        event.row.children.push({
          application_number: actualNumberApplication++,
          cropAppDatePk: event?.row?.cropAppDatePk,
          cropInterceptionPk: event?.row?.cropInterceptionPk,
          dateType: event?.row?.dateType,
          isChild: true,
        })
      }
    }

    if (event.row.children.length > event.row.numberOfApplications) {
      event.row.hasVariableRates = event.row.hasVariableRates ?? false;
      const deleteNumberApplication = event.row.children.length - event.row.numberOfApplications;
      for (let index = 0; index < deleteNumberApplication; index++) {
        event.row.children.pop();
      }
    }
    event?.row?.children.forEach((x: any) => {
      x.parentRowNodeId = grid.gridApi.getRowNode(event?.node ? event?.node?.id : event.id)?.id;
      x.cropAppDatePk = event?.row?.cropAppDatePk,
        x.cropInterceptionPk = event?.row?.cropInterceptionPk,
        x.isChild = true;
    });
  }

  toggleDetailGrid(event: any, grid: GridComponent) {
    event.row = event.row ? event.row : event.data;
    var node1 = grid.gridApi.getRowNode(event?.node ? event?.node?.id : event.id)!;
    grid.gridApi.setRowNodeExpanded(node1, event?.row?.isExpanded);
    if (event?.row?.isExpanded) {
      this.setInnerGridValues(event, grid);
    }
  }

  setChildrenValues(event: any, numberApplication: number, grid: GridComponent) {
    let childrenValues: any[] = [];
    for (let index = 0; index < numberApplication; index++) {
      childrenValues.push({
        application_number: index + 1,
        min_application_interval: null,
        cropAppDatePk: event?.row?.cropAppDatePk,
        cropInterceptionPk: event?.row?.cropInterceptionPk,
        parentRowNodeId: grid.gridApi.getRowNode(event?.node ? event?.node?.id : event.id)?.id,
        dateType: event?.row?.dateType,
        isChild: true,
      })
    }
    event.row.children.push(...childrenValues);
  }

  getNonLeapDate(defaultYear: number = 2001): any {
    const currentDate = new Date();
    if (this.isLeapYear(currentDate.getFullYear()) && currentDate.getMonth() === 1 && currentDate.getDate() === 29) {
      return moment(new Date(defaultYear, 2, 1));
    }
    return moment(new Date(defaultYear, currentDate.getMonth(), currentDate.getDate()));
  }

  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }

  transformLeapDate(date: Date, defaultYear: number = 2001): any {
    const currentDate = new Date(date);
    if (this.isLeapYear(currentDate.getFullYear()) && currentDate.getMonth() === 1 && currentDate.getDate() === 29) {
      return moment(new Date(defaultYear, 2, 1));
    }
    return moment(currentDate);
  }

  disableCropEvent(value: string) {
    let result = false;

    result = value == Constants.DATE_TYPES[0] ? true : false;

    return result;
  }

  public async getApplicationSchemesByProjectWithAllCompartments(projectPk: number): Promise<any[]> {
    return await firstValueFrom(this.gapAService.getApplicationSchemesByProjectWithAllCompartments(projectPk));
  }
}
