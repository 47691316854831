import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Project } from '../../models/project';
import { Constants } from '../../utils/constants';
import Swal from 'sweetalert2';
import { ProjectApiService } from '../../services/project.api.service';
import { SelectedProjectApiService } from '../selected-project/selected-project.api.service';
import { Subscription, switchMap, take, timer } from 'rxjs';
import { RunApiService } from '../../services/run.api.service';
import { ProjectPreviousRun } from '../../models/project-previous-run';
import { ColDef, DomLayoutType, RowGroupingDisplayType } from 'ag-grid-community';
import { GridComponent } from '../grid/grid.component';
import { PreviosRunsColdef } from './previous-runs.coldef';
import { ProjectXCompoundXModel } from '../../models/project-x-compound-x-model';
import { MetricsColdef } from './metrics-coldef';
import { ProjectMetrics } from '../../models/project-metrics';

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.css']
})
export class ProjectStatusComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('grid') private grid!: GridComponent;
  @Input() selectedProject?: Project;
  @Input() selectedProjectStatus?: number;
  statusId?: number;
  statusName: string = "";
  badgeStyle: string = "";
  models: any[] = [];
  isAllFilesDownloading: boolean = false;
  isSummaryDownloading: boolean = false;
  isStoping: boolean = false;
  subscriptionStatus!: Subscription;
  subscriptionRunsSaved!: Subscription;
  subscriptionMetrics!: Subscription;
  downloadOutputFilesVisible: boolean = false;
  metricsVisible: boolean = false;
  rowData: any[] = [];
  metricsRowData: any[] = [];
  public rowSelection: "single" | "multiple" = "multiple";
  groupDisplayType: RowGroupingDisplayType = "singleColumn";
  downloadAllFiles: boolean = false;
  paginationPageSize: number = 5;
  metricsPaginationPageSize: number = 5;
  gridStyle: any = {
    width: 'auto',
    height: '500px'
  };
  metricsGridStyle: any = {
    width: 'auto',
    height: '350px'
  };
  autoGroupColumnDef: ColDef = {
    headerName: 'Output Folder',
    width: 155
  };
  projectMetrics: ProjectMetrics[] = [];
  projectElapsedTime: string | undefined;
  showLabel: boolean = false;
  public domLayout: DomLayoutType = "normal";

  constructor(private projectService: ProjectApiService,
    private selectedProjectApiService: SelectedProjectApiService,
    public runApiService: RunApiService,
    public previosRunsColdef: PreviosRunsColdef,
    public metricsColdef: MetricsColdef) { }

  columnDefs: ColDef[] = this.previosRunsColdef.getColumnsDefinition();
  metricsColumnDefs: ColDef[] = this.metricsColdef.getColumnsDefinition();

  refresh(): boolean {
    return false;
  }

  ngOnInit(): void {
    this.statusId = this.selectedProject?.status;
    this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status!);
    this.statusStyle(this.statusId ?? 0);
    this.monitorProjectStatus();
    this.selectedProject?.projectXCompoundXModel?.find((x: ProjectXCompoundXModel) => x.ModelName === Constants.MODELS.PWC)
      ?
      timer(50000).subscribe(() => {
        this.monitorRunsHaveBeenSavedPwc()
      }) :
      timer(30000).subscribe(() => {
        this.monitorRunsHaveBeenSaved()
      });
  }

  ngOnDestroy() {
    this.unsubscribeSubscriptions();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['selectedProject']) {
      this.statusId = this.selectedProject?.status;
      this.statusStyle(this.statusId ?? 0);
    };
  }

  statusStyle(statusId: number) {
    switch (statusId) {
      case Constants.PROJECT_STATUS_ID.NOT_STARTED:
        this.statusName = Constants.PROJECT_STATUS.NOT_STARTED;
        this.badgeStyle = '';
        break;
      case Constants.PROJECT_STATUS_ID.IN_PROGRESS:
        this.statusName = Constants.PROJECT_STATUS.IN_PROGRESS.concat("\u00A0");
        this.badgeStyle = Constants.BADGE_STYLE.IN_PROGRESS;
        break;
      case Constants.PROJECT_STATUS_ID.COMPLETED:
        this.statusName = Constants.PROJECT_STATUS.COMPLETED.concat("\u00A0");
        this.badgeStyle = Constants.BADGE_STYLE.COMPLETED;
        break;
      case Constants.PROJECT_STATUS_ID.FAILED:
        this.statusName = Constants.PROJECT_STATUS.FAILED.concat("\u00A0");
        this.badgeStyle = Constants.BADGE_STYLE.FAILED;
        break;
      case Constants.PROJECT_STATUS_ID.IN_QUEUE:
        this.statusName = Constants.PROJECT_STATUS.IN_QUEUE.concat("\u00A0");
        this.badgeStyle = Constants.BADGE_STYLE.IN_QUEUE;
        break;
      case Constants.PROJECT_STATUS_ID.STOPPED:
        this.statusName = Constants.PROJECT_STATUS.STOPPED;
        this.badgeStyle = '';
        break;
      default:
        this.badgeStyle = '';
        break;
    }
  }

  showDownloadButton(): boolean {
    return this.statusId === Constants.PROJECT_STATUS_ID.COMPLETED && !!this.selectedProject?.previousRuns && this.selectedProject.previousRuns.length > 0;
  }

  showStopProjectButton(): boolean {
    return this.statusName === Constants.PROJECT_STATUS.IN_PROGRESS.concat("\u00A0");
  }

  onDownloadOutputFilesClick(pDownloadAllFiles: boolean = true): void {
    if (this.selectedProject?.previousRuns && this.selectedProject.previousRuns.length == 1) {
      this.downloadOutputFilesVisible = false;
      this.downloadOutputFilesByOutputFolders(this.selectedProject.previousRuns, pDownloadAllFiles);
    }
    if (this.selectedProject?.previousRuns && this.selectedProject.previousRuns.length > 1) {
      this.downloadOutputFilesVisible = true;
      this.downloadAllFiles = pDownloadAllFiles;
    }
  }

  onDownloadSelectedRowsClick() {
    this.downloadOutputFilesVisible = false;
    this.downloadOutputFilesByOutputFolders(this.grid.gridApi.getSelectedRows(), this.downloadAllFiles);
  }

  downloadOutputFilesByOutputFolders(previousRuns: any, pDownloadAllFiles: boolean = true): void {
    this.setButtonLoading(pDownloadAllFiles);
    let outpuFolders = previousRuns
      .map((run: any) => run.lastOutputFolder)
      .flat();
    this.projectService.downloadOutputFilesByOutputFolders(outpuFolders, pDownloadAllFiles)
      .subscribe({
        next: (urls) => {
          this.setButtonLoading(pDownloadAllFiles);
          if (urls != null && urls.length > 0) {
            urls.forEach((url: string) => {
              window.open(url, '_blank');
            });
          }
        },
        error: (error) => {
          console.error(error);
          this.setButtonLoading(pDownloadAllFiles);
          this.showErrorAlert("Files could not be downloaded")
        },
      });
  }

  onStopProjectClick(): void {
    if (this.selectedProject) {
      this.unsubscribeSubscriptions();
      this.isStoping = true;
      this.selectedProject!.featureAcronym = this.selectedProjectApiService.featureAcronym;
      this.projectService.stopProject(this.selectedProject).subscribe({
        next: () => {
          const status = Constants.PROJECT_STATUS_ID.STOPPED;
          this.selectedProject!.status = status;
          this.statusId = status;
          this.isStoping = false;
          this.statusStyle(this.statusId ?? Constants.PROJECT_STATUS_ID.STOPPED);
          this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
          this.onMetricsClick(false);
          this.unsubscribeSubscriptions();
        },
        error: (error) => {
          this.isStoping = false;
          console.error(error);
        },
      })
    }
  }

  monitorProjectStatus(): void {
    this.subscriptionStatus = timer(0, 30000).pipe(
      switchMap(() => this.projectService.getProjectStatus(this.selectedProject!.projectPk, this.selectedProjectApiService.featureAcronym!).pipe(take(1)))
    ).subscribe({
      next: (status) => {
        this.selectedProject!.status = status;
        this.statusId = this.selectedProject?.status;
        this.statusStyle(this.statusId ?? status);
        this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
        if (this.selectedProject!.status === Constants.PROJECT_STATUS_ID.IN_PROGRESS ||
          this.selectedProject!.status === Constants.PROJECT_STATUS_ID.STOPPED ||
          this.selectedProject!.status == Constants.PROJECT_STATUS_ID.COMPLETED)
          this.onMetricsClick(this.metricsVisible);
        if (this.selectedProject!.status == Constants.PROJECT_STATUS_ID.STOPPED ||
          this.selectedProject!.status == Constants.PROJECT_STATUS_ID.FAILED ||
          this.selectedProject!.status == Constants.PROJECT_STATUS_ID.COMPLETED ||
          this.selectedProject!.status == Constants.PROJECT_STATUS_ID.NOT_STARTED) {
          this.getPreviousRuns(this.selectedProject);
          this.unsubscribeSubscriptions();
        }
      },
      error: (error) => {
        console.error(error)
      }
    });
  }

  monitorProjectStatusPWC(): void {
    this.subscriptionStatus = timer(0, 30000).pipe(
      switchMap(() => this.projectService.getProjectStatus(this.selectedProject!.projectPk, this.selectedProjectApiService.featureAcronym!).pipe(take(1)))
    ).subscribe({
      next: (status) => {
        this.selectedProject!.status = status;
        this.statusId = this.selectedProject?.status;
        this.statusStyle(this.statusId ?? status);
        this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
        if (this.selectedProject!.status === Constants.PROJECT_STATUS_ID.IN_PROGRESS ||
          this.selectedProject!.status === Constants.PROJECT_STATUS_ID.STOPPED ||
          this.selectedProject!.status == Constants.PROJECT_STATUS_ID.COMPLETED)
          this.onMetricsClick(this.metricsVisible);
        this.getPreviousRuns(this.selectedProject);
      },
      error: (error) => {
        console.warn(error)
      }
    });
  }

  getPreviousRuns(selectedProject: Project | undefined) {
    if (selectedProject?.status === Constants.PROJECT_STATUS_ID.COMPLETED) {
      this.projectService.getPreviousRunsByProject(selectedProject.projectPk, this.selectedProjectApiService.featureAcronym!).subscribe({
        next: (projectPreviousRun: ProjectPreviousRun[]) => {
          selectedProject.previousRuns = projectPreviousRun;
          this.rowData = projectPreviousRun.filter((run: any) => run.projectStatus === Constants.PROJECT_STATUS_ID.COMPLETED && run.status === Constants.PROJECT_STATUS_ID.COMPLETED);
        },
        error: (error: Error) => {
          console.error(error)
        }
      });
    };
  }

  monitorRunsHaveBeenSaved(): void {
    this.subscriptionRunsSaved = timer(0, 30000).pipe(
      switchMap(() => this.runApiService.runsHaveBeenSaved(this.selectedProject!.projectPk).pipe(take(1)))
    ).subscribe({
      next: (status) => {
        this.selectedProject!.runsSaved = status;
        if (status) {
          this.selectedProjectApiService.updateRunsHaveBeenSaved(this.selectedProject!.runsSaved);
          this.subscriptionRunsSaved.unsubscribe();
        }
      },
      error: (error) => {
        console.error(error)
      }
    });
  }

  monitorRunsHaveBeenSavedPwc(): void {
    this.subscriptionRunsSaved = timer(0, 30000).pipe(
      switchMap(() => this.runApiService.runsHaveBeenSavedPwc(this.selectedProject!.projectPk).pipe(take(1)))
    ).subscribe({
      next: (status) => {
        this.selectedProject!.runsSaved = status;
        if (status) {
          this.selectedProjectApiService.updateRunsHaveBeenSaved(this.selectedProject!.runsSaved);
          this.subscriptionRunsSaved.unsubscribe();
        }
      },
      error: (error) => {
        console.warn(error)
      }
    });
  }

  setButtonLoading(pDownloadAllFiles: boolean = true): void {
    if (pDownloadAllFiles) {
      this.isAllFilesDownloading = !this.isAllFilesDownloading;
    } else {
      this.isSummaryDownloading = !this.isSummaryDownloading;
    }
  }

  unsubscribeSubscriptions() {
    if (this.subscriptionStatus)
      this.subscriptionStatus.unsubscribe();
    if (this.subscriptionRunsSaved)
      this.subscriptionRunsSaved.unsubscribe();
    if (this.subscriptionMetrics)
      this.subscriptionMetrics.unsubscribe();
  }

  showErrorAlert(text: string) {
    Swal.fire({
      title: 'Notification',
      html: text,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'OK',
    }).then((result) => {

    });
  }

  createProjectObject(data: any, pDownloadAllFiles: boolean = true): Project {
    return {
      projectPk: data.projectPk,
      active: data.active,
      createdBy: data.createdBy,
      deletable: data.deletable,
      description: data.description,
      formulationPk: data.formulationPk,
      name: data.name,
      status: data.status,
      downloadAllFiles: pDownloadAllFiles,
      createdDate: data.createdDate,
      featureAcronym: this.selectedProjectApiService.featureAcronym
    } as Project;
  }

  onMetricsClick(metricsVisible: boolean = true): void {
    this.projectService.getMetricsByProject(this.selectedProject!.projectPk, this.selectedProjectApiService.featureAcronym!).pipe(take(1))
      .subscribe({
        next: (projectMetrics) => {
          this.processMetrics(projectMetrics);
          this.metricsVisible = metricsVisible;
        },
        error: (error) => {
          console.error(error)
          this.metricsVisible = false;
        }
      });
  }

  processMetrics(projectMetrics: ProjectMetrics[]): ProjectMetrics {
    this.metricsRowData = projectMetrics.filter(pm => pm.type === 'Model');
    this.showLabel = this.metricsRowData.find(pm => pm.name === 'PRZM');
    var project = projectMetrics.find(pm => pm.type === 'Project');
    this.projectElapsedTime = project?.FormattedElapsedTime;
    return project ?? {} as ProjectMetrics;
  }

  showMetricsButton() {
    return this.metricsRowData != undefined && this.metricsRowData.length > 0;
  }

  showElapsedTime() {
    return this.projectElapsedTime && (this.selectedProject!.status === Constants.PROJECT_STATUS_ID.IN_PROGRESS || this.selectedProject!.status === Constants.PROJECT_STATUS_ID.STOPPED ||
      this.selectedProject!.status === Constants.PROJECT_STATUS_ID.COMPLETED)
  }

  showInfoPopup() {
    Swal.fire({
      title: 'Information',
      html: Constants.MESSAGES.METRICS,
      icon: 'info',
      width: 425
    });
  }
}