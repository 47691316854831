<div class="grid">
  <div class="col-12 mt-2" style="display: inline-flex;">
    <app-button [outlined]="true" severity="{{activeButtonColor}}" label="{{buttonLabel}}"
      (onClickEvent)="onActiveIngredientClick()"></app-button>
    <app-button [outlined]="true" severity="{{metaboliteButtonColor}}" label="Metabolites"
      (onClickEvent)="onMetaboliteClick()"></app-button>
    <app-dropdown [filter]="true" [options]="metabolitesOptions" optionLabel="metaboliteName"
      placeholder="Select Metabolite" *ngIf="metaboliteSelected"
      (onChangeEvent)="getSelectedMetabolite($event)"></app-dropdown>
    <div [ngClass]="disabled && !isMetaboliteUseAsPrecursor? '': 'disabled'" >
      <p-checkbox *ngIf="selectedMetabolitePkValue !== 0" label="N/A" name="N/A" [binary]="true"
        [(ngModel)]="excludeMetaboliteFromAI" (onChange)="onChangeExcludeMetabolite($event)"
        [disabled]="isDisabled()" />
    </div>
  </div>
</div>