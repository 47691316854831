import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.css']
})
export class LinkButtonRenderer implements ICellRendererAngularComp {

  public url: string = '';
  public show: boolean = true;
  diabled = false;

  agInit(params: any): void {
    this.url = params.value;
    this.diabled = params.disabled ?? false;
    this.show = this.url != undefined && this.url != '';
  }

  refresh(): boolean {
    return false;
  }

}
