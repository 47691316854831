<app-selected-project [featureAcronym]="featureAcronym"></app-selected-project>
<div *ngIf="!isProjectOwnershipValid && selectedProject" class="isProjectOwnershipValid">
    <p>You cannot modify or run someone's project</p>
</div>
<div *ngIf="selectedProject" class="pt-3 pr-5 pb-5 pl-5">
    <app-wizard class="surface-200" [items]="inverseModelingEEALogicService.items" [message]="message"
        [loading]="loading" [activeItem]="activeItem" [selectedProject]="selectedProject" [isSetupValid]="isSetupValid"
        (activeIndexChangeEmitter)="onActiveIndexChange($event)"></app-wizard>

    <div class="mt-3 shadow-8 surface-0 p-3">
        <ng-container *ngIf="activeItem?.index == 0">
            <app-eea-setup [isInverseModeling]="true" [menuService]="inverseModelingEEALogicService" [modelsCollection]="modelsCollection" [disciplinePk]="disciplinePk" [secondDisciplinePk]="disciplineAguaticTerrestrial" [columnDef]="columnDef"
                (isSetupValidEvent)="validateSetup($event)"></app-eea-setup>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 1">
            <app-eea-endpoints [selectedProject]="selectedProject" [isInverseModeling]="true"
                [menuService]="inverseModelingEEALogicService"></app-eea-endpoints>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 2">
            <app-eea-gap [selectedProject]="selectedProject" [isInverseModeling]="true"
                [menuService]="inverseModelingEEALogicService"></app-eea-gap>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 3">
            <app-inverse-modeling-endpoints [isProjectOwnershipValid]="isProjectOwnershipValid"
                [selectedProject]="selectedProject"
                [menuService]="inverseModelingEEALogicService"
                (isValid)="getIsValidInverseModelingEndpoints($event)"></app-inverse-modeling-endpoints>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 4">
            <app-inverse-modeling-run-project  [selectedProject]="selectedProject" [menuService]="inverseModelingEEALogicService"
            [selectedProjectStatus]="selectedProjectStatus" [IMEEAAssessment]="IMEEAAssessment"
            (updateEEAMenuLoadingStatus)="onUpdateLoadingStatus($event)"
            (showLoadingForOutput)="onShowLoadingForOutput($event)"></app-inverse-modeling-run-project>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 5">
            <app-run-risk-assessment [selectedProject]="selectedProject"
                [menuService]="inverseModelingEEALogicService"></app-run-risk-assessment>
        </ng-container>
    </div>
</div>