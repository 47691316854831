import { Injectable } from "@angular/core";
import { AzureBatchLinkCellRendererComponent } from "../../renderers/azure-batch-link-cell-renderer/azure-batch-link-cell-renderer.component";

@Injectable({
  providedIn: 'root'
})

export class MetricsColdef {
  constructor() { }

  public getColumnsDefinition(): any[] {
    return [
      {
        headerName: 'Output Folder',
        field: "lastOutputFolder",
        flex: 1,
        editable: false,
        wrapText: true,
        rowGroup: true, 
        hide: true,
        sort: 'desc'
      },
      {
        headerName: 'Model',
        field: "name",
        flex: 1,
        editable: false,
      },
      {
        headerName: 'Status',
        field: "status",
        cellRenderer: AzureBatchLinkCellRendererComponent,
        flex: 1,
        editable: false,
        sort: 'asc'
      },
      {
        headerName: 'Elapsed Time (HH:MM)',
        field: "FormattedElapsedTime",
        flex: 1,
        resizable: true,
        editable: false,
        wrapText: true,
      },
    ];
  }
}