import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ColDef, ColGroupDef, RowNode, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { Project } from 'src/app/shared/models/project';
import { ActiveIngredientApiService } from 'src/app/shared/services/echo/active-ingredient.api.service';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { take } from 'rxjs';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { EraGapBeesBamsApiService } from './era-gap-bees-bams.api.service';
import { ApplicationSchemeBees } from 'src/app/shared/models/bees/bees';
import Swal from 'sweetalert2';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';
import { DuplicateButtonRenderer } from 'src/app/shared/renderers/duplicate-button/duplicate-button.component';
import { InputTextApplicationSchemeRenderer } from 'src/app/shared/renderers/input-text/input-text-application-scheme/input-text-application-scheme.component';

@Component({
  selector: 'app-era-gap-bees',
  templateUrl: './era-gap-bees.component.html',
  styleUrls: ['./era-gap-bees.component.css']
})
export class EraGapBeesComponent implements OnInit {
  @ViewChild('grid') grid!: GridComponent;
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = true;

  @Output() public isValid = new EventEmitter<boolean>();

  isLoading!: boolean;
  public columnDefs: any[] = [];

  public detailsColumnDef: (ColDef | ColGroupDef)[] | null = [];
  destroyRef = inject(DestroyRef);
  saveRef?: string;
  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  rowHeight: number = 45;
  mainCompartment!: 'Plant';
  mainCompartmentPk: number = 0;
  activeIngredientRatesColDef: (ColDef | ColGroupDef)[] | null = [];
  rowData: any[] = [];

  ngOnInit(): void {
    this.isLoading = true;
    this.getData();

  }
  constructor(private activeIngredientApiService: ActiveIngredientApiService,
    private datasetService: DataSetApiService,
    private bamsApiService: EraGapBeesBamsApiService,
    private gapApplicationSchemeLogicService: GapApplicationSchemeLogicService
  ) { }

  initSubscribes() {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.menuService.saveRef;
      if (this.grid?.transactionList != undefined || this.grid?.transactionList > 0) {
        this.saveData(this.grid?.transactionList)
      }
      this.menuService.setSuccess(true)
    });
    this.subTabMenuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.subTabMenuService.saveRef;
      if (this.grid?.transactionList != undefined || this.grid?.transactionList > 0) {
        this.saveData(this.grid?.transactionList)
      }
      this.subTabMenuService.setSuccess(true);
    });
  }



  getData() {
    let promises = [
      this.getCompartments(),
    ]
    Promise.all(promises)
      .then((results) => {
        this.initSubscribes();
        this.mainCompartmentPk = results[0].find((x: any) => x.compartment === "Plant")?.endpointCompartmentPk!;
        this.bamsApiService.getApplicationSchemesByProjectAndCompartment(this.selectedProject?.projectPk!, this.mainCompartmentPk).subscribe({
          next: (applicationSchemes: ApplicationSchemeBees[]) => {
            this.configureGrid();
            this.addActiveIngredientsRatesToGrid(this.rowData, this.selectedProject!).then(() => {
              this.setMapActiveIngredients(applicationSchemes ? applicationSchemes : []);
              this.refreshSelectedValuesTimeout();
              this.isLoading = false;
            });
          }
        });
      })
  }

  setMapActiveIngredients(data: any) {
    data?.map((row: any) => {

      let method = data?.find((x: { ApplicationSchemePk: any; }) => x.ApplicationSchemePk === row.ApplicationSchemePk)?.ApplicationMethod;
      let unit = data?.find((x: { ApplicationSchemePk: any; }) => x.ApplicationSchemePk === row.ApplicationSchemePk)?.applicationSchemeXActiveIngredientRate[0].unitOfMeasure;

      if (method && unit) {
        row.ApplicationMethod = Number(Constants.BEES_APPLICATION_METHODS.find(x => x.name === method)?.key?.toString());
        row.UnitOfMeasure = Number(Constants.BEES_UNITS.find(x => x.name === unit)?.key?.toString());
      }
      row.applicationSchemeXActiveIngredientRate?.map((rate: any) => {
        if(rate.unitOfMeasure)
          row[`rate${rate.unitOfMeasure.replace(rate.unitOfMeasure, 'lbacr')}${rate.moleculePk}`] = rate.rate;
        row.applicationSchemeBees?.map((bees: any) => {
          row.EmpiricalResidueInJelly = bees.EmpiricalResidueInJelly == 0 ? null : bees.EmpiricalResidueInJelly;
          row.EmpiricalResidueInNectar = bees.EmpiricalResidueInNectar == 0 ? null : bees.EmpiricalResidueInNectar;
          row.EmpiricalResidueInPollenBread = bees.EmpiricalResidueInPollenBread == 0 ? null : bees.EmpiricalResidueInPollenBread;
          row.Koc = bees.Koc == 0 ? null : bees.Koc;
          row.LogKow = bees.LogKow == 0 ? null : bees.LogKow;
          row.MassOfTreeVegetation = bees.MassOfTreeVegetation == 0 ? null : bees.MassOfTreeVegetation;
          row.EmpiricalResidueDataAvailable = bees.EmpiricalResidueDataAvailable;

        });
      });

    });
    this.rowData = data;
  }

  async getCompartments(): Promise<any[]> {
    return new Promise<number[]>((resolve, reject) => {
      this.datasetService.getCompartments(Constants.CONST_COMPARTMENT).subscribe({
        next: (comparments: any) => {
          resolve(comparments);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }
  onFirstDataRendered(event: any) { }

  deleteRow(event: any) {

    this.rowData = this.rowData.filter((r: any) => r.deleted === undefined || r.deleted === false);

    const deletedRow = event.find( (row: any) => row.transactionType === Constants.TRANSACTION_TYPE.DELETE );
    this.gapApplicationSchemeLogicService.checkIfApplicationSchemeIsValid(this, deletedRow?.row.name);

    if (event != undefined) {
      this.bamsApiService
        .SaveTransaction(event)
        .pipe(take(1))
        .subscribe(() => { });
    }
    this.refreshSelectedValuesTimeout();
  }

  saveData(dataTransaction: any) {
    if (dataTransaction === undefined) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    if (dataTransaction.length <= 0) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    if (this.grid.transactionList != undefined || this.grid.transactionList > 0) {
      this.setProjectData(dataTransaction);
      this.createTransactionsForRates(dataTransaction);
      this.setActiveIngredientsRate(dataTransaction);
      this.bamsApiService.SaveTransaction(dataTransaction).subscribe({
        next: () => {
          this.grid.transactionList = [];
          this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.subTabMenuService.setSuccess(true);
        },
        error: (err) => {
          this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(false) : this.subTabMenuService.setSuccess(false);
        }
      })
    }
    return;

  }

  setProjectData(dataTransaction: any) {
    if (dataTransaction != null)
      dataTransaction?.map((x: any) => {
        x.row.projectPk = this.selectedProject?.projectPk;
        x.row.compartmentPk = this.mainCompartmentPk;
      });

  }

  onSelectionChanged(event: any) {
    this.onSelectOrUnselectAll( event );
    let pollenColumn = this.columnDefs.find((x: any) => x.field === 'emperical_residue_in_pollen_bread');
    let koc = this.columnDefs.find((x: any) => x.field === 'koc');
    if (event.value === 3 && event.field === 'ApplicationMethod' && pollenColumn) {
      pollenColumn.editable = false;
      pollenColumn.cellStyle = { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
      koc.editable = false;
      koc.cellStyle = { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
    }
    this.grid.gridApi.redrawRows();
  }

  createColDef(activeIngredients: any, unitOfMeasure: string): any[] {
    let rateColdef: any[] = [];
    activeIngredients?.forEach((activeIngredient: any) => {
      rateColdef.push(
        {
          headerName: activeIngredient.moleculeName,
          field: `rate${unitOfMeasure}${activeIngredient.moleculePk}`,
          type: 'default',
          filter: 'agSetColumnFilter',
          width: 150,
          resizable: true, cellRenderer: 'inputNumberRenderer',
          cellRendererParams: (params: any) => {
            return {
              disabled: !this.isProjectOwnershipValid,
            };
          },
          editable: false,
        }
      );
    });
    return rateColdef;
  }

  validateVariableEmpiricalResidueDataAvailable(params: any) {
    if (params.data.emperival_data_available === Constants.AFFIRMATIVE_VALUES.YES) {
      return false && this.isProjectOwnershipValid;
    }
    return true && this.isProjectOwnershipValid;
  }


  validateEmpiricalData(params: any) {
    if (params.data.EmpiricalResidueDataAvailable === Constants.AFFIRMATIVE_VALUES.NO && this.isProjectOwnershipValid) {

      return false;
    }
    return true;
  }

  isTreeTrunk(params: any) {
    if (params.data.ApplicationMethod === Constants.BEES_APPLICATION_METHODS[0].key && this.isProjectOwnershipValid) {
      return false;
    }
    return true;
  }

  setMissingApplicationSchemeXActiveIngredientRate(data: any): any[] {
    let activeIngredients: any[] = []
    this.activeIngredientRatesColDef?.forEach((x: any) => {
      const fieldName = "ratelbacr";
      let moleculePk = x.field?.toLowerCase().replace(fieldName.toLowerCase(), "");
      if (moleculePk) {
        let existMolecule = data.applicationSchemeXActiveIngredientRate.find((x: any) => x.moleculePk == moleculePk);
        if (!existMolecule) {
          activeIngredients.push({
            moleculePk,
            rate: null,
            unitOfMeasure: 'lbacr',
          });
        }
      }
    })
    return activeIngredients;
  }

  setActiveIngredientsRate(dataTransaction: any[]) {
    dataTransaction?.forEach(data => {
      data.row.applicationSchemeXActiveIngredientRate?.map((x: any) => {
        if(x.unitOfMeasure)
          x.rate = data.row[`rate${x.unitOfMeasure.replace(x.unitOfMeasure, 'lbacr')}${x.moleculePk}`];
        x.unitOfMeasure = x.unitOfMeasure;
      });
    });
    return dataTransaction;
  }

  createTransactionsForRates(dataTransaction: any[]) {
    this.grid.gridApi.forEachNode((node: RowNode) => {
      let transaction = dataTransaction.find((x: any) => x.rowUniqueID === node.id);
      if (transaction) {

        if ((transaction.transactionType == 1 || transaction.transactionType == 2) && transaction.rowUniqueID === node.id) {
          if (!transaction.row?.applicationSchemeXActiveIngredientRate) {
            transaction.row.applicationSchemeXActiveIngredientRate = [];
          }
          transaction.row.applicationSchemeXActiveIngredientRate = [...transaction.row.applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(transaction.row)]
        }
      } else {
        if (!node.data?.applicationSchemeXActiveIngredientRate) {
          node.data.applicationSchemeXActiveIngredientRate = [];
        }
        let rates = [...node.data.applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(node.data)];
        node.data.applicationSchemeXActiveIngredientRate = rates;
        this.grid.CreateTransaction(node.id, node.id, null, node.data);
      }
    })
  }

  addActiveIngredientsRatesToGrid(data: any, selectedProject: Project): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {

      this.activeIngredientApiService.getMoleculeByMoleculePks(selectedProject.projectXCompoundXModel?.flatMap(x => x.MoleculePk!) ?? []).pipe(take(1)).subscribe({
        next: (activeIngredients: any) => {
          const ghaColDefs = this.createActiveIngredientsAsColDef(activeIngredients, 'gha');
          const lbacrColDefs = this.createActiveIngredientsAsColDef(activeIngredients, 'lbacr');
          this.configureGrid();
          this.insertRatesTocolumnDefs(this.columnDefs, ghaColDefs, lbacrColDefs, false);
          resolve(true);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  transformData(rowData: any) {
    rowData?.map((row: any) => {
      row.applicationSchemeXActiveIngredientRate.map((rate: any) => {
        row[`rate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.rate;
        row[`UnitOfMeasure${rate.unitOfMeasure}`] = rate.unitOfMeasure;
      });
    });
    this.rowData = rowData;
  }

  createActiveIngredientsAsColDef(activeIngredients: any[], unitOfMeasure: string): any[] {
    return this.createColDef(activeIngredients, unitOfMeasure);
  }

  insertRatesTocolumnDefs(columDef: ColGroupDef[], rateGHAcolumnDefs: ColGroupDef[], rateLBACREcolumnDefs: ColGroupDef[], isDetail: boolean,) {
    columDef.map((columDef: any) => {
      if (columDef.headerName === Constants.COLUMN_NAMES.RATEGHA) {
        this.activeIngredientRatesColDef = rateGHAcolumnDefs;
        columDef.children = rateGHAcolumnDefs;
      }
      if (columDef.headerName === Constants.COLUMN_NAMES.RATELBACRE) {

        this.activeIngredientRatesColDef = rateLBACREcolumnDefs;
        columDef.children = rateLBACREcolumnDefs;
      }
    });
    return this.columnDefs = columDef;
  }

  isSoilApplicationMethod(params: any) {
    if (params.data.ApplicationMethod === Constants.BEES_APPLICATION_METHODS[2].key && this.isProjectOwnershipValid) {
      return true;
    }
    return false;
  }

  configureGrid(): void {
    this.columnDefs = [
      {
        headerName: 'Application Scheme',
        field: 'name',
        type: 'default',
        resizable: true, width: 300,
        filter: 'agSetColumnFilter',
        cellRenderer: InputTextApplicationSchemeRenderer,
        headerCheckboxSelection: this.isProjectOwnershipValid,
        checkboxSelection: this.isProjectOwnershipValid,
        cellRendererParams: {
          disabled: !this.isProjectOwnershipValid,
          isRequired: true
        },
        editable: false,
        cellEditorParams: {
          maxLength: 200,
        },
        pinned: 'left',
      },
      {
        headerName: 'Rate (lb/acre)',
        children: [
          {
            headerName: 'Default Active Ingredient',
            field: 'defaultAI',
            cellRenderer: '',
            editable: false,
          },
        ]
      },
      {
        headerName: 'Rate Unit',
        field: 'UnitOfMeasure',
        width: 180,
        type: 'select',
        wrapHeaderText: false,
        resizable: true,
        filter: 'agSetColumnfilter',
        editable: false,
        resize: true,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            instance: this,
            options: Constants.BEES_UNITS,
            optionValue: 'key',
            optionLabel: 'name',
            columnThatModifies: 'UnitOfMeasure',
            placeholder: 'Select an option',
            disabled: !this.isProjectOwnershipValid
          };
        }
      },
      {
        headerName: 'Application Methods',
        field: 'ApplicationMethod',
        type: 'select',
        wrapHeaderText: false,
        width: 180,
        resizable: true,
        filter: 'agSetColumnfilter',
        editable: false,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            instance: this,
            options: Constants.BEES_APPLICATION_METHODS,
            optionValue: 'key',
            optionLabel: 'name',
            columnThatModifies: 'ApplicationMethod',
            placeholder: 'Select an option',
            disabled: !this.isProjectOwnershipValid,
          };
        }
      },
      {
        headerName: "Log Kow",
        field: "LogKow",
        wrapHeaderText: true,
        width: 80,
        hide: false,
        resizable: true,
        type: 'leftAligned',
        cellRenderer: 'inputNumberRenderer',
        filter: "agSetColumnFilter",
        valueFormatter: (params: any) => {
          if (params.value == undefined) return 0

          return params.value
        },
        cellRendererParams: (params: any) => {
          return {

            disabled: !this.isSoilApplicationMethod(params),
          }
        },
        editable: false,
        cellStyle: (params: any) => {
          if (params.data.ApplicationMethod != Constants.BEES_APPLICATION_METHODS[2].key) {
            return { 'background-color': '#f0f0f0' };
          }
          return !this.isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null;
        }

      },
      {
        headerName: "Koc",
        field: "Koc",
        wrapHeaderText: false,
        resizable: true,
        width: 80,
        cellRenderer: 'inputNumberRenderer',
        valueFormatter: (params: any) => {
          if (params.value == undefined) return null

          return params.value
        },
        cellRendererParams: (params: any) => {
          return {
            disabled: params.data.ApplicationMethod === Constants.BEES_APPLICATION_METHODS[2].key && this.isProjectOwnershipValid ? false : true,
          }
        },
        hide: false,
        type: 'leftAligned',
        filter: "agSetColumnFilter",
        editable: false,
        cellStyle: (params: any) => {
          if (params.data.ApplicationMethod != Constants.BEES_APPLICATION_METHODS[2].key) {
            return { 'background-color': '#f0f0f0' };
          }
          return !this.isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null;
        }

      },
      {
        headerName: 'Empirical data available',
        field: 'EmpiricalResidueDataAvailable',
        width: 130,
        resizable: true,
        wrapText: true,
        type: 'select',
        editable: false,
        suppressSizeToFit: true,
        filter: 'agSetColumnfilter',
        filterParams: {
          valueFormatter: (params: any) => {
            return params.value === Constants.AFFIRMATIVE_VALUES.YES ? Constants.AFFIRMATIVE_VALUES.YES : !Utils.isEmptyValue(params.value) ? Constants.AFFIRMATIVE_VALUES.NO : '(Blanks)';
          }
        },
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: [{ key: Constants.AFFIRMATIVE_VALUES.YES }, { key: Constants.AFFIRMATIVE_VALUES.NO }],
            columnThatModifies: 'EmpiricalResidueDataAvailable',
            optionLabel: 'key',
            optionValue: 'key',
            parent: this,
            disabled: !this.isProjectOwnershipValid
          };
        },

      },
      {
        headerName: "Mass of tree vegetation (kg-wet weight)",
        field: "MassOfTreeVegetation",
        wrapHeaderText: true,
        wrapText: true,
        resizable: true,
        width: 170,
        cellRenderer: 'inputNumberRenderer',
        valueFormatter: (params: any) => {
          if (params.value == undefined) return 0

          return params.value
        },
        cellRendererParams: (params: any) => {
          return {
            disabled: this.isTreeTrunk(params),
          }
        },
        hide: false,
        type: 'leftAligned',
        filter: "agSetColumnFilter",
        editable: false,
        cellStyle: (params: any) => {
          if (params.data.ApplicationMethod != Constants.BEES_APPLICATION_METHODS[0].key) {
            return { 'background-color': '#f0f0f0' };
          }
          return !this.isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null;
        }

      },

      {
        headerName: "Empirical residue in pollen/bread (mg a.i./kg)",
        field: "EmpiricalResidueInPollenBread",
        wrapHeaderText: true,
        resizable: true,
        width: 180,
        hide: false,
        type: 'leftAligned',
        filter: "agSetColumnFilter",
        cellRenderer: 'inputNumberRenderer',
        editable: false,
        cellRendererParams: (params: any) => {
          return {
            disabled: !this.validateEmpiricalData(params),
          }
        },
        valueFormatter: (params: any) => {
          if (params.value == undefined) return 0

          return params.value
        },
        cellStyle: (params: any) => {
          if (this.validateEmpiricalData(params) === false) {
            return { 'background-color': '#f0f0f0' };
          }
          return !this.isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null;
        }

      },
      {
        headerName: "Empirical residue in nectar (mg a.i./kg)",
        field: "EmpiricalResidueInNectar",
        wrapHeaderText: true,
        resizable: true,
        cellRenderer: InputNumberRenderer,
        width: 155,
        hide: false,
        editable: false,
        type: 'leftAligned',
        filter: "agSetColumnFilter",
        valueFormatter: (params: any) => {
          if (params.value == undefined) return 0

          return params.value
        },
        cellRendererParams: (params: any) => {
          return {
            disabled: !this.validateEmpiricalData(params),
          }
        },
        cellStyle: (params: any) => {
          if (!this.validateEmpiricalData(params)) {
            return { 'background-color': '#f0f0f0' };
          }
          return !this.isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null;
        }

      },
      {
        headerName: "Emperical residue in jelly (mg a.i./kg)",
        field: "EmpiricalResidueInJelly",
        resizable: true,
        width: 150,
        wrapHeaderText: true,
        cellRenderer: InputNumberRenderer,
        cellRendererParams: (params: any) => {
          return {
            disabled: !this.validateEmpiricalData(params),
          }
        },
        valueFormatter: (params: any) => {
          if (params.value == undefined) return 0

          return params.value
        },
        hide: false,
        type: 'leftAligned',
        filter: "agSetColumnFilter",
        editable: false,
        cellStyle: (params: any) => {
          if (!this.validateEmpiricalData(params)) {
            return { 'background-color': '#f0f0f0' };
          }
          return !this.isProjectOwnershipValid ? { backgroundColor: '#f0f0f0' } : null;
        }
      },      
      {
          headerName: '',
          colId: 'duplicate',
          field: 'duplicateButton',
          cellRenderer: DuplicateButtonRenderer,
          type: 'rightAligned',
          width: 130,
          cellRendererParams: {
              disabled: !this.isProjectOwnershipValid,
              mainTablePkName: "applicationSchemePk"
          }        
      },         
      {
        colId: 'delete',
        width: 125,
        minWidth: 125,
        editable: false,
        cellRenderer: 'deleteButtonRenderer',
        cellRendererParams: {
          disabled: !this.isProjectOwnershipValid
        },
        type: 'rightAligned'
      }
    ]
  }

  newRowAdded(newItems: any[]) {
    if( !this.grid ) return;
    const itemsToProcess = Array.isArray(newItems) ? newItems : [newItems];      
    itemsToProcess.forEach(item => {
      if (!item.isDuplicate) {
        this.grid.enableControls(false);
      }
    });
    this.grid.setLastRowSelected('useInProject');
    this.refreshSelectedValues();
  }

  public onBlurInputText({ row }: { row: any }): void {
    this.gapApplicationSchemeLogicService.checkIfApplicationSchemeIsValid(this, row.name);
  }

  public enableControls(enable: boolean): void {
    this.isValid.emit(enable);
    this.grid.useAddAction = enable;
  }

  public onAdd(): void {
    this.grid.setLastRowSelected('useInProject');
    this.refreshSelectedValues();
  }

  private refreshSelectedValuesTimeout(): void {
    setTimeout(() => {
      this.refreshSelectedValues();
    }, 75);
  }

  private refreshSelectedValues(): void {
    if(! this.grid ) return;
    this.grid.refreshSelectedValues('useInProject');
  }

  public onSelectedRow (event: RowSelectedEvent): void {
    if( !this.grid ) return;
    this.createTransactionForSelectedRow( event.node );
  }

  public onSelectOrUnselectAll ( event: SelectionChangedEvent ){
    if( event.source !== 'uiSelectAll' || !this.grid ) return;
    this.grid.gridApi.forEachNode((node: any) => {
     this.createTransactionForSelectedRow(node);
    });
  }

  private createTransactionForSelectedRow( node: any ): void {
    const { id, data } = node;
    data.useInProject = node.isSelected();
    this.grid.CreateTransaction(id, id, data.useInProject, data);
  }

}
