import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EEAGapSurfaceWaterColdef } from '../../eea-gap-surfacewater/eea-gap-surfacewater.coldef';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { ApplicationDateSelection } from 'src/app/shared/models/application-date';
import { RowNode } from 'ag-grid-community';
import { EEAGapGroundWaterColdef } from '../../eea-gap-ground-water/eea-gap-ground-water.coldef';
import { Constants } from 'src/app/shared/utils/constants';
import { Project } from 'src/app/shared/models/project';
import { EEAGapLogicService } from '../../eea-gap.logic.service';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { DatePipe } from '@angular/common';
import { Utils } from 'src/app/shared/utils/utils';
import { ApplicationSchemeGap } from 'src/app/environmental-exposure-assessment/eea-output/eea-output-project/eea-output-interfaces/eea-out-put-gap-interface';
import * as moment from 'moment';

@Component({
  selector: 'app-eea-application-dialog',
  templateUrl: './eea-application-dialog.component.html',
  styleUrls: ['./eea-application-dialog.component.css']
})

export class EEAApplicationDialogComponent implements OnInit {
  @Input() compartment!: string;
  @Input() selectedProject?: Project;
  @Input() rowData!: any;
  @Input() isInverseModeling: boolean = false;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() appScheme!: ApplicationSchemeGap;
  @Output() onClickSaveButtonEvent = new EventEmitter<any>();
  @ViewChild('grid') grid!: GridComponent;
  columsDef: any = [];
  visible: boolean = false;
  parentRowId: number = 0;
  parentRowNode!: RowNode;
  geographies: Catalog[] = [];
  selectedGeography: string = '';
  geographiesToFilter!: string[];
  widthSize: string = '50vw';

  constructor(private gapSurfaceWaterColdef: EEAGapSurfaceWaterColdef,
    private gapGroundWaterColdef: EEAGapGroundWaterColdef,
    private gapGWLogicService: EEAGapLogicService,
    public datepipe: DatePipe) { }

  ngOnInit(): void {
    let promises = [
      this.gapGWLogicService.getGeographies()
    ]
    Promise.all(promises)
      .then((results) => {
        this.geographies = results[0];
        this.geographiesToFilter = [Constants.CROP_GEOGRAPHIES.EUROPE_UNION, Constants.CROP_GEOGRAPHIES.UK];
        const selectedGeography = this.geographies.filter((x: Catalog) => this.geographiesToFilter.includes(x.name ?? ''))?.find((x: Catalog) => this.selectedProject?.countryPk === x.key || this.selectedProject?.regionPk === x.key)?.name;
        this.selectedGeography = selectedGeography == undefined ? '' : selectedGeography;
        this.widthSize = this.compartment == Constants.COMPARTMENTS.SURFACE_WATER ? '65vw' : this.widthSize;
      })
  }

  showDialog(parentRowNode: any, compartment: string) {
    this.parentRowNode = parentRowNode;
    if (this.isInverseModeling && this.selectedProject?.useApplicationWindow && this.geographiesToFilter.includes(this.selectedGeography)) {
      if(compartment === Constants.COMPARTMENTS.SURFACE_WATER){
        this.columsDef = this.gapSurfaceWaterColdef.getInverseApplicationColumnsDefinition(this.parentRowNode,this.isProjectOwnershipValid);
        this.visible = true;
      }
      else{
        this.widthSize="25vw";
        this.columsDef = this.gapGroundWaterColdef.getInverseApplicationColumnsDefinition(this.parentRowNode,this.isProjectOwnershipValid);
        this.visible = true;
      }
      this.visible = true;
    } else {
      if(compartment === Constants.COMPARTMENTS.SURFACE_WATER){
        this.columsDef = this.gapSurfaceWaterColdef.getApplicationColumnsDefinition(this.parentRowNode,this.isProjectOwnershipValid);
        this.visible = true;
      }else{
        let isChild = this.parentRowNode?.data?.isChild ?? false;
        this.columsDef = this.gapGroundWaterColdef.getApplicationColumnsDefinition(this.parentRowNode, this.isProjectOwnershipValid, isChild);
        this.visible = true;
      }
    }
  }

  closeDialog() {
    let selectedRows: ApplicationDateSelection = {
      selectedRows: this.grid.gridApi.getSelectedNodes(),
      parentRowNode: this.parentRowNode,
    };
    this.onClickSaveButtonEvent.emit(selectedRows);
    this.visible = false;
  }

  onCalendarSelectionChanged(event: any): void {
    let oldvalue = event.row[event.field];
    Date.prototype.toJSON = function(){ return moment(this).format(); }
    event.row[event.field] = moment(this.datepipe.transform(event.value, Constants.SERVICE_DATE_FORMAT));
    if (event.field.includes(Constants.APPLICATION_DATE_FIELDS.BEGIN_WINDOW)){
      event.row[Constants.APPLICATION_DATE_FIELDS.END_WINDOW] = this.parentRowNode.data.isChild ? this.datepipe.transform(Utils.GetEndDateByApplication(event.value, this.parentRowNode.data.min_application_interval, 1), Constants.SERVICE_DATE_FORMAT) :
      this.datepipe.transform(Utils.GetEndDateByApplication(event.value, this.appScheme?.applicationInterval, this.appScheme?.numberOfApplications), Constants.SERVICE_DATE_FORMAT);
    }
    this.grid.CreateTransaction(event.id, event.id, oldvalue, event.row);
    this.grid.gridApi.refreshCells();
  }

  onJulianDayChanged(event: any): void {
    if ((event.data[Constants.APPLICATION_DATE_FIELDS.BEGIN_WINDOW_JULIAN] < 1 || event.data[Constants.APPLICATION_DATE_FIELDS.BEGIN_WINDOW_JULIAN] > 365) ||
      (event.data[Constants.APPLICATION_DATE_FIELDS.END_WINDOW_JULIAN] < 1 || event.data[Constants.APPLICATION_DATE_FIELDS.END_WINDOW_JULIAN] > 365)) return;

    let oldvalue;
    if (event.field === Constants.APPLICATION_DATE_FIELDS.BEGIN_WINDOW_JULIAN || event.field === Constants.APPLICATION_DATE_FIELDS.END_WINDOW_JULIAN) {
      let dateType = event.field === Constants.APPLICATION_DATE_FIELDS.BEGIN_WINDOW_JULIAN ? Constants.APPLICATION_DATE_FIELDS.BEGIN_WINDOW : Constants.APPLICATION_DATE_FIELDS.END_WINDOW;
      oldvalue = event.data[dateType];
      event.data[dateType] = this.datepipe.transform(this.convertDaysToDate(Number(event.data[event.field]), event.data[dateType]), Constants.SERVICE_DATE_FORMAT);
      if (event.field === Constants.APPLICATION_DATE_FIELDS.BEGIN_WINDOW_JULIAN && this.compartment === Constants.COMPARTMENTS.SURFACE_WATER){
          event.data[Constants.APPLICATION_DATE_FIELDS.END_WINDOW] = this.parentRowNode.data.isChild ? this.datepipe.transform(Utils.GetEndDateByApplication(event.data[dateType], this.parentRowNode.data.min_application_interval, 1), Constants.SERVICE_DATE_FORMAT) :
          this.datepipe.transform(Utils.GetEndDateByApplication(event.data[dateType], this.appScheme?.applicationInterval, this.appScheme?.numberOfApplications), Constants.SERVICE_DATE_FORMAT);
      }
      this.grid.CreateTransaction(event.id, event.id, oldvalue, event.data);
      this.grid.gridApi.refreshCells();
    }
  }

  convertDaysToDate(julianDay: number, date: Date): Date {
    let currentDate = Utils.isEmptyValue(date) ? new Date(2001, 0, 1) : new Date(date);
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let resultDate = new Date(startDate);
    resultDate.setDate(startDate.getDate() + julianDay - 1);
    return resultDate;
  }
}
