import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { EEAGapApiService } from '../eea-gap.api.service';
import { catchError, forkJoin, take } from 'rxjs';
import { Compartment } from 'src/app/shared/models/echo/compartment';
import { EEAGapLogicService } from '../eea-gap.logic.service';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { EEAGAPMenuLogicService } from '../eea-gap/eea-gap-menu.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationDateSelection } from 'src/app/shared/models/application-date';
import { ApplicationDateApiService } from 'src/app/shared/services/application-date.api.service';
import { EEAApplicationDialogComponent } from '../shared/eea-application-dialog/eea-application-dialog.component';
import { ApplicationScheme, ApplicationSchemeXActiveIngredientRate, ApplicationSchemeXApplication, ApplicationSchemeXApplicationWindow } from 'src/app/shared/models/application-scheme';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { CropList } from 'src/app/shared/models/crop-list';
import { CropListMatching } from 'src/app/shared/models/crop-list-matching';
import { RowNode, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { ApplicationSchemeGap } from '../../eea-output/eea-output-project/eea-output-interfaces/eea-out-put-gap-interface';
import { Utils } from 'src/app/shared/utils/utils';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';
import { SelectableRow } from 'primeng/table';
import { ExcelWriterService } from 'src/app/shared/services/excel-writer.service';
import { Molecule } from 'src/app/shared/models/echo/molecule';
import { ProjectXCompoundXModel } from 'src/app/shared/models/project-x-compound-x-model';

@Component({
  selector: 'app-eea-gap-non-core-compartment',
  templateUrl: './eea-gap-non-core-compartment.component.html',
  styleUrls: ['./eea-gap-non-core-compartment.component.css']
})

export class EeaGapNonCoreCompartmentComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() isInverseModeling: boolean = false;
  @Input() menuService!: TabMenuLogicService;
  @ViewChild('grid') grid!: GridComponent;
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() public isValid = new EventEmitter<boolean>();

  @ViewChild('appDialog') applicationDialog!: EEAApplicationDialogComponent;
  compartmentList: Compartment[] = [];
  columsDef: any;
  loading: boolean = false;
  rowData: any;
  compartmentPk: number = 0;
  molecules: any[] = [];
  isCore: boolean = false;
  compartment: string = '';
  datafieldOptions: any = {};
  fixedColumns: any[] = ['deleteButton', 'appWindow', 'expandButton'];
  geographiesList: any[] = [];
  rowHeight: number = 45;
  destroyRef = inject(DestroyRef);
  geographies: Catalog[] = [];
  selectedApplicationWindowRow?: RowNode;
  applicationDatesByCrop: any[] = [];
  cropList: CropList[] = [];
  cropListMatchings: CropListMatching[] = [];
  selectedModels: string[] = [];
  detailsColumsDef: any;
  applicationScheme: ApplicationSchemeGap = {} as ApplicationSchemeGap;
  private downloadCompleted = new EventEmitter<void>();

  constructor(private gapApiService: EEAGapApiService,
    private gapLogicService: EEAGapLogicService,
    public EEAGAPMenuLogicService: EEAGAPMenuLogicService,
    private applicationDateApiService: ApplicationDateApiService,
    public datepipe: DatePipe,
    public gapApplicationSchemeLogicService: GapApplicationSchemeLogicService,
    private excelWriterService: ExcelWriterService,) {
    this.loading = this.gapLogicService.loading;
  }

  ngOnInit(): void {
    this.EEAGAPMenuLogicService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (!this.grid) return;
      this.saveData(this.grid.transactionList);
    });

    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (!this.grid) return;
      this.saveData(this.grid.transactionList);
    });

    this.setLoadingState(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.fillInitValues(changes);
    }
  }

  public fillInitValues(changes: SimpleChanges): void {
    let promises = [
      this.gapLogicService.getCompartments(),
      this.gapLogicService.getDataFieldValueByListName(['Application Method', 'Model Chemical Application Method']),
      this.gapLogicService.getRegionCountry(changes['selectedProject'].currentValue)
    ]
    Promise.all(promises)
      .then((results) => {
        this.compartmentList = results[0];
        this.datafieldOptions = results[1];
        this.geographiesList = results[2];
        this.getApplicationSchemesByProjectAndCompartment(changes['selectedProject'].currentValue.projectPk, this.getCoreCompartment());
      })
  }

  getCoreCompartment() {
    this.compartmentPk = Number(this.compartmentList?.filter((x: any) => x.compartment == this.compartment)?.map(x => x.endpointCompartmentPk).flat());
    return this.compartmentPk;
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
  }

  getData() {
    this.getApplicationSchemesByProjectAndCompartment(this.selectedProject?.projectPk!, this.compartmentPk);
  }

  getApplicationSchemesByProjectAndCompartment(projectPk: number, compartmentPk: number) {
    if (projectPk != 0) {
      this.loading = true;
      forkJoin({
        applicationSchemes: this.gapApiService.getApplicationSchemesByProjectWithAllCompartments(projectPk)
      })
        .pipe(
          catchError((error) => {
            console.error(error);
            this.loading = false;
            return [];
          })
        )
        .subscribe({
          next: ({ applicationSchemes }) => {
            if (!this.selectedProject) return;
            this.gapLogicService.addActiveIngredientsRatesToGrid(applicationSchemes, this.getColDefFilteredBySelectedModels(), this.isInverseModeling, this.selectedProject, this.isProjectOwnershipValid, "").then(() => {
              this.rowData =  this.gapLogicService.rowData.filter((x:any) => x.compartmentPk == compartmentPk);
              this.columsDef = this.gapLogicService.columsDef;
              this.setLoadingState(false);
            });
          },
        });
    }
  }

  getColDefFilteredBySelectedModels() { }

  saveData(dataTransaction: any[]) {
    dataTransaction = dataTransaction.filter((x: any) => Utils.isEmptyValue(x.row?.isChild));
    this.gapLogicService.createTransactionsForRates(dataTransaction, this.grid, this.isInverseModeling);
    if (dataTransaction.length == 0) {
      this.EEAGAPMenuLogicService.setSuccess(true);
      this.menuService.setSuccess(true);
      return;
    }

    if (!this.selectedProject) return;
    this.gapLogicService.setParametersToNewRows(dataTransaction, this.compartmentPk, this.isInverseModeling, this.selectedProject?.projectPk, this.isCore, this.compartment, this.cropListMatchings, this.selectedProject?.geography);
    this.gapLogicService.setActiveIngredientsRate(dataTransaction, this.isCore, this.compartmentList, this.isInverseModeling, this.compartment);
    this.gapLogicService.setApplicationWindow(dataTransaction, this.compartment);
    this.gapApiService.save(dataTransaction).pipe(take(1)).subscribe({
      next: () => {
        this.grid.afterSaveSuccess();
        this.EEAGAPMenuLogicService.setSuccess(true);
        this.menuService.setSuccess(true);
      },
      error: (err: any) => {
        console.warn(err);
        this.EEAGAPMenuLogicService.setSuccess(false);
        this.menuService.setSuccess(false);
      }
    });
  }

  onModelOptionsChanged(models: string[]) {
    this.selectedModels = models;
    if (this.grid) {
      const columns: any[] = this.gapLogicService.getDisplayedColumnsByModel(models, this.selectedProject?.geography!);
      const displayedColumns = this.grid?.gridColumnApi.getColumns();
      const fixedColumns = models.includes(Constants.MODELS.SWASH) || models.includes(Constants.MODELS.SWAN) || models.includes(Constants.MODELS.MACRO_GW) || models.includes(Constants.MODELS.PEARL) || models.includes(Constants.MODELS.PELMO) ? ['deleteButton', 'appWindow', 'expandButton'] : ['deleteButton'];
      let columnsToShow: string[] = [...Constants.GAP_CORE_FIELDS, ...new Set(columns), ...this.gapLogicService.getFixedColumns(displayedColumns, fixedColumns)];
      if (this.isInverseModeling) columnsToShow = [...columnsToShow, ...Constants.GAP_CORE_INVERSE_MODELING_FIELDS];
      columnsToShow = this.removeColumnsByModel(models, columnsToShow);
      displayedColumns.forEach((column: any) => {
        this.grid?.gridColumnApi.setColumnVisible(column.colId, columnsToShow.includes(column.getColDef().field));
        const conditions = [Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MIN_RATE, Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MAX_RATE, Constants.GAP_INVERSE_MODELING_FIELD_NAMES.INCREMENT_RATE];
        if (this.isInverseModeling && conditions.some(x => column.colId.includes(x))) {
          this.grid?.gridColumnApi.setColumnVisible(column.colId, true);
        }
      });
      this.grid?.gridApi.refreshCells();
    }
  }

  removeColumnsByModel(models: string[], columnsToShow: string[]) {
    let columns = this.removeUKColumns(models, columnsToShow);
    columns = this.removeVariableRatesColumns(models, columnsToShow);
    return columns;
  }

  removeUKColumns(models: string[], columnsToShow: string[]) {
    return (JSON.stringify(models) === JSON.stringify(new Array(Constants.MODELS.UK))) ? columnsToShow.filter((x: string) => x !== Constants.GAP_FIELD_NAMES.GEOGRAPHIES) : columnsToShow;
  }

  removeVariableRatesColumns(models: string[], columnsToShow: string[]) {
    return (JSON.stringify(models) === JSON.stringify(new Array(Constants.MODELS.STEP_1_2))) ? columnsToShow.filter((x: string) => x !== Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES) : columnsToShow;
  }

  deleteRow(event: any) {

    this.rowData = this.rowData.filter((r: any) => r.deleted === undefined || r.deleted === false);

    const deletedRow = event.find( (row: any) => row.transactionType === Constants.TRANSACTION_TYPE.DELETE );
    this.gapApplicationSchemeLogicService.checkIfApplicationSchemeIsValid(this, deletedRow?.row.name);

    if (event != undefined) {
      this.gapApiService
        .save(event)
        .pipe(take(1))
        .subscribe(() => { });
    }

    this.refreshSelectedValuesTimeout();

  }

  geographiesListBoxSelectionChanged(params: any) {
    if (!this.selectedProject) return;
    let oldValue: any = params.data['geographies'];
    let values = params.selectedValues.map((x: any) => { return { geographyPk: x.key } })
    params.data.applicationSchemeXGeography = values;
    this.grid.CreateTransaction(params.id, params.id, oldValue, params.data);
  }

  onDropDownSelectionChanged(event: any) {
    event.row.geographies = event.row.applicationSchemeXGeography?.length > 0 ? this.geographiesList.filter((x: any) => event.row.applicationSchemeXGeography.map((y: any) => y.geographyPk).includes(x.key)) : [];
    if (event.field === Constants.GAP_FIELD_NAMES.INTERCEPTION_PK || event.field === Constants.GAP_FIELD_NAMES.BBCH)
      this.gapLogicService.setCropInterceptionByCropAndBBCH(event.row, this.compartment);
    if (event.field === Constants.GAP_FIELD_NAMES.DATE_TYPE)
      this.grid.gridApi.redrawRows();
    this.grid.gridApi.refreshCells();
  }

  onClickViewButton(event: any) { }

  getScenariosWithoutCropListPk() { }

  onSaveApplicationDates(selectedAppDates: ApplicationDateSelection) { }

  getCropCoverageObject(pNumber: number) { }

  setCropInterceptionStep1And2(pRow: any) { }

  onFirstDataRendered(params: any): void { }

  createTransactionForApplicationDates(selectedAppDates: ApplicationDateSelection): void {
    let dates = selectedAppDates.selectedRows.map((x: any) => {
      let object: ApplicationSchemeXApplicationWindow = {
        applicationDateSurfaceWaterPk: x.data.applicationDateSurfaceWaterPk,
        firstDate: x.data.beginWindow,
        endDate: x.data.endWindow
      }
      return object;
    })
    let parentRowNode = this.grid.gridApi.getRowNode(selectedAppDates.parentRowNode.id);
    let oldvalue = parentRowNode.data.applicationSchemeXApplicationWindow;
    parentRowNode.data.applicationSchemeXApplicationWindow = dates;
    this.grid.CreateTransaction(parentRowNode.id, parentRowNode.id, oldvalue, parentRowNode.data);
  }

  onCalendarSelectionChanged(event: any): void {
    let parentRowNode = this.grid.gridApi.getRowNode(event.id);
    let appDate: ApplicationSchemeXApplicationWindow = {
      firstDate: new Date(this.datepipe.transform(event.value, Constants.SERVICE_DATE_FORMAT) + 'Z'),
    }
    parentRowNode.data.applicationSchemeXApplicationWindow = [appDate];
    let oldvalue = event.row[event.field];
    event.row[event.field] = this.datepipe.transform(event.value, Constants.SERVICE_DATE_FORMAT);
    this.grid.CreateTransaction(event.id, event.id, oldvalue, event.row);
  }

  getCropValueMatchings(node: RowNode): number | undefined {
    let matching = this.cropListMatchings?.find((x: any) => x.geography == this.selectedProject?.geography && x.compartment == this.compartment && x.gapCrops.includes(node?.data?.cropPk));
    return matching?.appDateCrops.length! > 0 ? matching?.appDateCrops[0]! : undefined;
  }

  validateCoreCropPK(node: RowNode, compartment: string, selectedGeography: string, cropMatchings: CropListMatching[]): boolean {
    let coreMatchingCrop: number[] = [];
    let validateRowCrops: boolean[] = [];

    if (node?.data?.cropAppDatePk) {
      coreMatchingCrop = cropMatchings?.filter((x: CropListMatching) => (x.compartment === compartment) && (x.geography === selectedGeography) && (x.appDateCrops.includes(node?.data?.cropAppDatePk)))?.flatMap(x => x.gapCrops)!;
      validateRowCrops.push(!coreMatchingCrop?.includes(node?.data?.cropPk) ? true : false);
    }
    if (node?.data?.cropDriftInterceptionPk) {
      coreMatchingCrop = cropMatchings?.filter((x: CropListMatching) => (x.compartment === compartment) && (x.geography === selectedGeography) && (x.driftInterceptionCrops.includes(node?.data?.cropDriftInterceptionPk)))?.flatMap(x => x.gapCrops)!;
      validateRowCrops.push(!coreMatchingCrop?.includes(node?.data?.cropPk) ? true : false);
    }
    if (node?.data?.cropInterceptionPk) {
      coreMatchingCrop = cropMatchings?.filter((x: CropListMatching) => (x.compartment === compartment) && (x.geography === selectedGeography) && (x.interceptionCrops.includes(node?.data?.cropInterceptionPk)))?.flatMap(x => x.gapCrops)!;
      validateRowCrops.push(!coreMatchingCrop?.includes(node?.data?.cropPk) ? true : false);
    }
    if (node?.data?.cropSprayPk) {
      coreMatchingCrop = cropMatchings?.filter((x: CropListMatching) => (x.compartment === compartment) && (x.geography === selectedGeography) && (x.sprayCrops.includes(node?.data?.cropSprayPk)))?.flatMap(x => x.gapCrops)!;
      validateRowCrops.push(!coreMatchingCrop?.includes(node?.data?.cropPk) ? true : false);
    }
    return validateRowCrops.some((x: boolean) => x == true);
  }

  onButtonExpandClick(event: any) {
    this.gapLogicService.toggleDetailGrid(event, this.grid);
  }

  newRowAdded(newItems: any[]) {
    this.grid.setLastRowSelected('useInProject');
    this.refreshSelectedValues();
    const itemsToProcess = Array.isArray(newItems) ? newItems : [newItems];      
    itemsToProcess.forEach(item => {
      if (!item.isDuplicate) {
        this.grid.enableControls(false);
      }
    });
  }

  protected async serverExcelExport() {
    var data:any[] = await this.gapLogicService.getApplicationSchemesByProjectWithAllCompartments(this.selectedProject?.projectPk!);
    const transformedJson = this.transformGapList(data);
    const headerOrder = this.getHeaderOrder(transformedJson);
    this.excelWriterService.exportToExcel(transformedJson, this.selectedProject?.name + " Gaps", Constants.CONST_COMPARTMENT, headerOrder);
    this.downloadCompleted.emit();
  }

  transformGapList(gaps: any[]): any[] {
    let data: any[] = [];
    const compartmentsToUse = this.compartmentList.filter((x: Compartment) => Constants.COMPARTMENTS_FOR_GAP_EXCEL_EXPORT.includes(x.compartment))?.flatMap(x => x.endpointCompartmentPk) ?? [];
    gaps?.filter(gap => compartmentsToUse.includes(gap.compartmentPk))?.forEach(gap => {
      data = [...data, ...this.transformGapData(gap)];
    });
    return data;
  }

  getHeaderOrder(transformedJson: any[]): { [key: string]: string[] } {
    let headerValuesByCompartment: { [key: string]: string[] } = {};
    const groupedData = this.groupDataByCompartment(transformedJson, Constants.CONST_COMPARTMENT);
    for (const [groupName, groupData] of Object.entries(groupedData)) {
      headerValuesByCompartment[groupName] = this.getHeaderOrderByCompartment(groupData, groupName);
    }
    return headerValuesByCompartment;
  }

  private groupDataByCompartment(data: any[], key: string): { [key: string]: any[] } {
    return data.reduce((acc, item) => {
        const groupValue = item[key] || 'Undefined';
        if (!acc[groupValue]) {
            acc[groupValue] = [];
        }
        acc[groupValue].push(item);
        return acc;
    }, {} as { [key: string]: any[] });
  }

  getHeaderOrderByCompartment(transformedJson: any[], compartment: string): string[] {
    let orderedHeaders: string[] = [];
    const dynamicHeaders = this.getDynamicHeaderNames(transformedJson);
    let orderedHeaderValues = this.getHeaderTemplateOrderForCompartment(compartment);
    orderedHeaderValues?.forEach((headerName: string) => {
      if(Constants.GAP_EXCEL_DYNAMIC_FIELDS.includes(headerName)){
        let headerValues = dynamicHeaders.filter((x: string) => x.startsWith(headerName))?.sort();
        orderedHeaders = [...orderedHeaders, ...headerValues];
      }else{
        orderedHeaders.push(headerName);
      }
    });
    return orderedHeaders;
  }

  getDynamicHeaderNames(transformedJson: any[]): string[] {
    let headerValues: string[] = [];
    transformedJson.forEach(obj => {
      headerValues = [...headerValues, ...this.getHeaderFromAppDosages(obj?.dosages)];
      Object.keys(obj).forEach(key => {
        if (Constants.GAP_EXCEL_DYNAMIC_FIELDS.some(prefix => key.startsWith(prefix))) {
          headerValues.push(key);
        }
      });
    });
    return Array.from(new Set(headerValues));
  }

  getHeaderFromAppDosages(dosages: any[]): string[] {
    let headerValues: string[] = [];
    dosages?.forEach(obj => {
      Object.keys(obj).forEach(key => {
        headerValues.push(key);
      });
    });
    return Array.from(new Set(headerValues));
  }

  private getHeaderTemplateOrderForCompartment(compartment: string): string[] { 
    const columns = Constants.GAP_EXCEL_HEADER_ORDER_BY_COMPARTMENT[compartment];
    if (typeof columns === 'object' && !Array.isArray(columns) && this.selectedProject?.geography) {
        return Array.from(new Set(columns[this.selectedProject.geography] || []));
    }
    return Array.from(new Set((Array.isArray(columns) ? columns : []) || []));
  }

  private getExcelKeyReplacements(compartment: string): any { 
    const columns = Constants.GAP_EXCEL_KEY_REPLACEMENT_BY_COMPARTMENT[compartment];
    if (compartment === Constants.COMPARTMENTS.SURFACE_WATER && this.selectedProject?.geography) {
        return columns[this.selectedProject.geography] ?? {};
    }
    return columns;
  }

  private getTemplateForCompartment(compartment: string): Set<string> { 
    const columns = Constants.GAP_TEMPLATE_COLUMNS_BY_COMPARTMENT[compartment];
    if (typeof columns === 'object' && !Array.isArray(columns) && this.selectedProject?.geography) {
        return new Set(columns[this.selectedProject.geography] || []);
    }
    return new Set((Array.isArray(columns) ? columns : []) || []);
  }

  transformGapData(gap: any): any[] {
    let gapRows: any[] = [];
    const compartment: string = this.compartmentList.find((x: Compartment) => x.endpointCompartmentPk == gap.compartmentPk)?.compartment ?? '';
    let fields = this.getTemplateForCompartment(compartment);
    const appSchemeTransformedData = this.getAppSchemeTransformedDataByCompartment(gap, compartment, fields);
    gapRows = [...gapRows, ...appSchemeTransformedData];
    return gapRows;
  }

  getAppSchemeTransformedDataByCompartment(gap: any, compartment: string, fields: Set<string>): any[] {
    if (compartment == Constants.COMPARTMENTS.GROUND_WATER)
      return this.getAppSchemeTransformedDataGW(gap, fields);
    else if (compartment == Constants.COMPARTMENTS.SURFACE_WATER)
      return this.getAppSchemeTransformedDataSW(gap, fields);
    else if (compartment == Constants.COMPARTMENTS.SOIL)
      return this.getAppSchemeTransformedDataSoil(gap, fields);
    return [];
  }

  getAppSchemeTransformedDataGW(gap: any, fields: Set<string>): any[] {
    let groupedFields: { [key: string]: number | string } = {};
    let gapRows: any[] = [];
    const scenarios = this.getScenarios(gap);
    const dosages = this.getPlainDosages(gap, Constants.COMPARTMENTS.GROUND_WATER);
    const keyReplacements = this.getExcelKeyReplacements(Constants.COMPARTMENTS.GROUND_WATER);
    scenarios.forEach((scenario: string) => {
      groupedFields = {};
      fields?.forEach((key) => {
        if(gap.hasOwnProperty(key)){
          const newKey = keyReplacements[key] || key;
          if(Constants.GW_VARIABLE_RATE_VALUES.includes(key)){
            groupedFields = { ...groupedFields, ...this.getGWVariableRateValues(gap, key, keyReplacements) };
          }else{
            let transformedValue = this.transformExcelValues(gap, key);
            groupedFields[newKey] = transformedValue;
          }
        }
      });
      let dates = gap?.dateType == Constants.DATE_TYPE_VALUES.ABSOLUTE ? this.getPlainDatesGW(gap, scenario, Constants.COMPARTMENTS.GROUND_WATER) : [];
      gapRows.push({
        Compartment: Constants.COMPARTMENTS.GROUND_WATER,
        ...groupedFields,
        dosages: dosages,
        Scenario: scenario,
        dates: dates
      });
    });
    return gapRows;
  }

  getGWVariableRateValues(gap: any, key: string, keyReplacements: any): { [key: string]: number | string } {
    let gwVariableRateProperties: { [key: string]: number | string } = {};
    const newKey = keyReplacements[key] || key;
    if(gap.dateType == Constants.DATE_TYPE_VALUES.ABSOLUTE && [Constants.GAP_FIELD_NAMES.DAYS_SINCE, Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL].includes(key)){ gwVariableRateProperties[newKey] = ''; return gwVariableRateProperties};
    if(gap.hasVariableRates){
      const applicationKey = Constants.GW_APPLICATION_FIELDS_CONVERSION[key];
      let applications = gap.applicationSchemeXApplication?.filter((x: ApplicationSchemeXApplication) => x.application_number != null) ?? [];
      applications.forEach((application: ApplicationSchemeXApplication) => {
        if(application.hasOwnProperty(applicationKey)){
          const keyToUse = application.application_number == 1 ?  newKey : `${newKey} ${application.application_number}`;
          gwVariableRateProperties[keyToUse] = (application as any)[applicationKey];
        }
      });
    }else{
      gwVariableRateProperties[newKey] = gap[key];
    }
    return gwVariableRateProperties;
  }

  getAppSchemeTransformedDataSW(gap: any, fields: Set<string>): any[] {
    let groupedFields: { [key: string]: number | string } = {};
    let gapRows: any[] = [];
    let scenarios = this.getScenarios(gap);
    let dosages = this.getPlainDosages(gap, Constants.COMPARTMENTS.SURFACE_WATER);
    const keyReplacements = this.getExcelKeyReplacements(Constants.COMPARTMENTS.SURFACE_WATER);
    let includesStep1n2 = this.selectedProject?.projectXCompoundXModel?.flatMap( (x : ProjectXCompoundXModel) => x.ModelName)?.includes(Constants.MODELS.STEP_1_2);
    if(this.selectedProject?.geography === Constants.GEOGRAPHYS.UK){
      return this.getTransformedDataForUk(gap, fields);
    }
    if(scenarios.length == 0 && !includesStep1n2){
      gapRows = [...gapRows, ...this.getTransformedDataSWWithoutScenarios(gap, fields, dosages)];
    }
    if(includesStep1n2){
      scenarios = [...scenarios, ...Constants.STEP1N2_LOCATIONS]; 
    }
    scenarios.forEach((scenario: string) => {
      groupedFields = {};
      fields?.forEach((key) => {
        if(gap.hasOwnProperty(key)){
          const newKey = keyReplacements[key] || key;
          let transformedValue = this.transformExcelValues(gap, key);
          groupedFields[newKey] = transformedValue;
        }
      });
      let dates = !Constants.STEP1N2_LOCATIONS.includes(scenario) ? this.getPlainDatesSW(gap, scenario) : [];
      dosages = Constants.STEP1N2_LOCATIONS.includes(scenario) ? this.getPlainDosagesFromParent(gap) : dosages;
      gapRows.push({
        Compartment: Constants.COMPARTMENTS.SURFACE_WATER,
        ...groupedFields,
        dosages: dosages,
        Scenario: scenario,
        dates: dates
      });
    });
    return gapRows;
  }

  getTransformedDataSWWithoutScenarios(gap: any, fields: Set<string>, dosages: any[]): any[] {
    let groupedFields: { [key: string]: number | string } = {};
    const keyReplacements = this.getExcelKeyReplacements(Constants.COMPARTMENTS.SURFACE_WATER);
    let gapRows: any[] = [];
      fields?.forEach((key) => {
        if(gap.hasOwnProperty(key)){
          const newKey = keyReplacements[key] || key;
          let transformedValue = this.transformExcelValues(gap, key);
          groupedFields[newKey] = transformedValue;
        }
      });
      gapRows.push({
        Compartment: Constants.COMPARTMENTS.SURFACE_WATER,
        ...groupedFields,
        dosages: dosages,
      });
    return gapRows;
  }

  getTransformedDataForUk(gap: any, fields: Set<string>): any[] {
    let groupedFields: { [key: string]: number | string } = {};
    const keyReplacements = this.getExcelKeyReplacements(Constants.COMPARTMENTS.SURFACE_WATER);
    let gapRows: any[] = [];
      fields?.forEach((key) => {
        if(gap.hasOwnProperty(key)){
          const newKey = keyReplacements[key] || key;
          let transformedValue = this.transformExcelValues(gap, key);
          groupedFields[newKey] = transformedValue;
        }
      });
      const appDate = this.getUKApplicationDate(gap);
      const dosages = this.getPlainDosagesFromParent(gap);
      gapRows.push({
        Compartment: Constants.COMPARTMENTS.SURFACE_WATER,
        ...groupedFields,
        'Application Date': appDate,
        dosages: dosages,
      });
    return gapRows;
  }

  getAppSchemeTransformedDataSoil(gap: any, fields: Set<string>): any[] {
    let groupedFields: { [key: string]: number | string } = {};
    let gapRows: any[] = [];
    const dosages = this.getPlainDosages(gap, Constants.COMPARTMENTS.SOIL);
    const keyReplacements = this.getExcelKeyReplacements(Constants.COMPARTMENTS.SOIL);
    groupedFields = {};
    fields?.forEach((key) => {
      if(gap.hasOwnProperty(key)){
        const newKey = keyReplacements[key] || key;
        let transformedValue = this.transformExcelValues(gap, key);
        groupedFields[newKey] = transformedValue;
      }
    });
    gapRows.push({
      Compartment: Constants.COMPARTMENTS.SOIL,
      ...groupedFields,
      dosages: dosages
    });
    return gapRows;
  }

  transformExcelValues(gap: any, key: string): string {
    if(Constants.GAP_CROP_LIST_FIELDS.includes(key)){
      return this.cropList.find((x => x.CropListPk == gap[key]))?.CropName ?? '';
    }
    else if(key == Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES){
      return gap[key] ? 'Yes' : 'No';
    }
    else if(key == Constants.GAP_FIELD_NAMES.TILLAGE){
      return gap[key] ? 'Yes' : Utils.isEmptyValue(gap[key]) ? '' :'No';
    }
    return gap[key];
  }

  getScenarios(gap: any): string[] {
   let parentApplicationDates = gap?.applicationSchemeXApplication?.find((x: ApplicationSchemeXApplication) => x.application_number == null) ?? [];
   return parentApplicationDates?.applicationSchemeXApplicationWindow?.map((x: ApplicationSchemeXApplicationWindow) => x.location)?.sort() ?? [];
  }

  getPlainDosages(gap: any, compartment: string): any[] {
    let dosages: any[] = []
    if(gap.hasVariableRates && compartment !== Constants.COMPARTMENTS.SOIL){
      let parentApplication = gap.applicationSchemeXApplication?.find((x: ApplicationSchemeXApplication) => x.application_number == null) ?? null;
      let numberOfRates = parentApplication?.applicationSchemeXActiveIngredientRate?.length ?? 0;
      let applications = gap.applicationSchemeXApplication?.filter((x: ApplicationSchemeXApplication) => x.application_number != null) ?? [];
      for (let i = 0; i < numberOfRates; i++) {
        applications.forEach((application: ApplicationSchemeXApplication) => {
          let rate = application?.applicationSchemeXActiveIngredientRate ? application?.applicationSchemeXActiveIngredientRate[i] : null;
          if(rate){
            let moleculeName = this.gapLogicService.activeIngredientList.find((x: Molecule) => rate?.moleculePk! == x.moleculePk)?.moleculeName;
            const key = `Rate_${moleculeName}_${application.application_number}`;
            const value = rate.rate;
            dosages.push({ [key]: value });
          }
        });
      }
    }else{
     dosages = this.getPlainDosagesFromParent(gap);
    }
    return dosages;
  }

  getPlainDosagesFromParent(gap: any): any[] {
    let dosages: any[] = [];
    let parentApplicationRates = gap?.applicationSchemeXApplication?.find((x: ApplicationSchemeXApplication) => x.application_number == null) ?? [];
    parentApplicationRates?.applicationSchemeXActiveIngredientRate?.forEach((rate: ApplicationSchemeXActiveIngredientRate) => {
      let moleculeName = this.gapLogicService.activeIngredientList.find((x: Molecule) => rate.moleculePk == x.moleculePk)?.moleculeName;
      if(moleculeName){
        const key = `Rate_${moleculeName}`;
        const value = rate.rate;
        dosages.push({ [key]: value });
      }
    });
    return dosages;
  }

  getPlainDatesGW(gap: any, scenario: string, compartment: string): any[] {
    let appDates: any[] = []
    if(gap.hasVariableRates){
      let applications = compartment == Constants.COMPARTMENTS.GROUND_WATER ? gap.applicationSchemeXApplication?.filter((x: ApplicationSchemeXApplication) => x.application_number != null) ?? [] : gap.applicationSchemeXApplication ?? [];
      applications.forEach((application: ApplicationSchemeXApplication) => {
        let date = application?.applicationSchemeXApplicationWindow ? application?.applicationSchemeXApplicationWindow?.find(x =>  x.location == scenario) : null;
        if(date){
          const key = `AppDate${application.application_number}`;
          const value = `${Utils.getDayMonthDate(date.firstDate!)} (${Utils.getDayOfYear(date.firstDate!)})`;
          appDates.push({ [key]: value });
        }
      });
    }else{
      let parentApplicationDates = gap?.applicationSchemeXApplication?.find((x: ApplicationSchemeXApplication) => x.application_number == null) ?? [];
      let applicationDates = parentApplicationDates?.applicationSchemeXApplicationWindow?.find( (x: ApplicationSchemeXApplicationWindow) =>  x.location == scenario) ?? null;
      if(applicationDates){
        let interval = 0;
        for (let i = 0; i < gap.numberOfApplications; i++) {
          if(i == 0){
            const key = `AppDate${i + 1}`;
            const value = `${Utils.getDayMonthDate(applicationDates.firstDate)} (${Utils.getDayOfYear(applicationDates.firstDate)})`;
            appDates.push({ [key]: value });
          }else{
            const key = `AppDate${i + 1}`;
            interval += gap.applicationInterval;
            let date = new Date(Utils.addDaysToDateFormated(applicationDates.firstDate, interval));
            const value = `${Utils.getDayMonthDate(date)} (${Utils.getDayOfYear(date)})`;
            appDates.push({ [key]: value });
          }
        }
      }
    }
    return appDates;
  }

  getPlainDatesSW(gap: any, scenario: string): any[] {
    let appDates: any[] = []
    let parentApplicationDates = gap?.applicationSchemeXApplication?.find((x: ApplicationSchemeXApplication) => x.application_number == null) ?? [];
    let applicationDates = parentApplicationDates?.applicationSchemeXApplicationWindow?.find( (x: ApplicationSchemeXApplicationWindow) =>  x.location == scenario) ?? null;
    if(applicationDates){
      const firstDate = `${Utils.getDayMonthDate(applicationDates.firstDate)} (${Utils.getDayOfYear(applicationDates.firstDate)})`;
      const endDate = `${Utils.getDayMonthDate(applicationDates.endDate)} (${Utils.getDayOfYear(applicationDates.endDate)})`;
      appDates.push({ [`Beginning Date`]: firstDate });
      appDates.push({ [`End Date`]: endDate });
    }
    return appDates;
  }

  getUKApplicationDate(gap: any): string {
    let parentApplicationDate = gap?.applicationSchemeXApplication?.find((x: ApplicationSchemeXApplication) => x.application_number == null) ?? [];
    let applicationDate = parentApplicationDate?.applicationSchemeXApplicationWindow?.length > 0 ? parentApplicationDate?.applicationSchemeXApplicationWindow[0] : null;
    if(applicationDate){
      return `${Utils.getDayMonthDate(applicationDate.firstDate)} (${Utils.getDayOfYear(applicationDate.firstDate)})`;
    }
    return '';
  }

  public onBlurInputText({ row }: { row: any }): void {
    this.gapApplicationSchemeLogicService.checkIfApplicationSchemeIsValid(this, row?.name);
  }

  public enableControls(enable: boolean): void {
    this.isValid.emit(enable);
    this.grid.useAddAction = enable;
  }

  public refreshSelectedValuesTimeout(): void {
    setTimeout(() => {
      this.refreshSelectedValues();
    }, 75);
  }

  public refreshSelectedValues(): void {
    if(! this.grid ) return;
    this.grid.refreshSelectedValues('useInProject');
  }

  public onSelectedRow (event: RowSelectedEvent): void {
    if( !this.grid ) return;
    this.createTransactionForSelectedRow( event.node );
  }

  public onSelectionChanged ( event: SelectionChangedEvent ){
    if( event.source !== 'uiSelectAll' || !this.grid ) return;
    this.grid.gridApi.forEachNode((node: any) => {
     this.createTransactionForSelectedRow(node);
    });
  }

  private createTransactionForSelectedRow( node: any ): void {
    const { id, data } = node;
    data.useInProject = node.isSelected();
    this.grid.CreateTransaction(id, id, data.useInProject, data);
  }
  setCropPkToChildren(node: RowNode) {
    node.data?.children?.forEach((application: ApplicationSchemeXApplication) => {
      application.cropAppDatePk = node?.data?.cropAppDatePk;
      application.cropInterceptionPk = node?.data?.cropInterceptionPk;
    });
  }
}
