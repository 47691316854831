import { CustomTabMenuItem } from "../models/custom-tab-menu-item";
import { Role } from '../models/role';

export abstract class Constants {
  public static readonly THIS_APPLICATION_ENDPOINT_DATA_SOURCE = 'bamsui';
  public static readonly THIS_APPLICATION = 'BaMS';
  static COLUMNS_NO_FILTREABLES: string[] = ['source'];
  public static readonly MESSAGE_SAVED_SUCCESSFULLY: string = 'Data Saved Successfully';
  public static readonly ROLE_ADMIN: string = 'Admin';
  public static readonly ROLE_EDITOR: string = 'Editor';
  public static readonly ROLE_READER: string = 'Reader';
  public static readonly ROLE_LOGOUT: string = '(Logout)';
  public static readonly ROLE_ERA_EDITOR: string = 'ERA Editor';
  public static readonly ROLE_HHRA_EDITOR: string = 'HHRA Editor';
  public static readonly BADGE_TOOLTIP_NO_DATA: string = 'This section has no data in it';
  public static readonly BADGE_TOOLTIP_HAS_DATA: string = 'This section has data in it';
  public static readonly MESSAGE_UPLOAD_SUCCESSFULLY: string = 'Data Uploaded Successfully';
  public static readonly MESSAGE_COPY_SUCCESSFULLY: string = 'Data Copied Successfully';
  public static readonly TITLE_CONFIRM_TO_COPY: string = 'Are you sure you want to copy?';
  public static readonly TITLE_CONFIRM_TO_OVERWRITE: string = 'This Data Set contains information. Do you want to overwrite it?';
  public static readonly CONST_STATUS_PENDING_FOR_REVISION = 'Pending for Revision';
  public static readonly CONST_STATUS_PENDING_FOR_APPROVAL = 'Pending for Approval';
  public static readonly CONST_STATUS_APPROVED = 'Approved';
  public static readonly CONST_PENDING_FOR_REVISION = 1;
  public static readonly CONST_PENDING_FOR_APPROVAL = 2;
  public static readonly CONST_APPROVED = 3;
  public static readonly CONST_COMPARTMENT = "Compartment";
  public static readonly SERVICE_DATE_FORMAT = 'yyyy-MM-ddTHH:mm:ss';
  public static readonly SELECTED_PROJECT = 'selectedProject';
  public static readonly SELECTED_PROJECT_STATUS = 'selectedProjectStatus';
  public static readonly SELECTED_PROJECT_RUNS_SAVED = 'selectedProjectRunsSaved';
  public static readonly IS_SETUP_VALID = 'isSetupValid';
  public static readonly REGEX_EXPONENTIAL_NUMBERS = /^-?\d*\.?\d+(?:[eE][-+]?\d+)?$/;
  public static readonly INDOOR = 'Indoor';
  public static readonly OUTDOOR = 'Outdoor';
  public static readonly FORMULATION_TYPE_GRANULES = 'Granules, fine granules';
  public static readonly NOT_APPLICABLE = 'N/A';
  public static readonly NOT_APPLICABLE_NA = 'NA';

  public static PWCControlsValidations = {
    TBAND: 'Split',
    ApplicationDate: 'Application Date',
    DaySince: 'Days Since',
    TBANDSPLIT: 'T Band',
    AboveCrop: 'Above Crop',
    BelowCrop: 'Below Crop',
  }

  public static REQUIRED_COLUMNS = [
    {
      field: 'substanceType',
      headerName: 'Substance Type'
    }, {
      field: 'substanceName',
      headerName: 'Substance Name'
    }];

  public static STATUS_ARRAY = [
    {
      value: 1,
      text: Constants.CONST_STATUS_PENDING_FOR_REVISION
    },
    {
      value: 2,
      text: Constants.CONST_STATUS_PENDING_FOR_APPROVAL
    },
    {
      value: 3,
      text: Constants.CONST_STATUS_APPROVED
    }];

  public static MESSAGES = {
    rolesSystems: 'Get Roles Systems error',
    rolesUsers: 'Get User Systems error',
    profile: 'Get Profile error',
    PROJECT_NOT_STOPED: 'The project could not be stopped',
    APP_SCHEME_INFO: 'When adding an application scheme, use the following nomenclature: <br> Crop_BBCH(timing)_DoseRate. <br> Example: Sugarbeet_BBCH10_1x2',
    DATA_SET_INFO: 'When adding a dataset, use the following nomenclature: <br> Active Ingredient-Country-Purpose. <br> Example: Fluroxypyr-EU-Renewal, Rinskor-FR-PreSub2024',
    PROJECT_INFO: 'When creating a project, use the following nomenclature: <br> Formulation-Country-Purpose-Compartment. <br> Example: GF-3731-SZ-Art33-SW',
    METRICS: 'Because SWASH is a shell, the 3 models show running at the same time, however, MACRO and PRZM run first (in parallel) and then TOXWA. Therefore, if you hit the in progress hyperlink and nothing shows up, it is because the other model(s) is running.'
  };

  public static MAX_RUNS_LIMITS = {
    NONE_METABOLTE: 999,
    ONE_METABOLITE: 333,
    TWO_METABOLITES: 199,
  }
  
  static CLIENT_ID: string = 'b5c744e1-7415-4483-8abb-2042ab84e96b';
  static TENANT_ID: string = '3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4';
  static GRAPH_ENDPOINT: string = 'https://graph.microsoft.com/v1.0';

  static ECHO_CLIENT_ID: string = '06222c78-eca5-4a41-aa40-d822a4898250';
  static GAP_CLIENT_ID: string = 'a379deae-82da-41cb-bc17-6bb30fae52a3';
  public static requiredColumnsElement = {
    title: 'Required Data',
    content: 'You must fillout the following column(s) in order to save it: ',
  };

  public static cantRepeatInput = {
    title: 'Input already selected',
    content: 'Please select another input value',
  };

  public static readonly DateddMMMyy =
    '^(3[0-1]|2[0-9]|1[0-9]|0[1-9])-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-([0-9][1-9]){1}$';

  public static readonly MONTH_SHORT_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  public static readonly illegalCharacters: string[] = [
    ',', "'", '/', ':', ';', '[', ']', '{', '}', '=', '*', '$', '%', '@', '#', '^', '&', '+', '!', '?', '<', '>', '~', '`', '|', '\\', '"'
  ];

  public static readonly illegalCharactersInSubstanceNames: string[] = [
    ' ', 
    ...Constants.illegalCharacters
  ];

  public static SaveDialogElement = {
    title: 'Please confirm',
    content: 'Are you sure you want to save the data?',
    actions: [{ text: 'Yes' }, { text: 'No' }],
  };

  public static CancelDialogElement = {
    title: 'Please confirm',
    content: 'Are you sure you want to discard all changes?',
    actions: [{ text: 'Yes' }, { text: 'No' }],
  };

  public static ChangeStatusDialogElement = {
    title: 'Please confirm',
    content: 'Do you want to apply this change?',
    actions: [{ text: 'Yes' }, { text: 'No' }],
  };

  public static DeleteDialogElements = {
    title: 'Please confirm',
    content: 'Are you sure you want to delete selected row?',
    actions: [{ text: 'Yes' }, { text: 'No' }],
  };

  public static MultipleDeleteDialogElements = {
    title: 'Please confirm',
    content: 'Are you sure you want to delete the selected rows?',
    actions: [{ text: 'Yes' }, { text: 'No' }],
  };

  public static listManagerLists = [
    {
      comodities: {
        listLevel1: 'Commodities',
        listLevel2: 'NA',
        listLevel3: 'NA',
        mappingValueTest: 'CTVA',
      },
    },
  ];

  public static DEFAULT_GW_YEAR: number = 1901;
  public static ELEMENTS_TAGS = {
    archive: 'archive',
    geography: 'gapGeographies',
    modelGeography: 'modelGeographies',
    model: 'models',
    panelMenu: 'p-panelmenu',
    linkItemActive: '.p-menuitem-link-active',
    applicationNumberMin: 'applicationNumberMinPk',
    applicationIntervalMin: 'applicationIntervalMinPk',
  };

  public static TAB_INDEX = {
    SET_UP: 0,
    ENDPOINTS: 1,
    GAP: 2,
    RUN_PROJECT: 3,
    OUTPUT: 4,
  };

  public static TAB_INDEXIMEEA = {
    SET_UP: 0,
    ENDPOINTS: 1,
    GAP: 2,
    ECOTOXENDPOINT: 3,
    RUN_EEA: 4,
    RUN_ERA: 5
  };


  public static COLUMN_NAMES = {
    RATEGHA: 'Rate (g/ha)',
    RATELBACRE: 'Rate (lb/acre)',
    RATEKGHA: "Rate (kg/ha)",
    CONCENTRATION_ACTIVE_SUBSTANCE: "Concentration of active substance [g a.s./L or Kg]",
    APPLICATION_RATE: "Application Rate"
  }

  public static PWC_SIMULATION_TYPES = {
    USEPA_POND_WATERSHED: 'USEPA Pond Watershed',
    USEPA_RESERVOIR_WATERSHED: 'USEPA Reservoir Watershed',
    USEPA_TPEZ_WPEZ: 'USEPA TPEZ & WPEZ'
  }
  public static PWC_FIELD_NAMES = {
    ID: 'id',
    MODEL: 'model.name',
    FORMULATION: 'formulation',
    GLOBAL_CROP: 'applicationSchemeData.cropPk',
    APP_SCHEME_NAME: 'applicationSchemeData.name',
    NUMBER_OF_APPLICATIONS: 'application_number',
    APP_SCHEME_APPLICATION_INTERVAL: 'applicationSchemeData.applicationInterval',
    APP_SCHEME_BBCH_EARLIEST: 'applicationSchemeData.bbchEarliest',
    DATE_TYPE: 'dateType',
    SPECIFY_YEARS: 'specifyYear',
    WINDOWS_DAYS_TO_WINDOWS_DAYS: 'windowsDaysToWindowsDays',
    STEP_DAYS_TO_STEP_DAYS: 'stepDaysToStepDays',
    DATA_SET: 'dataSet',
    ACTIVE_INGREDIENT: 'substanceName',
    SUBSTANCE_NAME: 'substanceName',
    MOLECULAR_WEIGHT: 'molecularWeight',
    SOLUBILITY_IN_WATER: 'solubilityInWater',
    SATURATED_VAPOUR_PRESSURE: 'saturatedVapourPressure',
    K_FOC: 'kfoc',
    HALFLIFE_IN_SOIL: 'halfLifeInSoil',
    MEASURED_HALF_LIFE_SOIL: 'measuredAtSoil',
    HALFLIFE_IN_SEDIMENT: 'halfLifeinSediment',
    MEASURED_HALF_LIFE_SEDIMENT: 'measuredAtSediment',
    HALFLIFE_IN_WATER: 'halfLifeInWater',
    MEASURED_HALF_LIFE_WATER: 'measuredAtWater',
    AQUEOUS_PHOTOLYSIS_HALFLIFE: 'aqueousPhotolysisHalfLife',
    PHOTOLYSIS_REFERENCE_LATITUDE: 'photolysisReferenceLatitude',
    HYDROLYSIS_HALFLIFE: 'hydrolysisHalflifeDay',
    FOLIAR_HALFLIFE: 'foliarHalflife',
    HENRYS_CONSTANT: 'henrysConstant',
    AIR_DIFFUSION_COEFFICIENT: 'airDiffusionCoefficient',
    HEAT_OF_HENRY: 'heatOfHenry',
    SIMULATION_TYPES: 'pwcXDataSetXSimulationType',
    ADJUST_APPLICATION_DATES_IF_RAINING: 'applicationSchemeData.adjustApplicationDatesIfRaining',
    SCENARIOS: 'cropXRegionPks'
  }

  public static FIELD_NAMES = {
    MIN_BBCH: 'minBBCH',
    MAX_BBCH: 'maxBBCH',
    CROP_EVENT: 'cropEvent',
    BEGINNING_DATE: 'beginningDate',
    END_DATE: 'endDate',
    APPLICATION_METHOD: 'application_method',
    APPLICATION_METHOD_PK: 'applicationMethodPk',
    CHEMICAL_APPLICATION_METHOD: 'chemicalApplicationMethod',
    CHEMICAL_APPLICATION_METHOD_PK: 'chemicalApplicationMethodPk',
    SOILD_DEPTH_INCORPORATED: 'soilDepthIncorporated',
    ROTATION_PK: 'rotationPk',
    DATE_TYPE: 'dateType',
    NOZZLE_DRIFT_REDUCTION_10M: 'nozzleDriftReduction10m',
    NOZZLE_DRIFT_REDUCTION_20M: 'nozzleDriftReduction20m',
    MOLECULAR_WEIGHT: 'molecularWeight',
    SOLUBILITY_IN_WATER: 'solubilityInWater',
    SATURATED_VAPOUR_PRESSURE: 'saturatedVapourPressure',
    K_FOC: 'kfoc',
    KOM: 'kom',
    EXPONENT: 'exponent',
    COEFFICIENT_FOR_UPTAKE_BY_PLANT: 'coefficientForUptakeByPlant',
    HALFLIFE_IN_SOIL: 'halfLifeInSoil',
    HALFLIFE_IN_SEDIMENT: 'halfLifeinSediment',
    HALFLIFE_IN_WATER: 'halfLifeInWater',
    HALFLIFE_IN_SEDIMENT_WATER_SYSTEM: 'halfLifeInSedimentWaterSystem',
    MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT: 'maximumOccurrenceInWaterSediment',
    MAXIMUM_OCCURRENCE_IN_SOIL: 'maximumOccurrenceInSoil',
    MAXIMUM_OCCURRENCE_IN_WATER: 'maximumOccurrenceInWater',
    MAXIMUM_OCCURRENCE_IN_SEDIMENT: 'maximumOccurrenceInSediment',
    ACTIVE_INGREDIENT: 'activeIngredient',
    MIN_APPLICATION_INTERVAL: 'minApplicationInterval',
    APPLICATION_NUMBER: 'applicationNumber',
    SUBSTANCE_NAME_PK: 'substanceNamePK',
    APPLICATION_NUMBER_PK: 'applicationNumberPk',
    MIN_APPLICATION_INTERVAL_PK: 'applicationIntervalMinPk',
    FRACTION_IN_SOIL: 'fractionInSoil',
    FRACTION_IN_SEDIMENT: 'fractionInSediment',
    FRACTION_IN_SURFACE_WATER: 'fractionInSurfaceWater',
    ID: 'id',
    APPLICATION_SCHEME: 'applicationScheme',
    APPLICATION_SCHEME_DATA: 'applicationSchemeData',
    MODEL_CROP: 'modelCrop',
    DATA_SET: 'dataSet',
    RATE: 'rate',
    CROP_COVERAGE: 'cropCoverage',
    CROP_INTERCEPTION: 'cropInterception',
    DISTANCE_TO_WATER: 'distanceToWater',
    DAYS_SINCE: 'daysSince',
    DEPTH: 'depth',
    T_BAND_SPLIT: 'tBandSplit',
    EFFICIENCY: 'efficiency',
    DRIFT: 'drift',
    FORMULATION: 'formulation',
    SIMULATION_TYPE: 'simulationType',
    SPECIFY_YEARS: 'specifyYears',
    APPLICATION_OCCURANCE: 'applicationOccurance',
    APPLICATION_OCCURANCE_FROM_YEAR: 'applicationOccuranceFromYear',
    APPLICATION_OCCURANCE_TO_YEAR: 'applicationOccuranceToYear',
    APPLY_PESTICIDE_WINDOWS: 'applyPesticideWindows',
    APPLY_PESTICIDE_STEPS: 'applyPesticideSteps',
    AQUEOUS_PHOTOLYSIS_HALFLIFE: 'aqueousPhotolysisHalfLife',
    PHOTOLYSIS_REFERENCE_LATITUDE: 'photolysisReferenceLatitude',
    HYDROLYSIS_HALFLIFE: 'hydrolysisHalflifeDay',
    FOLIAR_HALFLIFE: 'foliarHalflife',
    HENRYS_CONSTANT: 'henrysConstant',
    AIR_DIFFUSION_COEFFICIENT: 'airDiffusionCoefficient',
    HEAT_OF_HENRY: 'heatOfHenry',
    SUBSTANCE_TYPE: 'substanceType',
    SUBSTANCE_NAME: 'substanceName',
    DT50_SOIL: 'dt50Soil',
    MEASURED_HALF_LIFE_SOIL: 'measuredAtSoil',
    MEASURED_HALF_LIFE_SEDIMENT: 'measuredAtSediment',
    MEASURED_HALF_LIFE_WATER: 'measuredAtWater',
    FORMATION_FRACTION: 'formationFraction',
    METABOLITE_PK: 'metabolitePk',
    MOLECULE_PK: 'moleculePk',
    K1: 'k1',
    K2: 'k2',
    TB: 'tb',
    G: 'g',
    ALPHA: 'alpha',
    BETA: 'beta',
    DT90: 'Dt90',
    FRACTION_OF_NON_EQ_SITES_FNE: 'fne',
    DESORPTION_RATE_KD: 'kdes',
    DATA_SOURCE: 'dataSource',
    APPLICATION_METHOD_DEPTH: 'At Specific Depth',
    APPLICATION_METHOD_T_BAND_SPLIT: 'Split',
    APPLICATION_METHOD_LINEARLY_DECREASING: 'Linearly decreasing with depth',
    APPLICATION_METHOD_LINEARLY_INCREASING: '▽',
    APPLICATION_METHOD_LINEARLY_UNIFORM: 'Uniform',
    APPLICATION_METHOD_ABOVE_CROP: 'Above Crop',
    APPLICATION_METHOD_BELOW_CROP: 'Below Crop',
    MODEL: 'model.name',
    REGION_SEASON: 'regionAndSeason.name',
    APPLICATION: 'applicationNumber',
    EMERGENCY_DAY: 'emergenceDate',
    HARVEST_DAY: 'harvestDate',
    SCENARIOS: 'modelCropXScenario.scenario',
    SEASON: 'modelCropXScenario.season',
    LOCATION: 'modelCropXLocationGW.location',
    FIRST_APPLICATION_DATE: 'firstApplicationDate',
    APPLICATION_DATE: 'applicationDate',
    ENDPOINT_TYPE: 'endpointType',
    ROTATION: 'rotation',
    FOLIAR_WASH_OFF_FACTOR: 'foliarWashOfFactor',
    CANOPY_PROCESS_OPTION: 'canopyProcessOption',
    WATER_DIFFUSION_COEFFICIENT: 'waterDiffusionCoefficient',
    KINETIC_MODEL: 'kineticModel',
    NON_NORMALISED_DT50_SOIL: 'nonNormalisedDt50Soil',
    QUALITATIVE_MEASSURE: 'qualitative meassure',
    COMPARTMENT: 'compartment',
    GEOGRAPHY: 'geography',
    APPLICATION_INTERVAL: 'applicationInterval',
    NUMBER_OF_APPLICATIONS: 'numberOfApplications',
    MIN_BBCH_EARLIEST: 'applicationSchemeData.minBbchEarliest',
    MAX_BBCH_EARLIEST: 'applicationSchemeData.maxBbchEarliest',
    APP_SCHEME_CROP_INTERCEPTION: 'applicationSchemeData.cropInterception',
    GLOBAL_CROP: 'applicationSchemeData.cropPk',
    APP_SCHEME_SEASON: 'applicationSchemeData.season',
    APP_SCHEME_LOCATION: 'applicationSchemeData.location',
    APP_SCHEME_FIRST_DATE: 'applicationSchemeData.firstDate',
    APP_SCHEME_END_DATE: 'applicationSchemeData.endDate',
    APP_SCHEME_EMERGENCY_DAY: 'applicationSchemeData.emergencyDate',
    APP_SCHEME_HARVEST_DAY: 'applicationSchemeData.harvestDate',
    APP_SCHEME_APPLICATION_METHOD: 'applicationSchemeData.applicationMethod',
    APP_SCHEME_CHEMICAL_APPLICATION_METHOD: 'applicationSchemeData.chemicalApplicationMethod',
    APP_SCHEME_NOZZLE_DRIFT_REDUCTION_10M: 'applicationSchemeData.nozzleDriftReduction10m',
    APP_SCHEME_NOZZLE_DRIFT_REDUCTION_20M: 'applicationSchemeData.nozzleDriftReduction20m',
    ACTIVE_INGREDIENT_PK: 'activeIngredientPk',
    APP_SCHEME_DATE_TYPE: 'applicationSchemeData.dateType',
    APP_SCHEME_CROP_EVENT: 'applicationSchemeData.cropEvent',
    APP_SCHEME_SOIL_DEPTH: 'applicationSchemeData.soilDepth',
    APP_SCHEME_DAYS_SINCE: 'applicationSchemeData.daysSince',
    APP_SCHEME_CROP_COVERAGE: 'applicationSchemeData.cropCoverage',
    APP_SCHEME_BBCH_EARLIEST: 'applicationSchemeData.bbchEarliest',
    APP_SCHEME_NAME: 'applicationSchemeData.name',
    APP_SCHEME_NUMBER_OF_APPLICATIONS: 'applicationSchemeData.numberOfApplications',
    APP_SCHEME_APPLICATION_INTERVAL: 'applicationSchemeData.applicationInterval',
    APP_SCHEME_CROP_APP_DATE: 'applicationSchemeData.cropAppDatePk',
    APP_SCHEME_CROP_DRIFT_INTERCEPTION_PK: 'applicationSchemeData.cropDriftInterceptionPk',
    APP_SCHEME_CROP_INTERCEPTION_PK: 'applicationSchemeData.cropInterceptionPk',
    APP_SCHEME_CROP_SPRAY_PK: 'applicationSchemeData.cropSprayPk',
    APP_SCHEME_REPEAT_INTERVAL: 'applicationSchemeData.repeatInterval',
    APP_SCHEME_TILLAGE: 'applicationSchemeData.tillage',
    APP_SCHEME_PLANTEAU_SOIL_DEPTH: 'applicationSchemeData.plateauSoilDepth',
    APP_SCHEME_SIMULATION_YEAR: 'applicationSchemeData.simulationYear',
    APP_SCHEME_APPLICATION_DATE: 'applicationSchemeData.recAppDate',
    PELMO_LETTER: 'pelmoLetter',
    DT50_FOLIAR: 'dt50Foliar',
    ACCEPTABLE_OPERATOR_EXPOSURE_LEVEL: 'aoelEndpoint',
    INITIAL_DISLODGEABLE_FOLIAR_RESIDUE: 'initialDislodgeableFoliarResidue',
    ACUTE_ACCEPTABLE_OPERATOR_EXPOSURE_LEVEL: 'aaoelEndpoint',
    DERMAL_ABSORPTION_PRODUCT: 'dermalAbsorptionProduct',
    DERMAL_ABSORPTION_USE_DILUTION: 'dermalAbsorptionUseDilution',
    ORAL_ABSORPTION_ACTIVE_SUBSTANCE: 'oralAbsorptionActiveSubstance',
    INHALATION_ABSORPTION_ACTIVE_SUBSTANCE: 'inhalationAbsorptionActiveSubstance',
    EXCLUDE_METABOLITE: 'excludeMetabolite',
    EXPOSURE_DURATION: 'exposureDuration',
    EXPOSURE_TYPE: 'exposureType',
    POD_NON_CANCER: 'podNonCancer',
    ORDER: 'order',
    POD_SOURCE_STUDY: 'podSourceStudy',
    LOC_NON_CANCER: 'locNonCancer',
    POD_NON_CANCER_BODY_WEIGHT: 'podNonCancerBodyWeight',
    FRACTION_ABSORPTION: 'fractionAbsorption',
    ABSORPTION_SOURCE_STUDY: 'absorptionSourceStudy',
    Q_DAY_VALUE: 'qDayValue',
    Q_SOURCE_STUDY: 'qSourceStudy',
    CANCER_BODY_WEIGHT: 'cancerBodyWeight',
    IS_KOC: 'isKoc',
    MOLES_WATER_COLUMN_DAUGHTER: 'molesWaterColumnDaughter',
    MOLES_WATER_COLUMN_GRANDDAUGHTER: 'molesWaterColumnGranddaughter',
    MOLES_BENTHIC_DAUGHTER: 'molesBenthicDaughter',
    MOLES_BENTHIC_GRANDDAUGHTER: 'molesBenthicGranddaughter',
    MOLES_AQUEOUS_PHOTOLYSIS_DAUGHTER: 'molesAqueousPhotolysisDaughter',
    MOLES_AQUEOUS_PHOTOLYSIS_GRANDDAUGHTER: 'molesAqueousPhotolysisGranddaughter',
    MOLES_HYDROLYSIS_HALFLIFE_DAUGHTER: 'molesHydrolysisHalflifeDaughter',
    MOLES_HYDROLYSIS_HALFLIFE_GRANDDAUGHTER: 'molesHydrolysisHalflifeGranddaughter',
    MOLES_SOIL_HALFLIFE_DAUGHTER: 'molesSoilHalflifeDaughter',
    MOLES_SOIL_HALFLIFE_GRANDDAUGHTER: 'molesSoilHalflifeGranddaughter',
    MOLES_FOLIAR_HALFLIFE_DAUGHTER: 'molesFoliarHalflifeDaughter',
    MOLES_FOLIAR_HLAFLIFE_GRANDDAUGHTER: 'molesFoliarHalflifeGranddaughter'
  }

  public static COLUMN_HEADER_NAMES = {
    MIN_BBCH_EARLIEST: 'Min BBCH',
    MAX_BBCH_EARLIEST: 'Max BBCH',
    CROP_EVENT: 'Crop Event',
    BEGINNING_DATE: 'First Date',
    END_DATE: 'End Date',
    APPLICATION_METHOD: 'Application Method',
    APPLICATION_METHOD_PK: 'Application Method Pk',
    CHEMICAL_APPLICATION_METHOD: 'Chemical Application Method',
    SOILD_DEPTH_INCORPORATED: 'Soil Depth Incorporated',
    ROTATION_PK: 'Rotation',
    DATE_TYPE: 'Date Type',
    NOZZLE_DRIFT_REDUCTION_10M: 'Nozzle Drift Reduction 10m',
    NOZZLE_DRIFT_REDUCTION_20M: 'Nozzle Drift Reduction 20m',
    MOLECULAR_WEIGHT: 'Molecular Weight',
    SOLUBILITY_IN_WATER: 'Solubility In Water',
    SATURATED_VAPOUR_PRESSURE: 'Saturated Vapour Pressure',
    K_FOC: 'Kfoc',
    KOM: 'Kom',
    EXPONENT: 'Freundlich sorption exponent in soil',
    COEFFICIENT_FOR_UPTAKE_BY_PLANT: 'Coefficient For Uptake By Plant',
    HALFLIFE_IN_SOIL: 'Half-life In Soil',
    HALFLIFE_IN_SEDIMENT: 'Half-life In Sediment',
    HALFLIFE_IN_WATER: 'Half-life In Water',
    HALFLIFE_IN_SEDIMENT_WATER_SYSTEM: 'Half-life In Sediment/water System',
    MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT: 'Maximum Occurrence In Water Sediment',
    MAXIMUM_OCCURRENCE_IN_SOIL: 'Maximum Occurrence In Soil',
    MAXIMUM_OCCURRENCE_IN_WATER: 'Maximum Occurrence In Water',
    MAXIMUM_OCCURRENCE_IN_SEDIMENT: 'Maximum Occurrence In Sediment',
    ACTIVE_INGREDIENT: 'Active Ingredient',
    MIN_APPLICATION_INTERVAL: 'Min Application Interval',
    APPLICATION_NUMBER: 'Application Number',
    SUBSTANCE_NAME_PK: 'Substance Name PK',
    APPLICATION_NUMBER_PK: 'Application Number Pk',
    MIN_APPLICATION_INTERVAL_PK: 'Application Interval Min Pk',
    FRACTION_IN_SOIL: 'Fraction In Soil',
    FRACTION_IN_SEDIMENT: 'Fraction In Sediment',
    FRACTION_IN_SURFACE_WATER: 'Fraction In Surface Water',
    ID: 'Id',
    APPLICATION_SCHEME: 'Application Scheme',
    MODEL_CROP: 'Crop',
    DATA_SET: 'Data Set',
    RATE: 'Rate',
    CROP_COVERAGE: 'Crop Coverage',
    CROP_INTERCEPTION: 'Interception(%)',
    S3_CROP_INTERCEPTION: 'Step 3 Interception(%)',
    DISTANCE_TO_WATER: 'Distance To Water',
    DAYS_SINCE: 'Days Since',
    DEPTH: 'Depth',
    T_BAND_SPLIT: 'T Band Split',
    EFFICIENCY: 'Efficiency',
    DRIFT: 'Drift',
    FORMULATION: 'Formulation',
    SIMULATION_TYPE: 'Simulation Type',
    SPECIFY_YEARS: 'Specify Years',
    APPLICATION_OCCURANCE: 'Application Occurance',
    APPLICATION_OCCURANCE_FROM_YEAR: 'Application Occurance From Year',
    APPLICATION_OCCURANCE_TO_YEAR: 'Application Occurance To Year',
    APPLY_PESTICIDE_WINDOWS: 'Apply Pesticide Windows',
    APPLY_PESTICIDE_STEPS: 'Apply Pesticide Steps',
    AQUEOUS_PHOTOLYSIS_HALFLIFE: 'Aqueous Photolysis Halflife',
    PHOTOLYSIS_REFERENCE_LATITUDE: 'Photolysis Reference Latitude',
    HYDROLYSIS_HALFLIFE: 'Hydrolysis Halflife Day',
    FOLIAR_HALFLIFE: 'Foliar Halflife',
    HENRYS_CONSTANT: 'Henrys Constant',
    AIR_DIFFUSION_COEFFICIENT: 'Air Diffusion Coefficient',
    HEAT_OF_HENRY: 'Heat Of Henry',
    SUBSTANCE_TYPE: 'Substance Type',
    SUBSTANCE_NAME: 'Substance Name',
    DT50_SOIL: 'Dt50 Soil',
    DT90: 'Dt90',
    MEASURED_HALF_LIFE_SOIL: 'Measured At Soil',
    MEASURED_HALF_LIFE_SEDIMENT: 'Measured At Sediment',
    MEASURED_HALF_LIFE_WATER: 'Measured At Water',
    FORMATION_FRACTION: 'Formation Fraction',
    METABOLITE_PK: 'Metabolite Pk',
    MOLECULE_PK: 'Molecule Pk',
    K1: 'K1',
    K2: 'K2',
    TB: 'Tb',
    G: 'G (0 - 1)',
    ALPHA: 'Alpha',
    BETA: 'Beta',
    FRACTION_OF_NON_EQ_SITES_FNE: 'Fne',
    DESORPTION_RATE_KD: 'Kdes',
    DATA_SOURCE: 'Data Source',
    APPLICATION_METHOD_DEPTH: 'At Specific Depth',
    APPLICATION_METHOD_T_BAND_SPLIT: 'T Band',
    APPLICATION_METHOD_LINEARLY_DECREASING: '△',
    APPLICATION_METHOD_LINEARLY_INCREASING: 'Linearly increasing with depth',
    APPLICATION_METHOD_LINEARLY_UNIFORM: 'Uniform',
    APPLICATION_METHOD_ABOVE_CROP: 'Above Crop',
    APPLICATION_METHOD_BELOW_CROP: 'Below Crop',
    MODEL: 'Model Name',
    REGION_SEASON: 'Region And Season',
    APPLICATION: 'Application Number',
    EMERGENCY_DAY: 'Emergence Date',
    HARVEST_DAY: 'Harvest Date',
    SCENARIOS: 'Scenario',
    SEASON: 'Season',
    LOCATION: 'Location',
    FIRST_APPLICATION_DATE: 'First Application Date',
    APPLICATION_DATE: 'Application Date',
    ENDPOINT_TYPE: 'Endpoint Type',
    ROTATION: 'Rotation',
    FOLIAR_WASH_OFF_FACTOR: 'Foliar Wash Of Factor',
    CANOPY_PROCESS_OPTION: 'Canopy Process Option',
    WATER_DIFFUSION_COEFFICIENT: 'Water Diffusion Coefficient',
    KINETIC_MODEL: 'Kinetic Model',
    NON_NORMALISED_DT50_SOIL: 'Non Normalised Dt50 Soil',
    QUALITATIVE_MEASSURE: 'Qualitative Meassure',
    COMPARTMENT: 'Compartment',
    GEOGRAPHY: 'Geography',
    APPLICATION_INTERVAL: 'Application Interval',
    NUMBER_OF_APPLICATIONS: 'Number Of Applications',
    APP_SCHEME_SEASON: 'Season',
    APP_SCHEME_LOCATION: 'Location',
    APP_SCHEME_FIRST_DATE: 'First Date',
    APP_SCHEME_END_DATE: 'End Date',
    APP_SCHEME_EMERGENCY_DAY: 'Emergence  Date',
    APP_SCHEME_HARVEST_DAY: 'Harvest Date',
    APP_SCHEME_SOIL_DEPTH: 'Soil Depth (cm)',
    APP_SCHEME_INITIAL_SOIL_DEPTH: 'Initial Soil Depth (cm)',
    APP_SCHEME_BBCH_EARLIEST: 'BBCH (earliest)',
    APP_SCHEME_NAME: 'Application Scheme',
    APP_SCHEME_NUMBER_OF_APPLICATIONS: 'Number Of Applications',
    APP_SCHEME_APPLICATION_INTERVAL: 'Application Interval',
    APP_SCHEME_CROP_APP_DATE: 'Crop (AppDate)',
    APP_SCHEME_CROP_DRIFT_INTERCEPTION_PK: 'Crop (Interception)',
    APP_SCHEME_CROP_INTERCEPTION_PK: 'Crop (Interception)',
    APP_SCHEME_CROP_SPRAY_PK: 'Crop (Spray)',
    APP_SCHEME_REPEAT_INTERVAL: 'Repeat Interval for Application Events',
    APP_SCHEME_TILLAGE: 'Tillage',
    APP_SCHEME_PLANTEAU_SOIL_DEPTH: 'Plateau Soil Depth (cm)',
    APP_SCHEME_SIMULATION_YEAR: 'Simulation Year (years)',
    PELMO_LETTER: 'Pelmo Letter',
    VARIABLE_RATES: 'Variable Rates or intervals?',
    VARIABLE_RATES_SW: 'Variable rates or intervals for FOCUS Step 3 (Swash)',
    BBCH: 'BBCH',
    DRIFT_INTERCEPTION: 'Crop (Drift)',
  };

  public static readonly EEA_APPLICATIONS_FIELDS = {
    MIN_APPLICATION_INTERVAL: 'min_application_interval',
    DAYS_SINCE: 'days_since',
    EARLIEST_BBCH: 'earliest_bbch',
    CROP_INTERCEPTION: 'crop_interception',
  }

  public static REQUIRED_APPLICATIONS_FIELDS = [
    Constants.EEA_APPLICATIONS_FIELDS.CROP_INTERCEPTION,
    Constants.EEA_APPLICATIONS_FIELDS.DAYS_SINCE,
    Constants.EEA_APPLICATIONS_FIELDS.MIN_APPLICATION_INTERVAL,
    Constants.EEA_APPLICATIONS_FIELDS.EARLIEST_BBCH
  ]

  public static HEADER_NAMES_BY_FIELD = {
    [Constants.FIELD_NAMES.APP_SCHEME_BBCH_EARLIEST]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_BBCH_EARLIEST,
    [Constants.FIELD_NAMES.MIN_BBCH_EARLIEST]: Constants.COLUMN_HEADER_NAMES.MIN_BBCH_EARLIEST,
    [Constants.FIELD_NAMES.MAX_BBCH_EARLIEST]: Constants.COLUMN_HEADER_NAMES.MAX_BBCH_EARLIEST,
    [Constants.FIELD_NAMES.APP_SCHEME_CROP_EVENT]: Constants.COLUMN_HEADER_NAMES.CROP_EVENT,
    [Constants.FIELD_NAMES.BEGINNING_DATE]: Constants.COLUMN_HEADER_NAMES.BEGINNING_DATE,
    [Constants.FIELD_NAMES.END_DATE]: Constants.COLUMN_HEADER_NAMES.END_DATE,
    [Constants.FIELD_NAMES.APPLICATION_METHOD]: Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD,
    [Constants.FIELD_NAMES.APP_SCHEME_APPLICATION_METHOD]: Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD,
    [Constants.FIELD_NAMES.APP_SCHEME_CHEMICAL_APPLICATION_METHOD]: Constants.COLUMN_HEADER_NAMES.CHEMICAL_APPLICATION_METHOD,
    [Constants.FIELD_NAMES.SOILD_DEPTH_INCORPORATED]: Constants.COLUMN_HEADER_NAMES.SOILD_DEPTH_INCORPORATED,
    [Constants.FIELD_NAMES.ROTATION_PK]: Constants.COLUMN_HEADER_NAMES.ROTATION_PK,
    [Constants.FIELD_NAMES.APP_SCHEME_DATE_TYPE]: Constants.COLUMN_HEADER_NAMES.DATE_TYPE,
    [Constants.FIELD_NAMES.APP_SCHEME_NOZZLE_DRIFT_REDUCTION_10M]: Constants.COLUMN_HEADER_NAMES.NOZZLE_DRIFT_REDUCTION_10M,
    [Constants.FIELD_NAMES.APP_SCHEME_NOZZLE_DRIFT_REDUCTION_20M]: Constants.COLUMN_HEADER_NAMES.NOZZLE_DRIFT_REDUCTION_20M,
    [Constants.FIELD_NAMES.MOLECULAR_WEIGHT]: Constants.COLUMN_HEADER_NAMES.MOLECULAR_WEIGHT,
    [Constants.FIELD_NAMES.SOLUBILITY_IN_WATER]: Constants.COLUMN_HEADER_NAMES.SOLUBILITY_IN_WATER,
    [Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE]: Constants.COLUMN_HEADER_NAMES.SATURATED_VAPOUR_PRESSURE,
    [Constants.FIELD_NAMES.K_FOC]: Constants.COLUMN_HEADER_NAMES.K_FOC,
    [Constants.FIELD_NAMES.KOM]: Constants.COLUMN_HEADER_NAMES.KOM,
    [Constants.FIELD_NAMES.EXPONENT]: Constants.COLUMN_HEADER_NAMES.EXPONENT,
    [Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT]: Constants.COLUMN_HEADER_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
    [Constants.FIELD_NAMES.HALFLIFE_IN_SOIL]: Constants.COLUMN_HEADER_NAMES.HALFLIFE_IN_SOIL,
    [Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT]: Constants.COLUMN_HEADER_NAMES.HALFLIFE_IN_SEDIMENT,
    [Constants.FIELD_NAMES.HALFLIFE_IN_WATER]: Constants.COLUMN_HEADER_NAMES.HALFLIFE_IN_WATER,
    [Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM]: Constants.COLUMN_HEADER_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM,
    [Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT]: Constants.COLUMN_HEADER_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
    [Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL]: Constants.COLUMN_HEADER_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL,
    [Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER]: Constants.COLUMN_HEADER_NAMES.MAXIMUM_OCCURRENCE_IN_WATER,
    [Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SEDIMENT]: Constants.COLUMN_HEADER_NAMES.MAXIMUM_OCCURRENCE_IN_SEDIMENT,
    [Constants.FIELD_NAMES.ACTIVE_INGREDIENT_PK]: Constants.COLUMN_HEADER_NAMES.ACTIVE_INGREDIENT,
    [Constants.FIELD_NAMES.MIN_APPLICATION_INTERVAL]: Constants.COLUMN_HEADER_NAMES.MIN_APPLICATION_INTERVAL,
    [Constants.FIELD_NAMES.APPLICATION_NUMBER]: Constants.COLUMN_HEADER_NAMES.APPLICATION_NUMBER,
    [Constants.FIELD_NAMES.SUBSTANCE_NAME_PK]: Constants.COLUMN_HEADER_NAMES.SUBSTANCE_NAME_PK,
    [Constants.FIELD_NAMES.APPLICATION_NUMBER_PK]: Constants.COLUMN_HEADER_NAMES.APPLICATION_NUMBER_PK,
    [Constants.FIELD_NAMES.MIN_APPLICATION_INTERVAL_PK]: Constants.COLUMN_HEADER_NAMES.MIN_APPLICATION_INTERVAL_PK,
    [Constants.FIELD_NAMES.FRACTION_IN_SOIL]: Constants.COLUMN_HEADER_NAMES.FRACTION_IN_SOIL,
    [Constants.FIELD_NAMES.FRACTION_IN_SEDIMENT]: Constants.COLUMN_HEADER_NAMES.FRACTION_IN_SEDIMENT,
    [Constants.FIELD_NAMES.FRACTION_IN_SURFACE_WATER]: Constants.COLUMN_HEADER_NAMES.FRACTION_IN_SURFACE_WATER,
    [Constants.FIELD_NAMES.ID]: Constants.COLUMN_HEADER_NAMES.ID,
    [Constants.FIELD_NAMES.APPLICATION_SCHEME_DATA]: Constants.COLUMN_HEADER_NAMES.APPLICATION_SCHEME,
    [Constants.FIELD_NAMES.GLOBAL_CROP]: Constants.COLUMN_HEADER_NAMES.MODEL_CROP,
    [Constants.FIELD_NAMES.DATA_SET]: Constants.COLUMN_HEADER_NAMES.DATA_SET,
    [Constants.FIELD_NAMES.RATE]: Constants.COLUMN_HEADER_NAMES.RATE,
    [Constants.FIELD_NAMES.APP_SCHEME_CROP_COVERAGE]: Constants.COLUMN_HEADER_NAMES.CROP_COVERAGE,
    [Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION]: Constants.COLUMN_HEADER_NAMES.CROP_INTERCEPTION,
    [Constants.FIELD_NAMES.DISTANCE_TO_WATER]: Constants.COLUMN_HEADER_NAMES.DISTANCE_TO_WATER,
    [Constants.FIELD_NAMES.APP_SCHEME_DAYS_SINCE]: Constants.COLUMN_HEADER_NAMES.DAYS_SINCE,
    [Constants.FIELD_NAMES.DEPTH]: Constants.COLUMN_HEADER_NAMES.DEPTH,
    [Constants.FIELD_NAMES.T_BAND_SPLIT]: Constants.COLUMN_HEADER_NAMES.T_BAND_SPLIT,
    [Constants.FIELD_NAMES.EFFICIENCY]: Constants.COLUMN_HEADER_NAMES.EFFICIENCY,
    [Constants.FIELD_NAMES.DRIFT]: Constants.COLUMN_HEADER_NAMES.DRIFT,
    [Constants.FIELD_NAMES.FORMULATION]: Constants.COLUMN_HEADER_NAMES.FORMULATION,
    [Constants.FIELD_NAMES.SIMULATION_TYPE]: Constants.COLUMN_HEADER_NAMES.SIMULATION_TYPE,
    [Constants.FIELD_NAMES.SPECIFY_YEARS]: Constants.COLUMN_HEADER_NAMES.SPECIFY_YEARS,
    [Constants.FIELD_NAMES.APPLICATION_OCCURANCE]: Constants.COLUMN_HEADER_NAMES.APPLICATION_OCCURANCE,
    [Constants.FIELD_NAMES.APPLICATION_OCCURANCE_FROM_YEAR]: Constants.COLUMN_HEADER_NAMES.APPLICATION_OCCURANCE_FROM_YEAR,
    [Constants.FIELD_NAMES.APPLICATION_OCCURANCE_TO_YEAR]: Constants.COLUMN_HEADER_NAMES.APPLICATION_OCCURANCE_TO_YEAR,
    [Constants.FIELD_NAMES.APPLY_PESTICIDE_WINDOWS]: Constants.COLUMN_HEADER_NAMES.APPLY_PESTICIDE_WINDOWS,
    [Constants.FIELD_NAMES.APPLY_PESTICIDE_STEPS]: Constants.COLUMN_HEADER_NAMES.APPLY_PESTICIDE_STEPS,
    [Constants.FIELD_NAMES.AQUEOUS_PHOTOLYSIS_HALFLIFE]: Constants.COLUMN_HEADER_NAMES.AQUEOUS_PHOTOLYSIS_HALFLIFE,
    [Constants.FIELD_NAMES.PHOTOLYSIS_REFERENCE_LATITUDE]: Constants.COLUMN_HEADER_NAMES.PHOTOLYSIS_REFERENCE_LATITUDE,
    [Constants.FIELD_NAMES.HYDROLYSIS_HALFLIFE]: Constants.COLUMN_HEADER_NAMES.HYDROLYSIS_HALFLIFE,
    [Constants.FIELD_NAMES.FOLIAR_HALFLIFE]: Constants.COLUMN_HEADER_NAMES.FOLIAR_HALFLIFE,
    [Constants.FIELD_NAMES.HENRYS_CONSTANT]: Constants.COLUMN_HEADER_NAMES.HENRYS_CONSTANT,
    [Constants.FIELD_NAMES.AIR_DIFFUSION_COEFFICIENT]: Constants.COLUMN_HEADER_NAMES.AIR_DIFFUSION_COEFFICIENT,
    [Constants.FIELD_NAMES.HEAT_OF_HENRY]: Constants.COLUMN_HEADER_NAMES.HEAT_OF_HENRY,
    [Constants.FIELD_NAMES.SUBSTANCE_TYPE]: Constants.COLUMN_HEADER_NAMES.SUBSTANCE_TYPE,
    [Constants.FIELD_NAMES.SUBSTANCE_NAME]: Constants.COLUMN_HEADER_NAMES.SUBSTANCE_NAME,
    [Constants.FIELD_NAMES.DT50_SOIL]: Constants.COLUMN_HEADER_NAMES.DT50_SOIL,
    [Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SOIL]: Constants.COLUMN_HEADER_NAMES.MEASURED_HALF_LIFE_SOIL,
    [Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SEDIMENT]: Constants.COLUMN_HEADER_NAMES.MEASURED_HALF_LIFE_SEDIMENT,
    [Constants.FIELD_NAMES.MEASURED_HALF_LIFE_WATER]: Constants.COLUMN_HEADER_NAMES.MEASURED_HALF_LIFE_WATER,
    [Constants.FIELD_NAMES.FORMATION_FRACTION]: Constants.COLUMN_HEADER_NAMES.FORMATION_FRACTION,
    [Constants.FIELD_NAMES.METABOLITE_PK]: Constants.COLUMN_HEADER_NAMES.METABOLITE_PK,
    [Constants.FIELD_NAMES.MOLECULE_PK]: Constants.COLUMN_HEADER_NAMES.MOLECULE_PK,
    [Constants.FIELD_NAMES.K1]: Constants.COLUMN_HEADER_NAMES.K1,
    [Constants.FIELD_NAMES.K2]: Constants.COLUMN_HEADER_NAMES.K2,
    [Constants.FIELD_NAMES.TB]: Constants.COLUMN_HEADER_NAMES.TB,
    [Constants.FIELD_NAMES.G]: Constants.COLUMN_HEADER_NAMES.G,
    [Constants.FIELD_NAMES.ALPHA]: Constants.COLUMN_HEADER_NAMES.ALPHA,
    [Constants.FIELD_NAMES.BETA]: Constants.COLUMN_HEADER_NAMES.BETA,
    [Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE]: Constants.COLUMN_HEADER_NAMES.FRACTION_OF_NON_EQ_SITES_FNE,
    [Constants.FIELD_NAMES.DESORPTION_RATE_KD]: Constants.COLUMN_HEADER_NAMES.DESORPTION_RATE_KD,
    [Constants.FIELD_NAMES.DATA_SOURCE]: Constants.COLUMN_HEADER_NAMES.DATA_SOURCE,
    [Constants.FIELD_NAMES.APPLICATION_METHOD_DEPTH]: Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_DEPTH,
    [Constants.FIELD_NAMES.APPLICATION_METHOD_T_BAND_SPLIT]: Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_T_BAND_SPLIT,
    [Constants.FIELD_NAMES.APPLICATION_METHOD_LINEARLY_DECREASING]: Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_LINEARLY_DECREASING,
    [Constants.FIELD_NAMES.APPLICATION_METHOD_LINEARLY_INCREASING]: Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_LINEARLY_INCREASING,
    [Constants.FIELD_NAMES.APPLICATION_METHOD_LINEARLY_UNIFORM]: Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_LINEARLY_UNIFORM,
    [Constants.FIELD_NAMES.APPLICATION_METHOD_ABOVE_CROP]: Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_ABOVE_CROP,
    [Constants.FIELD_NAMES.APPLICATION_METHOD_BELOW_CROP]: Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD_BELOW_CROP,
    [Constants.FIELD_NAMES.MODEL]: Constants.COLUMN_HEADER_NAMES.MODEL,
    [Constants.FIELD_NAMES.APP_SCHEME_SEASON]: Constants.COLUMN_HEADER_NAMES.REGION_SEASON,
    [Constants.FIELD_NAMES.APPLICATION]: Constants.COLUMN_HEADER_NAMES.APPLICATION,
    [Constants.FIELD_NAMES.EMERGENCY_DAY]: Constants.COLUMN_HEADER_NAMES.EMERGENCY_DAY,
    [Constants.FIELD_NAMES.HARVEST_DAY]: Constants.COLUMN_HEADER_NAMES.HARVEST_DAY,
    [Constants.FIELD_NAMES.SCENARIOS]: Constants.COLUMN_HEADER_NAMES.SCENARIOS,
    [Constants.FIELD_NAMES.APP_SCHEME_SEASON]: Constants.COLUMN_HEADER_NAMES.SEASON,
    [Constants.FIELD_NAMES.APP_SCHEME_LOCATION]: Constants.COLUMN_HEADER_NAMES.LOCATION,
    [Constants.FIELD_NAMES.FIRST_APPLICATION_DATE]: Constants.COLUMN_HEADER_NAMES.FIRST_APPLICATION_DATE,
    [Constants.FIELD_NAMES.APPLICATION_DATE]: Constants.COLUMN_HEADER_NAMES.APPLICATION_DATE,
    [Constants.FIELD_NAMES.ENDPOINT_TYPE]: Constants.COLUMN_HEADER_NAMES.ENDPOINT_TYPE,
    [Constants.FIELD_NAMES.ROTATION]: Constants.COLUMN_HEADER_NAMES.ROTATION,
    [Constants.FIELD_NAMES.FOLIAR_WASH_OFF_FACTOR]: Constants.COLUMN_HEADER_NAMES.FOLIAR_WASH_OFF_FACTOR,
    [Constants.FIELD_NAMES.CANOPY_PROCESS_OPTION]: Constants.COLUMN_HEADER_NAMES.CANOPY_PROCESS_OPTION,
    [Constants.FIELD_NAMES.WATER_DIFFUSION_COEFFICIENT]: Constants.COLUMN_HEADER_NAMES.WATER_DIFFUSION_COEFFICIENT,
    [Constants.FIELD_NAMES.KINETIC_MODEL]: Constants.COLUMN_HEADER_NAMES.KINETIC_MODEL,
    [Constants.FIELD_NAMES.NON_NORMALISED_DT50_SOIL]: Constants.COLUMN_HEADER_NAMES.NON_NORMALISED_DT50_SOIL,
    [Constants.FIELD_NAMES.DT90]: Constants.COLUMN_HEADER_NAMES.DT90,
    [Constants.FIELD_NAMES.QUALITATIVE_MEASSURE]: Constants.COLUMN_HEADER_NAMES.QUALITATIVE_MEASSURE,
    [Constants.FIELD_NAMES.COMPARTMENT]: Constants.COLUMN_HEADER_NAMES.COMPARTMENT,
    [Constants.FIELD_NAMES.GEOGRAPHY]: Constants.COLUMN_HEADER_NAMES.GEOGRAPHY,
    [Constants.FIELD_NAMES.APP_SCHEME_FIRST_DATE]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_FIRST_DATE,
    [Constants.FIELD_NAMES.APP_SCHEME_END_DATE]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_END_DATE,
    [Constants.FIELD_NAMES.APP_SCHEME_EMERGENCY_DAY]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_EMERGENCY_DAY,
    [Constants.FIELD_NAMES.APP_SCHEME_HARVEST_DAY]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_HARVEST_DAY,
    [Constants.FIELD_NAMES.APP_SCHEME_SOIL_DEPTH]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_SOIL_DEPTH,
    [Constants.FIELD_NAMES.APP_SCHEME_NAME]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_NAME,
    [Constants.FIELD_NAMES.APP_SCHEME_NUMBER_OF_APPLICATIONS]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_NUMBER_OF_APPLICATIONS,
    [Constants.FIELD_NAMES.APP_SCHEME_APPLICATION_INTERVAL]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_APPLICATION_INTERVAL,
    [Constants.FIELD_NAMES.APP_SCHEME_CROP_APP_DATE]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_APP_DATE,
    [Constants.FIELD_NAMES.APP_SCHEME_CROP_DRIFT_INTERCEPTION_PK]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_DRIFT_INTERCEPTION_PK,
    [Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
    [Constants.FIELD_NAMES.APP_SCHEME_CROP_SPRAY_PK]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_SPRAY_PK,
    [Constants.FIELD_NAMES.APP_SCHEME_REPEAT_INTERVAL]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_REPEAT_INTERVAL,
    [Constants.FIELD_NAMES.APP_SCHEME_TILLAGE]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_TILLAGE,
    [Constants.FIELD_NAMES.APP_SCHEME_PLANTEAU_SOIL_DEPTH]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_PLANTEAU_SOIL_DEPTH,
    [Constants.FIELD_NAMES.APP_SCHEME_SIMULATION_YEAR]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_SIMULATION_YEAR,
    [Constants.FIELD_NAMES.PELMO_LETTER]: Constants.COLUMN_HEADER_NAMES.PELMO_LETTER,
    [Constants.EEA_APPLICATIONS_FIELDS.MIN_APPLICATION_INTERVAL]: Constants.COLUMN_HEADER_NAMES.APPLICATION_INTERVAL,
    [Constants.EEA_APPLICATIONS_FIELDS.CROP_INTERCEPTION]: Constants.COLUMN_HEADER_NAMES.CROP_INTERCEPTION,
    [Constants.EEA_APPLICATIONS_FIELDS.EARLIEST_BBCH]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_BBCH_EARLIEST,
    [Constants.EEA_APPLICATIONS_FIELDS.DAYS_SINCE]: Constants.COLUMN_HEADER_NAMES.DAYS_SINCE,
    [Constants.PWC_FIELD_NAMES.SCENARIOS]: Constants.COLUMN_HEADER_NAMES.SCENARIOS,
    [Constants.PWC_FIELD_NAMES.SIMULATION_TYPES]: Constants.COLUMN_HEADER_NAMES.SIMULATION_TYPE,
  };

  public static MAIN_APPLICATIONS_FIELDS = [
    Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION,
    Constants.FIELD_NAMES.APP_SCHEME_DAYS_SINCE,
    Constants.FIELD_NAMES.APP_SCHEME_APPLICATION_INTERVAL,
    Constants.FIELD_NAMES.APP_SCHEME_BBCH_EARLIEST
  ]

  public static DATA_VALUES_NAMES = {
    modelGeography: 'model geography',
    modelCrop: 'model crop',
    modelApplicationMethod: 'model application method',
    applicationKind: 'application kind',
    applicationMethod: 'application method',
    compartment: 'compartment',
    modelInputsSource: 'model inputs source',
    modelChemicalApplicationMethods: 'model chemical application method',
    coefficientForUptakePlant: 'coefficient for uptake by plant',
    halfLifeSediment: 'half-life in sediment',
    halfLifeSedimentWaterSystem: 'half-life in sediment/water system',
    halfLifeInSoil: 'half-life in soil',
    halfLifeWater: 'half-life in water',
    Kfoc: 'kfoc',
    maximumOccurrenceSoil: 'maximum occurrence in soil',
    maximumOccurrenceWaterSediment: 'maximum occurrence in water/sediment',
    saturatedVapourPressure: 'saturated vapour pressure',
    solubilityInWater: 'solubility in water',
    exponent: 'freundlich sorption exponent in soil',
    fractionInSurfaceWater: 'Fraction transformed in surface water',
    fractionInSediment: 'Fraction transformed in sediment',
    fractionInSoil: 'Fraction transformed in soil',
    airDiffusionCoefficient: 'air diffusion coefficient',
    aqueousPhotolysisHalfLife: 'aqueous photolysis half-life',
    photolysisReferenceLatitude: 'photolysis reference latitude',
    hydrolysisHalfLife: 'hydrolysis half-life',
    henrysConstant: 'henry’s constant',
    heatOfHenry: 'heat of henry',
    dt50Soil: 'dt50',
    measuredHalfLifeSoil: 'measured at (half-life in soil)',
    measuredHalfLifeSediment: 'measured at (half-life in sediment)',
    measuredHalfLifeWater: 'measured at (half-life in water)',
    foliarHalfLife: 'foliar half-life',
    k1: 'k1',
    k2: 'k2',
    tb: 'tb',
    g: 'g (0 - 1)',
    alpha: 'alpha (-)',
    beta: 'beta (-)',
    rotation: 'rotation',
    maximumOccurrenceWater: 'maximum occurrence in water',
    maximumOccurrenceSediment: 'maximum occurrence in sediment',
    gapApplicationNumber: 'gap application number',
    applicationInterval: 'application interval',
    foliarWashOfFactor: 'foliar wash off factor',
    canopyProcessOption: 'canopy process option',
    waterDiffusionCoefficient: 'water diffusion coefficient',
    fne: 'fraction of non-eq.-sites to eq.-sites',
    kdes: 'desorption rate kd (1/d)',
    kineticModel: 'kinetic model',
    nonNormalisedDt50Soil: 'non normalised dt50 soil',
    scenario: 'Scenarios',
    qualitativeMeassure: 'qualitative meassure',
    molecularWeight: 'molecular weight',
    kom: 'kom',
    pelmoLetter: 'pelmo letter',
    dt50Foliar: 'dt50 foliar',
    initialDislodgableFoliarResidue: 'initial dislodgable foliar residue',
    acceptableOperatorExposureLevel: 'aoel endpoint',
    acuteAcceptableOperatorExposureLevel: 'aaoel endpoint',
    dermalAbsorptionProduct: 'dermal absorption of product',
    dermalAbsorptionInUseDilution: 'dermal absorption of in-use dilution',
    oralAbsorptionActiveSubstance: 'oral absorption of active substance',
    inhalationAbsorptionActiveSubstance: 'inhalation absorption of active substance',
    dt90: 'dt90',
    exposureDuration: 'exposure duration',
    exposureType: 'exposure type',
    podNonCancer: 'pod non cancer',
    podSourceStudy: 'pod source study',
    locNonCancer: 'loc non cancer',
    podNonCancerBodyWeight: 'pod non cancer body weight',
    fractionAbsorption: 'fraction absorption',
    absorptionSourceStudy: 'absorption source study',
    qDayValue: 'q day value',
    qSourceStudy: 'q source study',
    cancerBodyWeight: 'cancer body weight',
    isKoc: 'iskoc',
    molesWaterColumnDaughter: 'Moles half-life in water Daughter',
    molesWaterColumnGranddaughter: 'Moles half-life in water Granddaughter',
    molesBenthicDaughter: 'Moles half-life in sediment Daughter',
    molesBenthicGranddaughter: 'Moles half-life in sediment Granddaughter',
    molesAqueousPhotolysisDaughter: 'Moles aqueous photolysis half-life Daughter',
    molesAqueousPhotolysisGranddaughter: 'Moles aqueous photolysis half-life Granddaughter',
    molesHydrolysisHalflifeDaughter: 'Moles hydrolysis half-life Daughter',
    molesHydrolysisHalflifeGranddaughter: 'Moles hydrolysis half-life Granddaughter',
    molesSoilHalflifeDaughter: 'Moles half-life in soil Daughter',
    molesSoilHalflifeGranddaughter: 'Moles half-life in soil Granddaughter',
    molesFoliarHalflifeDaughter: 'Moles foliar half-life Daughter',
    molesFoliarHalflifeGranddaughter: 'Moles foliar half-life Granddaughter'
  };

  public static readonly PEC_SURFACEWATER_ENDPOINTS = [
    { name: Constants.DATA_VALUES_NAMES.coefficientForUptakePlant, field: Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT },
    { name: Constants.DATA_VALUES_NAMES.halfLifeSediment, field: Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT },
    { name: Constants.DATA_VALUES_NAMES.halfLifeSedimentWaterSystem, field: Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM },
    { name: Constants.DATA_VALUES_NAMES.halfLifeInSoil, field: Constants.FIELD_NAMES.HALFLIFE_IN_SOIL },
    { name: Constants.DATA_VALUES_NAMES.halfLifeWater, field: Constants.FIELD_NAMES.HALFLIFE_IN_WATER },
    { name: Constants.DATA_VALUES_NAMES.Kfoc, field: Constants.FIELD_NAMES.K_FOC },
    { name: Constants.DATA_VALUES_NAMES.maximumOccurrenceSoil, field: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL },
    { name: Constants.DATA_VALUES_NAMES.maximumOccurrenceWaterSediment, field: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT },
    { name: Constants.DATA_VALUES_NAMES.saturatedVapourPressure, field: Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE },
    { name: Constants.DATA_VALUES_NAMES.solubilityInWater, field: Constants.FIELD_NAMES.SOLUBILITY_IN_WATER },
    { name: Constants.DATA_VALUES_NAMES.exponent, field: Constants.FIELD_NAMES.EXPONENT },
    { name: Constants.DATA_VALUES_NAMES.fractionInSoil, field: Constants.FIELD_NAMES.FRACTION_IN_SOIL },
    { name: Constants.DATA_VALUES_NAMES.fractionInSediment, field: Constants.FIELD_NAMES.FRACTION_IN_SEDIMENT },
    { name: Constants.DATA_VALUES_NAMES.fractionInSurfaceWater, field: Constants.FIELD_NAMES.FRACTION_IN_SURFACE_WATER },
    { name: Constants.DATA_VALUES_NAMES.airDiffusionCoefficient, field: Constants.FIELD_NAMES.AIR_DIFFUSION_COEFFICIENT },
    { name: Constants.DATA_VALUES_NAMES.aqueousPhotolysisHalfLife, field: Constants.FIELD_NAMES.AQUEOUS_PHOTOLYSIS_HALFLIFE },
    { name: Constants.DATA_VALUES_NAMES.photolysisReferenceLatitude, field: Constants.FIELD_NAMES.PHOTOLYSIS_REFERENCE_LATITUDE },
    { name: Constants.DATA_VALUES_NAMES.hydrolysisHalfLife, field: Constants.FIELD_NAMES.HYDROLYSIS_HALFLIFE },
    { name: Constants.DATA_VALUES_NAMES.henrysConstant, field: Constants.FIELD_NAMES.HENRYS_CONSTANT },
    { name: Constants.DATA_VALUES_NAMES.heatOfHenry, field: Constants.FIELD_NAMES.HEAT_OF_HENRY },
    { name: Constants.DATA_VALUES_NAMES.dt50Soil, field: Constants.FIELD_NAMES.DT50_SOIL },
    { name: Constants.DATA_VALUES_NAMES.measuredHalfLifeSoil, field: Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SOIL },
    { name: Constants.DATA_VALUES_NAMES.measuredHalfLifeSediment, field: Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SEDIMENT },
    { name: Constants.DATA_VALUES_NAMES.measuredHalfLifeWater, field: Constants.FIELD_NAMES.MEASURED_HALF_LIFE_WATER },
    { name: Constants.DATA_VALUES_NAMES.foliarHalfLife, field: Constants.FIELD_NAMES.FOLIAR_HALFLIFE },
    { name: Constants.DATA_VALUES_NAMES.k1, field: Constants.FIELD_NAMES.K1 },
    { name: Constants.DATA_VALUES_NAMES.k2, field: Constants.FIELD_NAMES.K2 },
    { name: Constants.DATA_VALUES_NAMES.tb, field: Constants.FIELD_NAMES.TB },
    { name: Constants.DATA_VALUES_NAMES.g, field: Constants.FIELD_NAMES.G },
    { name: Constants.DATA_VALUES_NAMES.alpha, field: Constants.FIELD_NAMES.ALPHA },
    { name: Constants.DATA_VALUES_NAMES.beta, field: Constants.FIELD_NAMES.BETA },
    { name: Constants.DATA_VALUES_NAMES.maximumOccurrenceWater, field: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER },
    { name: Constants.DATA_VALUES_NAMES.maximumOccurrenceSediment, field: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SEDIMENT },
    { name: Constants.DATA_VALUES_NAMES.foliarWashOfFactor, field: Constants.FIELD_NAMES.FOLIAR_WASH_OFF_FACTOR },
    { name: Constants.DATA_VALUES_NAMES.canopyProcessOption, field: Constants.FIELD_NAMES.CANOPY_PROCESS_OPTION },
    { name: Constants.DATA_VALUES_NAMES.waterDiffusionCoefficient, field: Constants.FIELD_NAMES.WATER_DIFFUSION_COEFFICIENT },
    { name: Constants.DATA_VALUES_NAMES.molecularWeight, field: Constants.FIELD_NAMES.MOLECULAR_WEIGHT },
    { name: Constants.DATA_VALUES_NAMES.kom, field: Constants.FIELD_NAMES.KOM },
    { name: Constants.DATA_VALUES_NAMES.pelmoLetter, field: Constants.FIELD_NAMES.PELMO_LETTER },
    { name: Constants.DATA_VALUES_NAMES.fne, field: Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE },
    { name: Constants.DATA_VALUES_NAMES.kdes, field: Constants.FIELD_NAMES.DESORPTION_RATE_KD },
    { name: Constants.DATA_VALUES_NAMES.kineticModel, field: Constants.FIELD_NAMES.KINETIC_MODEL },
    { name: Constants.DATA_VALUES_NAMES.nonNormalisedDt50Soil, field: Constants.FIELD_NAMES.NON_NORMALISED_DT50_SOIL },
    { name: Constants.DATA_VALUES_NAMES.dt90, field: Constants.FIELD_NAMES.DT90 },
    { name: Constants.DATA_VALUES_NAMES.isKoc, field: Constants.FIELD_NAMES.IS_KOC },
    { name: Constants.DATA_VALUES_NAMES.molesWaterColumnDaughter, field: Constants.FIELD_NAMES.MOLES_WATER_COLUMN_DAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesWaterColumnGranddaughter, field: Constants.FIELD_NAMES.MOLES_WATER_COLUMN_GRANDDAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesBenthicDaughter, field: Constants.FIELD_NAMES.MOLES_BENTHIC_DAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesBenthicGranddaughter, field: Constants.FIELD_NAMES.MOLES_BENTHIC_GRANDDAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesAqueousPhotolysisDaughter, field: Constants.FIELD_NAMES.MOLES_AQUEOUS_PHOTOLYSIS_DAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesAqueousPhotolysisGranddaughter, field: Constants.FIELD_NAMES.MOLES_AQUEOUS_PHOTOLYSIS_GRANDDAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesHydrolysisHalflifeDaughter, field: Constants.FIELD_NAMES.MOLES_HYDROLYSIS_HALFLIFE_DAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesHydrolysisHalflifeGranddaughter, field: Constants.FIELD_NAMES.MOLES_HYDROLYSIS_HALFLIFE_GRANDDAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesSoilHalflifeDaughter, field: Constants.FIELD_NAMES.MOLES_SOIL_HALFLIFE_DAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesSoilHalflifeGranddaughter, field: Constants.FIELD_NAMES.MOLES_SOIL_HALFLIFE_GRANDDAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesFoliarHalflifeDaughter, field: Constants.FIELD_NAMES.MOLES_FOLIAR_HALFLIFE_DAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.molesFoliarHalflifeGranddaughter, field: Constants.FIELD_NAMES.MOLES_FOLIAR_HLAFLIFE_GRANDDAUGHTER },
    { name: Constants.DATA_VALUES_NAMES.foliarWashOfFactor, field: Constants.FIELD_NAMES.FOLIAR_WASH_OFF_FACTOR },
  ];

  public static readonly HHRA_EXPO_TOX_ENDPOINTS = [
    { name: Constants.DATA_VALUES_NAMES.exposureDuration, field: Constants.FIELD_NAMES.EXPOSURE_DURATION },
    { name: Constants.DATA_VALUES_NAMES.exposureType, field: Constants.FIELD_NAMES.EXPOSURE_TYPE },
    { name: Constants.DATA_VALUES_NAMES.podNonCancer, field: Constants.FIELD_NAMES.POD_NON_CANCER },
    { name: Constants.DATA_VALUES_NAMES.podSourceStudy, field: Constants.FIELD_NAMES.POD_SOURCE_STUDY },
    { name: Constants.DATA_VALUES_NAMES.locNonCancer, field: Constants.FIELD_NAMES.LOC_NON_CANCER },
    { name: Constants.DATA_VALUES_NAMES.podNonCancerBodyWeight, field: Constants.FIELD_NAMES.POD_NON_CANCER_BODY_WEIGHT },
    { name: Constants.DATA_VALUES_NAMES.fractionAbsorption, field: Constants.FIELD_NAMES.FRACTION_ABSORPTION },
    { name: Constants.DATA_VALUES_NAMES.absorptionSourceStudy, field: Constants.FIELD_NAMES.ABSORPTION_SOURCE_STUDY },
    { name: Constants.DATA_VALUES_NAMES.qDayValue, field: Constants.FIELD_NAMES.Q_DAY_VALUE },
    { name: Constants.DATA_VALUES_NAMES.qSourceStudy, field: Constants.FIELD_NAMES.Q_SOURCE_STUDY },
    { name: Constants.DATA_VALUES_NAMES.cancerBodyWeight, field: Constants.FIELD_NAMES.CANCER_BODY_WEIGHT },
  ];

  public static defaultValueFlag = '|default';
  public static readonly DISCIPLINE_IDENTITIES = {
    ENVIRONMENTAL_E_FATE: 'Environmental Fate',
    TOXICOLOGY: 'Toxicology',
    ECOTOXICOLOGY: 'Ecotoxicology',
    PHYSCHEM: 'Physical & Chemical',
    EXPOTOX: 'EXPO TOX',

  };

  public static readonly ECOTOXICOLOGY_COMPARMENTS = {
    AQUATIC: 'Aquatic',
    TERRESTRIAL: 'Terrestrial',
    PLANT: 'Plant'
  }

  public static readonly EFATE_COMPARMENT_IDENTITIES = {
    PEC_GROUNDWATER: 'PEC Groundwater',
    PEC_SOIL: 'PEC Soil',
    PEC_SURFACEWATER: 'PEC Surface Water',
  };
  public static readonly modelInputsSources = {
    PHYSCHEM: 'ECHO Physical & Chemical',
    EFATE: 'ECHO Environmental Fate',
    GAP: 'GAP',
    MODEL_MATCHING: 'Model Matching',
  };
  public static readonly efateInputConstantValues = [
    'Kinetic Model',
    'Precursor',
    'Substance',
    'Formation Fraction',
    'Q10',
    'exponent',
    'Kdes',
    'Fne',
  ];
  public static readonly gapInputConstantValues = [
    'Formulation Rate (Max)',
    'Active Ingredient Rate',
    'Application Kind',
    'Application Method',
    'Number of Applications (Max)',
    'Growth State (Initial)',
    'Growth State (Final)',
    'Application Season (Initial)',
    'Application Season (Final)',
    'Application Interval (Min)',
  ];
  public static readonly modelMatchingInputConstantValues = [
    'Model Crop',
    'Model Region',
    'Model Application Method',
  ];
  public static readonly ADVANCED_FILTER = {
    ACTIVE_INGREDIENT: 'Active Ingredient',
    APPLICATION_SCHEME: 'Application Scheme',
    COMPARTMENT: 'Compartment',
    CROP: 'Crop',
    DATA_SET: 'Data Set',
    FORMULATION: 'Formulation',
    MAIN_AI: 'Main ai',
    MODEL: 'Model',
    PROJECT: 'Project',
  };
  public static readonly defaultOptions = {
    ALL: 'All',
    SELECT_AN_OPTION: 'Select an Option',
    DATA_SET_TO_IMPORT: 'Import Data Set',
  };

  public static readonly APPLICATIONS = {
    BaMS: 'BaMS',
    ECHO: 'ECHO',
    GAP: 'GAP',
  };

  public static readonly MODELS = {
    SWASH: 'FOCUS Step 3 (Swash)',
    STEP_1_2: 'FOCUS Step 1 and 2',
    SWAN: 'FOCUS Step 4 (Swan)',
    UK: 'UK Tier 1',
    PWC: 'PWC_SW',
    MACRO_GW: 'MACRO_GW',
    PEARL: 'PEARL',
    PELMO: 'PELMO',
    EU_SOIL: 'EU Soil',
    GERMAN_SOIL: 'Germany Soil',
    EU_OPEX: "EU - OPEX",
    US_OHE: "US - Occupation Handler Exposure",
    US_OREX: "US - Occupational Re-entry Exposure",
    NEU_SOIL: 'NEU Soil',
    US_RE: "US - Residencial Exposure",
    AGD_AQUAMIX: "AGD_AquaMix",
    AQUATICS_US: "Aquatics_US",
    BEE_REX: "Bee-REX",
    T_REX: "T-Rex",
  };

  public static readonly COMPARTMENTS = {
    SURFACE_WATER: 'Surface Water',
    GROUND_WATER: 'Groundwater',
    SOIL: 'Soil',
    CORE: 'Core',
    DIETARY: 'Dietary',
    SUBSTANCE_PROPERTIES: 'Substance Properties',
    NONDIETARY: 'Nondietary',
    AQUATIC: 'Aquatic',
    TERRESTRIAL: 'Terrestrial',
    TOX_EXPO_INPUTS: 'TOX and EXPO INPUTS',
    PLANT: 'Plant',
    TERRESTRIAL_VERTEBRATE: "Terrestrial Vertebrate",
    BEES: "Bees",
    ARTHROPODS: "Arthropods",
  };

  public static readonly MASTER_DETAIL_COLUMNS = {
    APPLICATION_NUMBER: Constants.FIELD_NAMES.APPLICATION_NUMBER,
    FRACTION_TRANSFORMED: 'fractionIn',
    SUBSTANCE_NAME: 'substanceName',
    SIMULATION_TYPES: 'simulationType'
  };

  public static readonly PROJECT_STATUS = {
    NOT_STARTED: 'Not started',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    FAILED: 'Failed',
    CALCULATING: 'Calculating',
    STOPPED: 'Stopped',
    IN_QUEUE: 'In Queue',
  };

  public static readonly PROJECT_STATUS_ID = {
    NOT_STARTED: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2,
    FAILED: 3,
    CALCULATING: 4,
    IN_QUEUE: 6,
    STOPPED: 5,
  }

  public static readonly BADGE_STYLE = {
    IN_PROGRESS: "inProgress",
    COMPLETED: "complete",
    FAILED: "failed",
    IN_QUEUE: 'inQueue'
  }

  public static readonly UKCropGroups = {
    FIELD_CROPS_BELOW_50: 'Field crops (and vegetables < 50cm)',
    FRUIT_CROPS_EARLY: 'Fruit crops early',
    FRUIT_CROPS_LATE: 'Fruit crops late',
    HOPS: 'Hops',
    VINES_LATE: 'Vines late (and vegetables > 50cm)',
    VINES_EARLY: 'Vines early'
  }

  public static REQUIRED_COLUMNS_BY_MODEL = {
    [Constants.MODELS.STEP_1_2]: [
      Constants.FIELD_NAMES.ID,
      Constants.FIELD_NAMES.MODEL,
      Constants.FIELD_NAMES.APP_SCHEME_SEASON,
      Constants.FIELD_NAMES.GLOBAL_CROP,
      Constants.FIELD_NAMES.APP_SCHEME_NAME,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_DRIFT_INTERCEPTION_PK,
      Constants.FIELD_NAMES.APP_SCHEME_NUMBER_OF_APPLICATIONS,
      Constants.FIELD_NAMES.APP_SCHEME_BBCH_EARLIEST,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_COVERAGE,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION,
      Constants.FIELD_NAMES.DATA_SET,
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.KOM,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
      Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
      Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL
    ],
    [Constants.MODELS.SWASH]: [
      Constants.FIELD_NAMES.ID,
      Constants.FIELD_NAMES.MODEL,
      Constants.FIELD_NAMES.APP_SCHEME_LOCATION,
      Constants.FIELD_NAMES.APP_SCHEME_SEASON,
      Constants.FIELD_NAMES.GLOBAL_CROP,
      Constants.FIELD_NAMES.APP_SCHEME_NAME,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_APP_DATE,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_DRIFT_INTERCEPTION_PK,
      Constants.FIELD_NAMES.APP_SCHEME_NUMBER_OF_APPLICATIONS,
      Constants.FIELD_NAMES.APP_SCHEME_APPLICATION_METHOD,
      Constants.FIELD_NAMES.APP_SCHEME_CHEMICAL_APPLICATION_METHOD,
      Constants.FIELD_NAMES.APP_SCHEME_SOIL_DEPTH,
      Constants.FIELD_NAMES.APP_SCHEME_BBCH_EARLIEST,
      Constants.FIELD_NAMES.APP_SCHEME_FIRST_DATE,
      Constants.FIELD_NAMES.APP_SCHEME_END_DATE,
      Constants.FIELD_NAMES.DATA_SET,
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
      Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
      Constants.FIELD_NAMES.KOM,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.EXPONENT,
      Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
      Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
      Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT
    ],
    [Constants.MODELS.UK]: [
      Constants.FIELD_NAMES.ID,
      Constants.FIELD_NAMES.MODEL,
      Constants.FIELD_NAMES.GLOBAL_CROP,
      Constants.FIELD_NAMES.APP_SCHEME_NAME,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_SPRAY_PK,
      Constants.FIELD_NAMES.APP_SCHEME_NUMBER_OF_APPLICATIONS,
      Constants.FIELD_NAMES.APP_SCHEME_BBCH_EARLIEST,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
      Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SEDIMENT,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER,
    ],
    [Constants.MODELS.MACRO_GW]: [
      Constants.FIELD_NAMES.ID,
      Constants.FIELD_NAMES.MODEL,
      Constants.FIELD_NAMES.DATA_SET,
      Constants.FIELD_NAMES.GLOBAL_CROP,
      Constants.FIELD_NAMES.APP_SCHEME_NAME,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_APP_DATE,
      Constants.FIELD_NAMES.APP_SCHEME_NUMBER_OF_APPLICATIONS,
      Constants.FIELD_NAMES.APP_SCHEME_BBCH_EARLIEST,
      Constants.FIELD_NAMES.APP_SCHEME_APPLICATION_METHOD,
      Constants.FIELD_NAMES.APP_SCHEME_SOIL_DEPTH,
      Constants.FIELD_NAMES.MODEL,
      Constants.FIELD_NAMES.APP_SCHEME_DATE_TYPE,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_APP_DATE,
      Constants.FIELD_NAMES.APP_SCHEME_LOCATION,
      Constants.FIELD_NAMES.APP_SCHEME_FIRST_DATE,
      Constants.FIELD_NAMES.APP_SCHEME_REPEAT_INTERVAL,
      Constants.FIELD_NAMES.ACTIVE_INGREDIENT_PK,
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
      Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.EXPONENT,
      Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT
    ],
    [Constants.MODELS.PWC]: [
      Constants.PWC_FIELD_NAMES.ID,
      Constants.PWC_FIELD_NAMES.MODEL,
      Constants.PWC_FIELD_NAMES.APP_SCHEME_NAME,
      Constants.PWC_FIELD_NAMES.DATA_SET,
      Constants.PWC_FIELD_NAMES.ACTIVE_INGREDIENT,
      Constants.PWC_FIELD_NAMES.SUBSTANCE_NAME,
      Constants.PWC_FIELD_NAMES.K_FOC,
      Constants.PWC_FIELD_NAMES.SIMULATION_TYPES,
    ],
    [Constants.MODELS.PEARL]: [
      Constants.FIELD_NAMES.ID,
      Constants.FIELD_NAMES.MODEL,
      Constants.FIELD_NAMES.APP_SCHEME_LOCATION,
      Constants.FIELD_NAMES.APP_SCHEME_FIRST_DATE,
      Constants.FIELD_NAMES.GLOBAL_CROP,
      Constants.FIELD_NAMES.APP_SCHEME_NAME,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_APP_DATE,
      Constants.FIELD_NAMES.APP_SCHEME_NUMBER_OF_APPLICATIONS,
      Constants.FIELD_NAMES.APP_SCHEME_BBCH_EARLIEST,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION,
      Constants.FIELD_NAMES.APP_SCHEME_SOIL_DEPTH,
      Constants.FIELD_NAMES.APP_SCHEME_APPLICATION_METHOD,
      Constants.FIELD_NAMES.APP_SCHEME_DATE_TYPE,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_EVENT,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_APP_DATE,
      Constants.FIELD_NAMES.APP_SCHEME_REPEAT_INTERVAL,
      Constants.FIELD_NAMES.DATA_SET,
      Constants.FIELD_NAMES.ACTIVE_INGREDIENT_PK,
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
      Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.EXPONENT,
      Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE,
      Constants.FIELD_NAMES.DESORPTION_RATE_KD
    ],
    [Constants.MODELS.PELMO]: [
      Constants.FIELD_NAMES.ID,
      Constants.FIELD_NAMES.MODEL,
      Constants.FIELD_NAMES.APP_SCHEME_LOCATION,
      Constants.FIELD_NAMES.APP_SCHEME_FIRST_DATE,
      Constants.FIELD_NAMES.GLOBAL_CROP,
      Constants.FIELD_NAMES.APP_SCHEME_NAME,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_APP_DATE,
      Constants.FIELD_NAMES.APP_SCHEME_NUMBER_OF_APPLICATIONS,
      Constants.FIELD_NAMES.APP_SCHEME_BBCH_EARLIEST,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION,
      Constants.FIELD_NAMES.APP_SCHEME_SOIL_DEPTH,
      Constants.FIELD_NAMES.APP_SCHEME_APPLICATION_METHOD,
      Constants.FIELD_NAMES.APP_SCHEME_DATE_TYPE,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_EVENT,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_APP_DATE,
      Constants.FIELD_NAMES.APP_SCHEME_REPEAT_INTERVAL,
      Constants.FIELD_NAMES.ACTIVE_INGREDIENT_PK,
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
      Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.EXPONENT,
      Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    ],
    [Constants.MODELS.EU_SOIL]: [
      Constants.FIELD_NAMES.ID,
      Constants.FIELD_NAMES.MODEL,
      Constants.FIELD_NAMES.APP_SCHEME_NAME,
      Constants.FIELD_NAMES.GLOBAL_CROP,
      Constants.FIELD_NAMES.APP_SCHEME_NUMBER_OF_APPLICATIONS,
      Constants.FIELD_NAMES.APP_SCHEME_BBCH_EARLIEST,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
      Constants.FIELD_NAMES.APP_SCHEME_CROP_INTERCEPTION,
      Constants.FIELD_NAMES.APP_SCHEME_SOIL_DEPTH,
      Constants.FIELD_NAMES.APP_SCHEME_TILLAGE,
      Constants.FIELD_NAMES.APP_SCHEME_PLANTEAU_SOIL_DEPTH,
      Constants.FIELD_NAMES.APP_SCHEME_SIMULATION_YEAR,
      Constants.FIELD_NAMES.ACTIVE_INGREDIENT_PK,
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.DT90,
      Constants.FIELD_NAMES.K1,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL
    ],
  };

  public static readonly KINETIC_MODELS = {
    SFO: 'SFO',
    DFOP: 'DFOP',
    HS: 'HS',
    FOMC: 'FOMC'
  }

  public static REQUIRED_COLUMNS_BY_KINETIC_MODEL = {
    [Constants.KINETIC_MODELS.FOMC]: [
      Constants.FIELD_NAMES.ALPHA,
      Constants.FIELD_NAMES.BETA,
    ],
    [Constants.KINETIC_MODELS.DFOP]: [
      Constants.FIELD_NAMES.G,
      Constants.FIELD_NAMES.K2
    ],
    [Constants.KINETIC_MODELS.HS]: [
      Constants.FIELD_NAMES.TB,
      Constants.FIELD_NAMES.K2
    ]
  };

  public static readonly COLUMS_BY_SWAN = [
    Constants.FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_10M,
    Constants.FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_20M
  ]
  public static readonly COLUMS_BY_APPLICATIONS = [
    Constants.FIELD_NAMES.APPLICATION_INTERVAL,
    Constants.FIELD_NAMES.NUMBER_OF_APPLICATIONS,
    Constants.FIELD_NAMES.RATE,
  ]
  public static readonly APPLICATION_HEADERS = {
    'applicationNumber': 'Application Number',
    'minApplicationInterval': 'Min application Interval(days)',
    'rate': 'Rate (g/ha)'
  }
  public static readonly APPLICATION_FIELDS = {
    'Application Number': 'applicationNumber',
    'Min application Interval(days)': 'minApplicationInterval',
    'Rate (g/ha)': 'rate'
  }
  public static readonly COLUMS_BY_METABOLITES = [
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.EXPONENT,
    Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
    Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL,
    Constants.FIELD_NAMES.PHOTOLYSIS_REFERENCE_LATITUDE,
    Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SOIL,
    Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SEDIMENT,
    Constants.FIELD_NAMES.MEASURED_HALF_LIFE_WATER,
    Constants.FIELD_NAMES.AQUEOUS_PHOTOLYSIS_HALFLIFE,
    Constants.FIELD_NAMES.HYDROLYSIS_HALFLIFE,
    Constants.FIELD_NAMES.FOLIAR_HALFLIFE,
    Constants.FIELD_NAMES.HENRYS_CONSTANT,
    Constants.FIELD_NAMES.AIR_DIFFUSION_COEFFICIENT,
    Constants.FIELD_NAMES.HEAT_OF_HENRY,
    Constants.FIELD_NAMES.FRACTION_IN_SOIL,
    Constants.FIELD_NAMES.FRACTION_IN_SEDIMENT,
    Constants.FIELD_NAMES.FRACTION_IN_SURFACE_WATER,
    Constants.FIELD_NAMES.KINETIC_MODEL,
    Constants.FIELD_NAMES.ORDER
  ]

  public static METABOLITES_BY_MODEL = {
    [Constants.MODELS.STEP_1_2]: [
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.KOM,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
      Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
      Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL,
    ],
    [Constants.MODELS.SWASH]: [
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
      Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
      Constants.FIELD_NAMES.KOM,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.EXPONENT,
      Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
      Constants.FIELD_NAMES.HALFLIFE_IN_WATER
    ],
    [Constants.MODELS.UK]: [
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
      Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SEDIMENT,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL
    ],
    [Constants.MODELS.PWC]: [
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.K_FOC,
    ],
    [Constants.MODELS.MACRO_GW]: [
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
      Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
      Constants.FIELD_NAMES.EXPONENT,
      Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
      Constants.FIELD_NAMES.ORDER
    ],
    [Constants.MODELS.PEARL]: [
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
      Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.EXPONENT,
      Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE,
      Constants.FIELD_NAMES.DESORPTION_RATE_KD
    ],
    [Constants.MODELS.PELMO]: [
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.K_FOC,
      Constants.FIELD_NAMES.KOM,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
      Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
      Constants.FIELD_NAMES.EXPONENT,
      Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
      Constants.FIELD_NAMES.PELMO_LETTER,
    ],
    [Constants.MODELS.EU_SOIL]: [
      Constants.FIELD_NAMES.SUBSTANCE_NAME,
      Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
      Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
      Constants.FIELD_NAMES.DT90,
      Constants.FIELD_NAMES.K1,
      Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL
    ],
  };

  public static readonly REQUIRED_FRACTIONS_BY_METABOLITES = [
    Constants.FIELD_NAMES.FRACTION_IN_SOIL,
    Constants.FIELD_NAMES.FRACTION_IN_SEDIMENT,
    Constants.FIELD_NAMES.FRACTION_IN_SURFACE_WATER
  ]

  public static readonly REQUIRED_FIELDS_BY_RATES = [
    Constants.FIELD_NAMES.RATE,
  ]

  public static readonly REQUIRED_FRACTIONS_BY_METABOLITES_BY_MODEL = {
    [Constants.MODELS.SWASH]: [
      Constants.FIELD_NAMES.FRACTION_IN_SOIL,
      Constants.FIELD_NAMES.FRACTION_IN_SEDIMENT,
      Constants.FIELD_NAMES.FRACTION_IN_SURFACE_WATER
    ],
    [Constants.MODELS.MACRO_GW]: [
      Constants.FIELD_NAMES.FRACTION_IN_SOIL
    ],
    [Constants.MODELS.PELMO]: [
      Constants.FIELD_NAMES.FRACTION_IN_SOIL
    ],
    [Constants.MODELS.PEARL]: [
      Constants.FIELD_NAMES.FRACTION_IN_SOIL
    ],
  }

  public static readonly MODELS_THAT_REQUIRE_FORMATION_FRACTION = [
    Constants.MODELS.MACRO_GW,
    Constants.MODELS.SWASH,
    Constants.MODELS.PELMO,
    Constants.MODELS.PEARL
  ]
  public static readonly COLUMS_BY_FRACTIONS = [
    'substanceName',
    Constants.FIELD_NAMES.FORMATION_FRACTION
  ]
  public static readonly QC_ALERT = {
    text: 'Runs with a yellow background are not going to be executed. Do you still want to proceed?',
    showCancelButton: true,
    confirmButtonColor: '#0069be',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    icon: 'warning'
  };

  public static readonly RUN_ALERT = {
    title: 'Confirm',
    text: 'Are you sure you want to process these runs?',
    showCancelButton: true,
    confirmButtonColor: '#0069be',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }

  public static DATE_TYPES = ['Absolute', 'Relative'];

  public static CROP_EVENTS = ['Emergence', 'Harvest'];
  public static CROP_INTERCEPTIONS_STEP_1_2 = ["No interception", "Minimal crop cover", "Average crop cover", "Full canopy"]
  public static readonly APPLICATION_METHOD_COLUMN_NAME = 'Application Method';
  public static readonly GROUND_SPRAY = 'ground spray';
  public static readonly AIR_BLAST = 'Air-Blast';
  public static AIRBLAST_CROPS = ['Citrus', 'Hops', 'Olives', 'Pome-stone fruit, early applns', 'Pome-stone fruit, late applns', 'Vines, early applns', 'Vines, late applns'];
  // public static SW_APPLICATION_METHODS = ['aerial appl', 'granular appl', 'ground spray', 'soil incorp', 'air Blast', 'air-Blast'];
  public static readonly STEP_1_2_MODEL_COLUMNS = [
    Constants.FIELD_NAMES.ID,
    Constants.FIELD_NAMES.MODEL,
    Constants.FIELD_NAMES.REGION_SEASON,
    Constants.FIELD_NAMES.MODEL_CROP,
    Constants.FIELD_NAMES.APPLICATION_SCHEME,
    Constants.FIELD_NAMES.MIN_BBCH,
    Constants.FIELD_NAMES.MAX_BBCH,
    Constants.FIELD_NAMES.CROP_COVERAGE,
    Constants.FIELD_NAMES.CROP_INTERCEPTION,
    Constants.FIELD_NAMES.APPLICATION,
    Constants.FIELD_NAMES.DATA_SET,
    Constants.FIELD_NAMES.ACTIVE_INGREDIENT,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
    Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL
  ];
  public static readonly UK_MODEL_COLUMNS = [
    Constants.FIELD_NAMES.ID,
    Constants.FIELD_NAMES.MODEL,
    Constants.FIELD_NAMES.MODEL_CROP,
    Constants.FIELD_NAMES.APPLICATION_SCHEME,
    Constants.FIELD_NAMES.APPLICATION_METHOD,
    Constants.FIELD_NAMES.MIN_BBCH,
    Constants.FIELD_NAMES.CROP_COVERAGE,
    Constants.FIELD_NAMES.CROP_INTERCEPTION,
    Constants.FIELD_NAMES.FIRST_APPLICATION_DATE,
    Constants.FIELD_NAMES.APPLICATION,
    Constants.FIELD_NAMES.DATA_SET,
    Constants.FIELD_NAMES.ACTIVE_INGREDIENT,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
    Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
    Constants.FIELD_NAMES.FRACTION_IN_SEDIMENT,
    Constants.FIELD_NAMES.APPLICATION_DATE
  ];
  public static readonly SWASH_MODEL_COLUMNS = [
    Constants.FIELD_NAMES.ID,
    Constants.FIELD_NAMES.MODEL,
    Constants.FIELD_NAMES.SCENARIOS,
    Constants.FIELD_NAMES.SEASON,
    Constants.FIELD_NAMES.MODEL_CROP,
    Constants.FIELD_NAMES.APPLICATION_SCHEME,
    Constants.FIELD_NAMES.APPLICATION_METHOD,
    Constants.FIELD_NAMES.CHEMICAL_APPLICATION_METHOD,
    Constants.FIELD_NAMES.SOILD_DEPTH_INCORPORATED,
    Constants.FIELD_NAMES.MIN_BBCH,
    Constants.FIELD_NAMES.BEGINNING_DATE,
    Constants.FIELD_NAMES.END_DATE,
    Constants.FIELD_NAMES.EMERGENCY_DAY,
    Constants.FIELD_NAMES.HARVEST_DAY,
    Constants.FIELD_NAMES.APPLICATION,
    Constants.FIELD_NAMES.MIN_APPLICATION_INTERVAL,
    Constants.FIELD_NAMES.RATE,
    Constants.FIELD_NAMES.DATA_SET,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.EXPONENT,
    Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
    Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL,
    Constants.FIELD_NAMES.FRACTION_IN_SOIL,
    Constants.FIELD_NAMES.FRACTION_IN_SEDIMENT,
    Constants.FIELD_NAMES.FRACTION_IN_SURFACE_WATER
  ];
  public static readonly SWAN_MODEL_COLUMNS = [
    Constants.FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_10M,
    Constants.FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_20M,
  ];
  public static readonly MACRO_GW_MODEL_COLUMNS = [
    Constants.FIELD_NAMES.ID,
    Constants.FIELD_NAMES.MODEL,
    Constants.FIELD_NAMES.REGION_SEASON,
    Constants.FIELD_NAMES.LOCATION,
    Constants.FIELD_NAMES.APPLICATION_SCHEME,
    Constants.FIELD_NAMES.MODEL_CROP,
    Constants.FIELD_NAMES.APPLICATION_METHOD,
    Constants.FIELD_NAMES.SOILD_DEPTH_INCORPORATED,
    Constants.FIELD_NAMES.ROTATION_PK,
    Constants.FIELD_NAMES.MODEL,
    Constants.FIELD_NAMES.CROP_EVENT,
    Constants.FIELD_NAMES.MODEL,
    Constants.FIELD_NAMES.MIN_BBCH,
    Constants.FIELD_NAMES.MAX_BBCH,
    Constants.FIELD_NAMES.CROP_COVERAGE,
    Constants.FIELD_NAMES.CROP_INTERCEPTION,
    Constants.FIELD_NAMES.FIRST_APPLICATION_DATE,
    Constants.FIELD_NAMES.BEGINNING_DATE,
    Constants.FIELD_NAMES.END_DATE,
    Constants.FIELD_NAMES.APPLICATION,
    Constants.FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_10M,
    Constants.FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_20M,
    Constants.FIELD_NAMES.DATA_SET,
    Constants.FIELD_NAMES.ACTIVE_INGREDIENT,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.EXPONENT,
    Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.FRACTION_IN_SOIL
  ];
  public static readonly PWC_MODEL_COLUMNS = [
    Constants.FIELD_NAMES.ID,
    Constants.FIELD_NAMES.MODEL,
    Constants.FIELD_NAMES.APPLICATION_SCHEME,
    Constants.FIELD_NAMES.FORMULATION,
    Constants.FIELD_NAMES.MODEL_CROP,
    Constants.FIELD_NAMES.SIMULATION_TYPE,
    Constants.FIELD_NAMES.DATE_TYPE,
    Constants.FIELD_NAMES.CROP_EVENT,
    Constants.FIELD_NAMES.SPECIFY_YEARS,
    Constants.FIELD_NAMES.APPLICATION,
    Constants.FIELD_NAMES.APPLICATION_OCCURANCE,
    Constants.FIELD_NAMES.APPLICATION_OCCURANCE_FROM_YEAR,
    Constants.FIELD_NAMES.APPLICATION_OCCURANCE_TO_YEAR,
    Constants.FIELD_NAMES.APPLY_PESTICIDE_WINDOWS,
    Constants.FIELD_NAMES.APPLY_PESTICIDE_STEPS,
    Constants.FIELD_NAMES.DATA_SET,
    Constants.FIELD_NAMES.ACTIVE_INGREDIENT,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SOIL,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
    Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SEDIMENT,
    Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
    Constants.FIELD_NAMES.MEASURED_HALF_LIFE_WATER,
    Constants.FIELD_NAMES.AQUEOUS_PHOTOLYSIS_HALFLIFE,
    Constants.FIELD_NAMES.PHOTOLYSIS_REFERENCE_LATITUDE,
    Constants.FIELD_NAMES.HYDROLYSIS_HALFLIFE,
    Constants.FIELD_NAMES.FOLIAR_HALFLIFE,
    Constants.FIELD_NAMES.HENRYS_CONSTANT,
    Constants.FIELD_NAMES.AIR_DIFFUSION_COEFFICIENT,
    Constants.FIELD_NAMES.HEAT_OF_HENRY,
  ];
  public static readonly PEARL_MODEL_COLUMNS = [
    Constants.FIELD_NAMES.ID,
    Constants.FIELD_NAMES.MODEL,
    Constants.FIELD_NAMES.LOCATION,
    Constants.FIELD_NAMES.MODEL_CROP,
    Constants.FIELD_NAMES.SOILD_DEPTH_INCORPORATED,
    Constants.FIELD_NAMES.APPLICATION,
    Constants.FIELD_NAMES.APPLICATION_SCHEME,
    Constants.FIELD_NAMES.APPLICATION_METHOD,
    Constants.FIELD_NAMES.SOILD_DEPTH_INCORPORATED,
    Constants.FIELD_NAMES.ROTATION_PK,
    Constants.FIELD_NAMES.DATE_TYPE,
    Constants.FIELD_NAMES.CROP_EVENT,
    Constants.FIELD_NAMES.DATA_SET,
    Constants.FIELD_NAMES.ACTIVE_INGREDIENT,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.EXPONENT,
    Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE,
    Constants.FIELD_NAMES.DESORPTION_RATE_KD,
    Constants.FIELD_NAMES.FRACTION_IN_SOIL,
  ];

  public static readonly PEARL_MODEL_APPLICATIONS_COLUMNS = [
    Constants.FIELD_NAMES.APPLICATION_NUMBER_PK,
    Constants.FIELD_NAMES.MIN_BBCH,
    Constants.FIELD_NAMES.CROP_INTERCEPTION,
    Constants.FIELD_NAMES.APPLICATION_DATE,
    Constants.FIELD_NAMES.ACTIVE_INGREDIENT,
    Constants.FIELD_NAMES.RATE
  ];
  public static readonly PELMO_MODEL_APPLICATIONS_COLUMNS = [
    Constants.FIELD_NAMES.APPLICATION_NUMBER,
    Constants.FIELD_NAMES.MIN_BBCH,
    Constants.FIELD_NAMES.CROP_INTERCEPTION,
    Constants.FIELD_NAMES.APPLICATION_DATE,
    Constants.FIELD_NAMES.ACTIVE_INGREDIENT,
    Constants.FIELD_NAMES.RATE
  ];
  public static readonly MACRO_GW_MODEL_APPLICATIONS_COLUMNS = [
    Constants.FIELD_NAMES.APPLICATION_NUMBER,
    Constants.FIELD_NAMES.MIN_BBCH,
    Constants.FIELD_NAMES.CROP_INTERCEPTION,
    Constants.FIELD_NAMES.APPLICATION_DATE,
    Constants.FIELD_NAMES.ACTIVE_INGREDIENT,
    Constants.FIELD_NAMES.RATE
  ];
  public static readonly DEFAULT_MODEL_APPLICATIONS_COLUMNS = [
    Constants.FIELD_NAMES.APPLICATION_NUMBER_PK,
    Constants.FIELD_NAMES.MIN_APPLICATION_INTERVAL_PK,
    Constants.FIELD_NAMES.ACTIVE_INGREDIENT,
    Constants.FIELD_NAMES.RATE
  ]
  public static readonly PWC_MODEL_APPLICATIONS_COLUMNS = [
    Constants.FIELD_NAMES.APPLICATION_NUMBER_PK,
    Constants.FIELD_NAMES.APPLICATION_DATE,
    Constants.FIELD_NAMES.DAYS_SINCE,
    Constants.FIELD_NAMES.RATE,
    Constants.FIELD_NAMES.APPLICATION_METHOD,
    Constants.FIELD_NAMES.DEPTH,
    Constants.FIELD_NAMES.T_BAND_SPLIT,
    Constants.FIELD_NAMES.EFFICIENCY,
    Constants.FIELD_NAMES.DRIFT
  ];
  public static SUBSTANCE_TYPES = {
    ACTIVE: 'Active',
    METABOLITE: 'Metabolite'
  }
  public static GAP_EDITABLE_COLUMNS = [
    Constants.FIELD_NAMES.APPLICATION_METHOD,
    Constants.FIELD_NAMES.CROP_EVENT,
    Constants.FIELD_NAMES.APPLICATION_METHOD_PK,
    Constants.FIELD_NAMES.CHEMICAL_APPLICATION_METHOD,
    Constants.FIELD_NAMES.SOILD_DEPTH_INCORPORATED,
    Constants.FIELD_NAMES.ROTATION_PK,
    Constants.FIELD_NAMES.DATE_TYPE,
    Constants.FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_10M,
    Constants.FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_20M
  ]
  public static ENDPOINT_EDITABLE_COLUMNS = [
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.EXPONENT,
    Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
    Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL,
    Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE,
    Constants.FIELD_NAMES.DESORPTION_RATE_KD,
  ]
  public static GAP_BBCH_EDITABLE_COLUMNS = [
    Constants.FIELD_NAMES.MIN_BBCH,
    Constants.FIELD_NAMES.MAX_BBCH,
    Constants.FIELD_NAMES.CROP_COVERAGE,
    Constants.FIELD_NAMES.BEGINNING_DATE,
    Constants.FIELD_NAMES.END_DATE
  ]
  public static readonly PELMO_MODEL_COLUMNS = [
    Constants.FIELD_NAMES.ID,
    Constants.FIELD_NAMES.MODEL,
    Constants.FIELD_NAMES.LOCATION,
    Constants.FIELD_NAMES.MODEL_CROP,
    Constants.FIELD_NAMES.SOILD_DEPTH_INCORPORATED,
    Constants.FIELD_NAMES.APPLICATION,
    Constants.FIELD_NAMES.APPLICATION_SCHEME,
    //Ref. I43
    Constants.FIELD_NAMES.ROTATION,
    Constants.FIELD_NAMES.DATE_TYPE,
    Constants.FIELD_NAMES.CROP_EVENT,
    Constants.FIELD_NAMES.DATA_SET,
    Constants.FIELD_NAMES.ACTIVE_INGREDIENT,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.EXPONENT,
    Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE,
    Constants.FIELD_NAMES.DESORPTION_RATE_KD,
    Constants.FIELD_NAMES.FRACTION_IN_SOIL
  ];
  public static SIMULATION_TYPES = [{typeName: 'USEPA Pond Watershed'}, {typeName: 'USEPA Reservoir Watershed'}, {typeName: 'USEPA TPEZ & WPEZ'}];
  public static CROP_EVENTS_PWC = ['Emergence', 'Maturity', 'Removal'];

  public static DEFAULT_APPLICATION_METHODS = [
    'Application, aerial',
    'No Drift (incorporation/seed treatment)',
    'Soil Surface'
  ];

  public static PWC_APPLICATION_METHODS = [
    'Above Crop',
    'Below Crop',
    'At Specific Depth',
    'T Band',
    'Uniform',
    '△',
    '▽'
  ];

  public static PWC_APPLICATION_DRIFT_TYPES = [
    'Aerial (VF-F)',
    'Aerial (F-M) EPA Default',
    'Aerial (M-C)',
    'Aerial (C-VC)',
    'Ground (High Boom,VF-F) EPA Default',
    'Groud (High Boom,F-MC)',
    'Ground (Low Boom,VF-F)',
    'Ground (Low Boom,F-MC)',
    'Airblast (normal)',
    'Airblast (dense)',
    'Airblast (sparse) EPA Default',
    'Airblast (vinyard)',
    'Airblast (orchard)',
    'Directly applied to waterbody',
    'None',
    'For Advanced User'
  ];

  public static PWC_DRIFT_TYPES_DICTIONARY: { [id: string]: string } = {
    '1': 'Aerial (VF-F)',
    '2': 'Aerial (F-M) EPA Default',
    '3': 'Aerial (M-C)',
    '4': 'Aerial (C-VC)',
    '5': 'Ground (High Boom,VF-F) EPA Default',
    '6': 'Groud (High Boom,F-MC)',
    '7': 'Ground (Low Boom,VF-F)',
    '8': 'Ground (Low Boom,F-MC)',
    '9': 'Airblast (normal)',
    '10': 'Airblast (dense)',
    '11': 'Airblast (sparse) EPA Default',
    '12': 'Airblast (vinyard)',
    '13': 'Airblast (orchard)',
    '14': 'Directly applied to waterbody',
    '15': 'None',
    '16': 'For Advanced User'
  };

  public static DATE_TYPE_VALUES = {
    ABSOLUTE: 'Absolute',
    RELATIVE: 'Relative'
  }

  public static SIMULATION_TYPE_VALUES = [
    'EPA Reservoir',
    'EPA Pond',
    'User Defined'
  ]

  public static FRACTION_EDITABLE_COLUMNS = [
    Constants.FIELD_NAMES.FORMATION_FRACTION,
    Constants.FIELD_NAMES.METABOLITE_PK,
    Constants.FIELD_NAMES.MOLECULE_PK
  ]

  public static APPLICATION_EDITABLE_COLUMNS = [
    Constants.FIELD_NAMES.APPLICATION_NUMBER_PK,
    Constants.FIELD_NAMES.MIN_APPLICATION_INTERVAL_PK,
    Constants.FIELD_NAMES.RATE,
    Constants.FIELD_NAMES.MIN_BBCH,
    Constants.FIELD_NAMES.DAYS_SINCE,
    Constants.FIELD_NAMES.CROP_INTERCEPTION,
    Constants.FIELD_NAMES.APPLICATION_METHOD,
    Constants.FIELD_NAMES.DEPTH,
    Constants.FIELD_NAMES.T_BAND_SPLIT,
    Constants.FIELD_NAMES.EFFICIENCY,
    Constants.FIELD_NAMES.DRIFT
  ]

  public static MODELS_TO_EXCLUDE = [
    Constants.MODELS.SWAN.toLowerCase()
  ]

  public static SHARED_COLUMNS_BY_MODELS = [
    Constants.FIELD_NAMES.SUBSTANCE_TYPE,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.DATA_SOURCE,
  ]

  public static COLUMS_BY_STEP_1ND2 = [
    Constants.FIELD_NAMES.SUBSTANCE_TYPE,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
    Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL,
    Constants.FIELD_NAMES.DATA_SOURCE,
  ]

  public static COLUMS_BY_STEP_3ND4 = [
    Constants.FIELD_NAMES.SUBSTANCE_TYPE,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
    Constants.FIELD_NAMES.EXPONENT,
    Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
    Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM,
    Constants.FIELD_NAMES.FRACTION_IN_SOIL,
    Constants.FIELD_NAMES.FRACTION_IN_SURFACE_WATER,
    Constants.FIELD_NAMES.FRACTION_IN_SEDIMENT,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL,
    Constants.FIELD_NAMES.DATA_SOURCE,
  ]

  public static COLUMS_BY_TIER_1_UK = [
    Constants.FIELD_NAMES.SUBSTANCE_TYPE,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
    Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
    Constants.FIELD_NAMES.FRACTION_IN_SEDIMENT,
    Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT,
    Constants.FIELD_NAMES.DATA_SOURCE,
  ]

  public static COLUMS_BY_PWC = [
    Constants.FIELD_NAMES.SUBSTANCE_TYPE,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
    Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SOIL,
    Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT,
    Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SEDIMENT,
    Constants.FIELD_NAMES.HALFLIFE_IN_WATER,
    Constants.FIELD_NAMES.MEASURED_HALF_LIFE_WATER,
    Constants.FIELD_NAMES.AQUEOUS_PHOTOLYSIS_HALFLIFE,
    Constants.FIELD_NAMES.PHOTOLYSIS_REFERENCE_LATITUDE,
    Constants.FIELD_NAMES.HYDROLYSIS_HALFLIFE,
    Constants.FIELD_NAMES.FOLIAR_HALFLIFE,
    Constants.FIELD_NAMES.HENRYS_CONSTANT,
    Constants.FIELD_NAMES.AIR_DIFFUSION_COEFFICIENT,
    Constants.FIELD_NAMES.HEAT_OF_HENRY,
    Constants.FIELD_NAMES.DATA_SOURCE,
  ]

  public static COLUMS_BY_MACRO = [
    Constants.FIELD_NAMES.SUBSTANCE_TYPE,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.EXPONENT,
    Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT,
    Constants.FIELD_NAMES.FRACTION_IN_SOIL,
    Constants.FIELD_NAMES.DATA_SOURCE,
  ]

  public static COLUMS_BY_PELMO = [
    Constants.FIELD_NAMES.SUBSTANCE_TYPE,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE,
    Constants.FIELD_NAMES.DESORPTION_RATE_KD,
    Constants.FIELD_NAMES.DATA_SOURCE,
  ]

  public static COLUMS_BY_PEARL = [
    Constants.FIELD_NAMES.SUBSTANCE_TYPE,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE,
    Constants.FIELD_NAMES.DESORPTION_RATE_KD,
    Constants.FIELD_NAMES.DATA_SOURCE,
  ]

  public static COLUMS_BY_SOIL = [
    Constants.FIELD_NAMES.SUBSTANCE_TYPE,
    Constants.FIELD_NAMES.SUBSTANCE_NAME,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.KOM,
    Constants.FIELD_NAMES.K1,
    Constants.FIELD_NAMES.K2,
    Constants.FIELD_NAMES.TB,
    Constants.FIELD_NAMES.G,
    Constants.FIELD_NAMES.ALPHA,
    Constants.FIELD_NAMES.BETA,
    Constants.FIELD_NAMES.DATA_SOURCE,
    Constants.FIELD_NAMES.KINETIC_MODEL
  ]

  public static DELETE_COLUMN_NAME = "deleteColumn";

  public static readonly MODELS_BY_COMPARTMENT = [
    {
      compartment: Constants.COMPARTMENTS.SURFACE_WATER,
      models: [{ name: 'Step 1nd2 EU', columns: Constants.COLUMS_BY_STEP_1ND2 },
      { name: 'Step 3nd4 EU', columns: Constants.COLUMS_BY_STEP_3ND4 },
      { name: 'Tier 1 UK', columns: Constants.COLUMS_BY_TIER_1_UK },
      { name: 'PWC US', columns: Constants.COLUMS_BY_PWC },
      ]
    },
    {
      compartment: Constants.COMPARTMENTS.GROUND_WATER,
      models: [{ name: 'MACRO', columns: Constants.COLUMS_BY_MACRO },
      { name: 'PELMO', columns: Constants.COLUMS_BY_PELMO },
      { name: 'PEARL', columns: Constants.COLUMS_BY_PEARL },
      ]
    },
    {
      compartment: Constants.COMPARTMENTS.SOIL,
      models: [{ name: 'Step 1nd2 EU.', columns: Constants.COLUMS_BY_SOIL },
      { name: 'Northern Zone tool', columns: Constants.COLUMS_BY_SOIL },
      { name: 'German tool', columns: Constants.COLUMS_BY_SOIL },
      ]
    },
  ]

  public static readonly PROJECT_STATUS_CONVERSION = {
    "0": Constants.PROJECT_STATUS.NOT_STARTED,
    "1": Constants.PROJECT_STATUS.IN_PROGRESS,
    "2": Constants.PROJECT_STATUS.COMPLETED,
    "3": Constants.PROJECT_STATUS.FAILED,
    "5": Constants.PROJECT_STATUS.STOPPED,
    "6": Constants.PROJECT_STATUS.IN_QUEUE,
  }

  public static ENDPOINT_X_RUN_PROPERTY = "endpointXRunPk";

  public static CROP_EVENT_EXCEPTION_MODELS = [
    Constants.MODELS.PEARL,
    Constants.MODELS.PELMO,
  ]

  public static DATE_TYPE_EXCEPTION_MODELS = [
    Constants.MODELS.PEARL,
    Constants.MODELS.PELMO,
    Constants.MODELS.MACRO_GW,
  ]
  public static MODELS_FOR_VARIABLE_RATES = [
    Constants.MODELS.PEARL,
    Constants.MODELS.PELMO,
    Constants.MODELS.MACRO_GW,
    Constants.MODELS.SWASH,
  ]

  public static readonly MENU_PARENT = {
    HOME: 'Home',
    ADMINISTRATOR: 'Administrator',
    ENVIRONMENTAL_EXPOSURE_ASSESSMENT: 'Environmental Exposure Assessment',
    ENVIRONMENTAL_RISK_ASSESSMENT: 'Environmental Risk Assessment',
  }

  public static readonly ADVANCE_FILTER_COLUMNS = {
    MAIN_ACTIVE_INGREDIENT: 'activeIngredientPk',
    FORMULATION: 'formulationPK',
    PROJECT: 'projectPk',
    CROP_OR_COMPARTMENT: 'datafieldValuePk',
    APPLICATION_SCHEME: 'applicationSchemePk',
    ACTIVE_INGREDIENT: 'key',
    DATASET: 'dataSetPk',
    MODEL: 'modelPk',
  }

  public static readonly locationCodes: { [location: string]: string } = {
    "Porto": "POR",
    "Sevilla": "SEV",
    "Okehampton": "OKE",
    "Piacenza": "PIA",
    "Kremsmunster": "KRE",
    "Hamburg": "HAM",
    "Chateaudun": "CHA",
    "Jokioinen": "JOK",
    "Thiva": "THI"
  };

  public static GEOGRAPHYS = {
    USA: 'USA',
    EUROPE_UNION: 'Europe Union',
    UK: 'UK',

  }

  public static readonly SPECIES_TYPES = {
    STANDARD_TEST_SPECIES: 1
  }

  public static readonly CORE_ENDPOINTS_TABLE_LABELS = [
    'Molecular Mass (g/mol)',
    'Solubility In Water (mg/L)',
    'Saturated Vapour Pressure (Pa)',
    'Kfoc',
    'Kom (L/kg)',
    'Freundlich Sorption Exponent In Soil (-)',
    'Half-life In Soil (d)',
    'Measured At (Half-life In Soil) (ºC)'
  ];

  public static readonly CORE_ENDPOINTS_TABLE_FIELDS = [
    Constants.FIELD_NAMES.MOLECULAR_WEIGHT,
    Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE,
    Constants.FIELD_NAMES.SOLUBILITY_IN_WATER,
    Constants.FIELD_NAMES.EXPONENT,
    Constants.FIELD_NAMES.K_FOC,
    Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SOIL,
    Constants.FIELD_NAMES.HALFLIFE_IN_SOIL,
    Constants.FIELD_NAMES.KOM
  ];

  public static readonly EXPOSURE_ASSESSMENT_ENDPOINTS: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Core Endpoints',
      model: 'core'
    },
    {
      index: 1,
      label: 'Surface Water',
      model: 'surfacewater'
    },
    {
      index: 2,
      label: 'Groundwater',
      model: 'groundwater'
    },
    {
      index: 3,
      label: 'Soil',
      model: 'soil'
    }
  ]

  public static readonly EXPOSURE_ASSESSMENT_ENDPOINTS_PWC: CustomTabMenuItem[] = [
    {
      index: 1,
      label: 'Surface Water',
      model: 'surfacewater'
    },
  ]

  public static readonly EXPOSURE_ASSESSMENT_STEPS: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Setup',
      useNumberIcon: true,
      visible: true,
      disabled: true
    },
    {
      index: 1,
      label: 'Endpoints',
      useNumberIcon: true,
      visible: true,
      disabled: true
    },
    {
      index: 2,
      label: 'GAP',
      useNumberIcon: true,
      visible: true,
      disabled: true
    },
    {
      index: 3,
      label: 'Run Project',
      useNumberIcon: true,
      visible: true,
      disabled: true
    },
    {
      index: 4,
      label: 'Output',
      useNumberIcon: true,
      visible: true,
      disabled: true
    }
  ]

  public static readonly INVERSE_MODELING_EXPOSURE_ASSESSMENT_STEPS: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Setup',
      useNumberIcon: true,
      visible: true
    },
    {
      index: 1,
      label: 'Environmental Endpoints',
      useNumberIcon: true,
      visible: true
    },
    {
      index: 2,
      label: 'GAP',
      useNumberIcon: true,
      visible: true
    },
    {
      index: 3,
      label: 'Ecotox Endpoints',
      useNumberIcon: true,
      visible: true
    },
    {
      index: 4,
      label: 'Run EEA',
      useNumberIcon: true,
      visible: true
    },
    {
      index: 5,
      label: 'Run ERA',
      useNumberIcon: true,
      visible: true
    }
  ]

  public static readonly EXPOSURE_ASSESSMENT_GAPS: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Core GAP',
      model: 'core'
    },
    {
      index: 1,
      label: 'Surface Water',
      model: 'surfacewater'
    },
    {
      index: 1,
      label: 'Aquatic',
      model: 'surfacewater'
    },
    {
      index: 2,
      label: 'Groundwater',
      model: 'groundwater'
    },
    {
      index: 3,
      label: 'Soil',
      model: 'soil'
    },
    {
      index: 4,
      label: 'Terrestrial Vertebrate',
      model: 'Terrestrial'
    }
  ]

  public static readonly EXPOSURE_ASSESSMENT_GAPS_PWC: CustomTabMenuItem[] = [
    {
      index: 1,
      label: 'Surface Water',
      model: 'surfacewater'
    },
  ]

  public static readonly HUMAN_HEALTH_RISK_ASSESSMENT_STEPS: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Setup',
      useNumberIcon: true,
      visible: true,
      disabled: true
    },
    {
      index: 1,
      label: 'Substance Properties',
      useNumberIcon: true,
      visible: false,
      disabled: true
    },
    {
      index: 2,
      label: 'GAP',
      useNumberIcon: true,
      visible: false,
      disabled: true
    },
    {
      index: 3,
      label: 'PPE',
      useNumberIcon: true,
      visible: false,
      disabled: true
    },
    {
      index: 4,
      label: 'QC',
      useNumberIcon: true,
      visible: false,
      disabled: true
    },
    {
      index: 5,
      label: 'Run Risk Assessment',
      useNumberIcon: true,
      visible: false,
      disabled: true
    }
  ]

  public static readonly HUMAN_HEALTH_RISK_ASSESSMENT_ENDPOINTS: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Endpoints'
    },
  ]

  public static readonly ESA_ASSESSMENT_STEPS: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Setup',
      useNumberIcon: true,
      disabled: true,
      visible: true
    },
    {
      index: 1,
      label: 'Run Project',
      useNumberIcon: true,
      disabled: true,
      visible: true
    },
    {
      index: 2,
      label: 'Output',
      useNumberIcon: true,
      disabled: true,
      visible: true
    }
  ]

  public static readonly CROP_LIST_FILTER = {
    GLOBAL: 'Global',
    EUROPE_UNION: 'Europe Union',
    UK: 'UK',
    USA: 'USA',
    SURFACE_WATER: 'Surface Water',
    SPRAY: 'Spray',
    GROUND_WATER: 'Groundwater',
    INTERCEPTION: 'Interception',
    DRIFT_INTERCEPTION: 'Drift-Interception',
    GAP_CROP: 'GAP Crop'
  };

  public static readonly CROP_LIST_FILTER_GEOGRAPHY = ['Global', 'Europe Union', 'UK', 'USA'];

  public static readonly CROP_LIST_FILTER_COMPARTMENT = ['Surface Water', 'Groundwater', 'Soil'];

  public static readonly CROP_LIST_FILTER_PURPOSE_GLOBAL = ['GAP Crop'];

  public static readonly CROP_LIST_FILTER_PURPOSE_UK = ['Spray', 'Interception'];

  public static readonly CROP_LIST_FILTER_PURPOSE_EU = ['Interception', 'AppDate'];
  public static readonly CROP_LIST_FILTER_PURPOSE_EU_SURFACE_WATER = ['Drift-Interception', 'AppDate'];


  public static readonly SPECIFIC_MODELS_BY_COMPARTMENT = [
    {
      compartment: Constants.COMPARTMENTS.SURFACE_WATER,
      models: [Constants.MODELS.STEP_1_2, Constants.MODELS.SWASH, Constants.MODELS.UK, Constants.MODELS.PWC, Constants.MODELS.SWAN]
    },
    {
      compartment: Constants.COMPARTMENTS.GROUND_WATER,
      models: [Constants.MODELS.MACRO_GW, Constants.MODELS.PELMO, Constants.MODELS.PEARL]
    },
    {
      compartment: Constants.COMPARTMENTS.SOIL,
      models: [Constants.MODELS.EU_SOIL, Constants.MODELS.GERMAN_SOIL]
    },
    {
      compartment: Constants.COMPARTMENTS.SUBSTANCE_PROPERTIES,
      models: [Constants.MODELS.EU_OPEX, Constants.MODELS.US_OHE, Constants.MODELS.US_OREX, Constants.MODELS.US_RE]
    },
    {
      compartment: Constants.COMPARTMENTS.AQUATIC,
      models: [Constants.MODELS.AGD_AQUAMIX, Constants.MODELS.AQUATICS_US]
    },
    {
      compartment: Constants.COMPARTMENTS.TERRESTRIAL_VERTEBRATE,
      models: [Constants.MODELS.T_REX]
    },
    {
      compartment: Constants.COMPARTMENTS.BEES,
      models: [Constants.MODELS.BEE_REX]
    },
    {
      compartment: Constants.COMPARTMENTS.ARTHROPODS,
      models: []
    },
  ]
  public static readonly CANOPY_PROCESS_OPTIONS = [
    { key: '1', value: 'Lumped' },
    { key: '2', value: 'Specified' },
    { key: '3', value: 'Calculated' }
  ]

  public static readonly PELMO_LETTERS: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2', 'D1', 'D2'];

  public static readonly CONTROL_TYPES = {
    EXPONENTIAL_NUMBER: 'input-number-exponential',
    RADIO_BUTTON: 'radio-button',
    NUMBER: 'input-number',
    TEXT: 'input',
    BOOLEAN: 'boolean',
    AUTOCOMPLETE: 'autocomplete',
    DROPDOWN: 'dropdown',
    EXPONENTIAL_NUMBER_INSERT: 'input-number-exponential-insert',
  }

  public static readonly BEES_TYPES = {
    BumblebeeAdultAcuteContactLD50: "Bumblebee- Adult Acute Contact",
    BumblebeeAdultAcuteOralLD50: "Bumblebee- Adult Acute Oral",
    HoneyBeeAdultContactLD50: "Honey Bee- Adult Acute Contact",
    HoneyBeeAdultAcuteOral: "Honey Bee- Adult Acute Oral",
    HoneyBeeLarvalAcuteLD50: "Honey Bee- Larval Acute",
    HoneyBeeAdultChronicOralNOAEL: "Honey Bee- Adult Chronic Oral",
    HoneyBeeLarvalRepeatDoseNOAEL: "Honey Bee- Larval Repeat Dose",
  }

  public static readonly TAXON_GROUPS =
    {
      AQUATIC: "Aquatic",
      TERRESTRIAL_VERTEBRATE: "Terrestrial Vertebrate",
      BEES: "Bees",
      ARTHROPODS: "Arthropods",
    }

  public static readonly GAP_FIELD_NAMES = {
    APPLICATION_SCHEME: 'name',
    CROP: 'cropPk',
    REGION: 'countryPk',
    APPLICATION_NUMBER: 'numberOfApplications',
    APPLICATION_INTERVAL: 'applicationInterval',
    BBCH: 'bbchEarliest',
    CROP_INTERCEPTION: 'cropInterception',
    APPLICATION_METHOD_PK: 'applicationMethodPk',
    CHEMICAL_APPLICATION_METHOD_PK: 'chemicalApplicationMethodPk',
    SOIL_DEPTH: 'soilDepth',
    GEOGRAPHIES: 'geographies',
    UK_APPLICATION_DATE: 'ukApplicationDate',
    CROP_APP_DATE_PK: 'cropAppDatePk',
    DRIFT_INTERCEPTION_PK: 'cropDriftInterceptionPk',
    APPLICATION_DATE: 'applicationDate',
    DATE_TYPE: 'dateType',
    CROP_EVENT: 'cropEvent',
    RATE: 'rate',
    REPEAT_INTERVAL: 'repeatInterval',
    DAYS_SINCE: 'daysSince',
    INTERCEPTION_PK: 'cropInterceptionPk',
    CROP_SPRAY_PK: 'cropSprayPk',
    TILLAGE: 'tillage',
    PLATEAU_SOIL_DEPTH: 'plateauSoilDepth',
    SIMULATION_YEAR: 'simulationYear',
    APPLICATION_METHOD: 'applicationMethod',
    CHEMICAL_APPLICATION_METHOD: 'chemicalApplicationMethod',
    NOZZLE_DRIFT_REDUCTION_10M: 'nozzleDriftReduction10m',
    NOZZLE_DRIFT_REDUCTION_20M: 'nozzleDriftReduction20m',
    CROP_COVERAGE: 'cropCoverage',
    HAS_VARIABLE_RATES: 'hasVariableRates',
    EARLIEST_BBCH: 'earliest_bbch',
    CROP_PK: 'cropPk',
  }

  public static readonly GAP_INVERSE_MODELING_FIELD_NAMES = {
    MIN_APPLICATION_NUMBER: 'minNumberOfApplications',
    MAX_APPLICATION_NUMBER: 'maxNumberOfApplications',
    INCREMENT_APPLICATION_NUMBER: 'incrementNumberOfApplications',
    MIN_APPLICATION_INTERVAL: 'minApplicationInterval',
    MAX_APPLICATION_INTERVAL: 'maxApplicationInterval',
    INCREMENT_APPLICATION_INTERVAL: 'incrementApplicationInterval',
    MIN_BBCH: 'minBbchEarliest',
    MAX_BBCH: 'maxBbchEarliest',
    INCREMENT_BBCH: 'incrementBbchEarliest',
    MIN_RATE: 'minRate',
    MAX_RATE: 'maxRate',
    INCREMENT_RATE: 'incrementRate',
  }

  public static readonly CROP_GEOGRAPHIES = {
    GLOBAL: 'Global',
    EUROPE_UNION: 'Europe Union',
    UK: 'UK',
    USA: 'USA',
  };

  public static readonly GAP_CORE_FIELDS = [
    Constants.GAP_FIELD_NAMES.APPLICATION_SCHEME,
    Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL,
    Constants.GAP_FIELD_NAMES.REGION,
    Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER,
    Constants.GAP_FIELD_NAMES.BBCH,
    //Constants.GAP_FIELD_NAMES.GEOGRAPHIES, //TODO: Geremy Rivera - Uncomment this line when the geographies new component is ready
    Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES,
  ]

  public static readonly GAP_CORE_INVERSE_MODELING_FIELDS = [
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MIN_APPLICATION_NUMBER,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MAX_APPLICATION_NUMBER,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.INCREMENT_APPLICATION_NUMBER,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MIN_APPLICATION_INTERVAL,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MAX_APPLICATION_INTERVAL,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.INCREMENT_APPLICATION_INTERVAL,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MIN_BBCH,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MAX_BBCH,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.INCREMENT_BBCH,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MIN_RATE,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MAX_RATE,
    Constants.GAP_INVERSE_MODELING_FIELD_NAMES.INCREMENT_RATE
  ]

  public static readonly GAP_FIELDS_BY_MODEL = [
    {
      model: Constants.MODELS.STEP_1_2,
      columns: [
        Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION,
        Constants.GAP_FIELD_NAMES.CROP_COVERAGE
      ],
      geographies: [{
        name: Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
        columns: [
          Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK]
      },
      {
        name: Constants.CROP_GEOGRAPHIES.USA,
        columns: [Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK]
      }, {
        name: Constants.CROP_GEOGRAPHIES.UK,
        columns: [Constants.GAP_FIELD_NAMES.INTERCEPTION_PK]
      },
      ],
    },
    {
      model: Constants.MODELS.SWASH,
      columns: [
        Constants.GAP_FIELD_NAMES.APPLICATION_METHOD,
        Constants.GAP_FIELD_NAMES.CHEMICAL_APPLICATION_METHOD,
        Constants.GAP_FIELD_NAMES.SOIL_DEPTH],
      geographies: [{
        name: Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
        columns: [Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK,
        Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK]
      },
      {
        name: Constants.CROP_GEOGRAPHIES.USA,
        columns: [Constants.GAP_FIELD_NAMES.CROP]
      }, {
        name: Constants.CROP_GEOGRAPHIES.UK,
        columns: [Constants.GAP_FIELD_NAMES.INTERCEPTION_PK
        ]
      }]
    },
    {
      model: Constants.MODELS.SWAN,
      columns: [
        Constants.GAP_FIELD_NAMES.APPLICATION_METHOD,
        Constants.GAP_FIELD_NAMES.CHEMICAL_APPLICATION_METHOD,
        Constants.GAP_FIELD_NAMES.SOIL_DEPTH,
        Constants.GAP_FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_10M,
        Constants.GAP_FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_20M,
      ],
      geographies: [{
        name: Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
        columns: [Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK,
        Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK]
      },
      {
        name: Constants.CROP_GEOGRAPHIES.USA,
        columns: [Constants.GAP_FIELD_NAMES.CROP]
      }, {
        name: Constants.CROP_GEOGRAPHIES.UK,
        columns: [Constants.GAP_FIELD_NAMES.INTERCEPTION_PK
        ]
      }]
    },
    {
      model: Constants.MODELS.PWC,
      columns: [
      ],
      geographies: [{
        name: Constants.CROP_GEOGRAPHIES.EUROPE_UNION,
        columns: []
      },
      {
        name: Constants.CROP_GEOGRAPHIES.USA,
        columns: []
      }, {
        name: Constants.CROP_GEOGRAPHIES.UK,
        columns: []
      },
      ],
    },
    {
      model: Constants.MODELS.UK,
      columns: [
        Constants.GAP_FIELD_NAMES.INTERCEPTION_PK,
        Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION,
        Constants.GAP_FIELD_NAMES.CROP_SPRAY_PK,
        Constants.GAP_FIELD_NAMES.UK_APPLICATION_DATE,
        Constants.GAP_FIELD_NAMES.CROP_COVERAGE,
      ],
      geographies: [],
    },
    {
      model: Constants.MODELS.PELMO,
      columns: [
        Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK,
        Constants.GAP_FIELD_NAMES.INTERCEPTION_PK,
        Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION,
        Constants.GAP_FIELD_NAMES.DATE_TYPE,
        Constants.GAP_FIELD_NAMES.CROP_EVENT,
        Constants.GAP_FIELD_NAMES.APPLICATION_DATE,
        Constants.GAP_FIELD_NAMES.APPLICATION_METHOD,
        Constants.GAP_FIELD_NAMES.SOIL_DEPTH,
        Constants.GAP_FIELD_NAMES.REPEAT_INTERVAL,
      ],
      geographies: [],
    },
    {
      model: Constants.MODELS.PEARL,
      columns: [
        Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK,
        Constants.GAP_FIELD_NAMES.INTERCEPTION_PK,
        Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION,
        Constants.GAP_FIELD_NAMES.DATE_TYPE,
        Constants.GAP_FIELD_NAMES.CROP_EVENT,
        Constants.GAP_FIELD_NAMES.APPLICATION_DATE,
        Constants.GAP_FIELD_NAMES.APPLICATION_METHOD,
        Constants.GAP_FIELD_NAMES.SOIL_DEPTH,
        Constants.GAP_FIELD_NAMES.REPEAT_INTERVAL,
      ],
      geographies: [],
    },
    {
      model: Constants.MODELS.MACRO_GW,
      columns: [
        Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK,
        Constants.GAP_FIELD_NAMES.INTERCEPTION_PK,
        Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION,
        Constants.GAP_FIELD_NAMES.DATE_TYPE,
        Constants.GAP_FIELD_NAMES.CROP_EVENT,
        Constants.GAP_FIELD_NAMES.APPLICATION_DATE,
        Constants.GAP_FIELD_NAMES.APPLICATION_METHOD,
        Constants.GAP_FIELD_NAMES.SOIL_DEPTH,
        Constants.GAP_FIELD_NAMES.REPEAT_INTERVAL,
      ],
      geographies: [],
    },
    {
      model: Constants.MODELS.EU_SOIL,
      columns: [
        Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION,
        Constants.GAP_FIELD_NAMES.INTERCEPTION_PK,
        Constants.GAP_FIELD_NAMES.SOIL_DEPTH,
        Constants.GAP_FIELD_NAMES.TILLAGE,
        Constants.GAP_FIELD_NAMES.PLATEAU_SOIL_DEPTH,
        Constants.GAP_FIELD_NAMES.SIMULATION_YEAR,
      ],
      geographies: [],
    },
    {
      model: Constants.MODELS.GERMAN_SOIL,
      columns: [
        Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION,
        Constants.GAP_FIELD_NAMES.INTERCEPTION_PK,
        Constants.GAP_FIELD_NAMES.SOIL_DEPTH,
        Constants.GAP_FIELD_NAMES.TILLAGE,
        Constants.GAP_FIELD_NAMES.PLATEAU_SOIL_DEPTH,
        Constants.GAP_FIELD_NAMES.SIMULATION_YEAR,
      ],
      geographies: [],
    },
  ]

  public static readonly CROP_PURPOSES = {
    GAP_CROP: 'GAP Crop',
    SPRAY: 'Spray',
    INTERCEPTION: 'Interception',
    APP_DATE: 'AppDate',
    DRIFT_INTERCEPTION: 'Drift-Interception'
  };

  public static readonly GAP_OUTPUTS = [
    { pk: 1, label: 'Rate (g/ha)' },
    { pk: 2, label: 'Rate (lb/acre)' },
    { pk: 3, label: 'BBCH' },
    { pk: 4, label: 'Application window' },
    { pk: 5, label: 'Number of applications' },
    { pk: 6, label: 'Interval between applications' },
  ];

  public static readonly GAP_OUTPUTS_LABELS = {
    RateGHA: 'Rate (g/ha)',
    RateLBAcre: 'Rate (lb/acre)',
    BBCH: 'BBCH',
    ApplicationWindow: 'Application window',
    NumberOfApplications: 'Number of applications',
    IntervalBetweenApplications: 'Interval between applications'
  };

  public static readonly GAP_CROPS_FOR_APP_DATES = {
    CITRUS: 'Citrus',
    GRASS_ALFALFA: 'Grass-alfalfa',
    OLIVES: 'Olives',
    GRASS: 'Grass',
  }

  public static readonly GAP_CROPS_WITHOUT_APP_DATES = [
    Constants.GAP_CROPS_FOR_APP_DATES.CITRUS,
    Constants.GAP_CROPS_FOR_APP_DATES.GRASS_ALFALFA,
    Constants.GAP_CROPS_FOR_APP_DATES.OLIVES,
    Constants.GAP_CROPS_FOR_APP_DATES.GRASS,
  ];

  public static readonly GAP_CROP_SCENARIOS_WITHOUT_APP_DATES = {
    [Constants.GAP_CROPS_FOR_APP_DATES.CITRUS]: ['D6', 'R4'],
    [Constants.GAP_CROPS_FOR_APP_DATES.GRASS_ALFALFA]: ['D1', 'D2', 'D3', 'D5', 'R2', 'R3', 'D4'],
    [Constants.GAP_CROPS_FOR_APP_DATES.OLIVES]: ['D6', 'R4']
  }

  public static readonly GAP_CROPS_WITHOUT_APP_DATES_GROUNDWATER = [
    Constants.GAP_CROPS_FOR_APP_DATES.CITRUS,
    Constants.GAP_CROPS_FOR_APP_DATES.GRASS
  ];

  public static readonly GAP_CROP_SCENARIOS_WITHOUT_APP_DATES_GROUNDWATER: any = {
    [Constants.GAP_CROPS_FOR_APP_DATES.CITRUS]: ['Piacenza', 'Porto', 'Sevilla', 'Thiva'],
    [Constants.GAP_CROPS_FOR_APP_DATES.GRASS]: ['Chateaudun', 'Hamburg', 'Jokioinen', 'Kremsmunster', 'Okehampton', 'Piacenza', 'Porto', 'Sevilla', 'Thiva'],
  }

  public static readonly GW_LOCATIONS = {
    CHATEAUDUN: 'Chateaudun'
  }

  public static readonly STEP1N2_INTERCEPTION_RANGES: any = {
    "0-9": { rangeDescription: "No interception", rangeColumnName: "noInterception" },
    "10-19": { rangeDescription: "Minimal crop cover", rangeColumnName: "minimalCropCover" },
    "20-39": { rangeDescription: "Average crop cover", rangeColumnName: "averageCropCover" },
    "40-89": { rangeDescription: "Full canopy", rangeColumnName: "fullCanopy" }
  };

  public static readonly NO_INTERCEPTION_RANGE = "00-09";
  public static readonly NO_INTERCEPTION_RANGE_SIMPLE = "0-9";

  public static readonly INVERSE_MODELING_ENDPOINTS = [
    {
      index: 0,
      label: 'Aquatic'
    },
    {
      index: 1,
      label: 'Terrestrial'
    }
  ]

  public static readonly RISK_ASSESSMENT_STEPS: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Setup',
      useNumberIcon: true,
      disabled: true,
      visible: true
    },
    {
      index: 1,
      label: 'Ecotox Endpoints',
      useNumberIcon: true,
      disabled: true,
      visible: false
    },
    {
      index: 2,
      label: 'GAP',
      useNumberIcon: true,
      disabled: true,
      visible: false
    },
    {
      index: 3,
      label: 'PEC/EEC',
      useNumberIcon: true,
      disabled: true,
      visible: false
    },
    {
      index: 4,
      label: 'Run Risk Assessment',
      useNumberIcon: true,
      disabled: true,
      visible: false
    }
  ];
  public static readonly MODELS_WITH_INTERCEPTIONS = [
    Constants.MODELS.STEP_1_2,
    Constants.MODELS.UK,
    Constants.MODELS.MACRO_GW
  ]

  public static readonly WIZARD_MENU_REF = {
    Master: 'Master',
    Detail: 'Detail'
  }

  public static readonly EEA_MENU_LABELS = {
    Setup: 'Setup',
    Endpoints: 'Endpoints',
    GAP: 'GAP',
    Run_Project: 'Run Project',
    Output: 'Output'
  }

  public static readonly PRECURSOR_TYPES = {
    SOIL: 'fractionInSoil',
    SEDIMENT: 'fractionInSediment',
    SURFACE_WATER: 'fractionInSurfaceWater'
  }

  public static readonly RUN_RISK_ASSESSMENT_RESULT_VALUE = {
    Passed: 'Passed',
    Failed: 'Failed'
  }

  public static readonly CHEMICAL_APPLICATION_METHOD_VALUES = [
    '1 - appln soil linear',
    '4 - incorp soil uniform',
    '5 - incorp soil lin.increase',
    '6 - incorp soil lin.decrease',
    '8 - incorp soil at one depth'
  ]

  public static readonly APPLICATION_METHOD_VALUES_SURFACEWATER: any = {
    'aerial application': ['1 - appln soil linear', '2 - appln foliar linear'],
    'air blast': ['1 - appln soil linear', '2 - appln foliar linear'],
    'granular application': Constants.CHEMICAL_APPLICATION_METHOD_VALUES,
    'ground spray': ['1 - appln soil linear', '2 - appln foliar linear'],
    'soil incorporation': Constants.CHEMICAL_APPLICATION_METHOD_VALUES,
  }
  public static readonly APP_METHOD_VALUES_GROUNDWATER = {
    SOIL_SURFACE: 'Soil surface',
    INCORPORATION: 'Incorporation',
    INJECTION: 'Injection',
    CROP_CANOPY: 'Crop canopy',
  };

  public static readonly APPLICATION_METHOD_VALUES_GROUNDWATER = [
    Constants.APP_METHOD_VALUES_GROUNDWATER.SOIL_SURFACE,
    Constants.APP_METHOD_VALUES_GROUNDWATER.INCORPORATION,
    Constants.APP_METHOD_VALUES_GROUNDWATER.INJECTION,
    Constants.APP_METHOD_VALUES_GROUNDWATER.CROP_CANOPY,
  ]

  public static readonly FEATURE_ACRONYM = {
    ENVIRONMENTAL_EXPOSURE_ASSESSMENT: 'EEA',
    ENVIRONMENTAL_RISK_ASSESSMENT: 'ERA',
    HUMAN_HEALTH_RISK_ASSESSMENT: 'HHRA',
    INVERSE_MODELING_ENVIRONMENTAL_EXPOSURE_ASSESSMENT: 'IM_EEA',
    ESA_ASSESSMENT_UDL: 'UDL',
    INVERSE_MODELING_ENVIRONMENTAL_EXPOSURE_ASSESSMENT_ACRONYM: 'IMEEA'
  }

  public static readonly RUN_RISK_LISTED_NON_LISTED_VALUES = [
    'Listed',
    'Non Listed'
  ]

  public static readonly MODELS_WITH_APPDATES = [
    Constants.MODELS.SWASH,
    Constants.MODELS.SWAN,
    Constants.MODELS.MACRO_GW,
    Constants.MODELS.PEARL,
    Constants.MODELS.PELMO,
  ]

  public static readonly GRID_EVENTS = {
    FIRST_DATA_RENDERED: 'firstDataRendered',
  };

  public static readonly HUMAN_HEALTH_RISK_ASSESSMENT_GAP = [
    {
      index: 0,
      label: 'Non-Dietary'
    }
  ]

  public static readonly HUMAN_HEALTH_RISK_ASSESSMENT_RUN = {
    NON_DIETARY: 'Non-Dietary',
    DIETARY: 'Dietary',
    OCCUPATIONAL_HANDLER_EXPOSURE: 'Occupational Handler Exposure',
    OCCUPATIONAL_RE_ENTRY_EXPOSURE: 'Occupational Re-entry Exposure',
    RESIDENTIAL_EXPOSURE: 'Residential Exposure',
  };

  public static readonly AFFIRMATIVE_VALUES = {
    YES: 'Yes',
    NO: 'No'
  }

  public static readonly CONCENTRATION_ACTIVE = {
    AE: 'a.e',
    AI: 'a.i'
  }

  public static readonly CONCENTRATION_ACTIVE_TYPE = [
    { name: 'a.e' },
    { name: 'a.i' },
  ]

  public static readonly BBCH_RANGES: string[] = ['00-09', '10-19', '20-39', '40-89'];

  public static readonly SEED_TREATMENTS_T_REX = [
    { key: '1', name: 'corn, all or unspecified' },
    { key: '2', name: 'corn, field' },
    { key: '3', name: 'corn, pop' },
    { key: '4', name: 'corn, sweet' },
    { key: '5', name: 'cotton, all or unspecified' },
    { key: '6', name: 'cotton, pima' },
    { key: '7', name: 'rice' },
    { key: '8', name: 'soybean' },
    { key: '9', name: 'soybean for edamame' },
    { key: '10', name: 'wheat, all or unspecified' },
    { key: '11', name: 'wheat, winter' },
    { key: '12', name: 'wheat, spring' },
    { key: '13', name: 'wheat, hard red winter, dryland' },
    { key: '14', name: 'wheat, hard red winter, irrigated' },
    { key: '15', name: 'wheat, hard red winter, unspecified' },
    { key: '16', name: 'wheat, soft red winter, all' },
    { key: '17', name: 'wheat, hard red spring, dryland' },
    { key: '18', name: 'wheat, hard red spring, irrigated' },
    { key: '19', name: 'wheat, hard red spring, unspecified' },
    { key: '20', name: 'wheat, white' },
    { key: '21', name: 'wheat, durum' },
    { key: '22', name: 'hay or pasture, all or unspecified' },
    { key: '23', name: 'perennial legume hay or pasture' },
    { key: '24', name: 'alfalfa' },
    { key: '25', name: 'birdsfoot trefoil' },
    { key: '26', name: 'lespedeza' },
    { key: '27', name: 'clover, all or unspecified' },
    { key: '28', name: 'clover, alsike' },
    { key: '29', name: 'clover, arrowleaf' },
    { key: '30', name: 'clover, berseem' },
    { key: '31', name: 'clover, crimson' },
    { key: '32', name: 'clover, kura' },
    { key: '33', name: 'clover, red' },
    { key: '34', name: 'clover, rose' },
    { key: '35', name: 'clover, white' },
    { key: '36', name: 'lupine, all or unspecified' },
    { key: '37', name: 'lupine, blue' },
    { key: '38', name: 'lupine, narrow leaf' },
    { key: '39', name: 'lupine, white' },
    { key: '40', name: 'lupine, yellow' },
    { key: '41', name: 'crown vetch' },
    { key: '42', name: 'perennial grass hay or pasture' },
    { key: '43', name: 'festulolium' },
    { key: '44', name: 'Kentucky bluegrass' },
    { key: '45', name: 'orchard grass' },
    { key: '46', name: 'perennial ryegrass' },
    { key: '47', name: 'reed canary grass' },
    { key: '48', name: 'smooth brome' },
    { key: '49', name: 'Bermuda grass' },
    { key: '50', name: 'red fescue' },
    { key: '51', name: 'tall fescue' },
    { key: '52', name: 'timothy' },
    { key: '53', name: 'big bluestem' },
    { key: '54', name: 'eastern gama grass' },
    { key: '55', name: 'indian grass' },
    { key: '56', name: 'switch grass' },
    { key: '57', name: 'annual grass for forage' },
    { key: '58', name: 'annual ryegrass' },
    { key: '59', name: 'pearl millet for forage' },
    { key: '60', name: 'oats spring, for forage' },
    { key: '61', name: 'rye, winter for forage' },
    { key: '62', name: 'sorghum for forage' },
    { key: '63', name: 'sudan grass for forage' },
    { key: '64', name: 'triticale for forage' },
    { key: '65', name: 'winter wheat, for forage' },
    { key: '66', name: 'teff for forage' },
    { key: '67', name: 'asparagus' },
    { key: '68', name: 'barley' },
    { key: '69', name: 'beans, common or dry' },
    { key: '70', name: 'beans, lima' },
    { key: '71', name: 'beans, succulent, or green' },
    { key: '72', name: 'beets, garden' },
    { key: '73', name: 'broccoli' },
    { key: '74', name: 'brussels sprouts' },
    { key: '75', name: 'buckwheat' },
    { key: '76', name: 'cabbage' },
    { key: '77', name: 'carrot' },
    { key: '78', name: 'cauliflower' },
    { key: '79', name: 'cilantro' },
    { key: '80', name: 'collards' },
    { key: '81', name: 'cucumber' },
    { key: '82', name: 'dill weed' },
    { key: '83', name: 'ginseng' },
    { key: '84', name: 'kale for market' },
    { key: '85', name: 'lettuce, all or unspecifed' },
    { key: '86', name: 'lettuce, head' },
    { key: '87', name: 'lettuce, leaf, Bibb, Boston, or Romaine' },
    { key: '88', name: 'millet for feed, all or unspecified' },
    { key: '89', name: 'millet for feed, browntop' },
    { key: '90', name: 'millet for feed, finger' },
    { key: '91', name: 'millet for feed, foxtail' },
    { key: '92', name: 'millet for feed, Japanese' },
    { key: '93', name: 'millet for feed, pearl' },
    { key: '94', name: 'millet for feed, proso' },
    { key: '95', name: 'musk melon, all or unspecified' },
    { key: '96', name: 'canteloupe' },
    { key: '97', name: 'honeydew' },
    { key: '98', name: 'casaba' },
    { key: '99', name: 'Canary, or Juan Canary' },
    { key: '100', name: 'Japanese' },
    { key: '101', name: 'Crenshaw' },
    { key: '102', name: 'mustard greens' },
    { key: '103', name: 'mustard seed' },
    { key: '104', name: 'oats' },
    { key: '105', name: 'onions, all or unspecified' },
    { key: '106', name: 'onions, bulb types except pearl' },
    { key: '107', name: 'onions, pearl' },
    { key: '108', name: 'onions for seed' },
    { key: '109', name: 'onions, bunching (spring or green)' },
    { key: '110', name: 'parsley' },
    { key: '111', name: 'pea, field' },
    { key: '112', name: 'pea, garden' },
    { key: '113', name: 'pea, southern' },
    { key: '114', name: 'peanut' },
    { key: '115', name: 'peppers, all' },
    { key: '116', name: 'peppers, bell' },
    { key: '117', name: 'peppers, hot (paprika, chili, etc)' },
    { key: '118', name: 'potatoes' },
    { key: '119', name: 'pumpkin, all' },
    { key: '120', name: 'pumpkin, large or large vine' },
    { key: '121', name: 'pumpkin, bush or small fruit/vine' },
    { key: '122', name: 'radish' },
    { key: '123', name: 'rape' },
    { key: '124', name: 'canola' },
    { key: '125', name: 'rutabaga' },
    { key: '126', name: 'rye' },
    { key: '127', name: 'safflower' },
    { key: '128', name: 'sesame' },
    { key: '129', name: 'sorghum' },
    { key: '130', name: 'spinach' },
    { key: '131', name: 'squash, all' },
    { key: '132', name: 'squash, summer' },
    { key: '133', name: 'squash, winter' },
    { key: '134', name: 'squash, spagetti' },
    { key: '135', name: 'sugar beet for seed production' },
    { key: '136', name: 'sugar beet' },
    { key: '137', name: 'sunflower' },
    { key: '138', name: 'tomatoes' },
    { key: '139', name: 'turnip green' },
    { key: '140', name: 'turnips' },
    { key: '141', name: 'watermelon' }
  ]

  public static readonly DISABLE_SPECIES_LIST_TERRESTRIAL_VERTEBRATES = [
    'Anas platyrhynchos',
    'Colinus virginianus',
  ]

  public static readonly DEFAULT_VALUE_SPECIES_LIST_TERRESTRIAL_VERTEBRATES = [
    'Rattus sp.',
    'Rattus argentiventer',
    'Rattus exulans',
    'Rattus norvegicus',
    'Rattus rattus',
    'Rattus tiomanicus',
    'Mus musculus',
    'Oryctolagus cuniculus'
  ]

  public static readonly HHRA_PPES_GLOVES_GRANULES = [
    { value: false, name: 'None' },
    { value: true, name: 'Chemical resistant gloves' },
  ];


  public static readonly HHRA_PPES_BODY = [
    { index: 0, name: 'Potential exposure' },
    { index: 1, name: 'Certified protective coverall' },
  ];
  public static readonly HHRA_PPES_RPE = [
    { index: 0, name: 'None' },
    { index: 1, name: 'FP1, P1 and similar' },
    { index: 2, name: 'FP2, P2 and similar' },

  ];

  public static readonly HHRA_PPES_GLOVES_SOLUBLES = [
    { value: true, name: 'Yes' },
    { value: false, name: 'No' },
  ];

  public static readonly HHRA_PPE_SCENARIOS_NAMES: {
    SCENARIO1: "Potential exposure",
    SCENARIO2: "Standard workwear",
    SCENARIO3: "Workwear & gloves for M/L",
    SCENARIO4: "Workwear & gloves for M/L & A"
  }
  public static readonly HHRA_PPES_CLOTHING = [
    { index: 0, name: 'Potential exposure' },
    { index: 1, name: 'Work wear - arms, body and legs covered' },
  ];
  public static readonly HHRA_PPES_HEAD_RESPIRATORY = [
    { index: 0, name: 'None' },
    { index: 1, name: 'Hood' },
    { index: 2, name: 'Hood and face shield' },
    { index: 3, name: 'FP1, P1 and similar' },
    { index: 4, name: 'FP2,P2 and similar' },
  ];
  public static readonly HHRA_EXPOSURE_TYPE = [
    { key: 1, label: 'Dermal' },
    { key: 2, label: 'Inhalation' },
    { key: 3, label: 'Cancer' },
  ];
  public static readonly HHRA_EXPO_TOX_NON_CANCER_POD = [
    { key: 1, label: 'Route-specific' },
    { key: 2, label: 'Oral' },
  ];
  public static readonly HHRA_EXPO_TOX_NON_CANCER_BODY_WEIGHT = [
    { key: 1, label: '69' },
    { key: 2, label: '80' },
    { key: 3, label: '86' },
  ];
  public static readonly HHRA_EXPO_TOX_CANCER_BODY_WEIGHT = [
    { key: 1, label: '69' },
    { key: 2, label: '80' },
    { key: 3, label: '86' },
  ];

  public static readonly HHRA_EXPOSURE_DURATION = [
    { key: 1, label: 'Short-term' },
    { key: 2, label: 'Intermediate-Term' },
    { key: 3, label: 'Long-Term' },
  ];
  public static readonly HHRA_EXPO_TOX_ABSORPTION_SOURCE = [
    { key: 1, label: 'Human study' },
    { key: 2, label: 'Animal study' },
    { key: 3, label: 'Estimated by POD or LOAEL/NOAEL comparison' },
    { key: 4, label: 'In vitro study' },
    { key: 5, label: 'Other' },

  ];
  public static readonly HHRA_PPES_WATER_SOLUBLE_BAG = [
    { value: true, name: 'Yes' },
    { value: false, name: 'No' },
  ];
  public static readonly HHRA_PPES_CABIN = [
    { value: false, name: 'Not relevant' },
  ];
  public static TRANSACTION_TYPE = {
    INSERT: 2,
    DELETE: 3
  };
  public static readonly SOIL_MODELS = [
    Constants.MODELS.EU_SOIL,
    Constants.MODELS.NEU_SOIL,
    Constants.MODELS.GERMAN_SOIL,
  ];

  public static APPLICATION_DATE_FIELDS = {
    BEGIN_WINDOW_JULIAN: 'beginWindowJulian',
    END_WINDOW_JULIAN: 'endWindowJulian',
    BEGIN_WINDOW: 'beginWindow',
    END_WINDOW: 'endWindow',
  }

  public static readonly SUBSTANCE_TYPE = [
    { source: 'Active' },
    { source: 'Metabolite' }
  ]

  public static readonly FORMULATION_TYPES = {
    ORGANIC_BASED: ["CS", "DC", "EC", "EG", "EO", "EW", "OL", "OP", "SE"],
    WATER_BASED: ["DS", "FS", "SC", "SL", "SP", "WG"],
    SOLID: ["DP", "GR"],

    ORGANIC_BASED_VALUES: {
      CONCENTRATE: 25,
      DILUTION: 70
    },
    WATER_BASED_VALUES: {
      CONCENTRATE: 10,
      DILUTION: 50
    },
    SOLID_VALUES: {
      CONCENTRATE: 10,
      DILUTION: 10
    }
  }

  public static readonly BEES_APPLICATION_METHODS = [
    { key: 1, name: 'tree trunk' },
    { key: 2, name: 'foliar spray' },
    { key: 3, name: 'soil application' },
    { key: 4, name: 'seed treatment' },

  ]

  public static readonly BEES_UNITS = [
    { key: 0, name: 'lb a.i./A' },
    { key: 1, name: 'mg a.i./tree' },
    { key: 2, name: 'kg a.i./ha' }
  ]
  public static readonly CALCULATOR_GROUPS = [
    { key: 0, name: 'A' },
    { key: 1, name: 'B' },
    { key: 2, name: 'C' },
    { key: 3, name: 'D' },
    { key: 4, name: 'E' },
    { key: 5, name: 'F' },
    { key: 6, name: 'G' },
    { key: 7, name: 'H' },
    { key: 8, name: 'I' },
    { key: 9, name: 'J' },
    { key: 10, name: 'K' },
  ]

  public static readonly COPY_PROJECT_CREATE_VALUES = {
    COPY: 'Copy',
    REFERENCE: 'Reference'
  }


  public static readonly RISK_ASSESSMENT_ENDPOINTS: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Aquatic',
      model: 'surfacewater'
    },
    {
      index: 1,
      label: 'Terrestrial Vertebrate',
      model: 'T-Rex'
    },
    {
      index: 2,
      label: 'Bees',
      model: 'Bee-REX'
    },
    {
      index: 3,
      label: 'Arthropods',
      model: 'anthropods'
    }
  ]

  public static readonly RISK_ASSESSMENT_GAP: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Terrestrial Vertebrate',
      model: 'T-Rex'
    },
    {
      index: 1,
      label: 'Bees',
      model: 'Bee-REX'
    },
  ]


  public static readonly RUN_RISK_ASSESSMENT_SECTIONS: CustomTabMenuItem[] = [
    {
      index: 0,
      label: 'Aquatic Organisms Effects Characterization',
      model: 'surfacewater',
      visible: false
    },
    {
      index: 1,
      label: 'Terrestrial Vertebrate Effects Characterization',
      model: 'T-Rex',
      visible: false
    },
    {
      index: 2,
      label: 'Terrestrial Invertebrate Effects Characterization',
      model: 'Bee-REX',
      visible: false
    },
  ];

  public static readonly CHILD_LEVEL = {
    PARENT: 'Parent',
    DAUGHTER: "Daughter",
    GRANDDAUGHTER: "Granddaughter",
  }

  public static readonly ALTERNATIVE_NAME_MODELS = [
    Constants.MODELS.SWASH,
    Constants.MODELS.MACRO_GW,
    Constants.MODELS.PELMO,
  ];

  public static HHRA_QC_MESSAGES = {
    NOT_STARTED: 'You must run QC before accessing Run Risk Assessment.',
    FAILED: 'QC failed. Please review the results and try again.',
    CHANGES: 'You must run QC after making changes to the project.',
  }

  public static readonly PERSONAL_PROTECTION_EQUIPMENT_SCHEME_FIELDS_NAMES = {
    NAME: 'name',
  }

  public static readonly PWC_SHOW_COMPONENT_BUTTONS = {
    SCENARIOS: "scenarios",
    OPTIONAL_OUTPUTS: "optionalOutputs",
  }

  public static readonly PWC_OPTIONAL_OUTPUTS_CHECKBOX_UPPER_LEFT = [
    { field: 'pesticideRunoff', label: 'Pesticide runoff (kg/ha)', hasInputs: false },
    { field: 'pesticideEroded', label: 'Pesticide erode (kg/ha)', hasInputs: false },
    { field: 'dailyVolatilizationFromField', label: 'Daily volatilzation from field (kg/ha)', hasInputs: false },
    { field: 'dailyPesticideLeachedAtDepthOf', label: 'Daily pesticide leached (kg/ha) at depth of', hasInputs: true, inputs: [{ field: 'dailyPesticideLeachedAtDepthOfCm', prefix: '', suffix: " cm", col: "col-12" }] },
    { field: 'concentrationInBottomLayer', label: 'Concentration in bottom layer (ppb)', hasInputs: false },
    { field: 'pesticideMassOnFoliage', label: 'Pesticide mass on foliage (kg/ha)', hasInputs: false },
    { field: 'pesticideDecayedInField', label: 'Pesticide decayed in field (kg/ha)', hasInputs: true, inputs: [{ field: 'pesticideDecayedInFieldFromCm', prefix: "from: ", suffix: " cm", col: "col-6" }, { field: 'pesticideDecayedInFieldToCm', prefix: "to: ", suffix: " cm", col: "col-6" }] },
    { field: 'pesticideInEntireSoilProfile', label: 'Pesticide in entire soil profile (kg/ha)', hasInputs: false },
    { field: 'pesticideInPartSoilProfile', label: 'Pesticide in part of soil profile (kg/ha)', hasInputs: true, inputs: [{ field: 'pesticideInPartSoilProfileFromCm', prefix: "from: ", suffix: " cm", col: "col-6" }, { field: 'pesticideInPartSoilProfileToCm', prefix: "to: ", suffix: " cm", col: "col-6" }] },
  ]

  public static readonly PWC_OPTIONAL_OUTPUTS_CHECKBOX_UPPER_RIGHT = [
    { field: 'runoff', label: 'Runoff (cm)', hasInputs: false },
    { field: 'erosion', label: 'Erosion (mg)', hasInputs: false },
    { field: 'precipitation', label: 'Precipitation (cm)', hasInputs: false },
    { field: 'evapotranspiration', label: 'Evapotranspiration', hasInputs: false },
    { field: 'soilWaterInEntireProfile', label: 'Soil water in entire profile (cm)', hasInputs: false },
    { field: 'irrigation', label: 'Irrigation', hasInputs: false },
    { field: 'infiltrationAtLowerBoundary', label: 'Infiltration at lower boundary (cm)', hasInputs: false },
    { field: 'infiltrationAtADepthOf', label: 'Infiltration (cm) at a depth of', hasInputs: true, inputs: [{ field: 'infiltrationAtADepthOfCm', prefix: null, suffix: " cm" }] },
  ]

  public static readonly PWC_OPTIONAL_OUTPUTS_CHECKBOX_BOTTOM_LEFT = [
    { field: 'waterbodyDepthConcentrationBenthicPoreWaterConcentration', label: 'Waterbody depth, concentration, and benthic pore water concentration', hasInputs: false },
  ]

  public static readonly PWC_OPTIONAL_OUTPUTS_CHECKBOX_BOTTOM_RIGHT = [
    { field: 'printSpraydriftValuesInRunStatusFile', label: 'Print spraydrift values in run_status file', hasInputs: false },
  ]

  public static readonly PWC_OPTIONAL_OUTPUTS_MODE_OPTIONS = [
    { key: 'TSER' },
    { key: 'TCUM' },
    { key: 'TAVE' },
    { key: 'TSUM' },
  ]

  public static readonly endpointsExcelKeyOrder = [
    'molecular weight',
    'saturated vapour pressure',
    'solubility in water',
    'air diffusion coefficient',
    'water diffusion coefficient',
    'kom',
    'kfoc',
    'freundlich sorption exponent in soil',
    'desorption rate kd (1/d)',
    'fraction of non-eq.-sites to eq.-sites',
    'half-life in soil',
    'half-life in water',
    'half-life in sediment',
    'half-life in sediment/water system',
    'foliar wash off factor',
    'canopy process option',
    'coefficient for uptake by plant',
    'foliar half-life',
    'pelmo letter',
    'kinetic model',
    'dt90',
    'alpha (-)',
    'beta (-)',
    'g (0 - 1)',
    'k1',
    'k2',
    'tb',
    'maximum occurrence in sediment',
    'maximum occurrence in soil',
    'maximum occurrence in water',
    'maximum occurrence in water/sediment',
    'henry’s constant',
    'heat of henry',
    'measured at (half-life in water)',
    'aqueous photolysis half-life',
    'photolysis reference latitude',
    'hydrolysis half-life',
    'measured at (half-life in soil)',
    'measured at (half-life in sediment)',
    'half-life in sediment/water system'
  ];

  public static readonly endpointsExcelKeyReplacements: { [key: string]: string } = {
    'molecular weight': 'Molecular Weight (g/mol)',
    'solubility in water': 'Solubility In Water (mg/L)',
    'air diffusion coefficient': 'Air Diffusion Coefficient (m²/d)',
    'water diffusion coefficient': 'Water Diffusion Coefficient (m²/d)',
    'kom': 'Kom (L/Kg)',
    'kfoc': 'Kfoc',
    'freundlich sorption exponent in soil': 'Freundlich Sorption Exponent In Soil (-)',
    'desorption rate kd (1/d)': 'Desorption Rate Kd (1/d)',
    'fraction of non-eq.-sites to eq.-sites': 'Fraction Of Non-eq.-sites To Eq.-sites (f_ne)',
    'half-life in soil': 'Half-life In Soil (d)',
    'half-life in water': 'Half-life In Water (d)',
    'half-life in sediment': 'Half-life In Sediment (d)',
    'half-life in sediment/water system': 'Half-life In Sediment/Water System (d)',
    'foliar wash off factor': 'Foliar Wash Off Factor (m-1)',
    'canopy process option': 'Canopy Process Option',
    'coefficient for uptake by plant': 'Coefficient For Uptake By Plant (TSCF) (-)',
    'foliar half-life': 'Foliar Half-life (d)',
    'pelmo letter': 'PELMO Letter',
    'kinetic model': 'Kinetic Model',
    'saturated vapour pressure': 'Saturated Vapour Pressure (Pa)',
    'dt90': 'Dt90 (d)',
    'alpha (-)': 'Alpha (-)',
    'beta (-)': 'Beta (-)',
    'g (0 - 1)': 'G (0 - 1)',
    'k1': 'K1 (d-1)',
    'k2': 'K2 (d-1)',
    'tb': 'Tb',
    'maximum occurrence in sediment': 'Maximum Occurrence In Sediment (%)',
    'maximum occurrence in soil': 'Maximum Occurrence In Soil (%)',
    'maximum occurrence in water': 'Maximum Occurrence In Water (%)',
    'maximum occurrence in water/sediment': 'Maximum Occurrence In Water/sediment (%)',
    'measured at (half-life in water)': 'Measured At (Half-life In Water) (°C)',
    'measured at (half-life in sediment)': 'Measured At (Half-life In Sediment)',
    'measured at (half-life in soil)': 'Measured At (Half-life In Soil)',
    'aqueous photolysis half-life': 'Aqueous Photolysis Half-life (°C)',
    'photolysis reference latitude': 'Photolysis Reference Latitude (ºN)',
    'hydrolysis half-life': 'Hydrolysis Half-life (d)',
    'henry’s constant': 'Henry’s Constant',
    'heat of henry': 'Heat Of Henry (J/mol)'
  };

  public static readonly templateColumnsByCompartment = {
    [Constants.COMPARTMENTS.SOIL]: [
      'molecular weight', 'half-life in soil', 'kinetic model', 'dt90',
      'g (0 - 1)', 'k1', 'k2', 'maximum occurrence in soil', 'tb',
      'alpha (-)', 'beta (-)'
    ],
    [Constants.COMPARTMENTS.SURFACE_WATER]: {
      [Constants.GEOGRAPHYS.EUROPE_UNION]: [
        'molecular weight', 'solubility in water',
        'water diffusion coefficient', 'kom', 'kfoc', 'freundlich sorption exponent in soil',
        'half-life in soil', 'half-life in water', 'half-life in sediment', 'half-life in sediment/water system',
        'foliar wash off factor', 'canopy process option', 'maximum occurrence in soil',
        'maximum occurrence in water/sediment', 'saturated vapour pressure',
        'coefficient for uptake by plant'
      ],
      [Constants.GEOGRAPHYS.USA]: [
        'molecular weight', 'solubility in water', 'saturated vapour pressure', 'kfoc', 'half-life in soil',
        'half-life in water', 'half-life in sediment', 'measured at (half-life in soil)', 'measured at (half-life in sediment)',
        'measured at (half-life in water)', 'aqueous photolysis half-life', 'photolysis reference latitude',
        'hydrolysis half-life', 'foliar half-life', 'henry’s constant', 'heat of henry', 'air diffusion coefficient'
      ],
      [Constants.GEOGRAPHYS.UK]: [
        'molecular weight', 'kfoc', 'half-life in soil', 'half-life in sediment',
        'half-life in water', 'maximum occurrence in soil', 'maximum occurrence in sediment', 'maximum occurrence in water'
      ]
    },
    [Constants.COMPARTMENTS.GROUND_WATER]: [
      'molecular weight', 'solubility in water', 'air diffusion coefficient',
      'water diffusion coefficient', 'kfoc', 'freundlich sorption exponent in soil',
      'desorption rate kd (1/d)', 'fraction of non-eq.-sites to eq.-sites', 'half-life in soil',
      'foliar wash off factor', 'canopy process option', 'coefficient for uptake by plant',
      'foliar half-life', 'saturated vapour pressure', 'pelmo letter'
    ]
  };

  public static readonly APP_ROLES = {
    ADMIN: "BaMS_Administrator",
    EDITOR: "BaMS_Project_Editor",
    READER: "BaMS_Project_Reader",
    ERA_EDITOR: "BaMS_ERA_Editor",
    HHRA_EDITOR: "BaMS_HHRA_Editor"
  }

  public static readonly USER_ROLES = {
    ADMIN: {
      rolesPk: 0,
      role: this.ROLE_ADMIN,
      bitmask: 1,
      comment: '',
      active: '',
      rolesXPermissions: [],
      rolesXDisciplines: [],
    } as Role,
    EDITOR: {
      rolesPk: 0,
      role: this.ROLE_EDITOR,
      bitmask: 2,
      comment: '',
      active: '',
      rolesXPermissions: [],
      rolesXDisciplines: [],
    } as Role,
    READER: {
      rolesPk: 0,
      role: this.ROLE_READER,
      bitmask: 0,
      comment: '',
      active: '',
      rolesXPermissions: [],
      rolesXDisciplines: [],
    } as Role,
    ERA_EDITOR: {
      rolesPk: 0,
      role: this.ROLE_ERA_EDITOR,
      bitmask: 3,
      comment: '',
      active: '',
      rolesXPermissions: [],
      rolesXDisciplines: [],
    } as Role,
    HHRA_EDITOR: {
      rolesPk: 0,
      role: this.ROLE_HHRA_EDITOR,
      bitmask: 4,
      comment: '',
      active: '',
      rolesXPermissions: [],
      rolesXDisciplines: [],
    } as Role,
    LOGOUT:{
      rolesPk: 0,
      role: this.ROLE_LOGOUT,
      bitmask: -1,
      comment: '',
      active: '',
      rolesXPermissions: [],
      rolesXDisciplines: [],
    } as Role
  }

  public static readonly RUN_DETAIL_PWC_VALUE = {
    MODEL_VERSION: "3.0.0",
    MODEL: "PWC",
  }


  public static readonly GAP_EXCEL_KEY_ORDER = [
    Constants.GAP_FIELD_NAMES.APPLICATION_SCHEME,
    Constants.GAP_FIELD_NAMES.INTERCEPTION_PK,
    Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK,
    Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK,
    Constants.GAP_FIELD_NAMES.CROP_SPRAY_PK,
    Constants.GAP_FIELD_NAMES.CROP_PK,
    Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER,
    Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES,
    Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL,
    Constants.GAP_FIELD_NAMES.BBCH,
    Constants.GAP_FIELD_NAMES.CROP_COVERAGE,
    Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION,
    Constants.GAP_FIELD_NAMES.DATE_TYPE,
    Constants.GAP_FIELD_NAMES.CROP_EVENT,
    Constants.GAP_FIELD_NAMES.DAYS_SINCE,
    Constants.GAP_FIELD_NAMES.UK_APPLICATION_DATE,
    Constants.GAP_FIELD_NAMES.APPLICATION_METHOD,
    Constants.GAP_FIELD_NAMES.CHEMICAL_APPLICATION_METHOD,
    Constants.GAP_FIELD_NAMES.TILLAGE,
    Constants.GAP_FIELD_NAMES.PLATEAU_SOIL_DEPTH,
    Constants.GAP_FIELD_NAMES.SIMULATION_YEAR,
    Constants.GAP_FIELD_NAMES.SOIL_DEPTH,
    Constants.GAP_FIELD_NAMES.REPEAT_INTERVAL,
    Constants.GAP_FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_10M,
    Constants.GAP_FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_20M,
  ];


  public static readonly GAP_TEMPLATE_COLUMNS_BY_COMPARTMENT = {
    [Constants.COMPARTMENTS.SOIL]: [
        Constants.GAP_FIELD_NAMES.APPLICATION_SCHEME, Constants.GAP_FIELD_NAMES.INTERCEPTION_PK, Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER,
        Constants.GAP_FIELD_NAMES.BBCH, Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL, Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION, Constants.GAP_FIELD_NAMES.SOIL_DEPTH,
        Constants.GAP_FIELD_NAMES.TILLAGE, Constants.GAP_FIELD_NAMES.PLATEAU_SOIL_DEPTH, Constants.GAP_FIELD_NAMES.SIMULATION_YEAR,
    ],
    [Constants.COMPARTMENTS.SURFACE_WATER]: {
      [Constants.GEOGRAPHYS.EUROPE_UNION]: [
          Constants.GAP_FIELD_NAMES.APPLICATION_SCHEME, Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK, Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK, Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER, Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES,
          Constants.GAP_FIELD_NAMES.BBCH, Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL, Constants.GAP_FIELD_NAMES.CROP_COVERAGE, Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION, Constants.GAP_FIELD_NAMES.APPLICATION_METHOD, Constants.GAP_FIELD_NAMES.CHEMICAL_APPLICATION_METHOD,
          Constants.GAP_FIELD_NAMES.SOIL_DEPTH, Constants.GAP_FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_10M, Constants.GAP_FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_20M,
        ],
      [Constants.GEOGRAPHYS.USA]: [
        Constants.GAP_FIELD_NAMES.APPLICATION_SCHEME,  Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES, Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK, Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER,
        Constants.GAP_FIELD_NAMES.BBCH, Constants.GAP_FIELD_NAMES.CROP_PK, Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL, Constants.GAP_FIELD_NAMES.CROP_COVERAGE, Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION, Constants.GAP_FIELD_NAMES.APPLICATION_METHOD, Constants.GAP_FIELD_NAMES.CHEMICAL_APPLICATION_METHOD,
        Constants.GAP_FIELD_NAMES.SOIL_DEPTH,
      ],
      [Constants.GEOGRAPHYS.UK]: [
          Constants.GAP_FIELD_NAMES.APPLICATION_SCHEME, Constants.GAP_FIELD_NAMES.INTERCEPTION_PK, Constants.GAP_FIELD_NAMES.CROP_SPRAY_PK, Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER, Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL,
          Constants.GAP_FIELD_NAMES.BBCH, Constants.GAP_FIELD_NAMES.CROP_COVERAGE, Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION,
        ]
    },
    [Constants.COMPARTMENTS.GROUND_WATER]: [
      Constants.GAP_FIELD_NAMES.APPLICATION_SCHEME, Constants.GAP_FIELD_NAMES.INTERCEPTION_PK, Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK, Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER, Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES,
      Constants.GAP_FIELD_NAMES.BBCH, Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL, Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION, Constants.GAP_FIELD_NAMES.DATE_TYPE, Constants.GAP_FIELD_NAMES.CROP_EVENT, Constants.GAP_FIELD_NAMES.DAYS_SINCE,
      Constants.GAP_FIELD_NAMES.DATE_TYPE, Constants.GAP_FIELD_NAMES.APPLICATION_METHOD, Constants.GAP_FIELD_NAMES.SOIL_DEPTH, Constants.GAP_FIELD_NAMES.REPEAT_INTERVAL,
    ]
  };


  public static readonly GAP_EXCEL_KEY_REPLACEMENTS: { [key: string]: string } = {
    [Constants.GAP_FIELD_NAMES.APPLICATION_SCHEME]: Constants.COLUMN_HEADER_NAMES.APPLICATION_SCHEME,
    [Constants.GAP_FIELD_NAMES.BBCH] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_BBCH_EARLIEST,
    [Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER] : Constants.COLUMN_HEADER_NAMES.NUMBER_OF_APPLICATIONS,
    [Constants.GAP_FIELD_NAMES.CROP_PK] : Constants.COLUMN_HEADER_NAMES.MODEL_CROP,
    [Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL] : Constants.COLUMN_HEADER_NAMES.APPLICATION_INTERVAL,
    [Constants.GAP_FIELD_NAMES.CROP_COVERAGE] : Constants.COLUMN_HEADER_NAMES.CROP_COVERAGE,
    [Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION] : Constants.COLUMN_HEADER_NAMES.CROP_INTERCEPTION,
    [Constants.GAP_FIELD_NAMES.DATE_TYPE] : Constants.COLUMN_HEADER_NAMES.DATE_TYPE,
    [Constants.GAP_FIELD_NAMES.CROP_EVENT] : Constants.COLUMN_HEADER_NAMES.CROP_EVENT,
    [Constants.GAP_FIELD_NAMES.DAYS_SINCE] : Constants.COLUMN_HEADER_NAMES.DAYS_SINCE,
    [Constants.GAP_FIELD_NAMES.APPLICATION_METHOD] : Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD,
    [Constants.GAP_FIELD_NAMES.CHEMICAL_APPLICATION_METHOD] : Constants.COLUMN_HEADER_NAMES.CHEMICAL_APPLICATION_METHOD,
    [Constants.GAP_FIELD_NAMES.TILLAGE] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_TILLAGE,
    [Constants.GAP_FIELD_NAMES.PLATEAU_SOIL_DEPTH] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_PLANTEAU_SOIL_DEPTH,
    [Constants.GAP_FIELD_NAMES.SIMULATION_YEAR] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_SIMULATION_YEAR,
    [Constants.GAP_FIELD_NAMES.REPEAT_INTERVAL] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_REPEAT_INTERVAL,
    [Constants.GAP_FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_10M] : Constants.COLUMN_HEADER_NAMES.NOZZLE_DRIFT_REDUCTION_10M,
    [Constants.GAP_FIELD_NAMES.NOZZLE_DRIFT_REDUCTION_20M] : Constants.COLUMN_HEADER_NAMES.NOZZLE_DRIFT_REDUCTION_20M,
  };

  public static readonly GAP_CROP_LIST_FIELDS = [
    Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK,
    Constants.GAP_FIELD_NAMES.INTERCEPTION_PK,
    Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK,
    Constants.GAP_FIELD_NAMES.CROP_SPRAY_PK,
  ];

  public static readonly GW_VARIABLE_RATE_VALUES = [
    Constants.GAP_FIELD_NAMES.BBCH,
    Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL,
    Constants.GAP_FIELD_NAMES.DAYS_SINCE,
    Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION
  ];

  public static readonly GW_APPLICATION_FIELDS_CONVERSION: { [key: string]: string } = {
    [Constants.GAP_FIELD_NAMES.BBCH] : Constants.EEA_APPLICATIONS_FIELDS.EARLIEST_BBCH,
    [Constants.GAP_FIELD_NAMES.APPLICATION_INTERVAL] : Constants.EEA_APPLICATIONS_FIELDS.MIN_APPLICATION_INTERVAL,
    [Constants.GAP_FIELD_NAMES.DAYS_SINCE] : Constants.EEA_APPLICATIONS_FIELDS.DAYS_SINCE,
    [Constants.GAP_FIELD_NAMES.CROP_INTERCEPTION] : Constants.EEA_APPLICATIONS_FIELDS.CROP_INTERCEPTION,
  };

  public static readonly COMPARTMENTS_FOR_GAP_EXCEL_EXPORT = [
    Constants.COMPARTMENTS.GROUND_WATER,
    Constants.COMPARTMENTS.SURFACE_WATER,
    Constants.COMPARTMENTS.SOIL,
  ];

  public static readonly STEP1N2_LOCATIONS = [
    "No Run-off/drainage",
    "North Europe, Jun. - Sep.",
    "North Europe, Mar. - May",
    "North Europe, Oct. - Feb.",
    "South Europe, Jun. - Sep.",
    "South Europe, Mar. - May",
    "South Europe, Oct. - Feb.",
  ];

  public static readonly GAP_EXCEL_HEADER_ORDER_BY_COMPARTMENT = {
    [Constants.COMPARTMENTS.SOIL]: [
        Constants.COLUMN_HEADER_NAMES.APPLICATION_SCHEME, Constants.COLUMN_HEADER_NAMES.MODEL_CROP, Constants.COLUMN_HEADER_NAMES.NUMBER_OF_APPLICATIONS,
        Constants.COLUMN_HEADER_NAMES.APPLICATION_INTERVAL, Constants.COLUMN_HEADER_NAMES.RATE,
        Constants.COLUMN_HEADER_NAMES.APP_SCHEME_BBCH_EARLIEST,  Constants.COLUMN_HEADER_NAMES.CROP_INTERCEPTION, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_INITIAL_SOIL_DEPTH,
        Constants.COLUMN_HEADER_NAMES.APP_SCHEME_TILLAGE, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_PLANTEAU_SOIL_DEPTH,  Constants.COLUMN_HEADER_NAMES.APP_SCHEME_SIMULATION_YEAR,
    ],
    [Constants.COMPARTMENTS.SURFACE_WATER]: {
      [Constants.GEOGRAPHYS.EUROPE_UNION]: [
          Constants.COLUMN_HEADER_NAMES.APPLICATION_SCHEME,  Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_APP_DATE, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK, Constants.COLUMN_HEADER_NAMES.NUMBER_OF_APPLICATIONS, Constants.COLUMN_HEADER_NAMES.VARIABLE_RATES_SW,
          Constants.COLUMN_HEADER_NAMES.APPLICATION_INTERVAL, Constants.COLUMN_HEADER_NAMES.RATE, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_BBCH_EARLIEST, Constants.COLUMN_HEADER_NAMES.CROP_COVERAGE, Constants.COLUMN_HEADER_NAMES.CROP_INTERCEPTION, Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD, Constants.COLUMN_HEADER_NAMES.CHEMICAL_APPLICATION_METHOD,
          Constants.COLUMN_HEADER_NAMES.APP_SCHEME_SOIL_DEPTH, Constants.COLUMN_HEADER_NAMES.NOZZLE_DRIFT_REDUCTION_10M, Constants.COLUMN_HEADER_NAMES.NOZZLE_DRIFT_REDUCTION_20M,
        ],
      [Constants.GEOGRAPHYS.USA]: [
        Constants.COLUMN_HEADER_NAMES.APPLICATION_SCHEME
      ],
      [Constants.GEOGRAPHYS.UK]: [
          Constants.COLUMN_HEADER_NAMES.APPLICATION_SCHEME, Constants.COLUMN_HEADER_NAMES.DRIFT_INTERCEPTION, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_SPRAY_PK, Constants.COLUMN_HEADER_NAMES.NUMBER_OF_APPLICATIONS, Constants.COLUMN_HEADER_NAMES.APPLICATION_INTERVAL,
          Constants.COLUMN_HEADER_NAMES.RATE, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_BBCH_EARLIEST, Constants.COLUMN_HEADER_NAMES.CROP_COVERAGE, Constants.COLUMN_HEADER_NAMES.CROP_INTERCEPTION, Constants.COLUMN_HEADER_NAMES.APPLICATION_DATE,
        ]
    },
    [Constants.COMPARTMENTS.GROUND_WATER]: [
      Constants.COLUMN_HEADER_NAMES.APPLICATION_SCHEME, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_APP_DATE, Constants.COLUMN_HEADER_NAMES.NUMBER_OF_APPLICATIONS, Constants.COLUMN_HEADER_NAMES.VARIABLE_RATES,
      Constants.COLUMN_HEADER_NAMES.APPLICATION_INTERVAL, Constants.COLUMN_HEADER_NAMES.RATE, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_BBCH_EARLIEST, Constants.COLUMN_HEADER_NAMES.CROP_INTERCEPTION, Constants.COLUMN_HEADER_NAMES.DATE_TYPE, Constants.COLUMN_HEADER_NAMES.CROP_EVENT, Constants.COLUMN_HEADER_NAMES.DAYS_SINCE,
      Constants.COLUMN_HEADER_NAMES.APPLICATION_METHOD, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_SOIL_DEPTH, Constants.COLUMN_HEADER_NAMES.APP_SCHEME_REPEAT_INTERVAL,
    ]
  };

  public static readonly GAP_EXCEL_KEY_REPLACEMENT_BY_COMPARTMENT = {
    [Constants.COMPARTMENTS.SOIL]: {
      ...Constants.GAP_EXCEL_KEY_REPLACEMENTS,
      [Constants.GAP_FIELD_NAMES.INTERCEPTION_PK] : Constants.COLUMN_HEADER_NAMES.MODEL_CROP,
      [Constants.GAP_FIELD_NAMES.SOIL_DEPTH] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_INITIAL_SOIL_DEPTH,
    },
    [Constants.COMPARTMENTS.SURFACE_WATER]: {
      [Constants.GEOGRAPHYS.EUROPE_UNION]: {
        ...Constants.GAP_EXCEL_KEY_REPLACEMENTS,
        [Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_APP_DATE,
        [Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
        [Constants.GAP_FIELD_NAMES.SOIL_DEPTH] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_SOIL_DEPTH,
        [Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES] : Constants.COLUMN_HEADER_NAMES.VARIABLE_RATES_SW,
      },
      [Constants.GEOGRAPHYS.USA]: {
        ...Constants.GAP_EXCEL_KEY_REPLACEMENTS,
        [Constants.GAP_FIELD_NAMES.INTERCEPTION_PK] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
        [Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
        [Constants.GAP_FIELD_NAMES.CROP_SPRAY_PK] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_SPRAY_PK,
        [Constants.GAP_FIELD_NAMES.SOIL_DEPTH] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_SOIL_DEPTH,
      },
      [Constants.GEOGRAPHYS.UK]: {
        ...Constants.GAP_EXCEL_KEY_REPLACEMENTS,
        [Constants.GAP_FIELD_NAMES.INTERCEPTION_PK] : Constants.COLUMN_HEADER_NAMES.DRIFT_INTERCEPTION,
        [Constants.GAP_FIELD_NAMES.CROP_SPRAY_PK] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_SPRAY_PK,
      },
    },
    [Constants.COMPARTMENTS.GROUND_WATER]: {
      ...Constants.GAP_EXCEL_KEY_REPLACEMENTS,
      [Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK]: Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_APP_DATE,
      [Constants.GAP_FIELD_NAMES.INTERCEPTION_PK] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_CROP_INTERCEPTION_PK,
      [Constants.GAP_FIELD_NAMES.SOIL_DEPTH] : Constants.COLUMN_HEADER_NAMES.APP_SCHEME_SOIL_DEPTH,
      [Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES] : Constants.COLUMN_HEADER_NAMES.VARIABLE_RATES,
    },
  };

  public static readonly GAP_EXCEL_DYNAMIC_FIELDS = [
    Constants.COLUMN_HEADER_NAMES.APPLICATION_INTERVAL,
    Constants.COLUMN_HEADER_NAMES.RATE,
    Constants.COLUMN_HEADER_NAMES.APP_SCHEME_BBCH_EARLIEST,
    Constants.COLUMN_HEADER_NAMES.CROP_INTERCEPTION,
    Constants.COLUMN_HEADER_NAMES.DAYS_SINCE,
  ];

  public static readonly GAP_PWC_DETAIL_FIELDS = [
    'application_method',
    'drift_type'
  ]
}
export const HELPER_NOTES_CONSTANTS = {
  MESSAGES:{
    PROJECT_DESCRIPTION: 'Example: Preliminary risk assessment for Art 43 screening, or refined RA due to expected lower endpoint.',
    ORAL_ABSORPTION: 'Oral absorption is set to a default of 100%; if the actual value is less than 80%, please enter the specific percentage.',
    SATURATED_VAPOUR_PRESSURE: 'This field can remain blank unless user wants to add value for refinement.',
    APPLICATION_SCHEME: 'Info to name GAP lines: Formulation code_crop_work task_application technique (LCHH, HCHH, LCTH, HCTM) (e.g. V5D-1-160 cereals_inspection, LCTM)',
    PPE: 'All boxes should have data entered into them, this includes the default scenarios.'
  } as Record<string,string>
};

