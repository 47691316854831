import { Component, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectStep1n2ResultSummaryColdef } from './eea-output-project-step1n2-result-summary.coldef';
import { EEAOutputProjectSummaryResultSurfaceWaterApiService } from '../../../../../shared/services/out-put-project/eea-output-project-step1n2-summary-result.api.service';
import { RowSelectedEvent } from 'ag-grid-community';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { Constants } from 'src/app/shared/utils/constants';
import { EEAOutputProjectLogicService } from '../../eea-output-project.logic.service';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-eea-output-project-step1n2-result-summary',
  templateUrl: './eea-output-project-step1n2-result-summary.component.html',
  styleUrls: ['./eea-output-project-step1n2-result-summary.component.css']
})
export class EEAOutputProjectStep1n2ResultSummaryComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() selectedModel?:string;
  @Input({required: true}) isProjectOwnershipValid: boolean = false;

  @ViewChild('grid') grid!: GridComponent;

  rowData: any;
  columDef: any;
  loading: boolean = false;

  private logicService = inject(EEAOutputProjectLogicService);

  constructor(private step1n2ResultSummaryColdef: EEAOutputProjectStep1n2ResultSummaryColdef,
              private summaryResultService: EEAOutputProjectSummaryResultSurfaceWaterApiService) {
  }

  ngOnInit(): void {
    this.columDef = this.step1n2ResultSummaryColdef.getColumnsDefinition( this.isProjectOwnershipValid );
    if(this.selectedProject)
      this.getSummaryResult(this.selectedProject.projectPk);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.getSummaryResult(changes['selectedProject'].currentValue.projectPk);
    }
  }

  getSummaryResult(projectPk: number) {
    if (projectPk != 0) {
      this.loading = true;
      this.summaryResultService
        .getSummariesResultsSurfaceWaterByModel(projectPk,this.selectedModel??"")
        .pipe(take(1))
        .subscribe((result: any) => {
           this.rowData = result.Value;
           this.refreshSelectedValuesTimeout();
           this.loading = false;
        });
    }
  }

  public onSelectedRowEvent( event: RowSelectedEvent ){
    this.logicService.setSmeSelection( event, Constants.FEATURE_ACRONYM.ENVIRONMENTAL_EXPOSURE_ASSESSMENT);
  }

  public onSelectedAllChanged( event: any ){
    if( event.source !== 'uiSelectAll' || !this.grid ) return;
    this.logicService.setSmeSelections( this.grid, Constants.FEATURE_ACRONYM.ENVIRONMENTAL_EXPOSURE_ASSESSMENT);
  }

  private refreshSelectedValuesTimeout(): void {
    setTimeout(() => {
      this.refreshSelectedValues();
    }, 75);
  }

  private refreshSelectedValues(): void {
    if( !this.grid ) return;
    this.grid.refreshSelectedValues( 'EeaSmeSelection' );
  }
}