import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs';
import { ModelLibraryService } from 'src/app/administrator/regulatory-models/model-library/model-library.service';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UdlControlledVocabulary } from 'src/app/shared/models/udl-controlled-vocabulary.model';
import { EsaUdlOverlapToolService } from 'src/app/shared/services/esa-udl-overlap-tool.service';

@Component({
    selector: 'app-esa-output-project',
    templateUrl: 'esa-output.component.html',
    styleUrls: ['esa-output.component.css'],
    providers: [MessageService],
})
export class EASOutputProjectComponent implements OnInit, OnChanges {
    @Input() selectedProject?: Project;
    loading: boolean = false;
    models: UdlControlledVocabulary[] = [];
    selectedProjectStatus: number | undefined = 0;
    selectedProjectRunsSaved: boolean | undefined = false;
    destroyRef = inject(DestroyRef)
    selectedModel: UdlControlledVocabulary = {} as UdlControlledVocabulary;
    modelVersion: number = 0;

    constructor(private modelService: ModelLibraryService,
        private messageService: MessageService,
        private selectedProjectApiService: SelectedProjectApiService,
        private esaUdlOverlapToolService: EsaUdlOverlapToolService
    ) { }

    ngOnInit(): void {
        this.selectedProjectStatus = this.selectedProject?.status;
        this.selectedProjectRunsSaved = this.selectedProject?.runsSaved;

        this.initalizeModel();
        this.selectedProjectApiService.selectedProjectStatus.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status?: number) => {
            this.selectedProjectStatus = status;
        });
        this.selectedProjectApiService.selectedProjectRunsSaved.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((runsSaved?: boolean) => {
            this.selectedProjectRunsSaved = runsSaved;
        });
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges['selectedProject']) this.initalizeModel();
    }

    private async initalizeModel() {
        this.esaUdlOverlapToolService.getControlledVocabularyItemsByKey("Model").pipe(takeUntilDestroyed(this.destroyRef)).subscribe(items => {
            this.modelVersion = Number(items[0].metadata ? JSON.parse(items[0].metadata).version : 0)
            this.models = items;
            this.selectedModel = items[0]
        });
    }

    setLoadingState(pState: boolean): void {
        this.loading = pState;
    }

    onSeletedModelChange({ value }: DropdownChangeEvent): void {
        this.selectedModel = value;
    }

    getModelValues() {
        let models = this.models?.map((model) => model.long_value)
        return [...new Set(models)];
    }
}