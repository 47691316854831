import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { OptionalOutputs, OptionalOutputsByRow } from '../../models/pwc/optional-outputs';
import { Constants } from '../../utils/constants';
import { ApplicationScheme } from '../../models/pwc/application-scheme';
import { GridComponent } from '../grid/grid.component';
import { PwcOptionalOutputsLogicService } from './pwc-optional-outputs.logic.service';
import { PwcOptionalOutputsColdef } from './pwc-optional-outputs.coldef';
import { DataSet } from '../../models/echo/data-set';

@Component({
  selector: 'app-pwc-optional-outputs',
  templateUrl: './pwc-optional-outputs.component.html',
  styleUrls: ['./pwc-optional-outputs.component.css']
})
export class PwcOptionalOutputsComponent {
  @Input() row?: DataSet;
  @Input({ required: true }) isProjectOwner!: boolean;

  @Output() onSelectOptionalOutputs = new EventEmitter<OptionalOutputsByRow>;

  @ViewChild('grid') grid!: GridComponent;

  columnDefs: any;
  rowData: any = [];
  upperLeftCheckbox = Constants.PWC_OPTIONAL_OUTPUTS_CHECKBOX_UPPER_LEFT;
  upperRightCheckbox = Constants.PWC_OPTIONAL_OUTPUTS_CHECKBOX_UPPER_RIGHT;
  bottomLeftCheckbox = Constants.PWC_OPTIONAL_OUTPUTS_CHECKBOX_BOTTOM_LEFT;
  bottomRightCheckbox = Constants.PWC_OPTIONAL_OUTPUTS_CHECKBOX_BOTTOM_RIGHT;
  pwcXDataSetOptionalOutput?: any;

  private logicService = inject(PwcOptionalOutputsLogicService);

  constructor(
    private pwcOptionalOutputsColdef: PwcOptionalOutputsColdef,
  ) { }

  public ngOnInit(): void {
    this.getColumnDef();
    if (!this.row) {
      this.setInitialData();
      return
    };
    if (!this.row.pwcXDataSetOptionalOutput) {
      this.setInitialData();
      return
    }
    this.pwcXDataSetOptionalOutput = this.row.pwcXDataSetOptionalOutput;
    this.rowData = this.pwcXDataSetOptionalOutput?.pwcXDataSetOptionalOutputOtherOutputs ?? [];
  }

  getColumnDef() {
    this.columnDefs = this.pwcOptionalOutputsColdef.getColumnsDefinition(this.isProjectOwner);
  }

  public onShow(): void {
  }

  deleteRow(event: any) {
  }

  clearFields() {
    this.setInitialData();
    this.grid?.gridApi.redrawRows();
  }

  public onClose(): void {
    this.pwcXDataSetOptionalOutput.pwcXDataSetOptionalOutputOtherOutputs = [];

    this.grid?.gridApi.forEachNode((node: any) => {
      this.pwcXDataSetOptionalOutput?.pwcXDataSetOptionalOutputOtherOutputs!.push({
        item: node.data.item,
        chem: node.data.chem,
        mode: node.data.mode,
        arg1: node.data.arg1,
        arg2: node.data.arg2,
        multiplier: node.data.multiplier,
      })
    });

    const optionalOutputsByRow: OptionalOutputsByRow = {
      rowId: this.logicService.currentRowId()!,
      optionalOutputs: this.pwcXDataSetOptionalOutput!
    }

    this.onSelectOptionalOutputs.emit(optionalOutputsByRow);

    this.logicService.visible.set(false);
    this.logicService.currentRowId.set(undefined);
    this.pwcXDataSetOptionalOutput = undefined;
    this.row = undefined;
    this.rowData = [];
  }

  setInitialData() {
    this.pwcXDataSetOptionalOutput = <OptionalOutputs>{
      optionalOutputOtherOutputs: [],
      dailyPesticideLeachedAtDepthOfCm: 0,
      pesticideDecayedInFieldFromCm: 0,
      pesticideDecayedInFieldToCm: 0,
      pesticideInPartSoilProfileFromCm: 0,
      pesticideInPartSoilProfileToCm: 0,
      infiltrationAtADepthOfCm: 0,
    };
    this.rowData = [];
  }
}
