<div class="calendar-div calendar-class">
    <p-calendar *ngIf="!disabled"
        appendTo="body"
        [(ngModel)]="dateValue" 
        [dateFormat]= dateFormat 
        [showIcon]="true" 
        inputId="dateformat" 
        [defaultDate]="highlightDate" 
        (onSelect)="onSelect()">        
    </p-calendar>
    <div *ngIf="showCopyToAll" class="hover-zone">
        <div class="copy-wrapper">
            <p-button [style]="{ 'height': '2rem' , 'width' : '2rem', 'background-color' : 'white', 'bottom':'10px'}" 
                    icon="pi pi-clone" 
                    [rounded]="true" 
                    [text]="true"
                    (click)="onCopyAllClick()"
                    pTooltip="Copy To All"
                    tooltipPosition="right"></p-button>    
        </div>                      
    </div>    
</div> 

<span *ngIf="disabled">{{dateValue | date: readOnlyDateFormat}}</span>
