import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ordering-button',
  templateUrl: './ordering-button.component.html',
  styleUrls: ['./ordering-button.component.css']
})

export class OrderingButtonRenderer implements ICellRendererAngularComp {

  public params: any;
  public parent: any;
  public disabled: boolean = false;
  public field: string = '';

  agInit(params: any): void {
    this.params = params;
    this.disabled = this.params.disabled ?? false;
    this.parent = params.context ? params.context : params.instance;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  public onClick(): void {
    this.parent.onClickSetButton({ data: { ...this.params.data, disabled: this.disabled } });
  }

}
